export function TrashIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="23.5" viewBox="0 0 17.5 23.5">
      <g transform="translate(0.75 0.75)">
        <path d="M0,15H12V0H0Z" transform="translate(2 7)" fill="none" stroke="#f06c00" className="icon-stroke"
              strokeMiterlimit="10" strokeWidth="1.5"/>
        <path d="M.05,0V9" transform="translate(4.95 10)" fill="none" stroke="#f06c00" className="icon-stroke"
              strokeMiterlimit="10" strokeWidth="1.5"/>
        <path d="M.05,0V9" transform="translate(7.95 10)" fill="none" stroke="#f06c00" className="icon-stroke"
              strokeMiterlimit="10" strokeWidth="1.5"/>
        <path d="M.05,0V9" transform="translate(10.95 10)" fill="none" stroke="#f06c00" className="icon-stroke"
              strokeMiterlimit="10" strokeWidth="1.5"/>
        <path d="M0,4H16V0H0Z" transform="translate(0 3)" fill="none" stroke="#f06c00" className="icon-stroke"
              strokeMiterlimit="10" strokeWidth="1.5"/>
        <path d="M0,3H6V0H0Z" transform="translate(5)" fill="none" stroke="#f06c00" className="icon-stroke"
              strokeMiterlimit="10" strokeWidth="1.5"/>
      </g>
    </svg>
  )
}
