import {Alert} from 'components/Alert';
import {useState} from 'react';
import {Modal} from 'components/Modal';
import {csvTAndCsLink} from 'Config';
import {useAppContext} from 'context';
import {UserApi} from 'apis';


const rightsText = <>
  <p className="lead">Terms of Access to Janes Products (including Janes data retrieved via functionalities thereon (“Products”))</p>

  <p>Any use by you (“You”) of this Janes Product is subject to the terms and conditions of your organisation’s (“Client”) licensing contract (“Agreement”) with Janes that is deemed to incorporate terms and conditions at <a href={csvTAndCsLink} target="_blank">{csvTAndCsLink}</a>. Janes and/or its third-party providers owns all Janes Product and data contained within such Product.</p>

  <p>Except as specifically authorised in this Agreement, You may not copy, distribute, publish, republish, scan, translate, transfer, sell, license, lease, give, permanently retain, decompile or disassemble, reverse engineer, or otherwise reproduce, disclose or make available to others, or create derivative works from the Product or any portion thereof.</p>

  <p>You are permitted to use the Product for the limited purposes of creating reports and/or presentations (collectively “Work”) for Client’s internal business purposes (“Internal Use”). Except as otherwise specified on Agreement, Products are not licensed for external use. Nothing the aforesaid will operate so as to vest in Client any proprietary rights in any Products or portions of Work in any way derived from Products.</p>

  <p>You agree and understand that the information from the Product used in the Work will be insubstantial and de minimis in nature and will not be primarily a copy of the Product, and (b) not to create Work that uses a portion of the Product that could reasonably be considered substantial.</p>

  <p>You must never use Work: a) to produce a commercial product or service, or b) directly for revenue generating purposes.</p>

  <p>In Work, You will represent Janes or its third party provider as the source of the Product information in the following form: “Includes content supplied by [NAME OF Janes COMPANY or its third party provider]; Copyright © [NAME OF Janes COMPANY or its third party provider], [publication year]. All rights reserved”</p>

  <p>JANES AND ITS THIRD-PARTY PROVIDERS HEREBY DISCLAIM ALL EXPRESS OR IMPLIED WARRANTIES, CONDITIONS AND OTHER TERMS, WHETHER STATUTORY, ARISING FROM COURSE OF DEALING, OR OTHERWISE, INCLUDING WITHOUT LIMITATION TERMS AS TO QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. CLIENT ASSUMES ALL RISK IN USING THE RESULTS OF PRODUCT(S). REPORTS, INFORMATION, COMMENTS AND REMARKS PROVIDED AS PART OF THE CONSULTANCY PRODUCTS IS PROVIDED AS GENERAL MARKET COMMENTARY AND IS FOR INFORMATIONAL PURPOSES ONLY AND DOES NOT SERVE AS INVESTMENT ADVICE, OFFICIAL ENDORSEMENT, OR ANY OTHER PROFESSIONAL ADVICE AND/OR SERVICE BY JANES IN ANY WAY, SHAPE OR FORM.</p>
</>

export function CsvDownloadAcceptTermsModal(
  {
    onClose,
  } :{
    onClose: (triggerDownload: boolean) => void
  }
): JSX.Element {


  const { session, updateUser } = useAppContext()

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<string>()

  function handleAccept() {
    (async () => {
      setSaving(true)

      await new UserApi(session.token).acceptCsvTerms()

      updateUser({accept_csv_terms: true})
      onClose(true)

    })()
      .finally(() => {setSaving(false)})
      .catch(e => {setError(e)});
  }



  return (
    <Modal close={() => onClose(false)} showCloseButton>
      <div className="modal-title mb-4">CSV Download Terms</div>
      <div className="modal-text mb-6">
        {rightsText}
        <br />
        <a href={csvTAndCsLink} target='_blank'>Full Terms and Conditions</a>
      </div>
      {error &&
        <Alert type='danger'>
          <ul className="mb-0"><li>{error}</li></ul>
        </Alert>
      }
      <div className="modal-btns d-flex justify-content-between">
        <button type="button" className="btn btn-primary" onClick={handleAccept} disabled={saving}>Accept</button>
        <button type="button" className="btn btn-dark" onClick={() => onClose(false)}>Close</button>
      </div>
    </Modal>
  )

}
