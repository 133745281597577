import { GlobalSearchApiParams} from './SearchApi';
import {Api, UrlParams} from './Api';
import {Pager, TranFilterQueryReq, Transaction, TransactionsSearchItem} from 'models'
import {SortByOptionsT} from 'models/Transaction';

const URLS = {
  transactions: '/transactions',
  transactionsCsv: '/transactions.csv',
  transaction: '/transactions/:id',
}

export interface TransactionSearchResults {
  transactions: TransactionsSearchItem[],
  pager: Pager,
}


export interface EntityRelatedTranQueryReq extends UrlParams {
  "entity_ids[]"?: number[],

  order: SortByOptionsT
  sort_mode: 'desc'
}

export class TransactionsApi extends Api {
  async search(params: GlobalSearchApiParams | TranFilterQueryReq | EntityRelatedTranQueryReq): Promise<TransactionSearchResults> {
    const resp = (await this.get<TransactionSearchResults>(URLS.transactions, params)).data;

    // await (new Promise(r => setTimeout(r, 1000)));

    return resp;
  }

  downloadSearchLink(params: GlobalSearchApiParams | TranFilterQueryReq | EntityRelatedTranQueryReq): string {
    return this.requestUrl(URLS.transactionsCsv, params)
  }

  async id(id: number): Promise<Transaction> {
    const resp = (await this.get<{transaction: Transaction}>(URLS.transaction.replace(':id', id+''))).data;

    // await (new Promise(r => setTimeout(r, 1000)));

    return resp.transaction;
  }
}
