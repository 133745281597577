import {LoginMode} from 'apps/login/LoginHome';
import {Alert} from 'components';
import {FormEvent, useState} from 'react';
import {UserApi} from 'apis';
import {CaretLeftSmallIcon} from 'components/icons/CaretLeftSmallIcon';
import axios from 'axios';
import {handleAsyncLoader} from 'lib';

export function ForgotPassword(
  {
    setMode,
    email,
    setEmail,
  } : {
    setMode: (val: LoginMode) => void
    email: string
    setEmail: (val: string) => void
  }
): JSX.Element {

  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [completed, setCompleted] = useState(false)

  const doForgotPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    handleAsyncLoader(
      setSubmitting,
      e => setErrors(e?.message ? [e?.message] : []),async () => {
        await new UserApi().requestPasswordReset({'email': email})
        setCompleted(true)
      })()

  }

  return (
    <>
      {!completed &&
      <form onSubmit={doForgotPassword}>
        <div className="form-group">
        </div>


        <div className="form-group">
          <input className="form-control"
                 type="text"
                 placeholder="Email Address"
                 value={email}
                 onChange={e => setEmail(e.target.value)}
          />
        </div>
        {errors && errors.map((error) => (
          <Alert type="danger" dismissable>{error}</Alert>
        ))}
        <div className="form-group d-flex align-items-center">
          <CaretLeftSmallIcon />&nbsp;
          <a href="#" onClick={e => {setMode('login'); e.preventDefault()}}>Login</a>
          <input className="btn btn-primary ml-auto"
                 type="submit"
                 value="Send a password reset"
                 disabled={submitting}
          />
        </div>
      </form>
      }
      {completed &&
        <>
          <Alert type="primary">Your password reset request has been sent.</Alert>
          <div>
            <CaretLeftSmallIcon />&nbsp;
            <a href="#" onClick={e => {setMode('login'); e.preventDefault()}}>Login</a>
          </div>
        </>
      }
    </>



  )
}
