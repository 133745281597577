import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'context'

import { 
  CountryReportApi, 
  CountryReportSearch 
} from 'apis'
import {
  Card,
  DateView,
  LoadingComponent
} from 'components'
import { CaretRightSmallIcon } from 'components/icons/CaretRightSmallIcon'
import { handleAsyncLoader } from 'lib'
import {
  CountryReportsSearchItem,
  ErrorDetail
} from 'models'
import {CountryReportPaths} from 'apps/countryreports/CountryReports';


const SearchParams:CountryReportSearch = {
  page: 1,
  per_page: 5,
  order: 'publication_date',
  sort_mode: 'desc'
}

export const CountryReportsWidget = ({
  className
}: {
  className: string
}): JSX.Element => {

  const { session } = useAppContext()

  const [list, setList] = useState<CountryReportsSearchItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()
  
  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setList((await new CountryReportApi(session.token).search(SearchParams)).country_reports)
    }
  ), [])


  return (
    <Card className={className} 
      title={
        <div className="d-flex flex-column flex-md-row">
          <div className="mb-2 mb-md-0">Country Reports</div>
          <small className="dashboard-link pl-md-2">
            most recently updated
          </small>
          <small className="pl-md-2 ml-md-auto">
            <Link className="dashboard-link" to={CountryReportPaths.list}>all <CaretRightSmallIcon/></Link>
          </small>
        </div>
      }
    >
      <LoadingComponent loading={loading} error={error}>
        <div className="table-responsive-lg">
          <table className="table mt-4">
            <thead className="thead-light">
              <tr>
                <th>Country</th>
                <th>Updated</th>
              </tr>
            </thead>
            <tbody>
              {list.map(({id, slug, title, publication_date}) => (
                <tr key={id}>
                  <td>
                    <Link to={`/countryreports/${slug}`}>{title}</Link>
                  </td>
                  <td>
                    <DateView type="date">
                      {publication_date}
                    </DateView>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </LoadingComponent>
    </Card>
  )
}
