import {
  MainContainer,
  PageContainer
} from "components"

import { AlertsWidget } from './widgets/AlertsWidget'
import { CountryReportsWidget } from './widgets/CountryReportsWidget'
import { EntitiesWidget } from './widgets/EntitiesWidget'
import { TransactionsWidget } from './widgets/TransactionsWidget'

export const Dashboard = ({
  searchBar
}: {
  searchBar: JSX.Element
}) => {
  return (
    <PageContainer navItem="dashboard">
      <MainContainer searchBar={searchBar} >
          <div className="dashboard row mx-1">
            <div className="col-xl-8">
              <TransactionsWidget className="mt-5" />
              <EntitiesWidget className="mt-5" />
              <CountryReportsWidget className="mt-5" />
            </div>
            <div className="col-xl-4">
              <AlertsWidget className="mt-5" />
            </div>
          </div>
      </MainContainer>
    </PageContainer> 
  )
}
