import {CsvDownloadAcceptTermsModal} from 'components/CsvDownloadAcceptTermsModal';
import {useRef, useState} from 'react';
import {useAppContext} from 'context';

export function CsvDownload(
  {
    url,
  } :{
    url: string
  }
) : JSX.Element {

  const { user } = useAppContext()

  const [showTerms, setShowTerms] = useState(false)

  const downloadLink = useRef<HTMLAnchorElement>(null);

  function onCloseTerms(triggerDownload: boolean) {
    setShowTerms(false)
    if (triggerDownload && downloadLink.current) {
      downloadLink.current.click()
    }
  }

  const linkText = "CSV"

  return (
    <> (
      <a href={url} 
        target='_blank' 
        style={{ display: user.accept_csv_terms ? 'inline' : 'none' }} 
        ref={downloadLink}
      >
        {linkText}
      </a>
      {!user.accept_csv_terms &&
        <a href='#' onClick={(e) => { setShowTerms(true); e.preventDefault(); return false}}>
          {linkText}
        </a>
      })
      { showTerms &&
        <CsvDownloadAcceptTermsModal onClose={onCloseTerms} />
      }
    </>
  )
}
