import {MsOption} from 'models';

export type HierarchyOption = [number, string, HierarchyOption[]?];

export const toOption: (i: HierarchyOption) => MsOption = i => ({
  name: i[1],
  value: i[0].toString(),
  subOptions: i[2]?.map(toOption)
});


export const industries: Array<HierarchyOption> = [
  [911,'Aerospace & Civil Aviation', [
    [921,'Aerospace'],
    [931,'Civil Aviation'],
  ]],
  [1371,'Agriculture', [
    [841,'Aquaculture'],
    [851,'Crops'],
    [861,'Livestock'],
  ]],
  [91,'Automobiles & Components'],
  [1221,'Commercial Services & Supplies'],
  [1301,'Construction & Engineering'],
  [231,'Consumer', [
    [341, 'Apparel & Goods'],
    [1071,'Appliances'],
    [1081,'Consumer Services'],
    [1091,'E-commerce'],
    [1101,'Food & Beverage'],
    [1111,'Hotel'],
    [1121,'Retail'],
    [1131,'Tourism'],
    [1391,'Consumer Technology'],
  ]],
  [891,'Defense'],
  [201,'Education', [
    [1141,'Colleges & Universities'],
    [1151,'Edu-tech'],
    [1161,'Vocational'],
  ]],
  [1231,'Electrical Equipment'],
  [281,'Energy', [
    [521, 'Energy Equipment & Services'],
    [531, 'Oil, Gas & Consumable Fuels'],
    [1341,'Petrochemical'],
  ]],
  [1361,'Entertainment & Media', [
    [1271,'Sponsorships & Advertising'],
    [1291,'Sports'],
    [1281,'Television & Movie'],
  ]],
  [321,'Financial', [
    [721,'Banks'],
    [741,'Capital Markets'],
    [751,'Consumer Finance'],
    [761,'Diversified Financial Services'],
    [821,'Fin-tech'],
    [771,'Insurance'],
    [731,'Real Estate'],
  ]],
  [251,'Healthcare', [
    [411,'Healthcare Equipment & Services'],
    [421,'Pharmaceuticals, Biotechnology & Life Sciences'],
  ]],
  [1211,'Heavy Industry'],
  [1311,'Manufacturing'],
  [291,'Materials', [
    [541,'Chemicals'],
    [551,'Construction Materials'],
    [561,'Containers & Packaging'],
    [8,  'Metals & Mining'],
    [581,'Paper & Forest Products'],
  ]],
  [801,'Non-designated'],
  [301,'Power & Utilities', [
    [1471,'Biomass Power'],
    [591, 'Electric Utilities'],
    [1401,'Geothermal Plant'],
    [601, 'Hydrocarbon/Conventional Power Plants'],
    [811, 'Hydropower'],
    [1321,'Nuclear', [
      [871, 'Nuclear Power Plants'],
      [881, 'Nuclear Research'],
      [1331,'Nuclear Fuel'],
    ]],
    [1051,'Solar Power'],
    [1411,'Tidal Power'],
    [631, 'Water & Sewage Utilities'],
    [1061,'Wind Power'],
  ]],
  [901,'Public Safety'],
  [1261,'Rolling Stock Production'],
  [1251,'Shipbuilding'],
  [1421,'Space', [
    [1431,'Ground Stations'],
    [1441,'Launch & Recovery Facilities'],
    [1451,'Satellites'],
    [1461,'Telescopes'],
  ]],
  [1381,'Technology', [
    [441,'Technology Hardware'],
    [431,'Technology Software'],
  ]],
  [461,'Telecommunications', [
    [1181,'Telecommunications Services'],
    [1191,'Wired Telecommunications'],
    [1201,'Wireless Telecommunications'],
  ]],
  [1241,'Trading Companies & Distributors'],
  [1351,'Transportation', [
    [941, 'Airports'],
    [971, 'Bridges'],
    [961, 'Landports'],
    [981, 'Logistics'],
    [991, 'Railways'],
    [1001,'Roads & Highways'],
    [951, 'Seaports'],
  ]],
]


export const transactionTypes: Array<[number, string]> = [
  [1,'Mergers and Acquisitions'],
  [11,'Import/Export'],
  [21,'Foreign Investment'],
  [31,'Humanitarian'],
  [41,'Other'],
  [51,'Currency and Banking Activity'],
  [61,'Loans'],
  [71,'Donation'],
]



export const regions: Array<[number, string]> = [
  [1,'North America'],
  [4,'Oceania/Pacific'],
  [6,'Central Asia'],
  [8,'Caribbean'],
  [9,'Central America'],
  [10,'South America'],
  [11,'Eastern Europe'],
  [12,'Northern Europe'],
  [13,'Southern Europe'],
  [14,'Western Europe'],
  [15,'Southern Asia'],
  [16,'Eastern Asia'],
  [17,'South-Eastern Asia'],
  [18,'Australia and New Zealand'],
  [19,'Melanesia'],
  [20,'Micronesia'],
  [21,'Polynesia'],
  [22,'Eastern Africa'],
  [23,'Middle Africa'],
  [24,'Northern Africa'],
  [25,'Southern Africa'],
  [26,'Western Africa'],
  [31,'Middle East'],
  [51,'Western Asia'],
  [61,'Russia'],
]


export const continents: Array<[number, string]> = [
  [1,'North America'],
  [2,'South America'],
  [3,'Africa'],
  [4,'Europe'],
  [5,'Asia'],
  [6,'Australia'],
]


export const countryRiskDesignations: Array<HierarchyOption> = [
  [1,'U.S. Sanctioned Countries', [
    [11, 'Terrorist-Sponsoring States (U.S.)'],
    [31, 'Broad Trade Embargo (U.S.)'],
    [211,'Arms Embargo; Select Export Ban (U.S.)'],
    [231,'Targeted Sanctions (U.S.)'],
  ]],
  [51,'EU Sanctioned Countries', [
    [81, 'Broad Trade Embargo (EU)'],
    [221,'Arms Embargo; Ban on Select Exports (EU)'],
    [241,'Targeted Sanctions (EU)'],
  ]],
  [191,'Japan Sanctioned Countries', [
    [251,'Broad Trade Embargo (Japan)'],
    [261,'Arms Embargo; Select Export Ban (Japan)'],
    [271,'Targeted Sanctions (Japan)'],
  ]],
  [291,'Conflict Risk'],
  [311,'Corruption Risk'],
  [321,'Political Freedom Risk'],
  [331,'Economic Freedom Risk'],
  [341,'Religious Freedom Risk', [
    [361,'State Dept.: Countries of Concern'],
    [381,'USCIRF: Countries of Concern (Tier 1)'],
    [391,'USCIRF: Countries of Concern (Tier 2)'],
  ]],
  [351,'Press Freedom Risk'],
  [401,'Low Creditworthiness', [
    [451,'Credit Rating: Low'],
    [461,'Credit Rating: Very Low'],
  ]],
  [421,'Credit Rating: Excellent'],
  [431,'Credit Rating: Good'],
  [441,'Credit Rating: Medium'],
]


export const entityRiskDesignations: Array<HierarchyOption> = [
  [5581,'National Security Concerns', [
    [2001,'Potential Dual-Use, Military Application of Commercial Project', [
      [3941,'Raised Concern Over the Potential Dual-Use Application of Commercial Project  '],
      [4151,'Allegation of Actual Military Use of Commercial Project  '],
    ]],
    [1251,'Emerging Technology with Dual-use, Civil-Military Fusion Potential', [
      [3581,'Emerging Technology with Dual-Use, Civil-Military Fusion Potential'],
      [3621,'Legal Involvement in Foreign Acquisitions of Emerging Technology  '],
      [3691,'Business Involvement in Emerging Technology (Artificial Intelligence)'],
      [3741,'Emerging Technology Company Alleged to Have Government Ties  '],
      [3841,'Business Involvement in Emerging Technology (Imaging)'],
      [3871,'Business Involvement in Emerging Technology (Satellite)  '],
      [3881,'Business Involvement in Emerging Technology (Materials)'],
      [3911,'Business Involvement in Emerging Technology (Drones)'],
      [3921,'Concern Expressed Over Targeting Foreign Emerging Technology'],
      [3971,'Business Involvement in Emerging Technology (Autonomous Technology) '],
      [3981,'Business Involvement in Emerging Technology (Data Collection)'],
      [3991,'Business Involvement in Emerging Technology (Machine Learning) '],
      [4001,'Business Involvement in Emerging Technology (Body Recognition) '],
      [4031,'Business Involvement in Emerging Technology (Facial Recognition)'],
      [4041,'Business Involvement in Emerging Technology (Semiconductors)'],
      [4051,'Involved in Foreign M&A Activity Targeting Technology Companies '],
      [4061,'Business Involvement in Emerging Technology (Biotech)'],
      [4101,'Business Involvement in Emerging Technology (Investment)'],
      [4211,'Business Involvement in Emerging Technology (Nuclear) '],
      [4231,'Business Involvement in Emerging Technology (5G)'],
      [4241,'Evidence of Company Targeting Foreign Emerging Technology  '],
      [4331,'Accused of Illegally Acquiring Emerging Technologies'],
      [4491,'Business Involvement in Emerging Technology (Thermal Imagery)'],
      [4531,'Business Involvement in Emerging Technology (AI)'],
    ]],
    [2581,'Had Transaction Blocked on National Security Grounds', [
      [4161,'Had Transaction Blocked on National Security Grounds'],
    ]],
    [1291,'Company Has Alleged Ties to Government/Military', [
      [3591,'Has Ties to Government or Military Officials'],
      [4411,'Company Has Alleged Ties to Government/Military'],
    ]],
    [3521,'Officially Listed for Sanctions, Export Controls or Debarment', [
      [3531,'Debarred by the World Bank'],
      [3551,'Sanctioned by the United States (China)'],
      [3571,'Sanctioned by the United States (Russia)'],
      [3651,'Accused of Violating or Circumventing Sanctions'],
      [4421,'Sanctioned by the United States (China) -- Lifted'],
      [4521,'Sanctioned by the US Treasury'],
      [4571,'Sanctioned by the United States (Ukraine)'],
      [5551,'Sanctioned by the United States (Russia) -- Lifted'],
      [5561,'Listed on the Entity List by the Bureau of Industry and Security'],
      [5811,'Debarred by the World Bank -- Lifted'],
      [5841,'Designated by US Department of Defense as a "Communist Chinese Military Company"'],
      [5861,'Designated by US Department of Treasury as Supporting China\'s Military-Industrial Base'],
    ]],
    [1161,'Military Contractor or Private Security Contractor', [
      [3541,'Military Contractor (Domestic Sales)'],
      [3611,'Military Contractor (Foreign Sales)'],
      [4581,'Miltary Contractor, Private Security Contractor '],
    ]],
    [2211,'Operating in Disputed Parts of the South China Sea', [
      [4081,'Involved in Equipping Artificial Islands in South China Sea'],
      [4091,'Potential Militarization of Artificial Islands in South China Sea'],
      [4111,'Involved in the Building of Artificial Islands in South China Sea'],
      [4191,'Civilian and Economic Activity in Disputed Parts of the South China Sea'],
      [4261,'Linked to Offshore Vessels Pursuing Energy Assets'],
    ]],
    [2831,'Operating in Disputed Parts of the East China Sea', [
      [4251,'Linked to Offshore Vessels Pursuing Energy Assets'],
    ]],
    [2541,'Company Activities Raise Propaganda Concerns', [
      [4141,'Raises Propaganda Concern '],
      [4441,'Accused of Advancing State Propaganda'],
    ]],
    [1671,'Concern of Creating Strategic Economic Vulnerability', [
      [3791,'Raised Concern for Creating Strategic Economic Vulnerability'],
      [4121,'Concern Over the Acquisition of Strategic Assets or Critical Infrastructure'],
      [4181,'Economic Projects with Potential Military Applications  '],
    ]],
    [1211,'Suspected of Engaging in Criminal Activity or Espionage', [
      [3561,'Criminal Activity (Economic Espionage)'],
      [3631,'Criminal Activity (Financial Crime) '],
      [3661,'Suspected of Criminal Activity (Financial Crime) '],
      [3761,'Criminal Activity (Environmental Abuses) '],
      [3771,'Suspected of Criminal Activity (Corruption)  '],
      [3781,'Suspected of Criminal Activity or Espionage'],
      [3961,'Suspected of Criminal Activity (Labor Abuses) '],
      [4201,'Criminal Activity (Nuclear) '],
      [4271,'Criminal Activity (Labor Abuses) '],
      [4371,'Suspected of Criminal Activity (Economic Espionage) '],
      [4451,'Criminal Activity (Trafficking) '],
      [4461,'Criminal Activity (Fraud) '],
      [4541,'Criminal Activity with Syria'],
      [4551,'Criminal Activity (Corruption)'],
      [5071,'Suspected of Criminal Activity (Environmental)'],
    ]],
    [5641,'Company Has Prompted Cybersecurity Concerns', [
      [5651,'Allegations of Creating Cyber Vulnerability (China)'],
      [5661,'Allegations of Creating Cyber Vulnerability (Russia)'],
      [5671,'Allegations of Disinformation and Election Interference (Russia)'],
      [5681,'Allegations of Hacking (China)'],
      [5691,'Allegations of Hacking (North Korea)'],
      [5701,'Allegations or Concern of Cyber-Espionage (China)'],
      [5711,'Allegations or Concern of Cyber-Espionage (Russia)'],
      [5721,'Data Privacy Concerns'],
      [5731,'Linked to Cyberattacks (China)'],
      [5741,'Linked to Cyberattacks (North Korea)'],
      [5751,'Linked to Cyberattacks (Russia)'],
      [5761,'Political Concern About Company Creating Cybersecurity Vulnerabilities'],
      [5771,'Providing Cyber Support for North Korea'],
      [5781,'Concern Over Targeting Foreign Emerging Technology'],
      [5791,'Ties to the Chinese Military'],
    ]],
    [5851,'Operating in Disputed Territory'],
  ]],
  [5591,'Suspected of Corruption, Non-Transparency', [
    [1411,'Suspected of Corrupt, Non-Transparent Business Dealings', [
      [3641,'Engaged in Non-Transparent Business Practices'],
      [3721,'Suspicion of Corrupt Business Practices'],
      [3811,'Engaged in Corrupt Business Practices  '],
      [4511,'Suspected of Corruption, Non-Transparency'],
    ]],
  ]],

  [5601,'Human Rights Violations', [
    [1541,'Company Has Alleged Links to Human Rights Violations', [
      [3801,'Implicated in the Forced Displacement of Local Populations'],
      [5881,'Raised Human Rights Concerns Due to Support of Oppressive Governments'],
      [5891,'Linked to Oppression of Ethnic Minorities within Chinese Territories'],
    ]],
  ]],

  [5611,'Environmental Concerns', [
    [4591,'Accused of Causing Environmental Damage (Foreign Projects)', [
      [4601,'Environmental Damage: Water'],
      [4771,'Environmental Damage: Pollution '],
      [4791,'Environmental Damage: Biodiversity '],
      [4881,'Displacement of Persons'],
      [4891,'Accused of Disrupting or Depriving Local Populations of Livelihoods'],
      [4901,'Public Health Issues Stemming From Environmental Damage'],
      [4931,'Caused Significant or Irreparable Damage to Local Environment '],
      [5021,'Environmental Damage: Air'],
      [5531,'Environmental Damage: Local Environment'],
    ]],
    [4631,'Alleged Violations of Environmental Laws and Regulations', [
      [4641,'Noncompliance with Local Environmental Laws/Regulations'],
      [5241,'Inconsistencies With or Lack of Environmental Impact Assessment'],
      [4941,'Failure to Follow Designated Permits or Environmental Impact Assessment'],
      [5031,'Legal Action Pertaining to Environmental Concerns or Damage'],
      [4671,'Imposition on Preserved or Protected Area '],
      [4971,'Alleged Criminal Activity'],
    ]],
    [4651,'Engaged in Projects that Raised Environmental Concerns', [
      [4681,'Plans for Project Would Cause Significant Environmental Damages'],
      [4751,'Pursuing Project in Area with Pre-Existing Environmental Damage'],
      [4961,'Concerns Expressed about Environmental Impact'],
      [5081,'Concern Over Environmental Hazard to Local Communities'],
      [5221,'Cancelled Due to Environmental Concerns'],
      [5001,'Project in Area Disruptive to Critical Habitat/Breeding Ground'],
      [5011,'Contributed to Environmental Catastrophe'],
    ]],
    [4691,'Local Opposition to Company for Environmental Reasons', [
      [4701,'Opposition from Environmental Groups '],
      [4781,'Company Project Led to Environmental Protests'],
      [4811,'Opposition from Government Due to Environmental Concerns'],
      [4821,'Local Opposition Due to Environmental Concerns'],
    ]],
  ]],

  [5621,'Labor Rights Concerns', [
    [5351,'Company is Involved in Alleged Labor Rights Violations', [
      [5361,'Accusations of Workforce Discrimination'],
      [5371,'Accused of Having a Dangerous Work Environment'],
      [5381,'Concerns Over the Alleged Non-Payment of Workers'],
      [5391,'Accused of Worker Intimidation or Threats of Violence'],
      [5401,'Allegations of Violence in the Workplace'],
      [5411,'Poor Worksite Conditions Resulting in Death or Injury'],
      [5421,'Concerns Regarding Wages, Visas, or Employment Status'],
      [5431,'Onsite Clashes, Protests, or Riots Due to Poor Labor Conditions'],
      [5441,'Allegations of Excessive Reliance on Chinese Labor'],
      [5451,'Accused of Violating Local Labor Laws and Regulations'],
      [5461,'Accused of Violating Worker Rights or Contracts'],
      [5471,'Accused of Creating a Hostile Work Environment'],
    ]],
  ]],

  [5631,'Safety and Quality Control Problems', [
    [3701,' Company is Accused of Quality Control or Safety Problems', [
      [5261,'Quality Concerns Expressed Over Delivered Product'],
      [5271,'Accused of Work Showing Significant Defects or Flaws'],
      [5281,'Accused of Failure to Meet Tender Requirements'],
      [5301,'Accused of Lacking of Proper Licensing or Experience'],
      [5311,'Accused of Suffering Catastrophic Project Failure'],
      [5321,'Use of Sub-Standard Materials on a Construction Site'],
      [5331,'Accused of Failing to Meet Safety Standards'],
      [5341,'Failure to Meet Worksite Safety Requirements'],
    ]],
  ]],
]


export const personRiskDesignations: Array<HierarchyOption> = [
  [1,'All U.S. Sanctioned Persons', [
    [31, 'Russia Sanctions, Ukraine (U.S.)'],
    [61, 'Magnitsky Act Sanctions (U.S.)'],
    [71, 'Violator of the Arms Export Control Act (AECA)'],
    [81, 'Export Administration Regulations (EAR)'],
    [91, 'Member of Transnational Criminal Organization'],
    [101,'Iran Sanctions Specially Designated National (SDN)'],
    [111,'Linked to Syrian Military SDN'],
    [121,'Weapons of Mass Destruction Proliferators Sanctions Regulations (NPWMD)'],
    [131,'Treasury Sanctions Persons for Role in Undermining Zimbabwean Democracy'],
    [141,'Prolific Drug Traffickers'],
    [151,'OFAC BALKAN SDN'],
  ]],
]


export const taggedTransactions: Array<[number, string]> = [
  [371,'Accused of Using Excessive Imported Chinese Labor or Replacing Local Workers with Chinese'],
  [121,'Bribery & Corruption Allegations'],
  [71,'Local Protest/Opposition to Business Operation'],
  [51,'Safety Problems'],
  [41,'Quality Control Problems'],
  [681,'Delayed Transaction'],
  [561,'Dispute with Business Partner'],
  [591,'Accused of Failing to Adhere to Local Laws'],
  [341,'Concern Raised About Cyber Security'],
  [431,'Transaction in Disputed or Breakaway Territory, Crimea'],
  [291,'Allegations of Coercion Tactics to Secure Contracts'],
  [891,'Suspension of Transaction by Regulators'],
  [771,'Transaction in Disputed or Breakaway Territory, South Ossetia'],
  [421,'Transaction in Disputed or Breakaway Territory, South China Sea'],
  [411,'Accused of Causing Significant Environmental Damage'],
  [61,'Labor Problems'],
  [101,'Dispute over Failure to Perform or Meet Contractual Agreements'],
  [141,'Problems with Deteriorating Local Security Situation'],
  [21,'Local Concern Over Dual Use Military Potential'],
  [211,'Problems with Non-Payment'],
  [501,'Environmental Concerns'],
  [671,'Canceled Transaction'],
  [111,'Local Political/Regulatory Opposition'],
  [171,'Controversy over Non-Competitive Tender Process'],
  [811,'Transaction in Disputed or Breakaway Territory'],
  [441,'Transaction in Disputed or Breakaway Territory, Kashmir'],
  [881,'Allegations of Coercion Tactics to Maintain Contracts'],
  [151,'Unexpected Cost Increases'],
  [261,'Allegation of Lack of Project Transparency'],
]
