import { AlertsSearchItem } from 'models'
import { HighlightText } from 'components'
import { SearchCityBar } from 'apps/search/components/SearchCityBar'


export function SearchAlertResults(
  {
    results,
    onSelect
  }: {
    results: AlertsSearchItem[],
    onSelect: (id: number, title: string) => void,
  }
): JSX.Element {
  return (
    <div className="tab-pane fade show active pb-6 px-4" role="tabpanel">
      <div className="table-responsive">
        <table className="table table-large table-alignment-top">
          <colgroup>
            <col style={{width: '320px'}}>
            </col>
          </colgroup>
          <tbody>
          {results.map(r => {
            return (
              <tr key={r.id}
                  onClick={() => onSelect(+r.id, r.title)}
              >
                <td className="col-name">{r.title}</td>
                <td>
                  {r?.cities && <div className="info-box dot dot-left my-2">
                    <SearchCityBar
                      cities={r.cities.map(c => (
                        {
                          cityName: c.city.display_name,
                          to: '/transactions/' + r.id
                        }))}
                    />
                  </div>}
                  <HighlightText text={r.comment} />
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
