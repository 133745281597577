import { Link } from 'react-router-dom'
import { Alert } from 'models'
import {AlertPaths} from 'apps/alerts/Alerts';

export const AlertEntitiesList = ({
  alert,
  setEntityId
}: {
  alert: Alert
  setEntityId: (id: number) => void
}): JSX.Element => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Name</th>
            <th>Entity Type</th>
            <th>Ownership Type</th>
            <th>Country Of Domicile</th>
          </tr>
        </thead>
        <tbody>
          {alert.entities.map(({entity}) => (
            <tr key={entity.id}>
              <td>
                <a role="button" className="buttonRole"
                  onClick={() => setEntityId(entity.id)}
                >
                  {entity.name}
                </a>
              </td>
              <td>{entity.entity_type?.name}</td>
              <td>
                {entity.ownership_types.map(
                  ({ownership_type}) => ownership_type.name
                ).join(", ")}
              </td>
              <td>
                <Link to={`${AlertPaths.list}?country_ids[]=${entity.country.id}`}>
                  {entity.country.name}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
