import {EntityRiskVm, ErtFilterFormVm, ErtFilterFormQs} from 'models';




export function countErtFilters(filter: ErtFilterFormQs) : number {
  return (filter.public ? 1 : 0)
    + (filter.exclude_distant_relatives ? 1 : 0)
    + (filter.exclude_owners ? 1 : 0)
    + (filter.exclude_subsidiaries ? 1 : 0)
    + filter.risk_ids.length
}

function countSelectedRisks(risks: EntityRiskVm[] | undefined): number {
  if (typeof risks === 'undefined')
    return 0
  return risks.filter(r => r.selected).length
    + risks.map(r => countSelectedRisks(r.children))
      .reduce((a, b) => a + b, 0)
}

export function countErtFiltersOnForm(filter: ErtFilterFormVm): number {
  return (filter.public ? 1 : 0)
    + (filter.exclude_distant_relatives ? 1 : 0)
    + (filter.exclude_owners ? 1 : 0)
    + (filter.exclude_subsidiaries ? 1 : 0)
    + countSelectedRisks(filter.risks)
}
