import {
  TranFilterQueryReq,
  TranFilterSavedItemReq,
  TranFilterSavedItemRes,
  TransactionFilterForm,
  TranFilterSavedItemResTransaction,
  TranFilterSavedItemResGroup,
} from 'models';
import {
  emptyToAry,
  emptyToUndef,
  parseBool,
  parseDate,
  parseEnum,
  parseInteger,
  parseModifiedWithin,
  parsePendingCancelled,
  undefToEmpty,
  parseStr,
  toInt, toInts
} from 'models/converters/ConverterUtil';
import {ClusterByOptions, ScaleOptions, SearchGroupOptions} from 'models';
import {SortByOptions, TranRelatedFilterQueryReq} from 'models/Transaction';

export function qsToTranForm(query: URLSearchParams): TransactionFilterForm {
  return {
    companyIds: emptyToAry(query.getAll('companyIds')),
    includeSubsidiaries: parseBool(query.get('includeSubsidiaries')) ? true : undefined,
    includeOwners: parseBool(query.get('includeOwners')) ? true : undefined,
    industryIds: emptyToAry(query.getAll('industryIds')),

    transactionTypeIds: emptyToAry(query.getAll('transactionTypeIds')),
    minTranValue: parseInteger(query.get('minTranValue')),
    maxTranValue: parseInteger(query.get('maxTranValue')),

    cityIds: emptyToAry(query.getAll('cityIds')),
    countryIds: emptyToAry(query.getAll('countryIds')),
    regionIds: emptyToAry(query.getAll('regionIds')),
    continentIds: emptyToAry(query.getAll('continentIds')),

    keywords: parseStr(query.get('keywords') || query.get('text')), // global search uses 'text'

    countryRiskDesignationIds: emptyToAry(query.getAll('countryRiskDesignationIds')),
    entityRiskDesignationIds: emptyToAry(query.getAll('entityRiskDesignationIds')),
    personRiskDesignationIds: emptyToAry(query.getAll('personRiskDesignationIds')),
    taggedTransactionsIds: emptyToAry(query.getAll('taggedTransactionsIds')),

    pending: parsePendingCancelled(query.get('pending')),
    cancelled: parsePendingCancelled(query.get('cancelled')),
    startDate: parseDate(query.get('startDate')),
    endDate: parseDate(query.get('endDate')),
    dateRangeActive: query.get('dateRangeActive') === 'starting' ? 'starting' : '',
    modifiedWithin: parseModifiedWithin(query.get('modifiedWithin')),

    year: parseInteger(query.get('year')),
    clusterBy: parseEnum(query.get('clusterBy'), ClusterByOptions, 'country'),
    scale: parseEnum(query.get('scale'),  ScaleOptions),

    searchGroup: parseEnum(query.get('searchGroup'), SearchGroupOptions),

    page: emptyToUndef(query.get('page') ?? ''),
    per_page: emptyToUndef(query.get('per_page') ?? ''),

    filterId: toInt(parseInteger(query.get('filterId'))),
    filterName: parseStr(query.get('filterName')),

    order: parseEnum(query.get('order'), SortByOptions, SortByOptions[0]),
    sortMode: query.get('sortMode') === 'desc' ? 'desc' : 'desc',
  }
}




// Used when querying with the filter
export function tranToQueryReq(filter: TransactionFilterForm): TranFilterQueryReq {
  return  {
    'entity_ids[]': emptyToUndef(toInts(filter.companyIds)),
    'subsidiaries': filter.includeSubsidiaries ? '1' : undefined,
    'owners': filter.includeOwners ? '1' : undefined,
    'industry_ids[]': emptyToUndef(toInts(filter.industryIds)),

    'transaction_type_ids[]': emptyToUndef(toInts(filter.transactionTypeIds)),
    'project_value_gte': toInt(filter.minTranValue),
    'project_value_lte': toInt(filter.maxTranValue),

    'city_ids[]': emptyToUndef(toInts(filter.cityIds)),
    'country_ids[]': emptyToUndef(toInts(filter.countryIds)),
    'region_ids[]': emptyToUndef(toInts(filter.regionIds)),
    'continent_ids[]': emptyToUndef(toInts(filter.continentIds)),

    'keywords': emptyToUndef(filter.keywords),

    'country_risk_ids[]': emptyToUndef(toInts(filter.countryRiskDesignationIds)),
    'entity_risk_ids[]': emptyToUndef(toInts(filter.entityRiskDesignationIds)),
    'person_risk_ids[]': emptyToUndef(toInts(filter.personRiskDesignationIds)),
    'transaction_tag_ids[]': emptyToUndef(toInts(filter.taggedTransactionsIds)),

    pending: emptyToUndef(filter.pending),
    cancelled: emptyToUndef(filter.cancelled),
    'start_date': emptyToUndef(filter.startDate),
    'end_date': emptyToUndef(filter.endDate),
    'active': emptyToUndef(filter.dateRangeActive),
    'updated_days': toInt(filter.modifiedWithin),

    'search_group': emptyToUndef(filter.searchGroup),

    page: toInt(filter.page ?? ''),
    per_page: toInt(filter.per_page ?? ''),

    'current_for_year': toInt(filter.year),
    'group[group]': filter.clusterBy,
    'group[scale]': filter.scale,

    order: filter.order,
    sort_mode: filter.sortMode,
  }
}


// Used when querying with the filter
export function tranRelatedToQueryReq(filter: TransactionFilterForm): TranRelatedFilterQueryReq {
  return  {
    'entity_ids[]': emptyToUndef(toInts(filter.companyIds)),
    'subsidiaries': filter.includeSubsidiaries ? '1' : undefined,
    'owners': filter.includeOwners ? '1' : undefined,
    'industry_ids[]': emptyToUndef(toInts(filter.industryIds)),

    'transaction_type_ids[]': emptyToUndef(toInts(filter.transactionTypeIds)),
    'project_value_gte': toInt(filter.minTranValue),
    'project_value_lte': toInt(filter.maxTranValue),

    'city_ids[]': emptyToUndef(toInts(filter.cityIds)),
    'country_ids[]': emptyToUndef(toInts(filter.countryIds)),
    'region_ids[]': emptyToUndef(toInts(filter.regionIds)),
    'continent_ids[]': emptyToUndef(toInts(filter.continentIds)),

    'keywords': emptyToUndef(filter.keywords),

    'country_risk_ids[]': emptyToUndef(toInts(filter.countryRiskDesignationIds)),
    'entity_risk_ids[]': emptyToUndef(toInts(filter.entityRiskDesignationIds)),
    'person_risk_ids[]': emptyToUndef(toInts(filter.personRiskDesignationIds)),
    'transaction_tag_ids[]': emptyToUndef(toInts(filter.taggedTransactionsIds)),

    pending: emptyToUndef(filter.pending),
    cancelled: emptyToUndef(filter.cancelled),
    'start_date': emptyToUndef(filter.startDate),
    'end_date': emptyToUndef(filter.endDate),
    'active': emptyToUndef(filter.dateRangeActive),
    'updated_days': toInt(filter.modifiedWithin),

    'search_group': emptyToUndef(filter.searchGroup),

    page: toInt(filter.page ?? ''),
    per_page: toInt(filter.per_page ?? ''),

    'current_for_year': toInt(filter.year),
    'group[group]': filter.clusterBy,
    'group[scale]': filter.scale,
  }
}


// Used when reading a saved filter to edit it
export function tranSavedItemResToForm(filterId: number, filterName: string, transactions: TranFilterSavedItemResTransaction, group: TranFilterSavedItemResGroup): TransactionFilterForm {
  return {
    companyIds: transactions?.entity_ids?.map(e => `${e}`) ?? [],
    includeSubsidiaries: parseBool(transactions?.subsidiaries) ? true : undefined,
    includeOwners: parseBool(transactions?.owners) ? true : undefined,
    industryIds: transactions?.industry_ids?.map(e => `${e}`) ?? [],

    transactionTypeIds: transactions?.transaction_type_ids?.map(e => `${e}`) ?? [],
    minTranValue: parseInteger(transactions?.project_value_gte),
    maxTranValue: parseInteger(transactions?.project_value_lte),

    cityIds: transactions?.city_ids?.map(e => `${e}`) ?? [],
    countryIds: transactions?.country_ids?.map(e => `${e}`) ?? [],
    regionIds: transactions?.region_ids?.map(e => `${e}`) ?? [],
    continentIds: transactions?.continent_ids?.map(e => `${e}`) ?? [],

    keywords: parseStr(transactions?.keywords),

    countryRiskDesignationIds: transactions?.country_risk_ids?.map(e => `${e}`) ?? [],
    entityRiskDesignationIds: transactions?.entity_risk_ids?.map(e => `${e}`) ?? [],
    personRiskDesignationIds: transactions?.person_risk_ids?.map(e => `${e}`) ?? [],
    taggedTransactionsIds: transactions?.transaction_tag_ids?.map(e => `${e}`) ?? [],

    pending: undefToEmpty(transactions?.pending),
    cancelled: undefToEmpty(transactions?.cancelled),
    startDate: parseDate(transactions?.start_date),
    endDate: parseDate(transactions?.end_date),
    dateRangeActive: transactions?.active === 'starting' ? 'starting' : '',
    modifiedWithin: parseModifiedWithin(transactions?.updated_days),

    year: parseInteger(transactions.current_for_year),
    clusterBy: parseEnum(group?.group, ClusterByOptions),
    scale: parseEnum(group?.scale,  ScaleOptions),

    searchGroup: parseEnum(transactions?.search_group, SearchGroupOptions),

    filterId,
    filterName: undefToEmpty(filterName),

    order: parseEnum(transactions?.order, SortByOptions),
    sortMode: transactions?.sort_mode === 'desc' ? 'desc' : 'desc',
  }
}



// Used when saving a filter
export function tranToSavedItemReq(filter: TransactionFilterForm): TranFilterSavedItemReq {


  return  {
    'search[name]': filter.filterName,

    'search[filters][transactions][entity_ids][]': emptyToUndef(toInts(filter.companyIds)),
    'search[filters][transactions][subsidiaries]': filter.includeSubsidiaries ? '1' : undefined,
    'search[filters][transactions][owners]': filter.includeOwners ? '1' : undefined,
    'search[filters][transactions][industry_ids][]': emptyToUndef(toInts(filter.industryIds)),

    'search[filters][transactions][transaction_type_ids][]': emptyToUndef(toInts(filter.transactionTypeIds)),
    'search[filters][transactions][project_value_gte]': toInt(filter.minTranValue),
    'search[filters][transactions][project_value_lte]': toInt(filter.maxTranValue),

    'search[filters][transactions][city_ids][]': emptyToUndef(toInts(filter.cityIds)),
    'search[filters][transactions][country_ids][]': emptyToUndef(toInts(filter.countryIds)),
    'search[filters][transactions][region_ids][]': emptyToUndef(toInts(filter.regionIds)),
    'search[filters][transactions][continent_ids][]': emptyToUndef(toInts(filter.continentIds)),

    'search[filters][transactions][keywords]': emptyToUndef(filter.keywords),

    'search[filters][transactions][country_risk_ids][]': emptyToUndef(toInts(filter.countryRiskDesignationIds)),
    'search[filters][transactions][entity_risk_ids][]': emptyToUndef(toInts(filter.entityRiskDesignationIds)),
    'search[filters][transactions][person_risk_ids][]': emptyToUndef(toInts(filter.personRiskDesignationIds)),
    'search[filters][transactions][transaction_tag_ids][]': emptyToUndef(toInts(filter.taggedTransactionsIds)),


    'search[filters][transactions][pending]': emptyToUndef(filter.pending),
    'search[filters][transactions][cancelled]': emptyToUndef(filter.cancelled),
    'search[filters][transactions][start_date]': emptyToUndef(filter.startDate),
    'search[filters][transactions][end_date]': emptyToUndef(filter.endDate),
    'search[filters][transactions][active]': emptyToUndef(filter.dateRangeActive),
    'search[filters][transactions][updated_days]': toInt(filter.modifiedWithin),

    'search[filters][transactions][current_for_year]': toInt(filter.year),

    'search[filters][transactions][search_group]': emptyToUndef(filter.searchGroup),

    'search[filters][group][group]': filter.clusterBy,
    'search[filters][group][scale]': filter.scale,

    'search[filters][transactions][order]': filter.order,
    'search[filters][transactions][sort_mode]': filter.sortMode,
  }

}
