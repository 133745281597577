import { ReactNode } from 'react'

export const FilterFormGroup =(
  {
    className = 'col-xl-4 col-lg-6',
    children
  }: {
    className?: string
    children: ReactNode
  }): JSX.Element => {
  return (
    <div className={className}>
      <div className="form-group">
        {children}
      </div>
    </div>
  )
}
