export const ExcerptText = ({
  text
}: {
  text: string
}) => {
  let content = ''
  if(text.indexOf('</p>') > -1) {
    content = text.substr(0, (text.indexOf('</p>') + 4))
  } else if (text.indexOf('<br')) {
    content = text.substr(0, (text.indexOf('<br') - 1))
  } else {
    content = text.substr(0, 400).replace(/[^ ]+$/, '...')
  }

  return (
    <span
      className="excerpt-text"
      dangerouslySetInnerHTML={{__html: content}}
    />
  )
}
