import { ReactNode } from 'react'

export const SectionedSidebarContentSectionTitle = ({
  children
}: {
  children: ReactNode
}) => (
  <div className="list-group-item-title">{children}</div>
)

export const SectionedSidebarContentSection = ({
  children,
  title
}: {
  children: ReactNode
  title?: ReactNode
}): JSX.Element => (
  <li className="list-group-item">
    {title && 
      <SectionedSidebarContentSectionTitle>
        {title}
      </SectionedSidebarContentSectionTitle>
    }
    {children}
  </li>
)

export const SectionedSidebarContentTitle = ({
  children,
  secondary
}: {
  children?: ReactNode
  secondary?: boolean
}): JSX.Element => (
  <div className={`content-sidebar-title px-4 pt-2 ${secondary ? 'pb-1' : 'pb-4'} border-bottom`}>
    {children}
  </div>
)

export const SectionedSidebarContent = ({
  title,
  children
}: {
  title?: ReactNode
  children?: ReactNode
}): JSX.Element => (
  <div className="tab-content">
    <div className="tab-pane fade show active print-on-same-page">
      {title &&
        <SectionedSidebarContentTitle>
          {title}
        </SectionedSidebarContentTitle>
      }
      <ul className="list-group list-group-flush">
        {children}
      </ul>
    </div>
  </div>
)

export const SectionedSidebar = ({
  className,
  children
}: {
  className?: string,
  children: ReactNode
}) => (
  <div className={className || 'content-sidebar'}>
    {(Array.isArray(children) ? children : [children]).map((section, index) => (
      <div key={index}>
        {section}
      </div>
    ))}
  </div>
)
