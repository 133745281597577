import {Fragment, useState} from 'react'

import logo from 'images/logo.svg'
import janesLogo from 'images/janes-logo.svg'

import NavBarItem from './nav/NavBarItem'

// TODO either move to images or update prop names for JSX
import SidebarToggleIcon from './icons/SidebarToggleIcon'
import DashboardIcon from './icons/DashboardIcon'
import TransactionsIcon from './icons/TransactionsIcon'
import EntityRiskTrackerIcon from './icons/EntityRiskTrackerIcon'
import AlertsIcon from './icons/AlertsIcon'
import CountryReportsIcon from './icons/CountryReportsIcon'
import SavedItemsIcon from './icons/SavedItemsIcon'
import {CountryReportPaths} from 'apps/countryreports/CountryReports';
import {AlertPaths} from 'apps/alerts/Alerts';
import {TranPaths} from 'apps/transactions/Transactions';
import {SessionStorage} from 'lib';
import {buildQuery} from 'apis';
import {useAppNavBarContext} from 'context';


export type NavKey = 'dashboard' | 'transactions' | 'entityrisktracker' | 'alerts' | 'countryreports' | 'saveditems' | 'profile'

interface NavItem {
  key: NavKey
  to: string
  icon: JSX.Element
  value: JSX.Element
}

export function AppNavBar(
  {
    current,
  }: {
    current: NavKey | ''
  }) {

  const ou = useAppNavBarContext()
  ou.toggleOpen = () => setShowSidebar(old => !old)

  const [showSidebar, setShowSidebar] = useState(false)

  const tranStickyFilter = SessionStorage.currentTransactionsFilter;
  const tranPath = tranStickyFilter.view === 'list' ? TranPaths.list : TranPaths.map
  const tranFilter = {...tranStickyFilter, view: undefined}

  const ertFilter = SessionStorage.currentERTFilter;

  const alertStickyFilter = SessionStorage.currentAlertsFilter;
  const alertPath = alertStickyFilter.view === 'list' ? AlertPaths.list : AlertPaths.map
  const alertFilter = {...alertStickyFilter, view: undefined}


  const appNav: NavItem[] = [
    {
      key: "dashboard",
      to: "/",
      icon: <DashboardIcon/>,
      value: <Fragment>Dashboard</Fragment>
    },
    {
      key: "transactions",
      to: `${tranPath}${buildQuery(tranFilter, true)}`,
      icon: <TransactionsIcon/>,
      value: <Fragment>Transactions</Fragment>
    },
    {
      key: "alerts",
      to: `${alertPath}${buildQuery(alertFilter)}`,
      icon: <AlertsIcon/>,
      value: <Fragment>Alerts</Fragment>
    },
    {
      key: "entityrisktracker",
      to: "/entityrisktracker" + buildQuery(ertFilter, true),
      icon: <EntityRiskTrackerIcon/>,
      value: <Fragment>Entity Risk Tracker</Fragment>
    },
    {
      key: "countryreports",
      to: CountryReportPaths.map,
      icon: <CountryReportsIcon/>,
      value: <Fragment>Country Reports</Fragment>
    },
    {
      key: "saveditems",
      to: "/saveditems",
      icon: <SavedItemsIcon/>,
      value: <Fragment>Saved Items</Fragment>
    }
  ]

  return (
    <nav className={'bg-light sidebar sidebar-left' + (showSidebar ? ' sidebar-show' : '')}>
      <div className="sidebar-content">
        <div className="sidebar-header">
          <a className="sidebar-logo" href="/" title="Intel Trak">
             <img src={logo} alt="IntelTrak" />
          </a>
          <button className="btn btn-link sidebar-toggle" onClick={() => setShowSidebar(old => !old)}>
            <SidebarToggleIcon />
          </button>
        </div>
        <div className="sidebar-section">
          <ul className="nav flex-column">
            {
              appNav.map(({key, to, icon, value}) => (
                <NavBarItem key={key} to={to} icon={icon} 
                    current={current === key}>
                  {value}
                </NavBarItem>
              ))
            }
          </ul>
        </div>
      </div>
      <div className="sidebar-footer">
        <a href="https://www.janes.com">
          <img className="sidebar-footer-logo" src={janesLogo} 
            title="Janes" alt="Janes" />
        </a>
      </div>
    </nav>
  )
}
