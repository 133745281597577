import {Link, useLocation} from 'react-router-dom';
import {ClusterByOptionsT, ScaleOptionsT, SearchGroupOptionsT} from 'models';
import {ResetXIcon} from 'components/icons/ResetXIcon';
import {TranMapSubTab} from 'apps/transactions/TransactionMap';
import {TranPaths} from 'apps/transactions/Transactions';
import {TransactionYearSlider} from 'apps/transactions/components/TransactionYearSlider';
import {NavDropdown} from 'components/NavDropdown';

export function TransactionMapSubNav(
  {
    filterCount,
    subTab,
    year,
    setYear,
    scale,
    setScale,
    clusterBy,
    setClusterBy,
    searchGroup,
    setSearchGroup,
    reset,
  }: {
    filterCount: number
    subTab: TranMapSubTab
    year: number | undefined
    setYear: (val: number | undefined) => void
    scale: ScaleOptionsT
    setScale: (val: ScaleOptionsT) => void
    clusterBy: ClusterByOptionsT
    setClusterBy: (val: ClusterByOptionsT) => void
    searchGroup: SearchGroupOptionsT
    setSearchGroup: (val: SearchGroupOptionsT) => void
    reset: () => void
  }
): JSX.Element {

  const location = useLocation()

  const filterPath = TranPaths.mapFilters + location.search


  const view: TranPaths = Object.values(TranPaths).includes(location.pathname as TranPaths)
    ? location.pathname as TranPaths
    : TranPaths.map

  const filterActive = view === TranPaths.listFilters
    || view === TranPaths.mapFilters
    || view === TranPaths.relatedFilters


  // 4 rows at min screen size
  // 3 rows at sm
  // 2 rows at md
  // 1 row at xl

  return (
    <>
      <TransactionYearSlider
        year={year}
        setYear={setYear}
        isFiltersTab={subTab === TranMapSubTab.Filters}
      />
      <div className="filters-header py-1">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <NavDropdown
            title="Country"
            className="flex-fill col-12 col-sm-6"
            value={searchGroup}
            setValue={val => setSearchGroup(val)}
            options={[
              ['all', 'All'],
              ['china', 'China'],
              ['russia', 'Russia'],
            ]}
          />
          <NavDropdown
            title="Cluster"
            className="flex-fill col-12 col-sm-6"
            value={clusterBy}
            setValue={val => setClusterBy(val)}
            options={[
              ['city', 'City'],
              ['country', 'Country'],
              ['region', 'Region'],
              ['continent', 'Continent'],
            ]}
          />
          <NavDropdown
            title="Scale"
            className="flex-fill col-12 col-md-6"
            value={scale}
            setValue={val => setScale(val)}
            options={[
              ['count', 'Count'],
              ['value', 'Value (Total)'],
              ['amortized', 'Value (Amortized)'],
              ['gdp', '% of GDP'],
            ]}
          />
          <div className="filters-header-item flex-fill col-6 col-md-3 col-xl-2">
            <Link to={filterPath} className={'btn btn-gray-light btn-sm text-uppercase btn-filters w-100' + (filterActive ? ' active' : '')}>
              Filters {filterCount > 0 && <span className="badge badge-primary ml-2">{filterCount}</span>}
            </Link>
          </div>
          <div className="filters-header-item flex-fill col-6 col-md-3 col-xl-2">
            <button type="button" className="btn btn-gray-light btn-sm text-uppercase btn-reset w-100" onClick={reset}>
              Reset
              <ResetXIcon />
            </button>
          </div>
        </div>
      </div>
    </>

  )
}
