import { MouseEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'context'
import { CountryReportApi } from 'apis'
import {CountryReport, ErrorDetail} from 'models'
import { LoadingComponent, Sidebar, SidebarContent } from 'components'
import { CountryReportDocument } from './components/CountryReportDocument'
import {handleAsyncLoader} from 'lib';
 
interface SidebarParams {
  onClose: (event: MouseEvent) => void
  id: number
}

export function CountryReportSidebar({
  onClose,
  id
}: SidebarParams) {

  const { session } = useAppContext()

  const [countryReport, setCountryReport] = useState<CountryReport>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()



  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setCountryReport(await new CountryReportApi(session.token).id(id))
    }), [id, session.token])

  const view = countryReport ? (
    <CountryReportDocument countryReport={countryReport} preview />
   ) : (
    <>Error loading Country Report</>
   )

  const nav = loading || !!error ? undefined : (
    <Link className="btn btn-gray text-primary btn-block"
      to={`/countryreports/${countryReport?.slug}`}
    >
      View Full Country Report
    </Link>
  )

  return (
    <Sidebar onClose={onClose}
      header="Country Report Preview"
      nav={nav}
    >
      <LoadingComponent loading={loading} error={error}>
        <SidebarContent>
          <div className="content-title">{countryReport?.title}</div>
          <div className="content-subtitle dot dot-left active">Alert</div>
        </SidebarContent>
        <SidebarContent>
          {view}
        </SidebarContent>
      </LoadingComponent>
    </Sidebar>
  )
}
