export default function AlertsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.231" height="22.008" viewBox="0 0 23.231 22.008">
      <g transform="translate(0.037 -0.492)">
      <g fill="none" strokeMiterlimit="10">
      <path className="icon-fill" d="M 19.68902397155762 20 C 21.22968292236328 20 22.19179344177246 18.33127021789551 21.41988372802734 16.99794006347656 L 13.30992317199707 2.989660263061523 C 12.9247579574585 2.324375152587891 12.25190830230713 1.991727709770203 11.57906436920166 1.991727709770203 C 10.90622043609619 1.991727709770203 10.23338317871094 2.324365139007568 9.848213195800781 2.989650249481201 L 1.738063335418701 16.99792098999023 C 0.9661332964897156 18.33125114440918 1.928243279457092 20 3.468913316726685 20 L 19.68902397155762 20 M 19.68902397155762 21.5 L 3.468913316726685 21.5 C 2.202723264694214 21.5 1.069213271141052 20.84511947631836 0.4367832839488983 19.7481803894043 C -0.1956567168235779 18.6512508392334 -0.1944867223501205 17.34216117858887 0.4399232864379883 16.24636077880859 L 8.550073623657227 2.23809027671814 C 9.183183670043945 1.144570231437683 10.31551361083984 0.4917302429676056 11.57906341552734 0.4917302429676056 C 12.84263324737549 0.4917302429676056 13.97497367858887 1.144580245018005 14.60806369781494 2.238110303878784 L 22.7180233001709 16.24638938903809 C 23.3524227142334 17.34218978881836 23.35359382629395 18.65127944946289 22.72114372253418 19.74819946289063 C 22.08870315551758 20.84511947631836 20.95520401000977 21.5 19.68902397155762 21.5 Z" stroke="none" fill="#747576"/>
      </g>
      <path className="icon-stroke" d="M3,1.5A1.5,1.5,0,1,1,2.56.44,1.5,1.5,0,0,1,3,1.5Z" transform="translate(10 14)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="1.5"/>
      <path className="icon-stroke" d="M.5,6.5V.5" transform="translate(11 5)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="1.5"/>
      </g>
    </svg>
  )
}
