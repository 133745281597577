import {LoadingComponent, MainContainer, PageContainer} from 'components';
import {PersonCircle} from 'components/icons/PersonCircle';
import {useEffect, useRef, useState} from 'react';
import {ErrorDetail, User} from 'models';
import {handleAsyncLoader} from 'lib';
import {UserApi} from 'apis';
import {useAppContext} from 'context';
import {ChangePasswordModal, ChangePasswordModalRef} from 'apps/profile/components/ChangePasswordModal';

export function Profile(
  {
    searchBar
  }: {
    searchBar: JSX.Element
  }
): JSX.Element {

  const { session } = useAppContext()

  const [user, setUser] = useState<User>({loggedIn: false, accept_csv_terms: false})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ErrorDetail>()
  const modalRef = useRef<ChangePasswordModalRef>(null)


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      const user = await new UserApi(session.token).me()

      setUser(user)
    }), [session.token])



  return (
      <>
        {
          user.loggedIn && (<>
          <PageContainer navItem='profile'>
            <MainContainer searchBar={searchBar}>
              <LoadingComponent loading={loading} error={error}>
                <div className="my-3 mx-4">
                  <h3>My Profile</h3>

                  <div className="row mt-5">
                    <label className="col-xl-2 col-lg-3 col-md-4 text-md-right col-form-label">
                      First Name
                    </label>
                    <div className="col-xl-10 col-lg-9 col-md-8">
                      <input type="text"
                             className="form-control"
                             value={user.first_name}
                             disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <label className="col-xl-2 col-lg-3 col-md-4 text-md-right col-form-label">
                      Last Name
                    </label>
                    <div className="col-xl-10 col-lg-9 col-md-8">
                      <input type="text"
                             className="form-control"
                             value={user.last_name}
                             disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <label className="col-xl-2 col-lg-3 col-md-4 text-md-right col-form-label">
                      Email
                    </label>
                    <div className="col-xl-10 col-lg-9 col-md-8">
                      <input type="text"
                             className="form-control"
                             value={user.email}
                             disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <label className="col-xl-2 col-lg-3 col-md-4 text-md-right col-form-label">
                      Password
                    </label>
                    <div className="col-xl-10 col-lg-9 col-md-8 d-flex flex-row">
                      <input type="password"
                             className="form-control flex-fill"
                             value="***************"
                             disabled={true}
                      />
                      <button className='btn btn-dark ml-1' onClick={modalRef.current?.show}>
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </LoadingComponent>
            </MainContainer>
          </PageContainer>
          <ChangePasswordModal afterChanged={() => window.alert('password changed')} ref={modalRef}/>
          </>)}
      </>
  )
}
