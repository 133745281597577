import { useEffect, useState } from 'react'
import { useAppContext } from 'context'
import { CountryReportApi, CountryReportSearch } from 'apis'
import { LoadingComponent } from 'components'
import { CountryReportTable } from './components/CountryReportTable'
import {CountryReportsSearchItem, ErrorDetail} from 'models';
import {handleAsyncLoader} from 'lib';
import {NavDropdown} from 'components/NavDropdown';

interface CountryReportListParams {
  className?: string
  setCountryReportId: (id: number) => void
  search: CountryReportSearch
  previewId?: number
}

export function CountryReportList({
  className,
  setCountryReportId,
  search,
  previewId
}: CountryReportListParams) {

  const { session } = useAppContext()

  const [list, setList] = useState<CountryReportsSearchItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()
  const [browseBy, setBrowseBy] = useState<string>('Title')


  useEffect(handleAsyncLoader(
    setLoading,
    setError,async () => {
      setList((await new CountryReportApi(session.token).search(search)).country_reports)
    }), [search, session.token])

  return (
    <LoadingComponent loading={loading} error={error}>
      <div className="filters-header">
        <div className="d-flex">
          <NavDropdown
            title="Browse By"
            className="flex-fill"
            value={browseBy}
            setValue={val => setBrowseBy(val)}
            options={[
              ['Title', 'Title'],
              ['Region', 'Region'],
              ['Continent', 'Continent'],
            ]}
          />
        </div>
      </div>
      <CountryReportTable
        className={className || ""}
        countryReports={list}
        setCountryReportId={setCountryReportId}
        previewId={previewId}
        browseBy={browseBy}
      />
    </LoadingComponent>
  )

}
