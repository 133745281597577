import {TransactionFilterForm, TransactionsSearchItem} from 'models'
import { DateRange, HighlightText } from 'components'
import { SearchCityBar } from 'apps/search/components/SearchCityBar'
import {buildQueryParams} from 'apis';
import {qsToTranForm} from 'models/converters';
import { Link } from 'react-router-dom';
import {TranPaths} from 'apps/transactions/Transactions';

export function TransactionTable(
  {
    className,
    onSelect,
    list,
    previewId
  }
    : {
    className?: string
    onSelect: (id: number, title: string) => void
    list: TransactionsSearchItem[]
    previewId?: number
  }
): JSX.Element {

  return (
    <div className={`table-responsive ${className || ''}`}>
      <table className="table table-large table-alignment-top table-hover">
        <colgroup>
          <col style={{width: '320px'}}>
          </col>
        </colgroup>
        <tbody>
          {list && list.map(t => (
            <tr key={t.id}
                className={previewId === t.id ? 'active' : ''}
                onClick={() => onSelect(t.id, t.title)}>
              <td className="col-name d-none d-md-table-cell">
                <Link
                  to={'/transactions/' + t.id}
                  onClick={e => e.stopPropagation()}
                  >
                  {t.title}
                </Link>
              </td>
              <td>
                <Link
                  to={'/transactions/' + t.id}
                  onClick={e => e.stopPropagation()}
                  className="d-md-none"
                >
                  {t.title}
                </Link>
                <div className={'info-box dot dot-left my-2' + (t.status === 'Active' ? ' active' : '')}>
                  {t.status}
                  <span className="info-box-divider">|</span>
                  {t?.cities && <SearchCityBar
                    cities={t.cities.map(c => (
                      {
                        cityName: c.city?.display_name,
                        to: TranPaths.list + '?' + buildQueryParams<TransactionFilterForm>(
                          {
                            ...qsToTranForm(new URLSearchParams()),
                            cityIds: [`${t.id}`]
                          }, true)
                      }))}
                  />}
                  <span className="info-box-divider">|</span>
                  <DateRange startDate={t.start_date} endDate={t.end_date} />
                </div>
                <HighlightText text={t.description} />
              </td>
            </tr>
           ))}
        </tbody>
      </table>
    </div>
  )

}
