import React, {useMemo, useState} from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import _uniqueId from 'lodash/uniqueId';
import {MsOption} from 'models';
import {findOption} from 'lib';





function toTypeAheadOptionsRecur(options: MsOption[] | undefined, prefix: string): TypeAheadOption[] {
  if (typeof options === 'undefined')
    return [];
  return options.flatMap(o => [
    {id: o.value, label: (prefix === '' ? '' : prefix + ' ') + o.name},
    ...toTypeAheadOptionsRecur(o.subOptions, prefix + '-'),
  ])
}

interface TypeAheadOption {
  id: string,
  label: string
}

function toTypeAheadOptions(options: MsOption[]): TypeAheadOption[] {
  return toTypeAheadOptionsRecur(options, '');
}

function buildParentIds(option: MsOption): Map<string, string[]> {
  if (option.subOptions) {
    const map = buildParentIdsFromAry(option.subOptions);
    option.subOptions.forEach(so => {
      const vals = map.get(so.value) ?? [];
      map.set(so.value, [...vals, option.value])
    })
    return map;
  }

  return new Map<string, string[]>();
}
function buildParentIdsFromAry(options: MsOption[]): Map<string, string[]> {
  const maps = options.map(buildParentIds);
  const result = new Map<string, string[]>();
  for (const m of maps) {
    m.forEach((value, key) => result.set(key, value));
  }
  return result;
}

function isMeOrParentSelected(id: string, selectedIds: string[], parentIds: Map<string, string[]>): boolean {
  if (selectedIds.includes(id))
    return true;

  return parentIds.get(id)?.some(pid => selectedIds.includes(pid)) ?? false;
}


function isMeOrChildMatchedRecur(textToLower: string, msOption: MsOption, options: MsOption[]): boolean {

  if (msOption.name.toLocaleLowerCase().includes(textToLower))
    return true;

  if (msOption.subOptions) {
    for (const subOption of msOption.subOptions) {
      if (isMeOrChildMatchedRecur(textToLower, subOption, options))
        return true
    }
  }

  return false
}

function isMeOrChildMatched(textToLower: string, option: TypeAheadOption, options: MsOption[]): boolean {
  const msOption = findOption(options, option.id)
  if (typeof msOption === 'undefined')
    return false;

  if (isMeOrChildMatchedRecur(textToLower, msOption, options))
    return true

  return false

}

export function MultiSelectHierarchy(
  {
    options,
    selectedValues,
    setSelectedValues
  } : {
    options: MsOption[],
    selectedValues: string[],
    setSelectedValues: (newSelection: string[]) => void
  }
): JSX.Element {

  function handleChange(selected: TypeAheadOption[]) {
    setSelectedValues(selected.map(s => s.id));
  }

  const [id] = useState(_uniqueId('multiselector-'));

  const parentIds: Map<string, string[]> = useMemo(() => buildParentIdsFromAry(options), [options])

  const selectedOptions = useMemo<TypeAheadOption[]>(
    () => (selectedValues
      .map(k => findOption(options, k))
      .filter(o => typeof o !== 'undefined') as MsOption[])
      .map(o => ({id: o.value, label: o.name})),
    [selectedValues, options])

  const typeAheadOptions = useMemo(
    () => toTypeAheadOptions(options),
    [options])

  return (
    <>
    <Typeahead
      multiple
      id={id}
      onChange={handleChange}
      options={typeAheadOptions}
      selected={selectedOptions}
      filterBy={(o: TypeAheadOption, p) => {
        return (p.text.trim() === '' || isMeOrChildMatched(p.text.toLocaleLowerCase(), o, options))
          && !isMeOrParentSelected(o.id, p.selected.map(s => s.id), parentIds)
      }}
      placeholder={'Search'}
    />
    </>
  )
}
