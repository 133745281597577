import { useEffect, useState } from 'react'

import { useAppContext } from 'context'

import {EntityRiskTrackerApi} from 'apis'

import {Entity, EntityRiskResult, ErrorDetail} from 'models'
import { EntityTable } from 'apps/entityrisktracker/components/EntityTable'
import {handleAsyncLoader} from 'lib';
import {LoadingComponent} from 'components';


export function SearchEntityResults({
  results,
  onSelect
}: {
  results: Entity[],
  onSelect: (id: number, title: string) => void,
}): JSX.Element {

  const { session } = useAppContext()

  const [risks, setRisks] = useState<EntityRiskResult[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorDetail>()


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setRisks((await new EntityRiskTrackerApi(session.token).risks()))
    }
  ), [])


  return (
    <LoadingComponent loading={loading} error={error}>
      <EntityTable
        className="p-4"
        entities={results}
        risks={risks}
        onSelect={onSelect}
      />
    </LoadingComponent>
  );
}
