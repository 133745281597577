export const regions: Array<[number, string]> = [
  [1,'North America'],
  [4,'Oceania/Pacific'],
  [6,'Central Asia'],
  [8,'Caribbean'],
  [9,'Central America'],
  [10,'South America'],
  [11,'Eastern Europe'],
  [12,'Northern Europe'],
  [13,'Southern Europe'],
  [14,'Western Europe'],
  [15,'Southern Asia'],
  [16,'Eastern Asia'],
  [17,'South-Eastern Asia'],
  [18,'Australia and New Zealand'],
  [19,'Melanesia'],
  [20,'Micronesia'],
  [21,'Polynesia'],
  [22,'Eastern Africa'],
  [23,'Middle Africa'],
  [24,'Northern Africa'],
  [25,'Southern Africa'],
  [26,'Western Africa'],
  [31,'Middle East'],
  [51,'Western Asia'],
  [61,'Russia'],
]
