import {useEffect, useRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import {LoadingComponent, setupMapboxMap} from 'components';
import {useAppContext} from 'context';
import {convertError} from 'lib/HandleAsyncLoader';
import {ErrorDetail} from 'models';


const MapContainerStyle = {
  width: '100%',
  height: '200px'
}

const FillColor = 'rgb(13, 92, 179)'

export function CountryReportSidebarMapbox(
  {
    countryReport
  }: {
  countryReport: {
    country: {
      latitude: string
      longitude: string
    }
  }
}): JSX.Element {

  const { session } = useAppContext()

  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);


  const latitude = Number.parseFloat(countryReport.country.latitude)
  const longitude = Number.parseFloat(countryReport.country.longitude)

  const [error, setError] = useState<ErrorDetail>()


  const loadSource = () => {
    if (map.current !== null) {
      const marker = new mapboxgl.Marker(
        {
          color: FillColor
        })
        .setLngLat([longitude, latitude])
        .addTo(map.current);

      map.current.resize()  // Call resize to resize when parent resizes

    }
  }

  useEffect(() => {
    const setupMap = () => {
      map.current = setupMapboxMap({
        session,
        container: mapContainer.current,
        center: [0, 0],
        zoom: -1,
        onLoad: loadSource,
        onIdle: () => map.current!.resize,
        onError: e => setError(convertError(e)),
      })
    }
    if (!map.current && mapContainer.current) setupMap() // initialize map only once
  }, [mapContainer.current])




  return (
    <LoadingComponent loading={false} error={error}>
      <div className="content-sidebar-image" style={{position: 'relative'}}>
        <div ref={mapContainer}
             className="map-container"
             style={MapContainerStyle}
        />
      </div>
    </LoadingComponent>
  )
}
