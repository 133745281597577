export default function ListIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.6" height="13" viewBox="0 0 15.6 13">
      <g transform="translate(16.5) rotate(90)">
      <path className="icon-stroke" d="M.5,16V.4" transform="translate(0.5 0.5)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="2"/>
      <path className="icon-stroke" d="M.5,9V.409" transform="translate(6 7)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="2"/>
      <path className="icon-stroke" d="M.5,12V.4" transform="translate(11.5 4.5)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="2"/>
      </g>
    </svg>
  )
}