import { ReactNode } from 'react'
import { LoadingPage } from 'components'
import {ErrorDetail} from 'models';
import {ErrorPage} from 'components/ErrorPage';
import {useAppContext} from 'context';


export function LoadingComponent(
  {
    loading,
    error,
    children,
    marginTopPx,
  }: {
    loading: boolean
    error?: ErrorDetail
    children: ReactNode
    marginTopPx?: number
  }
) {

  const { logout } = useAppContext()

  if (error?.is401)
    logout()

  return (
    <>
      {error
        ? <ErrorPage error={error} />
        : loading
          ? <LoadingPage
            marginTopPx={marginTopPx ?? 200}
          />
          : (children ? children : <></>)
      }
    </>
  )
}
