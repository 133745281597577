import {
  emptyToAry,
  emptyToUndef,
  parseBool, parseBool0, parseEnum, parseInteger, parseStr,
  toInt,
  toInts, undefToEmpty
} from 'models/converters/ConverterUtil';
import {
  EntityRiskVm,
  EntityRiskResult,
  ErtFilterFormVm,
  ErtFilterFormQs,
  ErtFilterQueryReq,
  ErtFilterSavedItemRes,
  ErtSavedItemReq, SearchGroupOptions
} from 'models';
import {orderBy} from 'lib';

export function qsToErtForm(query: URLSearchParams): ErtFilterFormQs {
  return {
    risk_ids: emptyToAry(query.getAll('risk_ids')),
    entity_ids: emptyToAry(query.getAll('entity_ids')),

    public: parseBool(query.get('public')) ? true : undefined,
    exclude_owners: parseBool(query.get('exclude_owners')) ? true : undefined,
    exclude_subsidiaries: parseBool(query.get('exclude_subsidiaries')) ? true : undefined,
    exclude_distant_relatives: parseBool(query.get('exclude_distant_relatives')) ? true : undefined,
    search_group: parseEnum(query.get('search_group'), SearchGroupOptions),
    text: parseStr(query.get('text')),

    page: emptyToUndef(query.get('page') ?? ''),
    per_page: emptyToUndef(query.get('per_page') ?? ''),

    filterId: toInt(parseInteger(query.get('filterId'))),
    filterName: parseStr(query.get('filterName')),
  }
}




// Used when querying with the filter
export function ertToQueryReq(filter: ErtFilterFormQs): ErtFilterQueryReq {
  return  {
    'risk_ids[]': emptyToUndef(toInts(filter.risk_ids)),
    entity_ids: emptyToUndef(toInts(filter.entity_ids)),

    public: filter.public ? '1' : undefined,
    include_owners: filter.exclude_owners ? '0' : undefined,
    include_subsidiaries: filter.exclude_subsidiaries ? '0' : undefined,
    include_distant_relatives: filter.exclude_distant_relatives ? '0' : undefined,
    search_group: filter.search_group,
    text: filter.text || undefined,

    page: toInt(filter.page ?? ''),
    per_page: toInt(filter.per_page ?? ''),
  }
}


// Used when reading a saved filter to edit it
export function ertSavedItemResToForm(filterId: number, filterName: string, item: ErtFilterSavedItemRes): ErtFilterFormQs {
  return {
    risk_ids: item.entities?.risk_ids?.map(e => `${e}`) ?? [],
    entity_ids: item.entities?.entity_ids?.map(e => `${e}`) ?? [],

    public: parseBool(item.entities?.public) ? true : undefined,
    exclude_owners: parseBool0(item.entities?.include_owners) ? undefined : true,
    exclude_subsidiaries: parseBool0(item.entities?.include_subsidiaries) ? undefined : true,
    exclude_distant_relatives: parseBool0(item.entities?.include_distant_relatives) ? undefined : true,
    search_group: item.entities?.search_group,

    filterId,
    filterName: undefToEmpty(filterName),
  }
}



// Used when saving a filter
export function ertToSavedItemReq(filter: ErtFilterFormQs): ErtSavedItemReq {

  return  {
    'search[name]': filter.filterName,

    'search[filters][entities][risk_ids][]': emptyToUndef(toInts(filter.risk_ids)),
    'search[filters][entities][entity_ids][]': emptyToUndef(toInts(filter.entity_ids)),
    'search[filters][entities][public]': filter.public ? '1' : undefined,
    'search[filters][entities][include_owners]': filter.exclude_owners ? '0' : undefined,
    'search[filters][entities][include_subsidiaries]': filter.exclude_subsidiaries ? '0' : undefined,
    'search[filters][entities][include_distant_relatives]': filter.exclude_distant_relatives ? '0' : undefined,
    'search[filters][entities][search_group]': emptyToUndef(filter.search_group ?? ''),

  }

}




export function qsErtToErtForm(item: ErtFilterFormQs, filterOptions: EntityRiskResult[]): ErtFilterFormVm {
  return {
    ...item,
    search_group: item.search_group ?? 'all',
    risks: buildRisks(item.risk_ids, filterOptions),
  }
}

function buildRisks(riskIds: string[], filterOptions: EntityRiskResult[]): EntityRiskVm[] {
  return filterOptions.map(o => ({
    ...o,
    children: !!o.children && o.children.length > 0 ? buildRisks(riskIds, o.children) : undefined,
    selected: riskIds.includes(`${o.id}`)
  })).sort(orderBy(r => r.name))
}



export function ertToQsErtForm(item: ErtFilterFormVm): ErtFilterFormQs {
  return {
    risk_ids: buildRiskIds(item.risks).map(r => `${r}`),
    entity_ids: item.entity_ids,
    public: item.public,
    exclude_owners: item.exclude_owners,
    exclude_subsidiaries: item.exclude_subsidiaries,
    exclude_distant_relatives: item.exclude_distant_relatives,
    search_group: item.search_group,
    filterId: item.filterId,
    filterName: item.filterName,
  }
}

function buildRiskIds(risks: EntityRiskVm[]): number[] {
  return risks.flatMap(r => {
    const children = !!r.children ? buildRiskIds(r.children) : []
    return r.selected
      ? [r.id, ...children]
      : children
  })
}

