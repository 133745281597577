import {
  CollapsibleGroup,
  CollapsibleGroupSection,
  DropdownMenu,
  MainContainer, 
  Modal,
  PageContainer, 
  LoadingComponent
} from 'components'

import {
  buildQuery,
  CountryReportApi,
  FilterSavedItemReq,
  SavedItemsApi,
  SavedSearchMetadataDto,
  SearchesApi,
  UserApi
} from 'apis';

import {useEffect, useState} from 'react';
import AlarmIcon from 'components/icons/AlarmIcon';
import {GearIcon} from 'components/icons/GearIcon';
import {TrashIcon} from 'components/icons/TrashIcon';
import {useAppContext} from 'context';
import {useHistory, useLocation} from 'react-router-dom';
import AlertsIcon from 'components/icons/AlertsIcon';
import {
  alertSavedItemResToForm, alertToSavedItemReq, ertSavedItemResToForm, ertToSavedItemReq,
  qsToTranForm,
  tranSavedItemResToForm,
} from 'models/converters';
import {TranPaths} from 'apps/transactions/Transactions';
import {AlertPaths} from 'apps/alerts/Alerts';
import {
  AlertIdsFilterSavedItemRes,
  AlertIdsSavedItemReq,
  AlertsFilterSavedItemRes,
  CountryRptFilterSavedItemRes,
  CountryRptSavedItemReq,
  ErrorDetail,
  ErtFilterSavedItemRes,
  SavedItemsListResponseItem,
  TranFilterSavedItemRes,
  TranIdsFilterSavedItemReq,
  TranIdsFilterSavedItemRes,
  User
} from 'models';
import {handleAsyncLoader, orderBy} from 'lib';
import {tranToSavedItemReq} from 'models/converters/TransactionConverters';
import TransactionsIcon from 'components/icons/TransactionsIcon';
import CountryReportsIcon from 'components/icons/CountryReportsIcon';
import {FilterIcon} from 'components/icons/FilterIcon';
import {ErtIdsFilterSavedItemReq, ErtIdsFilterSavedItemRes} from 'models/Entity';
import EntityRiskTrackerIcon from 'components/icons/EntityRiskTrackerIcon';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';

import { pick } from 'lodash'

import './SavedItems.scss'


enum View {
  SavedItems,
  Tab2
}

interface SavedSearchMetadataCategoryVm {
  category: CategoryOptionsT
  savedItems: SavedItemsVmT[]
  expanded: boolean
}

interface BaseSavedItem {
  name: string
  id: number
  notify: boolean
}

interface SavedItem extends BaseSavedItem {
  type: 'saved_item'
  category: 'tran' | 'alert' | 'ert' | 'cr'
  orig: SavedItemsListResponseItem
}
interface SavedItemTran extends BaseSavedItem {
  type: 'tran_item'
  category: 'tran'
  filters: TranIdsFilterSavedItemRes
}
interface SavedFilterTran extends BaseSavedItem {
  type: 'tran_filter'
  category: 'tran'
  filters: TranFilterSavedItemRes
}
interface SavedFilterAlert extends BaseSavedItem {
  type: 'alert_filter'
  category: 'alert'
  filters: AlertsFilterSavedItemRes
}
interface SavedItemAlert extends BaseSavedItem {
  type: 'alert_item'
  category: 'alert'
  filters: AlertIdsFilterSavedItemRes
}
interface SavedItemErt extends BaseSavedItem {
  type: 'ert_item'
  category: 'ert'
  filters: ErtIdsFilterSavedItemRes
}
interface SavedFilterErt extends BaseSavedItem {
  type: 'ert_filter'
  category: 'ert'
  filters: ErtFilterSavedItemRes
}
interface SavedItemCr extends BaseSavedItem {
  type: 'cr_item'
  category: 'cr'
  filters: CountryRptFilterSavedItemRes
}

type SavedItemsVmT = SavedItemTran | SavedFilterTran | SavedFilterAlert | SavedFilterErt | SavedItemCr | SavedItemAlert | SavedItemErt | SavedItem


const CategoryOptions = ['tran', 'ert', 'alert', 'cr'] as const; // this dictates order on the screen
type CategoryOptionsAryT = typeof CategoryOptions
type CategoryOptionsT = CategoryOptionsAryT[number]


const editableTypes = ['tran_filter', 'alert_filter', 'ert_filter']

const masterSearchFields = [
  'master_search_alerts',
  'master_search_transactions',
  'master_search_ert',
  'master_search_country_reports'
]


function toSavedSearchMetadataCategories(savedFilters: SavedSearchMetadataDto[], savedItems: SavedItemsListResponseItem[]): SavedSearchMetadataCategoryVm[] {

  const itemsVm = savedFilters.map<SavedItemsVmT | null>(sf => {
    if ('transactions' in sf.filters) {
      if ('ids' in sf.filters.transactions) {
        return {
          type: 'tran_item',
          category: 'tran',
          filters: { transactions: sf.filters.transactions },
          notify: sf.notify,
          name: sf.name,
          id: sf.id,
        }
      }
      return {
        type: 'tran_filter',
        category: 'tran',
        filters: sf.filters as TranFilterSavedItemRes,
        notify: sf.notify,
        name: sf.name,
        id: sf.id,
      }
    } else if ('alerts' in sf.filters) {
      if ('ids' in sf.filters.alerts)
        return {
          type: 'alert_item',
          category: 'alert',
          filters: {alerts: sf.filters.alerts},
          notify: sf.notify,
          name: sf.name,
          id: sf.id,
        }
      return {
        type: 'alert_filter',
        category: 'alert',
        filters: sf.filters as AlertsFilterSavedItemRes,
        notify: sf.notify,
        name: sf.name,
        id: sf.id,
      }
    } else if ('country_reports' in sf.filters) {
      return {
        type: 'cr_item',
        category: 'cr',
        filters: sf.filters,
        notify: sf.notify,
        name: sf.name,
        id: sf.id,
      }
    } else if ('entities' in sf.filters) {
      if ('ids' in sf.filters.entities) {
        const res: SavedItemErt = {
          type: 'ert_item',
          category: 'ert',
          filters: {entities: sf.filters.entities},
          notify: sf.notify,
          name: sf.name,
          id: sf.id,
        }
        return res
      }
      const res: SavedFilterErt = {
        type: 'ert_filter',
        category: 'ert',
        filters: sf.filters as ErtFilterSavedItemRes,
        notify: sf.notify,
        name: sf.name,
        id: sf.id,
      }
      return res
    }

    console.error('unknown type:')
    console.error(sf)
    return null
  })
    .concat(savedItems.map<SavedItem>(si => {
      return {
        type: 'saved_item',
        id: si.saved_record_id,
        notify: si.notify,
        name: si.name,
        category: si.saved_record_type === 'Transaction'
          ? 'tran'
          : si.saved_record_type === 'CountryReport'
            ? 'cr'
            : si.saved_record_type === 'Entity'
              ? 'ert'
              : 'alert',
        orig: si
      }

    }))
    .filter(r => r !== null) as SavedItemsVmT[]

  const result = itemsVm
    .sort(orderBy(si => si.name.toLocaleLowerCase()))
    .reduce<SavedSearchMetadataCategoryVm[]>(
    (p, c) => {
      if (p.some(s => s.category === c.category)) {
        return p.map(e => (e.category !== c.category ? e : {
          ...e,
          savedItems: [...e.savedItems, c],
          expanded: false,
        }))
      }
      return [...p, {category: c.category, savedItems: [c], expanded: false, enableEmailButtons: c.category === 'alert' || c.category === 'tran'}]
    } , [])

  CategoryOptions.map(c => {
    if (!result.some(r => r.category === c)) {
      result.push({category: c, savedItems: [], expanded: false})
    }
  })


  // sort the data:
  return CategoryOptions.map(c => result.filter(r => r.category === c)[0])
}

function categoryName(cat: CategoryOptionsT) {
  switch (cat) {
    case 'tran': return 'Transactions'
    case 'ert': return 'Entity Risk Tracker'
    case 'alert': return 'Alerts'
    case 'cr': return 'Country Reports'
  }
  return 'unknown'
}

function itemIcon(si: SavedItemsVmT): JSX.Element {
  switch (si.type) {
    case 'tran_item': return <TransactionsIcon />
    case 'tran_filter': return <FilterIcon />
    case 'cr_item': return <CountryReportsIcon />
    case 'alert_item': return <AlertsIcon />
    case 'ert_filter': return <FilterIcon />
    case 'ert_item': return <EntityRiskTrackerIcon />
    case 'alert_filter': return <FilterIcon />
    case 'saved_item': {
      switch (si.category) {
        case 'tran': return <TransactionsIcon />
        case 'cr': return <CountryReportsIcon />
        case 'ert': return <EntityRiskTrackerIcon />
        case 'alert': return <AlertsIcon />
      }
    }
  }
  throw new Error('Unknown type: ' + JSON.stringify(si))
}

export const SavedItems = ({
  searchBar
}: {
  searchBar: JSX.Element
}) => {

  const { session } = useAppContext()
  const history = useHistory()
  const location = useLocation()


  const view = location.pathname.startsWith('/saveditems/tab2') ? View.Tab2
    : View.SavedItems;

  const [user, setUser] = useState<User>()
  const [savedItemCategories, setSavedItemCategories] = useState<SavedSearchMetadataCategoryVm[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()
  const [deleteItemRequest, setDeleteItemRequest] = useState<SavedItemsVmT>()

  // Master searches

  async function loadSearches(): Promise<void> {
    const api = new SavedItemsApi(session.token)
    const data = await Promise.all([
      (async () => await new SearchesApi(session.token).list())(),
      (async () => (await api.list('Transaction')).saved_items)(),
      (async () => (await api.list('Alert')).saved_items)(),
      (async () => (await api.list('Entity')).saved_items)(),
      (async () => (await api.list('CountryReport')).saved_items)(),
      (async () => setUser(await new UserApi(session.token).me()))(),
    ])


    const list = data[0]
    const items = [...data[1], ...data[2], ...data[3], ...data[4]]
    setSavedItemCategories(toSavedSearchMetadataCategories(list.searches.map(i => i.search), items))

  }


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      await loadSearches()
    }), [session.token])

  function handleDeleteItemRequest(si: SavedItemsVmT): void {
    setDeleteItemRequest(si)
  }


  function handleDeleteItem(si: SavedItemsVmT): void {
    (async () => {
      if (si.type === 'saved_item') {
        await new SavedItemsApi(session.token).destroy(si.orig.saved_record_type, si.id)
      } else {
        await new SearchesApi(session.token).destroy(si.id)
      }
      setDeleteItemRequest(undefined)
      await loadSearches()
    })()
      .catch((e) => {logExceptionFromCatch(e); alert(e)});
  }

  function handleEditClick(si: SavedItemsVmT): void {
    if (!editableTypes.includes(si.type))
      history.push(TranPaths.listFilters + buildQuery(qsToTranForm(new URLSearchParams()), true))


    switch (si.type) {
      case 'tran_filter': {
        history.push(TranPaths.listFilters + buildQuery(tranSavedItemResToForm(si.id, si.name, si.filters.transactions, si.filters.group), true))
        return
      }
      case 'alert_filter': {
        history.push(AlertPaths.listFilters + buildQuery(alertSavedItemResToForm(si.id, si.name, si.filters.alerts), true))
        return
      }
      case 'ert_filter': {
        history.push('/entityrisktracker/filters' + buildQuery(ertSavedItemResToForm(si.id, si.name, si.filters), true))
        return
      }
    }

    throw new Error(`Unhandled type: ${si.type}`)
  }


  async function calcSearchLink(si: SavedItemsVmT): Promise<string> {
    if (si.type === 'saved_item') {
      switch (si.category) {
        case 'tran': return '/transactions/' + si.id
        case 'alert': return '/alerts/' + si.id
        case 'cr': {
          const rpt = await new CountryReportApi(session.token).id(si.id)
          return '/countryreports/' + rpt.slug
        }
        case 'ert': return '/entityrisktracker/' + si.id
      }
    }

    switch (si.type) {
      case 'tran_item': {
        if (si.filters.transactions.ids.length === 1)
          return '/transactions/' + si.filters.transactions.ids[0]
        // TODO
        return '/transactions'
      }
      case 'tran_filter': {
        return TranPaths.list + buildQuery(tranSavedItemResToForm(si.id, si.name, si.filters.transactions, si.filters.group), true)
      }
      case 'alert_item': {
        if (si.filters.alerts.ids.length === 1)
          return '/alerts/' + si.filters.alerts.ids[0]
        // TODO
        return '/alerts'
      }
      case 'alert_filter': {
        return AlertPaths.list + buildQuery(alertSavedItemResToForm(si.id, si.name, si.filters.alerts), true)
      }
      case 'cr_item': {
        if (si.filters.country_reports.country_ids.length === 1) {
          const id = +si.filters.country_reports.country_ids[0]
          const rpt = await new CountryReportApi(session.token).id(id)
          return '/countryreports/' + rpt.slug
        }

        // TODO
        // return AlertPaths.list + buildQuery(countryRptSavedItemResToForm(si.filters), true)
        return '/countryreports'
      }
      case 'ert_filter': {
        return '/entityrisktracker' + buildQuery(ertSavedItemResToForm(si.id, si.name, si.filters), true)
      }
      case 'ert_item': {
        if (si.filters.entities.ids.length === 1) {
          const id = +si.filters.entities.ids[0]
          return '/entityrisktracker/' + id
        }

        // TODO
        // return AlertPaths.list + buildQuery(countryRptSavedItemResToForm(si.filters), true)
        return '/entityrisktracker'
      }

    }



    return TranPaths.listFilters + buildQuery(qsToTranForm(new URLSearchParams()), true)
  }

  function handleRowClick(si: SavedItemsVmT) {
    (async () => {
      history.push(await calcSearchLink(si))
    })()
      .catch((e) => {logExceptionFromCatch(e); alert(e)})
  }

  function toSavedItemReq(si: SavedItemsVmT) : FilterSavedItemReq {

    if (si.type === 'saved_item') {
      throw new Error('This shouldn\'t happen')
    }


    switch (si.type) {
      case 'tran_item': {
        const tranItem: TranIdsFilterSavedItemReq = {
          'search[name]': si.name,
          'search[notify]': si.notify ? '1' : '0',
          'search[filters][transactions][ids][]': si.filters.transactions.ids.map(i => +i)
        }
        return tranItem
      }
      case 'tran_filter':
        return tranToSavedItemReq(tranSavedItemResToForm(si.id, si.name, si.filters.transactions, si.filters.group))
      case 'alert_item': {
        const alertItem: AlertIdsSavedItemReq = {
          'search[name]': si.name,
          'search[notify]': si.notify ? '1' : '0',
          'search[filters][alerts][ids][]': si.filters.alerts.ids.map(i => +i)
        }
        return alertItem
      }
      case 'alert_filter': {
        return alertToSavedItemReq(alertSavedItemResToForm(si.id, si.name, si.filters.alerts))
      }
      case 'cr_item': {
        const countryItem: CountryRptSavedItemReq = {
          'search[name]': si.name,
          'search[notify]': si.notify ? '1' : '0',
          'search[filters][country_reports][country_ids][]': si.filters.country_reports.country_ids.map(i => +i)
        }
        return countryItem
      }
      case 'ert_filter': {
        return ertToSavedItemReq(ertSavedItemResToForm(si.id, si.name, si.filters))
      }
      case 'ert_item': {
        const ertItem: ErtIdsFilterSavedItemReq = {
          'search[name]': si.name,
          'search[notify]': si.notify ? '1' : '0',
          'search[filters][entities][ids][]': si.filters.entities.ids.map(i => +i)
        }
        return ertItem
      }

    }


    throw Error('Unknown type: ' + JSON.stringify(si))

  }

  async function changeNotify(si: SavedItemTran | SavedFilterTran | SavedFilterAlert | SavedFilterErt | SavedItemCr | SavedItemAlert | SavedItemErt | SavedItem, newVal: boolean) {
    if (si.type === 'saved_item') {
      await new SavedItemsApi(session.token).upsert(si.orig.saved_record_type, [si.orig.saved_record_id], newVal)
    } else {
      const item = toSavedItemReq(si)
      item['search[notify]'] = newVal ? '1' : '0'
      await new SearchesApi(session.token).update(si.id, item)
    }

    setSavedItemCategories(oldList => oldList.map(c => (
      {
        ...c,
        savedItems: c.savedItems.map(f => f.id !== si.id
          ? f
          : {
            ...f,
            notify: newVal
          })
      }
    )))
  }

  function handleNotifyClick(si: SavedItemsVmT, newVal: boolean) {
    (async () => {
      await changeNotify(si, newVal);
    })()
      .catch((e) => {logExceptionFromCatch(e); alert(e)})
  }



  function canEdit(si: SavedItemsVmT): boolean {
    return editableTypes.includes(si.type)
  }

  function buildDropdownActions(si: SavedItemsVmT, cat: SavedSearchMetadataCategoryVm) {
    return [
      {
        icon: <AlarmIcon />,
        onClick: () => handleNotifyClick(si, !si.notify),
        children: <>{si.notify ? 'Deactivate' : 'Activate'} Email Notification</>
      },
      {
        icon:<GearIcon />,
        onClick: () => handleEditClick(si),
        children: <>Edit Filter Set</>
      },
      {
        icon:<TrashIcon />,
        onClick: () => handleDeleteItemRequest(si),
        children: <>Remove</>
      },
    ].filter((r, i) => (i !== 1 || canEdit(si)))
  }

  function toggleAllNotify(newVal: boolean) {
    (async () => {
      const savedItems = savedItemCategories.flatMap(si => si.savedItems)

      await toggleNotifyAsync(savedItems, newVal)

    })()
      .catch(e => {logExceptionFromCatch(e); alert(e)})
  }


  async function toggleNotifyAsync(savedItems: SavedItemsVmT[], newVal: boolean) {
    const toChange = savedItems.filter(si => si.notify !== newVal)

    for (const si of toChange) {
      await changeNotify(si, newVal);
    }
  }


  function toggleNotify(savedItems: SavedItemsVmT[], newVal: boolean) {
    (async () => {
      await toggleNotifyAsync(savedItems, newVal)
    })()
      .catch(e => {logExceptionFromCatch(e); alert(e)})
  }

  function allMasterSearchesOn() {
    return user && user.master_search_alerts &&
      user.master_search_transactions &&
      user.master_search_ert &&
      user.master_search_country_reports
  }
  async function setAllMasterSearches(e: React.ChangeEvent<HTMLInputElement>) {
    const searchOn = e.target.checked
    const newUser = {
      ...user, 
      master_search_alerts: searchOn,
      master_search_transactions: searchOn,
      master_search_ert: searchOn,
      master_search_country_reports: searchOn,
    }
    setUser(newUser)
    await new UserApi(session.token).updateMasterSearches(
      pick(newUser, masterSearchFields)
    )
  }

  function masterSearchOnForCategory(category: CategoryOptionsT) {
    if (user) {
      switch (category) {
        case 'tran': return user.master_search_transactions;
        case 'ert': return user.master_search_ert;
        case 'alert': return user.master_search_alerts;
        case 'cr': return user.master_search_country_reports;
      }
    }
  }
  async function setMasterSearchForCategory(
    e: React.ChangeEvent<HTMLInputElement>, 
    category: CategoryOptionsT
  ) {
    if (user) {
      const searchOn = e.target.checked
      if (category === 'tran') user.master_search_transactions = searchOn;
      if (category === 'ert') user.master_search_ert = searchOn;
      if (category === 'alert') user.master_search_alerts = searchOn;
      if (category === 'cr') user.master_search_country_reports = searchOn;
      setUser({...user})
      await new UserApi(session.token).updateMasterSearches(
        pick(user, masterSearchFields)
      )
    }
  }



  return (
    <PageContainer navItem="saveditems">
      <MainContainer searchBar={searchBar} >
        <LoadingComponent loading={loading ?? false} error={error}>
          <div className='d-flex'>
            <h1 className="content-title small mt-4 ml-2">
              Saved Items &amp; Search Filters
            </h1>
            <div className='ml-auto mt-6 mr-3'>
              <div className="custom-control custom-switch">
                <input type="checkbox" className="custom-control-input" 
                  id="all_master_searches"
                  checked={allMasterSearchesOn()}
                  onChange={setAllMasterSearches}
                />
                <label className="custom-control-label" htmlFor="all_master_searches">
                  Enable All Notifications
                </label>
              </div>
            </div>
          </div>
          <CollapsibleGroup className="my-4">
            {savedItemCategories && savedItemCategories.map(c => (
              <CollapsibleGroupSection
                open={true}
                className="mb-4 col-name"
                title={categoryName(c.category)}
                key={c.category}
                headerButton={<>
                  <div className="custom-control custom-switch">
                    <input type="checkbox" className="custom-control-input" 
                      id={`${c.category}_master_searches`}
                      disabled={allMasterSearchesOn()}
                      checked={masterSearchOnForCategory(c.category)}
                      onChange={(e) => setMasterSearchForCategory(e, c.category)}
                    />
                    <label className="custom-control-label" 
                      htmlFor={`${c.category}_master_searches`}
                    >
                      Enable All Notifications
                    </label>
                  </div>
                </>}
              >
                <table className="table table-hover mb-0">
                  <colgroup>
                    <col />
                  </colgroup>
                  <tbody>
                  {c.savedItems && c.savedItems.map(si => (
                    <tr key={si.id}
                      onClick={(e) => {handleRowClick(si); e.stopPropagation()}}
                    >
                      <td className=" d-flex align-items-center">
                        <div className="ml-0 mr-2 mx-sm-3"><span>{itemIcon(si)}</span></div>
                        <div className="mr-auto col-name">
                          <a className="link-primary" onClick={e => false}>
                            {si.name}
                          </a>
                        </div>
                        {si.notify && <div><AlarmIcon  /></div>}
                        <div>
                          <DropdownMenu className="ml-2"
                                        items={buildDropdownActions(si, c)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </CollapsibleGroupSection>
            ))}
          </CollapsibleGroup>  
        </LoadingComponent>
      </MainContainer>

      {deleteItemRequest &&
      <Modal close={() => setDeleteItemRequest(undefined)}>
        <div className="modal-title mb-2">Delete Saved Filter Set?</div>
        <div className="modal-text mb-6">You have selected to delete the saved filter set "{deleteItemRequest.name}."
          This saved filter set will be deleted permanently.
        </div>
        <div className="modal-btns">
          <button type="button" className="btn btn-primary" onClick={() => handleDeleteItem(deleteItemRequest)}>Confirm</button>
          <button type="button" className="btn btn-dark" onClick={() => setDeleteItemRequest(undefined)}>Cancel</button>
        </div>
      </Modal>
      }
    </PageContainer>
  )
}
