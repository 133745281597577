import { ReactNode } from 'react'

export const FilterFormFooter = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {

  return (
    <div className="filters-form-footer">
      {children}
    </div>
  )

}
