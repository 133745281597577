import { MouseEvent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { findIndex } from 'lodash'
import {LoadingComponent} from 'components/LoadingComponent';
import {ErrorDetail} from 'models';

export interface Tab {
  active: boolean,
  to: string,
  onClick?: (event: MouseEvent) => void,
  children: JSX.Element
}

const navClass = (active: boolean) => `nav-link ${active ? 'active' : ''}`
const ContentClass = 'tab-pane fade p-0'
const ActiveClass = 'show active-tab'

export function TabbedNavContainer(
  {
    tabs,
    tabButtons,
    children,
    loading,
    renderInactive,
    renderAll,
    className,
    error,
    tabClassName,
  }: {
    tabs: Tab[]
    tabButtons?: {parentClassName: string, component: JSX.Element}[]
    children: ReactNode
    loading?: boolean
    renderInactive?: boolean
    renderAll?: boolean
    className?: string
    error?: ErrorDetail
    tabClassName?: string
  }) {

  const contents = Array.isArray(children) ? children : [children]
  const tabButtonsList = Array.isArray(tabButtons) ? tabButtons : !!tabButtons ? [tabButtons] : []

  const activeIndex = findIndex(tabs, 'active')
  const lis = tabs.map(({active, to, onClick, children}: Tab, index) => (
    <li className="nav-item" key={index}>
      <Link to={to}
        onClick={(e) => {
          if (onClick) {
            e.preventDefault()
            onClick(e)
            return false
          }
        }}
        className={navClass(active)}
      >
        {children}
      </Link>
    </li>
  ))

  return (
    <>
      <div className={`d-flex flex-wrap ${className ? className : ''}`}>
        <ul className={'nav nav-tabs nav-fill flex-fill ' + tabClassName}>
          {lis}
        </ul>
        {tabButtonsList.length > 0 &&
          <div className="filters-header border-0 col-12 col-lg-7 col-xl-5">
            <div className="d-flex flex-wrap">
              {tabButtonsList.map((button, index) => (
                <div className={"filters-header-item " + button.parentClassName} key={index}>
                  {button.component}
                </div>
              ))}
            </div>
          </div>
        }
      </div>
      <div className="nav-tab-content" style={{flex: 1, display: 'flex'}}>
        <LoadingComponent loading={loading ?? false} error={error}>
          {contents.map((content, index) => {
            if (renderAll || renderInactive || index === activeIndex) {
              const activeClass = index === activeIndex || renderAll ? ActiveClass : ''
              return (
                <div key={index}
                  style={{flex: 1}}
                  className={`${ContentClass} ${activeClass}`}
                >
                  {content}
                </div>
              )
            } else {
              return null
            }
          })
            .filter(e => e !== null)
            .map(e => e as JSX.Element)}
        </LoadingComponent>
      </div>
    </>
  )

}
