import { ReactElement, ReactNode, useState } from 'react'

import CaretDown from 'components/icons/CaretDown'
import { CaretRightIcon } from 'components/icons/CaretRightIcon'

export const CollapsibleGroupSection = ({
  className,
  title,
  open,
  headerButton,
  children,
}: {
  className?: string
  title: string
  open?: boolean
  headerButton?: ReactNode
  children: ReactNode
}) => {
  
  const [isOpen, setIsOpen] = useState<boolean>(open || false)

  return (
    <div className={`card ${className || ''}`}>
      <div className="card-header d-flex">
        <h5 className="mb-0">
          <button className="btn btn-lg p-0"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span style={{
              width: '28px', 
              display: 'inline-block',
              marginRight: '8px'
            }}>
              {isOpen ? <CaretDown/> : <CaretRightIcon/>}
            </span>
            {title}
          </button>
        </h5>
        <div className='ml-auto d-flex align-items-center'>
          {headerButton}
        </div>
      </div>

      <div className={`collapse ${isOpen ? 'show' : ''}`}>
        <div className="card-body p-0">
          {children}
        </div>
      </div>
    </div>
  )
}

export const CollapsibleGroup = ({
  className,
  children
}: {
  className?: string
  children: ReactElement<typeof CollapsibleGroupSection>[]
}) => {
  return (
    <div className={`CollapsibleGroup ${className || ''}`}>
      {children}
    </div>
  )
}
