import { MouseEvent } from 'react'
import { CloseIcon } from 'components/icons/CloseIcon'

interface SidebarContentParams {
  children: JSX.Element | JSX.Element[]
}

interface SidebarParams {
  header: JSX.Element|string
  children: string|JSX.Element|JSX.Element[]
  nav?: JSX.Element
  onClose: (event: MouseEvent) => void
}

export const SidebarContent = ({
  children
}: SidebarContentParams) => (
  <div className="sidebar-section p-4">{children}</div>
)

export function Sidebar({
  header,
  children,
  nav,
  onClose
}: SidebarParams) {

  const noFooter = !nav ? 'sidebar-no-footer' : ''

  return (
    <nav id="sidebarMenu" 
      className={`bg-light sidebar sidebar-right ${noFooter}`}
    >
      <div className="sidebar-scroll">
        <div className="sidebar-content">
          <div className="sidebar-header pr-8 pl-4">
            {header}
            <button className="btn btn-link sidebar-close" onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          {children}
        </div>
        {nav &&
          <div className="sidebar-footer px-4 pt-4">
            {nav}
          </div>
        }
      </div>
    </nav>
  )
}
