import {
  CountryReport,
  CountryReportContent,
  CountryReportContentElement
} from 'models'
import { AuthImage } from 'components'


const SectionContent = ({children}: {
  children: CountryReportContentElement[]
}) => {
  return (
    <>
      {children.map(({text, url}, index) => (
        url ? <a target="_blank" href={url} key={index}>{text}</a> : <span key={index}>{text}</span>
      ))}
    </>
  )
}

const SectionImages = ({urls}: {
  urls: string[]
}) => {
  return (
    <>
      {urls.map((url) => <AuthImage className="rounded" srcPath={url} key={url}/> )}
    </>
  )
}

const Section = ({content}: {
  content: CountryReportContent
}) => {
  const el = (
    <>
      {content.content &&
        <SectionContent>{content.content}</SectionContent>
      }
      {content.images &&
        <SectionImages urls={content.images.map(i => `/s3files/${i}`)}/>
      }
    </>
  )
  switch (content.type) {
    case 'title':
      return <h1 className="display-4">{el}</h1>
    case 'subtitle':
      return <p className="lead">{el} </p>
    case 'heading_1':
      return <h1>{el}</h1>
    case 'heading_2':
      return <h2>{el}</h2>
    case 'heading_3':
      return <h3>{el}</h3>
    case 'heading_4':
      return <h4>{el}</h4>
    case 'normal_text':
      return <p>{el}</p>
    case 'bullet':
      return <ul><li>{el}</li></ul>
    default:
      return <p>{el}</p>
  }
}

const Preview = ({countryReport}: {
  countryReport: CountryReport
}) => {
  let sections = []
  let paragraphs = 1 // Number of paragraphs to display in preview
  for (let i = 0; i < countryReport.content.length; i++) {
    if (countryReport.content[i].type === 'normal_text') {
      sections.push(<Section content={countryReport.content[i]} key={`preview-${i}`}/>)
      if (sections.length >= paragraphs) break
    }
  }
  return <>{sections}</>
}

export function CountryReportDocument({
  countryReport,
  preview
}: {
  countryReport: CountryReport,
  preview?: boolean
}): JSX.Element {
  return (
    <div className="document">
      {preview ?
        <Preview countryReport={countryReport}/>
        :
        countryReport.content.map((content, index) => {
          // Don't render title if is first content element
          if (!(index === 0 && content.type === 'title')) {
            return <Section content={content} key={index}/>
          } else {
            return <span key={index}></span>
          }
        })
      }
    </div>
  )
}
