import {createContext, ReactNode, useContext} from 'react';

export type ModalContent = {
  openModal: (onClose: () => void, children?: ReactNode) => void
  closeModal: () => void
}
export const ModalContext = createContext<ModalContent>({
  openModal: () => null,
  closeModal: () => null,
})

export const useModalContext = () => useContext(ModalContext)

