import {useState } from 'react'
import {FilterFormFooter} from 'components/filterform/FilterFormFooter'
import {Modal} from 'components';
import {SaveNameForm} from 'components/filterform/SaveNameForm';
import {ModalSavedSuccessfully} from 'components/ModalSavedSuccessfully';

export function FilterFormSave(
  {
    touchForm,
    validate,
    applyFilter,
    saveFilter,
    cancel,
    closeAfterSaving,
    viewSaved,
    valid,
    namePlaceholder,
    savedTitle,
    typeBeingSaved,
    filterId,
    filterName,
  } :
  {
    touchForm?: () => void
    validate?: () => boolean
    applyFilter: (id: number | undefined, name: string) => void
    saveFilter: (name: string) => Promise<number>
    cancel: () => void
    closeAfterSaving: (id: number, name: string) => void
    viewSaved: () => void
    valid?: boolean
    namePlaceholder: string
    savedTitle: string
    typeBeingSaved: string
    filterId: number | undefined
    filterName: string
  }
  ): JSX.Element {

  const [saveMode, setSaveMode] = useState<'normal' | 'save' | 'confirm'>('normal')
  const [savedId, setSavedId] = useState<number | undefined>(filterId)
  const [savedName, setSavedName] = useState<string>(filterName)

  function handleApplyFilters(): void {
    if (touchForm)
      touchForm()
    if (typeof validate !== 'undefined' && !validate())
      return;

    applyFilter(savedId, savedName)
  }

  function validateSaveName(): boolean {
    if (touchForm)
      touchForm()
    if (typeof validate !== 'undefined' && !validate())
      return false

    return true
  }

  async function handleSaveFilter(name: string) {
    const filterId = await saveFilter(name)
    setSavedName(name)
    setSavedId(filterId)
  }

  function handleFilterClose(cancelled: boolean) {
    if (cancelled)
      setSaveMode('normal')
    else
      setSaveMode('confirm')
  }

  function handleCancel(): void {
    cancel()
  }

  function handleViewSaved() {
    viewSaved()
  }

  function handleCloseAfterSaving() {
    setSaveMode('normal')
    closeAfterSaving(savedId!, savedName)
  }

  return (
    <>
      {saveMode === 'normal' &&
      <FilterFormFooter>
        <div className="filters-form-footer-btns">
          <button type="button" className={'btn' + ((typeof valid === 'undefined') || valid ? ' btn-primary' : ' btn-dark')} disabled={(typeof valid !== 'undefined') && !valid} onClick={handleApplyFilters}>Apply Filters</button>
          <button type="button" className="btn btn-dark" disabled={typeof valid !== 'undefined' && !valid} onClick={() => setSaveMode('save')}>Save Search</button>
          <button type="button" className="btn btn-dark" onClick={handleCancel}>Cancel</button>
        </div>
      </FilterFormFooter>
      }
      {saveMode === 'save' &&
      <FilterFormFooter>
        <SaveNameForm
          namePlaceholder={namePlaceholder}
          validate={validateSaveName}
          save={handleSaveFilter}
          close={handleFilterClose}
          typeBeingSaved={typeBeingSaved}
          defaultName={filterName}
        />
      </FilterFormFooter>
      }
      {saveMode === 'confirm' &&
        <ModalSavedSuccessfully
          title={savedTitle}
          subtitle={`The custom filter setting "${savedName}" has been saved to your “Saved Items”`}
          close={() => setSaveMode('normal')}
          buttons={<>
            <button type="button" className="btn btn-primary" onClick={handleApplyFilters}>View Filter Results</button>
            <button type="button" className="btn btn-dark" onClick={handleViewSaved}>View Saved Items</button>
            <button type="button" className="btn btn-dark" onClick={handleCloseAfterSaving}>Cancel</button>
          </>
          }
        />
      }
    </>
  )
}
