import {ReactNode, useEffect} from 'react';
import {useModalContext} from 'context';

export function Modal(
  {
    children,
    close,
    showCloseButton
  }: {
    children: ReactNode
    close: () => void
    showCloseButton?: boolean
  }
): JSX.Element {

  const { openModal, closeModal } = useModalContext()

  useEffect(() => {

    const modalChildren = <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-body" onClick={e => e.stopPropagation()}>
          {showCloseButton &&
            <button type="button" 
              className="close" 
              style={{marginTop: '-15px', marginRight: '-15px'}}
              onClick={close} 
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          }
          {children}
        </div>
      </div>
    </div>

    openModal(() => close(), modalChildren)

    return closeModal
  }, [])

  return (
    <div style={{display: 'none'}}></div>
  )
}
