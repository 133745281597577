import {
  emptyToAry,
  emptyToUndef, 
  parseEnum,
  parseInteger,
  parseModifiedWithin,
  parseStr,
  toInt, toInts, undefToEmpty
} from 'models/converters/ConverterUtil';
import {
  AlertFilterForm,
  AlertsSavedItemReq,
  SearchGroupOptions
} from 'models';
import {AlertsFilterQueryReq, AlertsFilterSavedItemSubRes} from 'models/Alert';


export function qsToAlertForm(query: URLSearchParams): AlertFilterForm {
  return {
    text: parseStr(query.get('text')),
    city_ids: emptyToAry(query.getAll('city_ids')),
    continent_ids: emptyToAry(query.getAll('continent_ids')),
    country_ids: emptyToAry(query.getAll('country_ids')),
    region_ids: emptyToAry(query.getAll('region_ids')),
    updated_days: parseModifiedWithin(query.get('updated_days')),
    search_group: parseEnum(query.get('search_group'), SearchGroupOptions),

    page: emptyToUndef(query.get('page') ?? ''),
    per_page: emptyToUndef(query.get('per_page') ?? ''),

    filterId: toInt(parseInteger(query.get('filterId'))),
    filterName: parseStr(query.get('filterName')),
  }
}



// Used when querying with the filter
export function alertToQueryReq(filter: AlertFilterForm): AlertsFilterQueryReq {
  return  {
    text: emptyToUndef(filter.text),
    'city_ids[]': emptyToUndef(toInts(filter.city_ids || [])),
    'country_ids[]': emptyToUndef(toInts(filter.country_ids)),
    'region_ids[]': emptyToUndef(toInts(filter.region_ids)),
    'continent_ids[]': emptyToUndef(toInts(filter.continent_ids)),
    updated_days: toInt(filter.updated_days),
    search_group: emptyToUndef(filter.search_group),

    page: toInt(filter.page ?? ''),
    per_page: toInt(filter.per_page ?? ''),

    order: 'start_date',
    sort_mode: 'desc',
  }
}


// Used when reading a saved filter to edit it
export function alertSavedItemResToForm(filterId: number, filterName: string, alerts: AlertsFilterSavedItemSubRes): AlertFilterForm {
  return {
    text: parseStr(alerts?.text),
    region_ids: alerts.region_ids,
    country_ids: alerts.country_ids,
    continent_ids:  alerts.continent_ids,
    updated_days: parseModifiedWithin(alerts?.updated_days),
    search_group: parseEnum(alerts?.search_group, SearchGroupOptions),

    filterId,
    filterName: undefToEmpty(filterName),
  }
}



// Used when saving a filter
export function alertToSavedItemReq(filter: AlertFilterForm): AlertsSavedItemReq {

  return  {
    'search[name]': filter.filterName,

    'search[filters][alerts][text]': emptyToUndef(filter.text),
    'search[filters][alerts][country_ids][]': emptyToUndef(toInts(filter.country_ids || [])),
    'search[filters][alerts][region_ids][]': emptyToUndef(toInts(filter.region_ids|| [])),
    'search[filters][alerts][continent_ids][]': emptyToUndef(toInts(filter.continent_ids || [])),
    'search[filters][alerts][updated_days]': toInt(filter.updated_days),
    'search[filters][alerts][search_group]': emptyToUndef(filter.search_group),
  }

}




