import {EntityRiskVm, EntityRiskResult, ErtFilterFormVm, ErtFilterFormQs} from 'models';
import {EntityRiskFilterForm, EntityTabbedNav} from 'apps/entityrisktracker/components/EntityRiskFilters';

export function EntityFilters(
  {
    origFilter,
    Paths,
    filterOptions,
    filterCount,
    setFilterCount,
  }: {
    origFilter: ErtFilterFormQs
    Paths: { list: string, events: string, upload: string, filters: string }
    filterOptions: EntityRiskResult[]
    filterCount: number
    setFilterCount: (cnt: number) => void
  }
): JSX.Element {




  return (
    <>
      <EntityRiskFilterForm
        origFilter={origFilter}
        filterOptions={filterOptions}
        Paths={Paths}
        filterCount={filterCount}
        setFilterCount={setFilterCount}
      />
    </>

  )
}
