export default function EntityRiskTrackerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.5" height="21.5" viewBox="0 0 21.5 21.5">
      <g transform="translate(0.75 0.75)">
      <path className="icon-stroke" d="M4,2A2,2,0,1,1,3.414.586,1.994,1.994,0,0,1,4,2Z" transform="translate(8 8)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="1.5"/>
      <path className="icon-stroke" d="M12,6a6,6,0,1,1-1.757-4.243" transform="translate(4 4)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="1.5"/>
      <path className="icon-stroke" d="M11.414,8.586l5.657-5.657A10,10,0,1,0,20,10" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="1.5"/>
      </g>
    </svg>
  )
}