import {useState} from 'react';
import {useAppContext} from 'context';
import {UserApi} from 'apis';
import {Alert} from 'components';

export function ChangePasswordModalContents(
  {
    onClose,
    token,
  } :{
    onClose: () => void
    token: string | undefined
  }
): JSX.Element {

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [passwordSaved, setPasswordSaved] = useState<boolean>(false)


  function beginSave() {
    (async () => {
      try {
        const resp = await new UserApi(token).changePassword({
          password: newPassword,
          current_password: currentPassword
        })
        if (resp.success) {
          setPasswordSaved(true)
          return
        }
        setErrors(resp.errors)
      }
      finally {
        setSaving(false)
      }
    })().catch((e) => {setErrors([e])})
  }

  function valid(): boolean {
    if (newPassword === '') {
      setErrors(['New Password is required'])
      return false
    }
    if (newPassword !== confirmPassword) {
      setErrors(['The passwords do not match'])
      return false
    }
    return true
  }

  function handleChangePassword(): boolean {
    if (valid()) {
      setSaving(true)
      beginSave()
    }
    return false
  }

  return (
    <>
      <div className="modal-title mb-4">Change Password</div>
      <div className="modal-text mb-6">
        <form onSubmit={handleChangePassword}>
          <div className="mb-3">
            <label htmlFor="currentPassword" className="form-label">Current Password</label>
            <input
              type="password"
              className="form-control"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="newPassword" className="form-label">New Password</label>
            <input
              type="password"
              className="form-control"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </form>
      </div>
      {errors && errors.length > 0 &&
        <Alert type='danger'>
          <ul className="mb-0">{errors.map(e => <li key={e}>{e}</li>)}</ul>
        </Alert>
      }
      <div className="modal-text mb-6">
        Password must have:
        <ul>
          <li>12 or more characters</li>
          <li>At least 1 uppercase, 1 lowercase, 1 digit, and 1 special character (#?!@$%^&*-)</li>
        </ul>
      </div>
      <div className="modal-btns d-flex justify-content-between">
        {passwordSaved && 
          <button type="button" className="btn btn-success" onClick={onClose}>Password Updated!</button>
        }
        {!passwordSaved &&
          <button type="button" className="btn btn-primary" onClick={handleChangePassword} disabled={saving}>Save</button>
        }
        <button type="button" className="btn btn-dark" onClick={onClose}>Close</button>
      </div>
    </>
  )
}
