import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAppContext } from 'context'

import {AlertApi, buildQueryParams} from 'apis'
import {
  Card,
  DateRange,
  LoadingComponent
} from 'components'
import { CaretRightSmallIcon } from 'components/icons/CaretRightSmallIcon'
import { handleAsyncLoader } from 'lib'
import {
  AlertFilterForm,
  AlertsFilterQueryReq,
  AlertsSearchItem,
  ErrorDetail
} from 'models'
import {AlertPaths} from 'apps/alerts/Alerts';
import {UpdatedDaysType} from 'data';
import {qsToAlertForm} from 'models/converters';

const SearchParams:AlertsFilterQueryReq = {
  page: 1,
  per_page: 10,
  order: 'start_date',
  sort_mode: 'desc'
}

export const AlertsWidget = ({
  className
}: {
  className: string
}): JSX.Element => {

  const { session } = useAppContext()

  const [list, setList] = useState<AlertsSearchItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()


  function toAlertSearchResults(updated_days: UpdatedDaysType) {
    return `${AlertPaths.list}?${buildQueryParams<AlertFilterForm>({
      ...qsToAlertForm(new URLSearchParams()),
      updated_days
    }, true)}`
  }

  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setList((await new AlertApi(session.token).search(SearchParams)).alerts)
    }
  ), [])

  return (
    <Card className={className} 
      title={
        <>
        <div className="d-flex flex-column flex-md-row">
          <div className="mb-2 mb-md-0">Recent Alerts</div>
        </div>
        <div className="d-flex flex-column flex-md-row">
          <small>
            <Link className="dashboard-link" to={toAlertSearchResults('7')}>last week <CaretRightSmallIcon/></Link>
          </small>
          <small className="dashboard-link pl-md-2 ml-md-auto">
            <Link className="dashboard-link" to={toAlertSearchResults('')}>all <CaretRightSmallIcon/></Link>
          </small>
        </div>
        </>
      }
    >
      <LoadingComponent loading={loading} error={error} marginTopPx={60}>
        {list.map(({id, title, status, cities, start_date, end_date}) => (
          <div className="mb-5" key={id}>
            <div><Link to={`/alerts/${id}`}>{title}</Link></div>
            <small>
              <div
                className={`dot dot-left ${status === 'Active' ? 'active' : ''}`}
              >
                {status}
              </div>
              <div>
                {cities && cities[0] && cities[0].city.display_name}
              </div>
              <div>
                <DateRange startDate={start_date} endDate={end_date} />
              </div>
            </small>
          </div>
        ))}
      </LoadingComponent>
    </Card>
  )
}
