export default function AlarmIcon(
  {
    color
  } : {
    color?: string
  }
) {
  color = color ?? '#f06c00'
  return (
    <svg className="icon icon-alarm" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="17" height="20" viewBox="0 0 17 20"><defs><clipPath id="icon-alert-clip-path"><path d="M0,0H17V20H0Z" fill="none"/></clipPath></defs><g><path d="M1.333,4.522V.812A.759.759,0,0,0,.667,0,.759.759,0,0,0,0,.812v3.71a.744.744,0,0,0,.667.812A.759.759,0,0,0,1.333,4.522Z" transform="translate(7.833 5)" fill={color} /><path d="M.667,0A.667.667,0,1,1,0,.667.667.667,0,0,1,.667,0" transform="translate(7.833 11.667)" fill={color} /><g><path d="M0,0H17V20H0Z" fill="none"/><g clipPath="url(#icon-alert-clip-path)"><path d="M8.491,20a3.419,3.419,0,0,1-3.427-3.4H1.954A1.937,1.937,0,0,1,.389,13.487l1.4-1.849V8.277a6.6,6.6,0,0,1,1.2-3.805A6.675,6.675,0,0,1,6.143,2.038,2.048,2.048,0,0,1,8.2,0h.614a2.037,2.037,0,0,1,2.052,2.017,6.694,6.694,0,0,1,4.358,6.239v3.361l1.4,1.849a1.943,1.943,0,0,1,.169,2.059A1.977,1.977,0,0,1,15.029,16.6h-3.11A3.42,3.42,0,0,1,8.491,20Zm-1.84-3.424a1.862,1.862,0,0,0,3.723,0ZM8.216,1.534a.474.474,0,0,0-.486.483v.567a.77.77,0,0,1-.571.757A5.127,5.127,0,0,0,3.371,8.256V11.87a.72.72,0,0,1-.148.462L1.679,14.391a.376.376,0,0,0,.3.609H15.05a.373.373,0,0,0,.331-.2l.008-.011a.321.321,0,0,0-.042-.4L13.8,12.332a.854.854,0,0,1-.148-.462V8.256A5.169,5.169,0,0,0,9.887,3.319a.786.786,0,0,1-.571-.756V2.017a.474.474,0,0,0-.487-.483Z" fill={color} /></g></g></g></svg>

  )
}
