import { Link } from 'react-router-dom'
import { Alert } from 'models'
import { CurrencyValue, DateView } from 'components'
import {AlertPaths} from 'apps/alerts/Alerts';

export const AlertTransactionsList = ({
  alert
}: {
  alert: Alert
}): JSX.Element => {
  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Title</th>
            <th>Value</th>
            <th>Status</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Industries</th>
            <th>Cities</th>
          </tr>
        </thead>
        <tbody>
          {alert.transactions.map(({transaction}) => (
            <tr key={transaction.id}>
              <td>
                <Link to={`/transactions/${transaction.id}`}>
                  {transaction.title}
                </Link>
              </td>
              <td><CurrencyValue value={transaction.project_value}/></td>
              <td>{transaction.status}</td>
              <td>
                <DateView type="dateshort">{transaction.start_date}</DateView>
              </td>

              <td>
                <DateView type="dateshort">{transaction.end_date}</DateView>
              </td>
              <td>
                {transaction.industries.map(
                  ({industry}) => industry.name
                ).join(", ")}
              </td>
              <td>
                {transaction.cities && transaction.cities.length > 0 &&
                  <Link key={transaction.cities[0].city.id}
                    to={`${AlertPaths.list}?city_ids[]=${transaction.cities[0].city.id}`}
                  >
                    {transaction.cities[0].city.display_name}
                  </Link>
                }
                {transaction.cities && transaction.cities.length > 1 &&
                  <span className="pl-1">and {transaction.cities.length - 1} more...</span>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
