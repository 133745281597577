import { MouseEvent } from 'react'

interface TableSelectParams {
  checked: boolean,
  id: number,
  onChange: (event: React.ChangeEvent) => void,
  label?: string
}

export function TableSelect({checked, id, onChange, label}: TableSelectParams) {
  return (
    <div className="form-check" onClick={e => {e.stopPropagation()}}>
      <input className="form-check-input"
        id={`form_check_input_${id}`}
        type="checkbox"
        checked={checked}
        onChange={(e) => {onChange(e)}}
       />
      <label className="form-check-label" htmlFor={`form_check_input_${id}`}>{label}</label>
    </div>
  )
}
