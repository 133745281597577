import {
  Switch,
  Route,
  RouteComponentProps, useLocation,
} from 'react-router-dom'
import {
  MainContainer,
  PageContainer,
} from "components"
import { CountryReportView } from './CountryReportView'
import {CountryReportHome} from 'apps/countryreports/CountryReportHome';
import {useEffect, useState} from 'react';
import {AlertPaths} from 'apps/alerts/Alerts';




export enum CountryReportPaths {
  list = '/countryreports/list',
  map = '/countryreports/map',
}


const HomePaths = [
  '/countryreports', // assume map
  CountryReportPaths.list,
  CountryReportPaths.map,
]

export function CountryReports(
  {
    searchBar
  }: {
    searchBar: JSX.Element
  }): JSX.Element {

  const location = useLocation()

  const [backPath, setBackPath] = useState(HomePaths.includes(location.pathname) ? location.pathname : AlertPaths.list)

  useEffect(() => {
    if (HomePaths.includes(location.pathname))
      setBackPath(location.pathname + location.search)
  }, [location.pathname, location.search])


  return (
    <PageContainer navItem="countryreports">
      <Switch>
        <Route exact path={HomePaths}>
          <CountryReportHome searchBar={searchBar} />
        </Route>
        <Route exact path="/countryreports/:slug" 
          render={({match}: RouteComponentProps<{slug: string}>) => (
            <MainContainer searchBar={searchBar} rightContentActive>
              <CountryReportView
                slug={match.params.slug}
                backPath={backPath}
              />
            </MainContainer>
          )} />
      </Switch>
    </PageContainer>
  );
}
