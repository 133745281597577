import {Link, useLocation} from 'react-router-dom';
import {ResetXIcon} from 'components/icons/ResetXIcon';
import {TranPaths} from 'apps/transactions/Transactions';
import {DropdownList} from 'components/DropdownList';
import {SortByOptionsT, SearchGroupOptionsT} from 'models';
import {TransactionYearSlider} from 'apps/transactions/components/TransactionYearSlider';
import {NavDropdown} from 'components/NavDropdown';


export enum TranListSubTab {
  Transactions = 'Transactions',
  Related = 'Related',
  Filters = 'Filters'
}



export type SortByType = {
  name: string
  code: SortByOptionsT
}

export const SortBy: SortByType[] = [
  {
    name: 'Citation Date',
    code: 'date',
  },
  {
    name: 'Start Date',
    code: 'start_date',
  },
  {
    name: 'End Date',
    code: 'end_date',
  },
  {
    name: 'Project Value',
    code: 'project_value',
  },
]


export function TransactionListSubNav(
  {
    filterCount,
    subTab,
    searchGroup,
    onSearchGroupChange,
    year,
    onYearChange,
    sortBy,
    onSortChange,
    reset,
    transactionListUrl,
  }: {
    filterCount: number
    subTab: TranListSubTab
    searchGroup: SearchGroupOptionsT
    onSearchGroupChange: (val: SearchGroupOptionsT) => void
    year: number | undefined
    onYearChange: (val: number | undefined) => void
    sortBy: SortByType
    onSortChange: (val: SortByType) => void
    reset: () => void
    transactionListUrl: string
  }
): JSX.Element {

  const location = useLocation()

  const filterPath = location.pathname === TranPaths.related
    ? TranPaths.relatedFilters + location.search
    : TranPaths.listFilters + location.search




  return (
    <>
      <TransactionYearSlider
        year={year}
        setYear={onYearChange}
        isFiltersTab={subTab === TranListSubTab.Filters}
      />
      <div className="filters-header py-1">
        <div className="d-flex flex-wrap flex-xl-nowrap">
          <NavDropdown
            title="Country"
            className="flex-fill col-6"
            value={searchGroup}
            setValue={val => onSearchGroupChange(val)}
            options={[
              ['all', 'All'],
              ['china', 'China'],
              ['russia', 'Russia'],
            ]}
          />
          <div className="filters-header-item flex-fill col-6">
            <Link to={transactionListUrl} className={'btn btn-gray-light btn-sm ' + (subTab === TranListSubTab.Transactions ? 'active' : '')}>Transactions</Link>
          </div>
          {/*<div className="filters-header-item flex-grow-1">
            <Link to={TranPaths.related + buildQuery({...filter, page: 1}, true)} className={'btn btn-gray-light btn-sm ' + (subTab === TranListSubTab.Related ? 'active' : '')}>Related Entities</Link>
          </div>*/}
          <NavDropdown
            title="Sort By"
            className="flex-fill col-12 col-sm-6"
            value={sortBy.code}
            setValue={val => onSortChange(SortBy.filter(s => s.code === val)[0])}
            options={SortBy.map(s => [s.code, s.name])}
          />
          <div className="filters-header-item flex-fill col-6 col-sm-3 col-xl-2">
            <Link
              to={filterPath}
              className={'btn btn-gray-light btn-sm text-uppercase btn-filters w-100' + (subTab === TranListSubTab.Filters ? ' active' : '')}
            >
              Filters {filterCount > 0 && <span className="badge badge-primary ml-2">{filterCount}</span>}
            </Link>
          </div>
          <div className="filters-header-item flex-fill col-6 col-sm-3 col-xl-2">
            <button type="button" className="btn btn-gray-light btn-sm text-uppercase btn-reset w-100" onClick={reset}>
              Reset
              <ResetXIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
