import {AlertFilterForm, AlertFilterCategory} from 'models';


export function calcAlertCategories(filter: AlertFilterForm): AlertFilterCategory[] {

  return [
    ((filter.country_ids.length !== 0
      || filter.region_ids.length !== 0
      || filter.continent_ids.length !== 0) ? AlertFilterCategory.Location : null),

    ((filter.text !== '') ? AlertFilterCategory.Keywords : null),

    ((filter.updated_days !== '') ? AlertFilterCategory.Date : null)
  ]
    .filter(c => c !== null) as AlertFilterCategory[]

}



export function countAlertFilters(filter: AlertFilterForm) : number {
  return calcAlertCategories(filter).length
}
