import {ReactNode, useEffect, useState} from 'react'

export const FilterFormSection = (
  {
    className,
    active,
    title,
    activeFilters,
    bodyClass = 'row',
    children,
    defaultExpanded,
  }: {
    className?: string
    active?: boolean
    title: string
    activeFilters?: ReactNode
    bodyClass?: string
    children: ReactNode
    defaultExpanded?: boolean
  }): JSX.Element => {

  const [expanded, setExpanded] = useState<boolean>(true)
  const [defaultSet, setDefaultSet] = useState<boolean>(false)

  useEffect(() => {
    if (typeof defaultExpanded !== 'undefined' && !defaultSet) {
      setExpanded(defaultExpanded)
      setDefaultSet(true)
    }
  }, [defaultExpanded, defaultSet])

  return (
    <div className="card">
      <div className={`card-header ${active ? 'active' : 'inactive'}`}>
        <div className="row align-items-center">
          <div className="col-sm-4">
            <button
              type="button"
              className="btn btn-link btn-block text-left"
              onClick={() => setExpanded(!expanded)}
            >
              {title}
            </button>
          </div>
          <div className="col-sm-8">
            {activeFilters}
          </div>
        </div>
      </div>
      { expanded &&
        <div className="card-body">
          <div className={`form-row ${bodyClass}`}>
            {children}
          </div>
        </div>
      }
    </div>
  )

}
