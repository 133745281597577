import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'context'
import {buildQueryParams, EntityRiskTrackerApi} from 'apis'
import {
  Card,
  LoadingComponent
} from 'components'
import { CaretRightSmallIcon } from 'components/icons/CaretRightSmallIcon'
import { handleAsyncLoader } from 'lib'
import {
  Entity,
  EntityRiskResult,
  ErrorDetail, ErtFilterFormQs,
  ErtFilterQueryReq
} from 'models'

import { EntityTable } from 'apps/entityrisktracker/components/EntityTable'
import {qsToErtForm} from 'models/converters';
import {ErtPaths} from 'apps/entityrisktracker/EntityRiskTracker';

const SearchParams:ErtFilterQueryReq = {
  page: 1,
  per_page: 5,
  // order: 'updated_at',
  // sort_mode: 'desc'
}

export const EntitiesWidget = ({
  className
}: {
  className: string
}): JSX.Element => {

  const { session } = useAppContext()

  const [list, setList] = useState<Entity[]>([])
  const [risks, setRisks] = useState<EntityRiskResult[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()


  function toErtSearchResults() {
    return `${ErtPaths.list}?${buildQueryParams<ErtFilterFormQs>({
      ...qsToErtForm(new URLSearchParams()),

    }, true)}`
  }

  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      await Promise.all([
        setList((await new EntityRiskTrackerApi(session.token).search(SearchParams)).results),   
        setRisks((await new EntityRiskTrackerApi(session.token).risks()))
      ])
    }
  ), [])

  return (
    <Card className={className} 
      title={
        <div className="d-flex flex-column flex-md-row">
          <div className="mb-2 mb-md-0">Entity Risk Tracker</div>
          <small className="pl-md-2">
            <Link className="dashboard-link" to={toErtSearchResults}>most recently updated <CaretRightSmallIcon/></Link>
          </small>
        </div>
      }
    >
      <LoadingComponent loading={loading} error={error}>
        <EntityTable entities={list} risks={risks} compact={true}/>
      </LoadingComponent>
    </Card>
  )


}
