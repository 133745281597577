import { ModalContext } from 'context';
import {ReactNode, useState} from 'react';

export function ModalBackdrop(
  {
    children,
  }: {
    children: ReactNode
  }
): JSX.Element {

  const [showBackdrop, setShowBackdrop] = useState(false)
  const [modalCxt, setModalCxt] = useState<{close: () => void, children?: ReactNode}>({close: () => null})

  function handleClose() {
    setShowBackdrop(false)
    modalCxt.close()
    setModalCxt({close: () => null})
  }

  function openModal(onClose: () => void, children?: ReactNode): void {
    setModalCxt({close: () => onClose(), children})
    setShowBackdrop(true)
  }

  function closeModal(): void {
    handleClose()
  }

  return (
    <ModalContext.Provider value={{
      openModal,
      closeModal
    }}>
      {children}
      {showBackdrop &&
        <>
          <div style={{display: 'block'}} className="modal fade show" data-backdrop="static"
               data-keyboard="false" tabIndex={-1} aria-hidden="true" onClick={handleClose}>
            { modalCxt.children }
          </div>
          <div style={{display: 'block'}} className="modal-backdrop fade show"></div>
        </>
      }
    </ModalContext.Provider>
  )
}
