import {Login} from 'apps/login/Login';
import {SessionUser} from 'App';
import {useState} from 'react';
import {ForgotPassword} from 'apps/login/ForgotPassword';
import logo from 'images/logo.svg';
import {MainContainer} from 'components';

import './Login.scss'

export type LoginMode = 'login' | 'forgot-password'

export function LoginHome(
  {
    setSessionUser
  } : {
    setSessionUser: (sessionUser: SessionUser) => void
  }
): JSX.Element {

  const [mode, setMode] = useState<LoginMode>('login')
  const [email, setEmail] = useState<string>("")

  return (

    <MainContainer className="bg-light" fullscreen>
      <div className="login card">
        <div className="card-body">
          <div className="title">
            <img src={logo} alt="Intel Trak" />
          </div>
            {mode === 'login'
            && <Login
              setSessionUser={setSessionUser}
              setMode={setMode}
              email={email}
              setEmail={setEmail}
            />
            }
            {mode === 'forgot-password'
            && <ForgotPassword
              setMode={setMode}
              email={email}
              setEmail={setEmail}
            />
            }
        </div>
      </div>
    </MainContainer>
  )
}
