import { EntityModel } from 'models'
import { Api } from './Api'

const URLS = {
  entity: '/entities/:id'
}

export class EntityApi extends Api {

  async id(id: number): Promise<EntityModel> {
    const url = URLS.entity.replace(":id", id + "")
    const entity = (await this.get<{'entity': EntityModel}>(url)).data
    return entity.entity
  }

}
