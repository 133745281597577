import {CountryReport} from 'models';
import { Link } from 'react-router-dom';
import {orderBy} from 'lib';


interface CountryReportTransactionsParams {
  countryReport: CountryReport
}



export const CountryReportTransactions = ({
  countryReport
}: CountryReportTransactionsParams): JSX.Element => (
  <>
    <div className="tab-content">
      <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="home-tab">
        <div className="content-sidebar-subtitle px-4 pt-2 pb-2">Key Transactions</div>

        <ol className="list-numeric">
          {countryReport.transactions.sort(orderBy(t => t.transaction.date)).map(t =>
            <li key={t.transaction.id} className="list-numeric-item">
              <Link to={`/transactions/${t.transaction.id}`}>{t.transaction.name}</Link>
            </li>)}
        </ol>
      </div>
    </div>
  </>
)
