import {ReactElement, useEffect, useMemo, useState} from 'react'

import { useAppContext } from 'context'
import {AlertApi, EntityRiskTrackerApi, TransactionsApi} from 'apis'
import {
  AlertsSearchItem,
  Entity,
  EntityRiskDesignations,
  ErrorDetail,
  TransactionsSearchItem,
  EntityRiskEventsRes, Pager, EntityRiskDesignation, EntityRiskEvent, EntityRelatedRisk, EntityRelatedRisksRes
} from 'models'
import {
  DropdownMenu,
  LoadingComponent, PagerControl, SectionedContent, TabbedContainer, TabbedContainerTab,
} from 'components'
import {CsvDownload} from 'components/CsvDownload';

import { EntityRiskEvents } from './components/EntityRiskEvents'
import { EntitySidebar } from './components/EntitySidebar'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {handleAsyncLoader} from 'lib';
import {TabbedContainerTabParams} from 'components/TabbedContainer';
import AddIcon from 'components/icons/AddIcon';
import {SavedItemsApi} from 'apis/SavedItemsApi';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';
import {DropdownItem} from 'components/DropdownMenu';
import {TrashIcon} from 'components/icons/TrashIcon';
import {ModalSavedItemSuccessfully, SavedItemState} from 'components/ModalSavedItemSuccessfully';
import {PrintFooter} from 'components/PrintFooter';
import PrinterIcon from 'components/icons/PrinterIcon';
import EmailIcon from 'components/icons/EmailIcon';
import {EntityAffiliatedRisks} from 'apps/entityrisktracker/components/EntityAffiliatedRisks';


interface EntityVm {
  entity: Entity
  notify: 'saved' | 'removed' | 'email'
}


enum SubTab {
  Any = '',
  Summary = '#summary',
  RiskEvents = '#risk_events',
  AffiliatedRisks = '#affiliated_risks',
}

interface TabVm {
  available: boolean
  tab: SubTab
  pager?: JSX.Element
}

interface TableState<T> {
  all: T[]
  total: number
  page: number
  pageSize: number
  sortCol: keyof T
  sortDirAsc: boolean
  loading: boolean
  filterText: string
}

export type RiskEventColumns = keyof EntityRiskDesignation
export type RelatedRiskEventColumns = keyof EntityRiskEvent

function titleCount(count: number|undefined): string {
  return count && count > 0 ? ` (${count})` : '';
}

function filterPage<T>(all: T[], page: number, pageSize: number, sortCol: keyof T, sortDirAsc: boolean): T[] {
  const pageOffset = page - 1 // page is 1-based

  const firstIndex = pageOffset * pageSize
  const lastIndex = (pageOffset + 1) * pageSize - 1

  const sortFuncAsc = (a: T, b: T) => {
    const aVal = a[sortCol] ?? ''
    const bVal = b[sortCol] ?? ''
    return aVal > bVal ? 1 : aVal < bVal ? -1 : 0
  }
  const sortFuncDesc = (a: T, b: T) => {
    const aVal = a[sortCol] ?? ''
    const bVal = b[sortCol] ?? ''
    return aVal > bVal ? -1 : aVal < bVal ? 1 : 0
  }


  return all
    .sort(sortDirAsc ? sortFuncAsc : sortFuncDesc)
    .filter((e, i) => i >= firstIndex && i <= lastIndex)
}
function buildPaginate<T>(total: number, page: number, pageSize: number): Pager {
  return {
    page,
    per_page: pageSize,
    total_records: total,
    total_pages: Math.floor((total - 1) / pageSize) + 1
  }
}

function pageAndFilterRisks(table: TableState<EntityRiskDesignation>): EntityRiskDesignations {
  const filtered = table.all.filter(whereRisk(table.filterText))
  return {
    results: filterPage(filtered, table.page, table.pageSize, table.sortCol, table.sortDirAsc),
    paginate: buildPaginate(table.total, table.page, table.pageSize)
  }
}


function pageAndFilterEvents(table: TableState<EntityRiskEvent>, isAffiliatedEvents: boolean): EntityRiskEventsRes {
  const filtered = table.all.filter(whereRiskEvent(table.filterText, isAffiliatedEvents))
  return {
    results: filterPage(filtered, table.page, table.pageSize, table.sortCol, table.sortDirAsc),
    paginate: buildPaginate(table.total, table.page, table.pageSize)
  }
}


function whereRisk(filterText: string): (r: EntityRiskDesignation) => boolean {
  if (filterText.trim() === '')
    return () => true

  const lowerFilterText = filterText.toLocaleLowerCase()

  return (f: EntityRiskDesignation) => f.risk.toLocaleLowerCase().includes(lowerFilterText)
    || f.risk_score.toLocaleString().includes(lowerFilterText)
    || f.summary.toLocaleLowerCase().includes(lowerFilterText)
    || f.type.toLocaleLowerCase().includes(lowerFilterText)
    || f.parent.toLocaleLowerCase().includes(lowerFilterText)
}

function whereRiskEvent(filterText: string, isAffiliatedEvents: boolean): (r: EntityRiskEvent) => boolean {
  if (filterText.trim() === '')
    return () => true

  const lowerFilterText = filterText.toLocaleLowerCase()

  return (f: EntityRiskEvent) => !!f.location?.toLocaleLowerCase().includes(lowerFilterText)
    || !!f.date?.includes(lowerFilterText)
    || f.event.toLocaleLowerCase().includes(lowerFilterText)
    || f.summary.toLocaleLowerCase().includes(lowerFilterText)
    || (isAffiliatedEvents && f.company.toLocaleLowerCase().includes(lowerFilterText))
    || (isAffiliatedEvents && !!f.relation?.toLocaleLowerCase().includes(lowerFilterText))
}

export function EntityView(
  {
    id,
    backPath,
    setPager,
  }: {
    id: number
    backPath: string
    setPager: (val?: JSX.Element) => void
  }) {

  const { session, user  } = useAppContext()

  const history = useHistory()
  const location = useLocation()

  const [entity, setEntity] = useState<EntityVm>()
  const [relatedTransactions, setRelatedTransactions] = useState<TransactionsSearchItem[]>()
  const [relatedAlerts, setRelatedAlerts] = useState<AlertsSearchItem[]>()

  const [savedItemState, setSavedItemState] = useState(SavedItemState.none)

  const [riskEventsTable, setRiskEventsTable] = useState<TableState<EntityRiskDesignation>>({
    all: [],
    total: 0,
    loading: true,
    page: 1,
    pageSize: 25,
    sortCol: 'risk_score',
    sortDirAsc: false,
    filterText: '',
  })

  const [relatedRisksTable, setRelatedRisksTable] = useState<{ loading: boolean, all: EntityRelatedRisk[], total?: number}>({
    all: [],
    loading: true
  })

  const [mainLoading, setMainLoading] = useState(false)
  const [error, setError] = useState<ErrorDetail>()

  useEffect(handleAsyncLoader(
    setMainLoading,
    setError,
    async () => {
      await Promise.all([
        (async () => {
          const entity = await new EntityRiskTrackerApi(session.token).entity(id)
          const notify = (await new SavedItemsApi(session.token).list('Entity')).saved_items.filter(si => si.saved_record_id === id)[0]?.notify
          setEntity({
            entity,
            notify: typeof notify === 'undefined' ? 'removed' : notify ? 'email' : 'saved',
          })
        })(),
        (async () =>
          setRelatedTransactions((await new TransactionsApi(session.token).search({
            'entity_ids[]': [id],
            order: 'date',
            sort_mode: 'desc',
          })).transactions))(),
        (async () =>
          setRelatedAlerts((await new AlertApi(session.token).search({
            'entity_ids[]': [id],
            order: 'updated_at',
            sort_mode: 'desc',
          })).alerts))(),
      ])

    }
  ), [session.token, id])

  useEffect(handleAsyncLoader(
    v => setRiskEventsTable(prev => ({...prev, loading: v})),
    setError,
    async () => {
      const data = await new EntityRiskTrackerApi(session.token).entity_risks(id, 1, 1_000_000)
      setRiskEventsTable(prev => ({...prev, all: data.results, total: data.paginate.total_records}))
    }
  ), [session.token, id])

  useEffect(handleAsyncLoader(
    v => setRelatedRisksTable(prev => ({...prev, loading: v})),
    setError,
    async () => {
      const data = await new EntityRiskTrackerApi(session.token).entity_related_risks(id, 1, 1_000_000)
      setRelatedRisksTable(prev => ({...prev, all: data.results, total: data.paginate.total_records}))
    }
  ), [session.token, id])

  const riskEvents = useMemo<EntityRiskDesignations>(() => pageAndFilterRisks(riskEventsTable), [riskEventsTable])
  const relatedRiskEvents = useMemo<EntityRelatedRisksRes>(() => ({
    paginate: {
      page: 1,
      per_page: 1_000_000,
      total_records: 1,
      total_pages: 1
    },
    results: relatedRisksTable.all
  }), [relatedRisksTable.all])
    //useMemo<EntityRiskEventsRes>(() => pageAndFilterEvents(relatedRisksTable, true), [relatedRisksTable])

  const anyLoading = mainLoading || riskEventsTable.loading || relatedRisksTable.loading

  const tabAvailable = useMemo<TabVm[]>(() => {

    const riskEventTab: TabVm = {
      available: riskEventsTable.total > 0,
      tab: SubTab.RiskEvents,
    }
    const relatedRiskTab: TabVm = {
      available: relatedRisksTable.all?.length > 0 ?? false,
      tab: SubTab.AffiliatedRisks,
    }

    const t: TabVm[] = [
      riskEventTab,
      relatedRiskTab,
    ]
    if (t.every(t => !t.available)) {
      t[0].available = true
    }

    return t
  }, [
    riskEvents,
    riskEventsTable,
    relatedRiskEvents,
    relatedRisksTable,
  ])

  const tab = useMemo<SubTab>(() => {

    switch (location.hash) {
      case SubTab.Summary:
      case SubTab.RiskEvents:
      case SubTab.AffiliatedRisks:
        return location.hash
    }

    return SubTab.Any
  }, [location.hash, tabAvailable])


  useEffect(() => {
    if (!anyLoading) {
      if (tab === SubTab.Any) {
        history.replace(tabAvailable.filter(t => t.available)[0].tab)
      }
      const sel = tabAvailable.filter(t => t.tab === tab)[0]
      if (!sel?.available) {
        history.replace(tabAvailable.filter(t => t.available)[0].tab)
      }
    }
  }, [tab, tabAvailable, anyLoading])



  useEffect(() => {

    const loading = tab === SubTab.Any ? true
      : tab === SubTab.RiskEvents ? riskEventsTable.loading
        : relatedRisksTable.loading

  }, [tab, riskEventsTable.loading, relatedRisksTable.loading, setPager, tabAvailable])

  const tabContents: ReactElement<TabbedContainerTabParams<SubTab>>[] = [
    <TabbedContainerTab
      key={2}
      title={'Company Risk Events' + (titleCount(riskEvents?.paginate?.total_records))}
      active={tab === SubTab.RiskEvents}
      state={SubTab.RiskEvents}
    >
      <>
        {tab === SubTab.RiskEvents &&
          <div>
            <EntityRiskEvents
              downloadLink={user.csvDownloadAllowed ? 
                <div className="mt-1">
                  Download as <CsvDownload url={
                    new EntityRiskTrackerApi(session.token).downloadEntityEventsLink(id)
                  } />
                </div> : undefined
              }
              riskEvents={riskEvents?.results ?? []}
              sortCol={riskEventsTable.sortCol}
              sortDirAsc={riskEventsTable.sortDirAsc}
              changeSort={(c, d) => setRiskEventsTable(prev => ({...prev, sortCol: c, sortDirAsc: d}))}
              filterText={riskEventsTable.filterText}
              changeFilterText={f => setRiskEventsTable(prev => ({...prev, filterText: f, page: 1}))}
            />
          </div>
        }
      </>
    </TabbedContainerTab>,
    <TabbedContainerTab
      key={3}
      title={'Affiliated Company Risks' + titleCount(relatedRisksTable?.total)}
      active={tab === SubTab.AffiliatedRisks}
      state={SubTab.AffiliatedRisks}
    >
      <>
        {tab === SubTab.AffiliatedRisks &&
          <EntityAffiliatedRisks relatedRisks={relatedRisksTable.all} />
          // <EntityAffiliatedRiskEvents
          //   relatedRiskEvents={relatedRiskEvents?.results ?? []}
          //   sortCol={relatedRiskEventsTable.sortCol}
          //   sortDirAsc={relatedRiskEventsTable.sortDirAsc}
          //   changeSort={(c, d) => setRelatedRiskEventsTable(prev => ({...prev, sortCol: c, sortDirAsc: d}))}
          //   filterText={relatedRiskEventsTable.filterText}
          //   changeFilterText={f => setRelatedRiskEventsTable(prev => ({...prev, filterText: f, page: 1}))}
          // />
        }
      </>
    </TabbedContainerTab>
  ]
    .filter((e, i) => tabAvailable[i].available)


  function handleActiveTabChange(newTab: SubTab) {
    history.replace(newTab)
  }




  async function handleSaveEmailAlert() {
    try {
      const successState = typeof entity?.notify === 'undefined'
        ? SavedItemState.saved
        : SavedItemState.activated
      await new SavedItemsApi(session.token).upsert('Entity', [entity?.entity.id ?? 0], true)
      setEntity(a => !!a ? ({...a, notify: 'email'}) : undefined)
      setSavedItemState(successState)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }

  async function handleSavedItems() {
    try {
      const successState = typeof entity?.notify === 'undefined'
        ? SavedItemState.saved
        : SavedItemState.deactivated
      await new SavedItemsApi(session.token).upsert('Entity', [entity?.entity.id ?? 0], false)
      setEntity(a => !!a ? ({...a, notify: 'saved'}) : undefined)
      setSavedItemState(successState)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }

  async function handleDeleteSavedItem() {
    try {
      await new SavedItemsApi(session.token).destroy('Entity', entity?.entity.id ?? 0)
      setEntity(a => !!a ? ({...a, notify: 'removed'}) : undefined)
      setSavedItemState(SavedItemState.deleted)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }


  const items: DropdownItem[] = [
    {
      icon: <EmailIcon />,
      onClick: handleSaveEmailAlert,
      children: <>Activate Email Notifications</>,
      show: (e: EntityVm) => e.notify !== 'email',
    },
    {
      icon: !!entity && entity.notify === 'email' ? <EmailIcon /> : <AddIcon />,
      onClick: handleSavedItems,
      children: <>{!!entity && entity.notify === 'email' ? 'Deactivate Email Notifications' : 'Add to Saved Items'}</>,
      show: (e: EntityVm) => e.notify !== 'saved',
    },
    {
      icon:<TrashIcon />,
      onClick: handleDeleteSavedItem,
      children: <>Delete Saved Item</>,
      show: (e: EntityVm) => e.notify !== 'removed',
    },
    {
      icon:<div style={{height: '19px', width: '19px', marginLeft: '8px', marginRight: '8px', marginTop: '-8px'}}><PrinterIcon /></div>,
      onClick: window.print,
      children: <>Save to PDF</>,
      show: (e: EntityVm) => true,
    },
  ]
    .filter(mi => !!entity && mi.show(entity))

  const show = entity ? (
    <>
      <div className="border-right">
        <div className="content-left">
          <SectionedContent
            className="content-left"
            title={entity.entity.name ?? ''}
            subtitle={(
              <div className="no-print d-flex w-100 align-items-center">
                <Link className="link mr-2" to={backPath}>
                  Back
                </Link>
                <DropdownMenu className="ml-auto" items={items}/>
              </div>
            )}
          >
            <LoadingComponent loading={riskEventsTable.loading || relatedRisksTable.loading}>
              <TabbedContainer onActiveChange={handleActiveTabChange}>
                {tabContents}
              </TabbedContainer>
            </LoadingComponent>
          </SectionedContent>
        </div>
      </div>
      <EntitySidebar
        entity={entity.entity}
        relatedTransactions={relatedTransactions}
        relatedAlerts={relatedAlerts}
      />

      <ModalSavedItemSuccessfully
        state={savedItemState}
        itemTitle={entity?.entity.name ?? ''}
        close={() => setSavedItemState(SavedItemState.none)}
      />

      <PrintFooter
        type={'Entity Risk Tracker'}
        updatedAt={''}
      />
    </>
  ) : (
    <>Error loading Entity Risk Report</>
  )

  return (
    <LoadingComponent loading={mainLoading} error={error}>
      {show}
    </LoadingComponent>
  )
}
