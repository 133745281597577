import {TransactionsSearchItem} from 'models';
import {TransactionTable} from 'apps/transactions/components/TransactionTable';


export function SearchTransactionResults(
  {
    results,
    onSelect,
  } : {
    results: TransactionsSearchItem[],
    onSelect: (id: number, title: string) => void,
  }
): JSX.Element {

  return (
    <div className="tab-pane fade show active pb-6 px-4" role="tabpanel">
      <TransactionTable onSelect={onSelect} list={results} />
    </div>
  );
}
