import {Field, MsOption} from 'models';

export function validateInt(field: Field<string>, setField: (f: Field<string>) => void): void {
  const hadMyError = field.errors.some(e => e === 'number')
  const otherErrs = field.errors.filter(e => e !== 'number')

  if ((field.value?.trim() ?? '') > '' && !Number.isInteger(+field.value)) {
    setField({...field, errors: [...otherErrs, 'number']})
    return
  }

  if (hadMyError)
    setField({...field, errors: otherErrs})


}



export function validateRequired(field: Field<string>, setField: (f: Field<string>) => void): void {
  const hadMyError = field.errors.some(e => e === 'required')
  const otherErrs = field.errors.filter(e => e !== 'required')

  if ((field.value?.trim() ?? '') === '') {
    setField({...field, errors: [...otherErrs, 'required']})
    return
  }

  if (hadMyError)
    setField({...field, errors: otherErrs})


}

// export function validateDate(field: Field<string>, setField: (f: Field<string>) => void): void {
//   const hadMyError = field.errors.some(e => e === 'date')
//   const otherErrs = field.errors.filter(e => e !== 'date')
//
//   if ((field.value?.trim() ?? '') > '' && !dayjs(field.value).isValid()) {
//     setField({...field, errors: [...otherErrs, 'date']})
//   }
//
//   if (hadMyError)
//     setField({...field, errors: otherErrs})
// }



export function findOption(options: MsOption[], value: string): MsOption | undefined {
  const found = options.filter(o => o.value === value)[0];
  if (found)
    return found

  return options.map(o => o.subOptions ? findOption(o.subOptions, value): undefined)
    .filter(o => typeof o !== 'undefined')[0];
}
