import React, {useEffect, useMemo, useState} from 'react'
import {find} from 'lodash'

import {useAppContext} from 'context'
import {AutocompleteApi, buildQuery, buildQueryParams, SearchesApi} from 'apis'

import {continents as continentData, regions as regionData, updatedDaysOptions, UpdatedDaysType,} from 'data'

import {MultiSelectAutocomplete, MultiSelectHierarchy} from 'components'
import {FilterForm, FilterFormGroup, FilterFormSave, FilterFormSection} from 'components/filterform'
import {
  AlertFilterCategory, 
  AlertFilterForm, 
  MsOption,
  SearchGroupOptionsT
} from 'models'
import {useHistory, useLocation} from 'react-router-dom';
import {parseModifiedWithin} from 'models/converters/ConverterUtil';
import {alertToSavedItemReq} from 'models/converters';
import {calcAlertCategories, countAlertFilters, SessionStorage} from 'lib';
import {AlertPaths} from 'apps/alerts/Alerts';
import {suppressAutoCompleteError} from 'lib/HandleAsyncLoader';

interface SearchType {
  id: string,
  label: string
}

const FromDataArray = (id: string, data: Array<[number, string]>): SearchType => {
  const val = find(data, (row) => `${row[0]}` === id) || ['','']
  return { id: `${val[0]}`, label: val[1] }
}

const ToTypeAheadOptions = (data: Array<[number, string]>): MsOption[] => (
  data.map((data) => ({name: data[1], value: `${data[0]}`}))
)

// Returns true if the search value has searchable data
const hasValue = (value: any): boolean => {
  if (typeof value === 'undefined' || value == null) {
    return false
  }
  if ((Array.isArray(value) || typeof value === 'string') && value.length < 1) {
    return false
  }
  return true
}

const idsToSearchTypes = (ids: number[]|undefined) => (
  ids ? ids.map((id) => ({id: `${id}`, label: ''})) : []
)

function toAlertSearch(
  filterId: number | undefined,
  filterName: string,
  countries: SearchType[],
  regions: SearchType[],
  continents: SearchType[],
  keywords: string,
  updatedDays: string,
  search_group: SearchGroupOptionsT): AlertFilterForm {

  return  {
    text: keywords,
    country_ids: countries?.map(c => c.id),
    region_ids: regions?.map(c => c.id),
    continent_ids: continents?.map(c => c.id),
    updated_days: parseModifiedWithin(updatedDays),
    search_group,
    filterId,
    filterName,
  }
}

export const AlertFilters = (
  {
    origFilter,
    setFilterCount,
  } : {
    origFilter: AlertFilterForm
    setFilterCount: (cnt: number) => void
  }
): JSX.Element => {

  const { session } = useAppContext()
  const history = useHistory()
  const location = useLocation()

  const autocompleteApi = useMemo(
    () => new AutocompleteApi(session.token), 
    [session]
  )


  // Parse the AlertSearch into search state
  const [countries, setCountries] = useState<SearchType[]>(idsToSearchTypes(origFilter.country_ids.map(id => +id)))
  const [regions, setRegions] = useState<SearchType[]>(idsToSearchTypes(origFilter.region_ids.map(id => +id)))
  const [continents, setContinents] = useState<SearchType[]>(idsToSearchTypes(origFilter.continent_ids.map(id => +id)))
  const [keywords, setKeywords] = useState<string>(origFilter.text)
  const [updatedDays, setUpdatedDays] = useState<UpdatedDaysType>(origFilter.updated_days)
  const [searchGroup, setSearchGroup] = useState<SearchGroupOptionsT>(origFilter.search_group)

  const [filterId, setFilterId] = useState(origFilter.filterId)
  const [filterName, setFilterName] = useState(origFilter.filterName)

  useEffect(() => {
    setCountries(idsToSearchTypes(origFilter.country_ids.map(id => +id)))
    setRegions(idsToSearchTypes(origFilter.region_ids.map(id => +id)))
    setContinents(idsToSearchTypes(origFilter.continent_ids.map(id => +id)))
    setKeywords(origFilter.text)
    setUpdatedDays(origFilter.updated_days)
    setFilterId(origFilter.filterId)
    setFilterName(origFilter.filterName)
    setSearchGroup(origFilter.search_group)
  }, [origFilter])

  const filterObj: AlertFilterForm = {

    country_ids: countries.map(c => c.id),
    region_ids: regions.map(c => c.id),
    continent_ids: continents.map(c => c.id),

    text: keywords,

    updated_days: updatedDays,

    search_group: searchGroup,

    filterId: filterId,
    filterName: filterName,
  }
  const filterCategories = calcAlertCategories(filterObj)

  useEffect(() => {
    setFilterCount(countAlertFilters(filterObj))
  }, [filterObj])

  const navigateToSearch = (newAlertSearch: AlertFilterForm, path: AlertPaths) => {
    const params = buildQueryParams(newAlertSearch)
    history.push(`${path}?${params}`)
  }

  // Apply the filters into an AlertSearch object
  const applyFilters = (id: number | undefined, name: string) => {
    const alertSearch = toAlertSearch(id, name, countries, regions, continents, keywords, updatedDays, searchGroup);
    const path = (location.pathname === AlertPaths.listFilters) ? AlertPaths.list : AlertPaths.map
    SessionStorage.setCurrentAlertsFilter({...alertSearch, view: path === AlertPaths.list ? 'list' : 'map'})
    navigateToSearch(alertSearch, path)
  }

  const saveFilters = async (name: string) : Promise<number> => {
    const req = alertToSavedItemReq(toAlertSearch(filterId, name, countries, regions, continents, keywords, updatedDays, searchGroup));
    if (filterId) {
      const item = await new SearchesApi(session.token).update(filterId, req)
      setFilterName(name)
      return filterId
    } else {
      const item = await new SearchesApi(session.token).create(req)
      setFilterName(name)
      setFilterId(item.id)
      return item.id
    }
  }

  const cancel = () => {
    const path = (location.pathname === AlertPaths.listFilters) ? AlertPaths.list : AlertPaths.map
    navigateToSearch(origFilter, path)
  }
  const viewSaved = () => {
    history.push('/saveditems')
  }

  const locationActiveFilterSummary =
    <>
      { countries.length > 0 &&
      <div className="pr-4">
        {countries.length} {countries.length === 1 ? 'Country' : 'Countries'}
      </div>
      }
      { regions.length > 0 &&
      <div className="pr-4">
        {regions.length} {regions.length === 1 ? 'Region' : 'Regions'}
      </div>
      }
      { continents.length > 0 &&
      <div className="pr-4">
        {continents.length} {continents.length === 1 ? 'Continents' : 'Continents'}
      </div>
      }
    </>

  const keywordActiveFilterSummary =
    <>
    {keywords.trim() !== '' && <div>{keywords}</div>}
    </>

  const dateActiveFilterSummary =
    <>
      {updatedDays !== '' && <div>{updatedDaysOptions.find(o => o.value === updatedDays)?.label}</div>}
    </>

  function handleCloseAfterSaving(id: number, name: string) {
    history.replace(location.pathname + buildQuery(toAlertSearch(id, name, countries, regions, continents, keywords, updatedDays, searchGroup), true))
  }

  return (
    <FilterForm>

      <FilterFormSection
        title="Location"
        active={filterCategories.includes(AlertFilterCategory.Location)}
        defaultExpanded={filterCategories.includes(AlertFilterCategory.Location)}
        activeFilters={locationActiveFilterSummary}
      >
        <FilterFormGroup>
          <label>Country</label>
          <MultiSelectAutocomplete
            selectedItems={countries}
            setSelectedItems={setCountries}
            getQuery={(q, page, per_page) => (
              suppressAutoCompleteError(() => autocompleteApi.countries({q, page, per_page, '_': 1}))
            )}
            getIds={(ids) => suppressAutoCompleteError(() => autocompleteApi.countriesFromIds({id: ids.map(d => +d)}))}
          />
        </FilterFormGroup>
        <FilterFormGroup>
          <label>Region</label>
          <MultiSelectHierarchy
            options={ToTypeAheadOptions(regionData)}
            selectedValues={regions.map(({id}) => id)}
            setSelectedValues={(ids: string[]) => (
              setRegions(ids.map((id) => FromDataArray(id, regionData)))
            )}
          />
        </FilterFormGroup>
        <FilterFormGroup>
          <label>Continents</label>
          <MultiSelectHierarchy
            options={ToTypeAheadOptions(continentData)}
            selectedValues={continents.map(({id}) => id)}
            setSelectedValues={(ids: string[]) => (
              setContinents(ids.map((id) => FromDataArray(id, continentData)))
            )}
          />
        </FilterFormGroup>
      </FilterFormSection>

      <FilterFormSection
        title="Keywords"
        active={filterCategories.includes(AlertFilterCategory.Keywords)}
        defaultExpanded={filterCategories.includes(AlertFilterCategory.Keywords)}
        activeFilters={keywordActiveFilterSummary}
      >
        <FilterFormGroup>
          <input 
            className="form-control" 
            placeholder="Search"
            onChange={(e) => setKeywords(e.target.value)}
            value={keywords}
          />
        </FilterFormGroup>
      </FilterFormSection>

      <FilterFormSection
        title="Alert Date"
        active={filterCategories.includes(AlertFilterCategory.Date)}
        defaultExpanded={filterCategories.includes(AlertFilterCategory.Date)}
        activeFilters={dateActiveFilterSummary}
      >
        <FilterFormGroup>
          <select
            className="form-control"
            value={updatedDays}
            onChange={(e) => setUpdatedDays(e.target.value as UpdatedDaysType)}
          >
            {updatedDaysOptions.map(({label, value}) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </FilterFormGroup>
      </FilterFormSection>

      <FilterFormSave
        applyFilter={applyFilters}
        saveFilter={saveFilters}
        cancel={cancel}
        viewSaved={viewSaved}
        closeAfterSaving={handleCloseAfterSaving}
        namePlaceholder="Name your Alert Filters"
        savedTitle="Alert Filter Set Saved"
        typeBeingSaved="Alert Filters"
        filterId={filterId}
        filterName={filterName}
      />


    </FilterForm>
  )

}
