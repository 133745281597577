const SI_SYMBOL = ["", "k", "M", "B", "T", "P", "E"]

export const CurrencyValue = ({value}: {value: number}): JSX.Element => {
  let tier = Math.log10(Math.abs(value)) / 3 | 0

  if (tier === 0) return <>{value}</>

  let suffix = SI_SYMBOL[tier]
  let scale = Math.pow(10, tier * 3)
  let scaled = value / scale

  return <>{scaled.toFixed(1) + suffix}</>
}
