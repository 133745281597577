import { useEffect, useState} from 'react'

import {EntityRiskVm, EntityRiskResult, ErtFilterFormVm, ErtFilterFormQs, SearchGroupOptionsT} from 'models'
import {countErtFiltersOnForm, SessionStorage} from 'lib';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {ertToQsErtForm, ertToSavedItemReq, qsErtToErtForm, qsToErtForm} from 'models/converters/EntityRiskConverters';
import {FilterFormSave} from 'components/filterform';
import {buildQuery, buildQueryParams, SearchesApi} from 'apis';
import {useAppContext} from 'context';
import {uniqueId} from 'lodash';
import EntityRiskTrackerIcon from 'components/icons/EntityRiskTrackerIcon'
import IconRiskAll from './icons/IconRiskAll'
import {NavDropdown} from 'components/NavDropdown';


function buildResetQs(filter: ErtFilterFormQs): string {
  return '?' + buildQueryParams(filter, true)
    + '&' + uniqueId('_unq=')
}


export function EntityTabbedNav(
  {
    filterCount,
    Paths,
    searchGroup,
    setSearchGroup,
  }: {
    filterCount: number
    Paths: { list: string, events: string, upload: string, filters: string }
    searchGroup: SearchGroupOptionsT
    setSearchGroup: (val: SearchGroupOptionsT) => void
  }) {

  const history = useHistory()
  const location = useLocation()



  function resetSearch() {
    const emptyFilter = qsToErtForm(new URLSearchParams())
    SessionStorage.setCurrentERTFilter({...emptyFilter})
    history.push(buildResetQs(emptyFilter))
  }

  return (
    <div className="filters-header-wrap" style={{minHeight: 110}}>
      <div className="filters-header py-1 filters-header--fixed">
        <div className="d-flex flex-wrap">
           <ul className="nav nav-tabs nav-fill flex-fill">
             <li className={"nav-item " + (location.pathname === Paths.list ? 'active' : '')}>
               <Link
                 to={Paths.list + location.search}
                 className={"nav-link " + (location.pathname === Paths.list ? 'active' : '')}
                 >
                   <IconRiskAll />
                   Entities
               </Link>
             </li>
             <li className={"nav-item " + (location.pathname === Paths.events ? 'active' : '')}>
             <Link
               to={Paths.events + location.search}
               className={"nav-link " + (location.pathname === Paths.events ? 'active' : '')}
               >
                 <EntityRiskTrackerIcon />
                 Risk Events
             </Link>
             </li>
           </ul>
         </div>
        <div className="d-flex flex-wrap flex-sm-nowrap">
          <NavDropdown
            title="Country"
            className="flex-fill col-6 col-sm-4"
            value={searchGroup}
            setValue={val => setSearchGroup(val)}
            options={[
              ['all', 'All'],
              ['china', 'China'],
              ['russia', 'Russia'],
            ]}
          />
          <div className="filters-header-item flex-fill col-6 col-sm-4">
            <Link
              to={Paths.list + location.search}
              className={"btn btn-gray-light btn-sm" + (location.pathname === Paths.list ? ' active' : '')}
            >
              Full Entity Database
            </Link>
          </div>
          {/*
          <div className="filters-header-item flex-grow-1">
            <Link
              to={Paths.upload + location.search}
              className={"btn btn-gray-light btn-sm" + (location.pathname === Paths.upload ? ' active' : '')}
            >
              Upload Custom List
            </Link>
          </div>
          */}
          <div className="filters-header-item col-6 col-sm-2">
            <Link
              to={Paths.filters + location.search}
              className={'btn btn-sm text-uppercase btn-filters w-100' + (location.pathname === Paths.filters ? ' active' : '') + ' btn-gray-light '}
            >
              Filters
              { filterCount > 0 &&
              <span className="badge badge-primary ml-2">
                  {filterCount}
                </span>
              }
            </Link>
          </div>
          <div className="filters-header-item col-6 col-sm-2">
            <button
              type="button"
              className="btn btn-gray-light btn-sm text-uppercase btn-reset w-100"
              onClick={resetSearch}
            >
                Reset
                <svg xmlns="http://www.w3.org/2000/svg" width="12.847" height="12.944" viewBox="0 0 12.847 12.944"><g transform="translate(0.826 1.414)"><path d="M.588,10.115,10.607,0" fill="none" stroke="#9d9ea0" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2"></path><path d="M0,0,9.588,9.685" transform="translate(1 0.43)" fill="none" stroke="#9d9ea0" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2"></path></g></svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export function EntityRiskFilterForm(
  {
    origFilter,
    filterOptions,
    Paths,
    filterCount,
    setFilterCount,
  }: {
    origFilter: ErtFilterFormQs
    filterOptions: EntityRiskResult[]
    Paths: { list: string, events: string, upload: string, filters: string }
    filterCount: number
    setFilterCount: (cnt: number) => void
  }) {

  const { session } = useAppContext()

  const history = useHistory()
  const location = useLocation()

  const [filters, setFilters] = useState(qsErtToErtForm(origFilter, filterOptions))



  useEffect(() => {
    setFilters(qsErtToErtForm(origFilter, filterOptions))
  }, [origFilter, filterOptions])

  useEffect(() => {
    setFilterCount(countErtFiltersOnForm(filters))
  }, [filters])



  function setSelected(riskIds: number[], newVal: boolean): void {
    setFilters({
      ...filters,
      risks: buildRisks(riskIds, newVal, filters.risks)
    })
  }

  function buildRisks(riskIds: number[], newVal: boolean, risks: EntityRiskVm[]): EntityRiskVm[] {
    return risks.map(c => ({
      ...c,
      children: !!c.children ? buildRisks(riskIds, newVal, c.children) : undefined,
      selected: riskIds.includes(c.id) ? newVal : c.selected
    }))
  }

  const navigateToSearch = (newSearch: ErtFilterFormQs) => {
    const params = buildQueryParams(newSearch)
    history.push(`${Paths.list}?${params}`)
  }

  const applyFilter = (id: number | undefined, name: string) => {
    const riskFilter = ertToQsErtForm({
      ...filters,
      filterId: id,
      filterName: name,
    });
    SessionStorage.setCurrentERTFilter(riskFilter)
    navigateToSearch(riskFilter)
  }

  const saveFilter = async (name: string): Promise<number> => {
    const req = ertToSavedItemReq(ertToQsErtForm({...filters, filterName: name}));
    if (filters.filterId) {
      const item = await new SearchesApi(session.token).update(filters.filterId, req)
      setFilters({
        ...filters,
        filterName: name
      })
      return item.id
    } else {
      const item = await new SearchesApi(session.token).create(req)
      setFilters({
        ...filters,
        filterName: name,
        filterId: item.id,
      })
      return item.id
    }
  }

  const cancel = () => {
    navigateToSearch(origFilter)
  }
  const viewSaved = () => {
    history.push('/saveditems')
  }

  function handleCloseAfterSaving(id: number, name: string) {
    const riskFilter = ertToQsErtForm({
      ...filters,
      filterId: id,
      filterName: name,
    });
    history.replace(location.pathname + buildQuery(riskFilter, true))
  }

  return (
    <>
      <EntityTabbedNav
        filterCount={filterCount}
        Paths={Paths}
        searchGroup={filters.search_group}
        setSearchGroup={sg => setFilters(f => ({...f, search_group: sg}))}
      />
      <form method="GET" id="ert-filters" className="filters-form py-10 py-sm-6">
        <EntityOptionFilterFormSection
          filters={filters}
          setFilters={setFilters}
        />
        {filters.risks.map((filterOpt) =>
          <EntityRiskFilterFormSection
            filter={filterOpt}
            setSelected={setSelected}
            key={filterOpt.id}
          />
        )}
        <FilterFormSave
          applyFilter={applyFilter}
          saveFilter={saveFilter}
          cancel={cancel}
          viewSaved={viewSaved}
          closeAfterSaving={handleCloseAfterSaving}
          namePlaceholder="Name your Entity Risk Filters"
          savedTitle="Entity Risk Filter Set Saved"
          typeBeingSaved="Entity Risk Filters"
          filterId={filters.filterId}
          filterName={filters.filterName}
        />
      </form>
    </>
  )
}

export function EntityOptionFilterFormSection(
  {
    filters,
    setFilters,
  }: {
    filters: ErtFilterFormVm,
    setFilters: (newVal: ErtFilterFormVm) => void,
}) {
  const [expanded, setExpanded] = useState<boolean>(false)
  const containsSelectedOption = !!filters.public
    || !!filters.exclude_owners
    || !!filters.exclude_distant_relatives
    || !!filters.exclude_subsidiaries

  const allSelected = !!filters.public
    && !!filters.exclude_owners
    && !!filters.exclude_distant_relatives
    && !!filters.exclude_subsidiaries

  function selectAllNone(selectAll: boolean): void {
    setFilters({
      ...filters,
      public: selectAll ? true : undefined,
      exclude_subsidiaries: selectAll ? true : undefined,
      exclude_owners: selectAll ? true : undefined,
      exclude_distant_relatives: selectAll ? true : undefined,
    })
  }

  return(
    <div className="accordion">
      <div className="card">
        <div className={containsSelectedOption ? 'card-header active' : 'card-header'}>
          <div className="d-flex">
            <div className="mr-auto">
              <button className="btn btn-link btn-block text-left" type="button" onClick={() => setExpanded(!expanded)}>
                Entity Options
              </button>
            </div>
            <div>
              <button className="btn btn-link btn-block" type="button" onClick={() => selectAllNone(!allSelected)}>
                {allSelected ? 'Select None' : 'Select All'}
              </button>
            </div>
          </div>
        </div>
        <div className={'card-body collapse' + (expanded ? ' show' : '')}>
            <div className="form-row">
              <div className="col-md-4">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="public"
                      id="entity_risk_filter_public"
                      checked={!!filters.public}
                      onChange={(e) => setFilters({
                        ...filters,
                        public: e.target.checked ? true : undefined
                      })}
                    />
                    <label className="form-check-label" htmlFor="entity_risk_filter_public">Publicly-Traded Companies Only</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-4">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="include_owners"
                      id="entity_risk_filter_owners"
                      checked={!!filters.exclude_owners}
                      onChange={(e) => setFilters({
                        ...filters,
                        exclude_owners: e.target.checked ? true : undefined
                      })}
                    />
                    <label className="form-check-label" htmlFor="entity_risk_filter_owners">Exclude companies that are only associated with risk via their parent companies.</label>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="include_subsidiaries"
                      id="entity_risk_filter_subsidiaries"
                      checked={!!filters.exclude_subsidiaries}
                      onChange={(e) => setFilters({
                        ...filters,
                        exclude_subsidiaries: e.target.checked ? true : undefined
                      })}
                    />
                    <label className="form-check-label" htmlFor="entity_risk_filter_subsidiaries">Exclude companies that are only associated with risk via their subsidiaries.</label>
                  </div>
                </div>
              </div>
              { false &&
              <div className="col-md-4">
                <div className="form-group">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="include_distant_relatives"
                      id="entity_risk_filter_distant_relatives"
                      checked={!!filters.exclude_distant_relatives}
                      onChange={(e) => setFilters({
                        ...filters,
                        exclude_distant_relatives: e.target.checked ? true : undefined
                      })}
                    />
                    <label className="form-check-label" htmlFor="entity_risk_filter_distant_relatives">Exclude companies that are only associated with risk via a corporate sibling or cousin.</label>
                  </div>
                </div>
              </div> }
            </div>
        </div>
      </div>
    </div>
  )
}


function calcContainsSelectedRisk(filter: EntityRiskVm): boolean {
  if (filter.selected)
    return true;
  if (filter.children) {
    return filter.children.some(c => calcContainsSelectedRisk(c))
  }
  return false
}

function findMyEditableRisks(filter: EntityRiskVm): {id: number, selected: boolean}[] {
  if (typeof filter.children === 'undefined' || filter.children.length === 0)
    return [filter]

  return filter.children.flatMap(f => findMyEditableRisks(f))
}

function to3Columns(l: EntityRiskVm[]): EntityRiskVm[][] {
  const result: EntityRiskVm[][] = []
  const cols = 3
  for(let col = 0; col < cols; col++) {
    result[col] = [] as EntityRiskVm[]
  }
  l.forEach((child, i) => {
    result[i % cols].push(child)
  })
  return result
}


export function EntityRiskFilterFormSection(
  {
    filter,
    setSelected,
  }: {
    filter: EntityRiskVm
    setSelected: (riskId: number[], newVal: boolean) => void
  }) {

  const [expanded, setExpanded] = useState<boolean>(false)
  // Conditional behavior depending on whether or not we're on the bottom level
  // const grandchildrenCount = filter.children?.map((child) => child.children?.length ?? 0).reduce((a,b) => a+b, 0) ?? 0
  const selectableFiltersList = filter.children?.filter(c => !c.children) ?? []

  const selectableFilters: EntityRiskVm[][] = to3Columns(selectableFiltersList)
  const containsSelectedRisk = calcContainsSelectedRisk(filter)

  const categoryFilters = filter.children?.filter(c => !!c.children) ?? []

  function selectAllNone(allSelected: boolean): void {
    setSelected(
      findMyEditableRisks(filter).map(f => f.id),
      allSelected
    )
  }

  const allSelected = findMyEditableRisks(filter).every(f => f.selected)


  return (
    <>
      <div className="accordion">
        <div className="card">
          <div className={containsSelectedRisk ? 'card-header active' : 'card-header'}>
            <div className="d-flex">
              <div className="mr-auto">
                <button className="btn btn-link btn-block text-left" type="button" onClick={() => setExpanded(!expanded)}>
                  {filter.name}
                </button>
              </div>
              <div>
                <button className="btn btn-link btn-block" type="button" onClick={() => selectAllNone(!allSelected)}>
                  {allSelected ? 'Select None' : 'Select All'}
                </button>
              </div>
            </div>
          </div>
          <div className={'card-body collapse pr-0 pl-7' + (expanded ? ' show' : '')}>
            {
              categoryFilters.map((filterOpt) =>
                <EntityRiskFilterFormSection
                  filter={filterOpt}
                  key={filterOpt.id}
                  setSelected={setSelected}
                />
              ) ?? <></>
            }
            { selectableFilters.length > 0 &&
              <div className={'form-row pl-3' + (categoryFilters.length > 0 ? ' pt-3' : '')}>
                {selectableFilters.map((column, index) =>
                  <div className="col-md-4" key={index}>
                    <div className="form-group">
                      {column.map((filterVm) =>
                        <div className="form-check form-check" key={filterVm.id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="risks[]"
                            id={"entity_risk_filter_" + filterVm.id}
                            value={filterVm.id}
                            checked={filterVm.selected}
                            onChange={(e) => setSelected([filterVm.id], e.target.checked)}
                          />
                          <label className="form-check-label" htmlFor={"entity_risk_filter_" + filterVm.id}>{filterVm.name}</label>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
