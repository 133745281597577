import { ReactNode } from 'react'

export const Card = ({
  title,
  className,
  children
}: {
  title?: ReactNode
  className: string
  children: ReactNode
}): JSX.Element => {
  return (
    <div className={`card ${className}`}>
      <div className="card-body">
        {title &&
          <h5 className="card-title">{title}</h5>
        }
        {children}
      </div>
    </div>
  )
}
