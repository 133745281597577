import { Link } from 'react-router-dom'

interface NavBarItemProps {
  current?: boolean,
  to: string,
  icon?: JSX.Element,
  children: JSX.Element
}

export default function NavBarItem({
  current,
  to, 
  icon, 
  children
}: NavBarItemProps) {
  return (
    <li className="nav-item"> 
      <Link className={`nav-link ${current ? 'active' : ''}`} to={to}>
        {icon}
        {children}
      </Link>
    </li>
  )
}