import {ModalSavedSuccessfully} from 'components/ModalSavedSuccessfully';
import {useHistory} from 'react-router-dom';

export enum SavedItemState {
  none,
  saved,
  activated,
  deactivated,
  deleted
}

export function ModalSavedItemSuccessfully(
  {
    state,
    itemTitle,
    close,
  } : {
    state: SavedItemState
    itemTitle: string
    close: () => void
  }
): JSX.Element {

  const history = useHistory()

  function handleGoToSavedItems() {
    history.push('/saveditems')
  }


  return (
    <>
      {state !== SavedItemState.none &&
      <ModalSavedSuccessfully
        title={state === SavedItemState.saved
          ? 'Item Saved'
          : state === SavedItemState.activated
            ? 'Email Activated'
            : state === SavedItemState.deactivated
              ? 'Email Deactivated'
              : 'Item Deleted'}
        subtitle={state === SavedItemState.saved
          ? `The saved item "${itemTitle}" has been saved to your "Saved Items"`
          : state === SavedItemState.activated
            ? `Email notification has been enabled for the saved item "${itemTitle}"`
            : state === SavedItemState.deactivated
              ? `Email notification has been disabled for the saved item "${itemTitle}"`
              : `The saved item "${itemTitle}" has been removed from your "Saved Items"`}
        close={close}
        buttons={<>
          <div className='flex-fill mr-1'>
            <button type="button" className="btn btn-dark w-100" onClick={handleGoToSavedItems}>Go to Saved Items</button>
          </div>
          <div className='flex-fill ml-1'>
            <button type="button" className="btn btn-primary w-100" onClick={close}>Close</button>
          </div>
        </>
        }
      />
      }
    </>
  )
}
