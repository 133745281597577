import {MsAutocompleteResponse} from 'models/Forms';
import {useEffect, useState} from 'react';
import _uniqueId from 'lodash/uniqueId';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';

export interface TypeAheadOption {
  id: string,
  label: string
}

export function MultiSelectAutocomplete(
  {
    selectedItems,
    setSelectedItems,
    getQuery,
    getIds,
  } : {
    selectedItems: TypeAheadOption[],
    setSelectedItems: (items: TypeAheadOption[]) => void,
    getQuery: (text: string, page: number, per_page: number) => Promise<MsAutocompleteResponse>,
    getIds: (ids: string[]) => Promise<MsAutocompleteResponse>,
  }
): JSX.Element {

  const PageSize = 30

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<TypeAheadOption[]>([])
  // const [query, setQuery] = useState('')
  // const [selected, setSelected] = useState<TypeAheadOption[]>([])
  const [id] = useState(_uniqueId('multiselectorac-'));

  useEffect(() => {
    (async () => {
      if (selectedItems.length > 0 && selectedItems.some(s => s.label === '')) {
        setIsLoading(true)
        const selectedObj = await getIds(selectedItems.map(s => s.id))
        const selectedOpts: TypeAheadOption[] = selectedObj.data.map(s => ({id: `${s.id}`, label: s.text}));
        setOptions([...options, ...selectedOpts])
        setSelectedItems(selectedOpts)
        setIsLoading(false)
      }
    })()
  }, [selectedItems])


  function handleSearch(query: string, page = 1) {
    (async () => {
      setIsLoading(true);
      const pageOptions = await getQuery(query, page, PageSize)
      setOptions(pageOptions.data.map(o => ({
        id: `${o.id}`,
        label: o.text
      })))
      setIsLoading(false)
    })()
  }


  function handleChange(selected: TypeAheadOption[]): void {
    function onlyUnique<T>(value: T, index: number, self: T[]) {
      return self.indexOf(value) === index;
    }

    const uniqueSelectedIds = selected
      .map(s => s.id)
      .filter(onlyUnique)
    const uniqueSelected = uniqueSelectedIds
      .map(si => selected.find(s => s.id === si))
      .filter(s => typeof s !== 'undefined') as TypeAheadOption[]
    setSelectedItems(uniqueSelected)
  }


  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  return <AsyncTypeahead
    filterBy={filterBy}
    id={id}
    isLoading={isLoading}
    minLength={2}
    onSearch={handleSearch}
    options={options}
    multiple
    placeholder={'Search'}
    // paginate={true}
    // onPaginate={handlePagination}
    // onInputChange={setQuery}
    selected={selectedItems}
    onChange={handleChange}
    // onKeyDown={(e) => console.log('onKeyDown')}

  />
}
