import {parseDate} from 'lib/Dates';

export function PrintFooter(
  {
    updatedAt,
    type,
  } : {
    updatedAt?: string
    type: string
  }
): JSX.Element {

  const lastUpdatedDate = parseDate(updatedAt ?? '')
  const lastUpdatedText = isNaN(lastUpdatedDate.getTime()) ? '' : ` Last Updated: ${lastUpdatedDate.toLocaleDateString('en-US', {year: 'numeric', month: 'long', day: 'numeric' })}`

  return (
    <div className='print-page-footer'>
      {`Prepared by IntelTrak, Copyright ${new Date().getFullYear()}. Extracted from IntelTrak: ${type}.${lastUpdatedText}`}
    </div>
  )
}
