interface Params {
  active: boolean
}

export default function MenuButton({active}: Params) {
  return active 
    ?
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="6" viewBox="0 0 26 6"><circle cx="3" cy="3" r="3" transform="translate(10)" fill="#f06c00" /><circle cx="3" cy="3" r="3" transform="translate(20)" fill="#f06c00" /><circle cx="3" cy="3" r="3" fill="#f06c00"/></svg>
    :
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="6" viewBox="0 0 26 6"><circle cx="3" cy="3" r="3" transform="translate(10)" fill="#757677" opacity="0.359"/><circle cx="3" cy="3" r="3" transform="translate(20)" fill="#757677" opacity="0.359"/><circle cx="3" cy="3" r="3" fill="#757677" opacity="0.359"/></svg>
}
