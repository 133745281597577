import { useState } from 'react'
import { Link } from 'react-router-dom'

import {AlertsSearchItem, Entity, TransactionsSearchItem} from 'models'
import {InfoIcon} from 'components/icons/InfoIcon';
import {Accordion} from 'components/Accordion';



export function EntitySidebar(
  {
    entity,
    relatedTransactions,
    relatedAlerts,
  }: {
    entity: Entity
    relatedTransactions?: TransactionsSearchItem[]
    relatedAlerts?: AlertsSearchItem[]
  }) {

  const [expanded, setExpanded] = useState(false)


  return (
    <div className="content-sidebar">
      <div className="tab-content" id="entityViewSidebar">
        <div className="tab-pane fade show active" id="home" role="tabpanel">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <div className="list-group-item-title clickable" onClick={() => setExpanded(!expanded)} style={{display: 'flex', alignItems: 'center'}}>
                Risk Score:
                <span style={{marginLeft: '1px', display: 'flex', alignItems: 'center'}}><InfoIcon widthPx={20} heightPx={20}/></span>
              </div>
              <Accordion expanded={expanded}>
                <div className="list-group-item-subtitle my-3">These scores are assigned by IntelTrak after review of the specific exposure of the company to
                  reputational risk across each category. They represent the likelihood of the company's
                  reputation and operations being negatively affected due to its track record in these areas.</div>
                <div className="list-group-item-subtitle">* Scores marked with an asterisk have been inherited from the riskiest corporate relative, due
                  to a lack of direct risk association.</div>
              </Accordion>
              <div className="list-group-item-text large">
                {entity.score}
                {entity.score_inherited ? <span>*</span> : <></>}
                </div>
            </li>
            {entity.sector &&
              <li className="list-group-item">
                <div className="list-group-item-title">Sector</div>
                <div>{entity.sector}</div>
              </li>
            }
            {(entity.address || entity.website || entity.ownership_types.length) &&
            <li className="list-group-item">
              { entity.address &&
              <>
                <div className="list-group-item-title">Address</div>
                <div className="field">
                  {entity.address}
                </div>
              </>
              }
              { entity.ownership_types && entity.ownership_types.length > 0 &&
              <>
                <div className="list-group-item-title">Ownership Type</div>
                <div className="field">
                  {entity.ownership_types.map(o => o.name).join(', ')}
                </div>
              </>
              }
              { entity.website &&
              <>
                <div className="list-group-item-title">Website</div>
                <div className="field">
                  {entity.website}
                </div>
              </>
              }
            </li>
            }
            {entity.owners.length > 0 &&
              <li className="list-group-item">
                <div className="list-group-item-title">Parent Companies</div>
                <ol className="list-numeric px-0">
                  {entity.owners.map(owner => (
                    <li className="list-numeric-item" key={owner.id}><Link to={ "/entityrisktracker/" + owner.id}>{owner.name}</Link></li>
                  ))}
                </ol>
              </li>
            }
            {entity.ultimate_owners.length > 0 &&
            <li className="list-group-item">
              <div className="list-group-item-title">Ultimate Owners</div>
              <ol className="list-numeric px-0">
                {entity.ultimate_owners.map(owner => (
                  <li className="list-numeric-item" key={owner.id}><Link to={ "/entityrisktracker/" + owner.id}>{owner.name}</Link></li>
                ))}
              </ol>
            </li>
            }
            {relatedTransactions && relatedTransactions.length > 0 &&
            <li className="list-group-item">
              <div className="list-group-item-title">Related Transactions</div>
              <ol className="list-numeric px-0">
                {relatedTransactions.map(tran => (
                  <li className="list-numeric-item" key={tran.id}><Link to={ "/transactions/" + tran.id}>{tran.title}</Link></li>
                ))}
              </ol>
            </li>
            }
            {relatedAlerts && relatedAlerts.length > 0 &&
            <li className="list-group-item">
              <div className="list-group-item-title">Related Alerts</div>
              <ol className="list-numeric px-0">
                {relatedAlerts.map(alert => (
                  <li className="list-numeric-item" key={alert.id}><Link to={ "/alerts/" + alert.id}>{alert.title}</Link></li>
                ))}
              </ol>
            </li>
            }
          </ul>
        </div>
      </div>
    </div>
  )
}
