import { MouseEvent, useState } from 'react'

import MenuButton from 'components/icons/MenuButton'

export interface DropdownItem {
  icon: JSX.Element,
  onClick: (event: MouseEvent) => void,
  children: JSX.Element
}

interface DropdownParams {
  className?: string,
  items: DropdownItem[]
}

export function DropdownMenu({className, items}: DropdownParams) {

  const [open, setOpen] = useState<boolean>(false)

  const getStyle = () => open ? {display: 'block'} : {}

  return (
    <div className={`dropdown ${className || ''}`}>
      <button onClick={(e) => {setOpen(true); e.stopPropagation()}}
        className="btn table-action-btn"
      >
        <MenuButton active={false} /> 
      </button>
      <div style={getStyle()}
        className="dropdown-menu dropdown-menu-right"
      >
        <div className="dropdown-header">
          <button onClick={(e) => {setOpen(false); e.stopPropagation()}}
            type="button" className="btn"
           >
            <MenuButton active={true} /> 
          </button>
        </div>
        {items.map(({icon, onClick, children}, index) => (
          <button className="btn btn-link dropdown-item" key={index} onClick={e => {onClick(e); e.stopPropagation(); setOpen(false)}}>
            <div className="dropdown-item-icon">
              {icon}
            </div>
            {children}
          </button>
        ))}
      </div>
    </div>
  )

}
