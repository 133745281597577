// /resetpassword?token=TOKEN

import {Alert, MainContainer} from 'components';
import logo from 'images/logo.svg';
import {FormEvent, useMemo, useState} from 'react';
import {UserApi} from 'apis';
import axios from 'axios';
import {Link, useLocation} from 'react-router-dom';
import {CaretLeftSmallIcon} from 'components/icons/CaretLeftSmallIcon';
import {handleAsyncLoader} from 'lib';

import './Login.scss'

export function ResetPassword(): JSX.Element {

  const location = useLocation()
  const search = useMemo(() => new URLSearchParams(location.search), [location.search])

  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [completed, setCompleted] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const token = search.get('token') ?? ''

  const doResetPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()



    handleAsyncLoader(
      setSubmitting,
      e => setErrors(e?.message ? [e?.message] : []),async () => {
        await new UserApi().resetPassword({
          'password': password,
          'password_confirmation': passwordConfirm,
          'reset_password_token': token,
        })
        setCompleted(true)
      })()


  }

  return (
    <MainContainer className="bg-light" fullscreen>
      <div className="login card">
        <div className="card-body">
          <div className="title">
            <img src={logo} alt="Intel Trak" />
          </div>
          {!completed &&
            <form onSubmit={doResetPassword}>
              <div className="form-group">
              </div>
              <div className="form-group">
                <input className="form-control"
                       type="password"
                       placeholder="Password"
                       value={password}
                       onChange={e => setPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input className="form-control"
                       type="password"
                       placeholder="Confirm Password"
                       value={passwordConfirm}
                       onChange={e => setPasswordConfirm(e.target.value)}
                />
              </div>
              <p>
                Password must have:
                <ul>
                  <li>12 or more characters</li>
                  <li>At least 1 uppercase, 1 lowercase, 1 digit, and 1 special character (#?!@$%^&*-)</li>
                </ul>
              </p>
              {errors && errors.map((error) => (
                <Alert type="danger" dismissable>{error}</Alert>
              ))}
              <div className="form-group d-flex align-items-center">
                <input className="btn btn-primary ml-auto"
                       type="submit"
                       value="Set Password"
                       disabled={submitting}
                />
              </div>
            </form>
          }
          {completed &&
            <div>
              <Alert type="primary">Password changed.</Alert>
              <div>
                <Link to='/'>Login</Link>
              </div>
            </div>
          }
        </div>
      </div>
    </MainContainer>
  )
}
