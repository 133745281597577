import React, { ReactElement, useState } from 'react'

export interface TabbedContainerTabParams<T> {
  title: JSX.Element | string,
  active?: boolean,
  children: JSX.Element[] | JSX.Element,   // JSX.Node
  state?: T
}

const navClass = (active?: boolean) => (
  `nav-link ${active ? 'active' : ''}`
)
const contentClass = (active?: boolean) => (
  `tab-pane fade show ${active ? 'active' : ''}`
)

export function TabbedContainerTab<T>({children}: TabbedContainerTabParams<T>) {
  return (
    <>{children}</>
  )
}

export function TabbedContainer<T>(
  {
    onActiveChange,
    children,
  }: {
    onActiveChange?: (childState: T) => void
    children: ReactElement<TabbedContainerTabParams<T>>[]
  }) {

  const [activeIndexState, setActiveIndexState] = useState(0)

  const activeIndex = children
    .map((c, index) => ({ active: c.props.active, index}))
    .filter(c => c.active)[0]?.index
    ?? activeIndexState

  function handleTabClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number, childState?: T) {
    e.preventDefault()
    e.stopPropagation()
    setActiveIndexState(index)
    if (onActiveChange && childState)
      onActiveChange(childState)
  }

  let lis:JSX.Element[] = []
  let contents:JSX.Element[] = []

  children.forEach((child, index) => {
    const { title, children }: TabbedContainerTabParams<T> = child.props
    const active = index === activeIndex
    lis.push(
      <li className="nav-item" key={index}>
        <a onClick={(e) => handleTabClick(e, index, child.props.state)}
          className={navClass(active)} href="#"
         >
          {title}
        </a>
      </li>
    )
    contents.push(
      <div className="tab-content" style={{flex: 1}} key={`a${index}`}>
        <div className={contentClass(active)}>
          {children}
        </div>
      </div>
    )
  })

  return (
    <div style={{display: 'flex', flexFlow: 'column'}}>
      <ul className="nav nav-pills nav-justified py-2">
        {lis}
      </ul>
      {contents}
    </div>
  )

}
