import {useHistory, useLocation} from 'react-router-dom';
import {useEffect} from 'react';

export function GoogleAnalyticsPageViews(
  {
    trackingId,
  } : {
    trackingId: string
  }
): JSX.Element {

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {

    {
      // @ts-ignore
      const gtag = window.gtag
      if (typeof gtag === 'function') {
        // console.log("tracking init page view");
        gtag('event', 'page_view')
      }
    }


    const unreg = history.listen(l => {
      // @ts-ignore
      const gtag = window.gtag

      if (typeof gtag === 'function') {



        const data =  {
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
          send_to: trackingId
        }
        // console.log("tracking page view: ")
        // console.log(data)
        gtag('event', 'page_view', data)

      }
    })

    return () => unreg()

  }, [])

  return (
    <></>
  )
}
