export function GearIcon(): JSX.Element {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
    <g data-name="Group 5" transform="translate(0.75 0.75)">
      <path
        d="M14.261,2.9,13.217,4.926a5.725,5.725,0,0,1,.626,1.5L16,7.092V8.838L13.843,9.5a5.725,5.725,0,0,1-.626,1.5L14.261,13l-1.253,1.258-1.982-1.048a5.669,5.669,0,0,1-1.5.629L8.87,16H7.13L6.47,13.834a5.662,5.662,0,0,1-1.5-.629L2.991,14.253,1.739,13.031l1.043-1.991a5.714,5.714,0,0,1-.626-1.5L0,8.873V7.127l2.157-.664a5.714,5.714,0,0,1,.626-1.5L1.739,2.969,2.991,1.747,4.974,2.795a5.662,5.662,0,0,1,1.5-.629L7.13,0H8.87L9.53,2.166a5.669,5.669,0,0,1,1.5.629l1.634-.873.348-.175Z"
        fill="none" stroke="#f06c00" className="icon-stroke" strokeMiterlimit="10" strokeWidth="1.5"/>
      <path d="M3,0A3,3,0,1,0,6,3,3.011,3.011,0,0,0,3,0Z" transform="translate(5 5)" fill="none" stroke="#f06c00"
            className="icon-stroke" strokeMiterlimit="10" strokeWidth="1.5"/>
    </g>
  </svg>)
}
