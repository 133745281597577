export default function SavedItemsBlueIcon(
  {
    classname
  } : {
    classname?: string
  }
) {
  return (
    <svg className="filters-form-footer-icon" xmlns="http://www.w3.org/2000/svg" width="18.5" height="20.595"
         viewBox="0 0 18.5 20.595">
      <g transform="translate(0.75 0.75)">
        <path d="M9.042,12.478l2.489,2.283a.878.878,0,0,0,1.225-.02A.839.839,0,0,0,13,14.15V0H0V3.842"
              transform="translate(4)" fill="none" stroke="#f06c00" strokeMiterlimit="10" strokeWidth="1.5"></path>
        <path
          d="M0,0V14.15A.858.858,0,0,0,.867,15a.875.875,0,0,0,.6-.239L6.5,9.984l5.031,4.777a.879.879,0,0,0,1.225-.02A.841.841,0,0,0,13,14.15V0Z"
          transform="translate(0 4)" fill="none" stroke="#f06c00" strokeMiterlimit="10" strokeWidth="1.5"></path>
      </g>
    </svg>
  )
}
