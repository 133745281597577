import {UrlParams} from 'apis';
import {UpdatedDaysType} from 'data';
import { SearchGroupOptions, SearchGroupOptionsT } from './SearchGroupOptions'

export interface Alert {
  "id": number
  "title": string
  "status": string
  "cities": null | {
    "city": {
      "id": number
      "display_name": string
    }
  }[]
  "start_date": string // "2020-05-27",
  "end_date": string // "2021-05-27",
  "created_at": string // "2021-05-27",
  "updated_at": string // "2021-05-27",
  "comment": string
  "city_ids": number[]
  "entity_ids": number[] // assumption
  "transaction_ids": number[] // assumption
  "type": "Alert"
  "entities": {
    "entity": {
      "id": number
      "name": string
      "entity_type": {
        "id": number
        "name": string
      }
      "ownership_types": {
        "ownership_type": {
          "id": number
          "name": string
        }
      }[]
      "country": {
        "id": number
        "name": string
        "iso3": string
      },
      "type": string
    }
  }[]
  "transactions": {
    "transaction": {
      "id": number
      "title": string
      "date": string
      "start_date": string
      "end_date": string
      "project_value": number
      "status": string
      "transaction_types": {
        "transaction_type": {
          "id": number
          "name": string
        }
      }[]
      "industries": {
        "industry": {
          "id": number
          "name": string
        }
      }[]
      "alert": boolean
      "type": string
      "cities": {
        "city": {
          "id": number
          "display_name": string
        }
      }[]
    }
  }[]
}




export interface AlertsSavedItemReq extends UrlParams {
  'search[name]': string,
  'search[notify]'?: '1',

  "search[filters][alerts][text]"?: string,
  "search[filters][alerts][country_ids][]"?: number[],
  "search[filters][alerts][region_ids][]"?: number[],
  "search[filters][alerts][continent_ids][]"?: number[],
  "search[filters][alerts][updated_days]"?: number,
  "search[filters][alerts][search_group]"?: SearchGroupOptionsT,
}


export interface AlertsFilterSavedItemSubRes {
  "continent_ids": string[],
  "country_ids": string[],
  "region_ids": string[],
  "text": string,
  "updated_days": string,
  "search_group": string
}

export interface AlertsFilterSavedItemRes {

  "alerts": AlertsFilterSavedItemSubRes,
}


export interface AlertIdsSavedItemReq extends UrlParams {
  'search[name]': string,
  'search[notify]'?: '1'|'0',

  "search[filters][alerts][ids][]"?: number[],
}

export interface AlertIdsFilterSavedItemRes {

  "alerts": {
    "ids": string[],
  },
}


export interface AlertsFilterQueryReq extends UrlParams {
  text?: string
  'city_ids[]'?: number[]
  'country_ids[]'?: number[]
  'region_ids[]'?: number[]
  'continent_ids[]'?: number[]
  'search_group'?: SearchGroupOptionsT
  // status?: string
  updated_days?: number
  page?: number
  per_page?: number

  order: 'start_date'|'updated_at'
  sort_mode: 'desc'
}



export enum AlertFilterCategory {
  Location,
  Keywords,
  Date
}


// used internally when editing a filter
export interface AlertFilterForm extends UrlParams {
  text: string
  city_ids?: string[]
  country_ids: string[]
  region_ids: string[]
  continent_ids: string[]
  // status: string
  updated_days: UpdatedDaysType
  search_group: SearchGroupOptionsT

  page?: string
  per_page?: string

  filterId?: number
  filterName: string
}

export interface StickyAlertFilterForm extends AlertFilterForm {
  view: 'map' | 'list'
}
