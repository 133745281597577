import { useState, useEffect } from 'react'
import { Switch, Route, RouteComponentProps, useLocation } from 'react-router-dom'



import {
  SearchBar,
  MainContainer,
  PageContainer,
} from "components"
import { EntityView } from './EntityView'
import { EntityRiskHome } from './EntityRiskHome'



export const ErtPaths = {
  list: '/entityrisktracker',
  events: '/entityrisktracker/events',
  upload: '/entityrisktracker/upload',
  filters: '/entityrisktracker/filters',
}

const HomePaths = [ErtPaths.list, ErtPaths.events, ErtPaths.upload, ErtPaths.filters]

export function EntityRiskTracker(
  {
    searchBar
  } : {
    searchBar: JSX.Element
  }
): JSX.Element {


  const location = useLocation()

  const [backPath, setBackPath] = useState(HomePaths.includes(location.pathname) ? location.pathname : ErtPaths.list)

  const [viewPager, setViewPager] = useState<JSX.Element>()

  useEffect(() => {
    if (HomePaths.includes(location.pathname))
      setBackPath(location.pathname + location.search)
  }, [location.pathname, location.search])




  return (
    <PageContainer navItem="entityrisktracker">
      <Switch>
        <Route exact path={HomePaths}>
          <EntityRiskHome
            searchBar={searchBar}
            Paths={ErtPaths}
          />
        </Route>
        <Route exact path="/entityrisktracker/:id"
          render={({match}: RouteComponentProps<{id: string}>) => (
            <>
            <MainContainer
              searchBar={searchBar}
              rightContentActive
              pager={viewPager}
            >
              <EntityView
                id={Number(match.params.id)}
                backPath={backPath}
                setPager={setViewPager}
              />
            </MainContainer>
            </>
          )} />
      </Switch>
    </PageContainer>
  );
}
