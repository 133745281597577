import {isNaN} from 'lodash';
import dayjs from 'dayjs';
import {UpdatedDaysType, updatedDaysValues} from 'data';

export const parseStr = (val: string | null | undefined) => typeof val !== 'string' ? '' : val;
export const parseBool = (val: string | null | undefined) => val === '1';
export const parseBool0 = (val: string | null | undefined) => val !== '0';
export const parseInteger: (val: string | null | number | undefined) => string
  = (val: string | null | number | undefined) =>
{
  if (typeof val === 'number')
    return `${val}`;
  if (typeof val !== 'string')
    return '';

  const num = parseInt(val);
  if (!Number.isInteger(num))
    return '';

  return isNaN(num)
    ? ''
    : num.toString();
}
export const parseDate = (val: string | null | undefined) =>
  typeof val !== 'string'
    ? ''
    : !dayjs(val).isValid()
      ? ''
      : dayjs(val).format('YYYY-MM-DD');



export function undefToEmpty<T extends (string)>(val?: undefined | T): T | '' {
  if (typeof val === 'undefined')
    return ''

  return val
}

export const parsePendingCancelled: (val: string | null) => 'only' | 'exclude' | ''
  = (val: string | null) => {
  if (val === 'only' || val === 'exclude')
    return val
  return ''
}

export const parseModifiedWithin: (val: string | null | number | undefined) => UpdatedDaysType
  = (val: string | null | number | undefined) => {
  const valStr = `${val}` as any
  if (updatedDaysValues.includes(valStr))
    return valStr

  return '';
}

export function parseEnum<T extends string>(val: string | null | undefined, enumVals: Readonly<T[]>, defaultVal?: T): T {
  const s = parseStr(val)
  for (const enumVal of enumVals) {
    if (enumVal === s)
      return enumVal
  }
  if (typeof defaultVal === 'undefined')
    return enumVals[0]

  return defaultVal
}



export function emptyToUndef<T extends (string[] | number[] | string)>(val: '' | T): T | undefined {
  return typeof val === 'string'
    ? (val === '' ? undefined : val)
    : (val.length === 0 ? undefined : val);
}
export function emptyToAry(val: string[]): string[] {
  if (val.length === 1 && val[0] === '')
    return []
  return val
}


export function toInts(val: string[]): number[] {
  return val
    .map(v => Number.parseInt(v))
    .filter(v => !isNaN(v))
}
export function toInt(val: string): number | undefined {
  const result = Number.parseInt(val);
  return isNaN(result)
    ? undefined
    : result;
}


