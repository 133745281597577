import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import { merge } from 'lodash'
import { intelTrakApiUrl } from 'Config'

export interface UrlParams {
  // I'm not sure how to type this:
  // [key: string]: string | number | number[] | string[] | boolean | undefined;
  // I want it to say IF there is a property, then it should be a 'flat' type (not an object)
  // The above, instead, allows any property that is flat, which is too permissive.
}


function param2String(val: string | number | number[] | string[] | boolean | undefined): string {
  switch (typeof (val)) {
    case 'undefined':
      return '';
    case 'string':
      return val;
    case 'number':
      return val.toString();
    case 'boolean':
      return val ? '1' : '0';
  }
  return val.map(v => v.toString()).join(',');
}

export function buildQueryParams<T extends UrlParams>(params: T, excludeEmpty?: boolean): string {

  // For arrays, params repeat
  // /v2/alerts?country_ids[]=68&country_ids[]=31

  const pairs = Object.entries(params)
    .filter(kv => typeof kv[1] !== 'undefined')
    .map(kv => ({key: kv[0], val: kv[1]}))

  const aryParams: {key: string, val: number[] | string[]}[] = pairs
    .filter(p => Array.isArray(p.val)) as any

  const valParams: {key: string, val: number | string | boolean}[] = pairs
    .filter(p => !Array.isArray(p.val)) as any

  const kv = valParams
    .concat(aryParams
      .flatMap(p => p.val.map(a => ({key: p.key, val: a}))))

  return kv
    .filter(pair => typeof excludeEmpty === 'undefined' || !excludeEmpty || pair.val !== '')
    .map(pair => `${encodeURIComponent(pair.key)}=${encodeURIComponent(param2String(pair.val))}`)
    .join('&')
}

export function buildQuery<T extends UrlParams>(params?: T, excludeEmpty?: boolean): string {
  return  params && Object.keys(params).length > 0
    ? "?" + buildQueryParams(params, excludeEmpty)
    : "";
}

export class Api {

  token: string|undefined|null

  constructor(token?: string) {
    this.token = token;
  }

  private headers(addHeaders?: {}) {
    return {
      headers: merge(
        this.token ? { Authorization: `Bearer ${this.token}` } : {},
        addHeaders
      )
    }
  }


  protected requestUrl(url: string, params?: UrlParams) {
    const queryString = buildQuery(params);
    return `${intelTrakApiUrl}${url}${queryString}`
  }

  get<T = any>(url: string, params?: UrlParams, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const fullConfig: AxiosRequestConfig = {...config, ...this.headers()};
    return axios.get<T>(this.requestUrl(url, params), fullConfig);
  }

  post<T = any>(url: string, params?: {}, headers?: {}): Promise<AxiosResponse<T>> {
    return axios.post<T>(this.requestUrl(url), params, this.headers(headers))
  }
  postForm<T = any>(url: string, params: UrlParams): Promise<AxiosResponse<T>> {
    const urlParams = buildQueryParams(params)
    return axios.post<T>(this.requestUrl(url), urlParams, this.headers())
  }

  put<T = any>(url: string, params?: {}): Promise<AxiosResponse<T>> {
    return axios.put<T>(this.requestUrl(url), params, this.headers())
  }
  putForm<T = any>(url: string, params: UrlParams): Promise<AxiosResponse<T>> {
    const urlParams = buildQueryParams(params)
    return axios.put<T>(this.requestUrl(url), urlParams, this.headers())
  }

  delete(url: string): Promise<void> {
    return axios.delete(this.requestUrl(url), this.headers())
  }

}
