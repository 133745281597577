import {useEffect, useRef, useState} from 'react'
import { Link } from 'react-router-dom'

import {EntityRelatedRisk} from 'models';
import {distinct, orderBy} from 'lib';
import CaretUp from 'components/icons/CaretUp';
import CaretDown from 'components/icons/CaretDown';

import './EntityAffliatedRisks.scss'


interface RiskItemVm {
  riskId: number
  name: string
  parentName: string
  entities: EntityRiskItemVm[]
  expanded: boolean
}

interface EntityRiskItemVm {
  entityId: number
  name: string
  relationship: string
  filterInclude: boolean
}

function buildVm(relatedRisks: EntityRelatedRisk[]): RiskItemVm[] {
  const riskIds = distinct(relatedRisks.map(r => r.entity_risk_id))

  return riskIds.map<RiskItemVm>(rid => {
    const entities = relatedRisks.filter(rr => rr.entity_risk_id == rid)

    return {
      riskId: rid,
      name: entities[0].entity_risk_name,
      parentName: entities[0].parent,
      expanded: false,
      entities: entities.map(e => ({
        entityId: e.company_id,
        name: e.company,
        relationship: e.relation,
        filterInclude: true
      }))
    }
  }).sort(orderBy(r => r.name))
}

function toggleRisk(risks: RiskItemVm[], riskId: number): RiskItemVm[] {
  return risks.map(r => r.riskId === riskId
    ? {...r, expanded: !r.expanded}
    : r)
}

function filterRisks(risks: RiskItemVm[], filterText: string): RiskItemVm[] {
  const normalizedFilter = filterText.trim().toLowerCase()

  return risks.map(r => ({
    ...r,
    entities: r.entities.map(e => (
      {
        ...e,
        filterInclude: normalizedFilter === '' || r.name.toLowerCase().includes(normalizedFilter) || e.name.toLowerCase().includes(normalizedFilter)
      }
    ))
  }))
}

export function EntityAffiliatedRisks(
  {
    relatedRisks,
  }: {
    relatedRisks: EntityRelatedRisk[]
  }): JSX.Element {

  const [filterText, setFilterText] = useState('')
  const searchInput = useRef<HTMLInputElement>(null);

  const [risks, setRisks] = useState<RiskItemVm[]>(buildVm(relatedRisks))

  function handleClearSearchText() {
    onFilterTextChange('')
    if (searchInput.current)
      searchInput.current.focus()
  }

  function onFilterTextChange(newFilterText: string) {
    setFilterText(newFilterText)
    setRisks(prevState => filterRisks(prevState, newFilterText))
  }

  return (
    <div className="content-section pt-2">
      <h3>Affiliated Company Risks</h3>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          onChange={(e) => onFilterTextChange(e.target.value)}
          value={filterText}
          ref={searchInput}
        />
        <div className="input-group-append">
          <button className="btn btn-secondary btn-clear" type="button" onClick={handleClearSearchText}>&times;</button>
        </div>
      </div>
      <div className="risk-events list-group">
        {risks.filter(r => r.entities.some(e => e.filterInclude)).map((risk) =>
          <div key={risk.riskId} className='risk-box'>
            <div className='clickable d-flex'
              onClick={() => setRisks(prevState => toggleRisk(prevState, risk.riskId)) }
            >
              <div className='flex-fill risk-name'>{risk.name}</div>
              <div className='entity-title'>
                Entities ({risk.entities.filter(entity => entity.filterInclude).length})
              </div>
              <div className='caret'>
                {(risk.expanded ? <CaretUp /> : <CaretDown />)}
              </div>
            </div>
            <div className='sub-title'>{risk.parentName}</div>
            {
              risk.expanded &&
              <div>
                {
                  risk.entities.filter(entity => entity.filterInclude).map(entity => (
                    <div className='d-flex'>
                      <div className='flex-fill entity-name'>
                        <Link to={"/entityrisktracker/" + entity.entityId}>{entity.name}</Link>
                      </div>
                      <div className='entity-relationship'>{entity.relationship}</div>
                    </div>
                  ))
                }
              </div>
            }
          </div>
        )}
      </div>
    </div>
  )
}
