import {
  DropdownMenu, 
  LoadingComponent, 
  Modal,
  NestedEntitySidebar,
  SectionedContent,
} from 'components'
import {Link} from 'react-router-dom';
import EmailIcon from 'components/icons/EmailIcon';
import AddIcon from 'components/icons/AddIcon';
import {useAppContext} from 'context';
import {useEffect, useState} from 'react';
import {
  ErrorDetail,
  Transaction, 
  TransactionFilterForm
} from 'models'
import { qsToTranForm } from 'models/converters'
import { buildQuery, TransactionsApi } from 'apis';
import { TranPaths } from 'apps/transactions/Transactions'
import { TransactionSidebar } from './components/TransactionSidebar'
import {handleAsyncLoader} from 'lib';
import {SavedItemsApi} from 'apis/SavedItemsApi';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';
import {DropdownItem} from 'components/DropdownMenu';
import {TrashIcon} from 'components/icons/TrashIcon';
import {ModalSavedItemSuccessfully, SavedItemState} from 'components/ModalSavedItemSuccessfully';
import {PrintFooter} from 'components/PrintFooter';
import PrinterIcon from 'components/icons/PrinterIcon';


interface TranVm {
  tran: Transaction
  notify: boolean | undefined
}


function tranCountrySearch(id: number) {
  const filter: TransactionFilterForm = {
    ...qsToTranForm(new URLSearchParams()),
    countryIds: [`${id}`]
  }

  return TranPaths.list + buildQuery(filter, true)
}

export function TransactionView(
  {
    id,
    backPath,
  }: {
    id: number,
    backPath: string,
  }
): JSX.Element {

  const { session } = useAppContext()

  const [transaction, setTransaction] = useState<TranVm>()
  const [selectedEntityId, setSelectedEntityId] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()
  const [savedItemState, setSavedItemState] = useState(SavedItemState.none)


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      const tran = await new TransactionsApi(session.token).id(id)
      const notify = (await new SavedItemsApi(session.token).list('Transaction')).saved_items.filter(si => si.saved_record_id === id)[0]?.notify
      setTransaction({
        tran,
        notify,
      })
      setSelectedEntityId(undefined)
    }), [id, session.token])



  async function handleSaveEmailAlert() {
    try {
      const successState = typeof transaction?.notify === 'undefined'
        ? SavedItemState.saved
        : SavedItemState.activated
      await new SavedItemsApi(session.token).upsert('Transaction', [transaction?.tran.id ?? 0], true)
      setTransaction(a => !!a ? ({...a, notify: true}) : undefined)
      setSavedItemState(successState)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }

  async function handleSavedItems() {
    try {
      const successState = typeof transaction?.notify === 'undefined'
        ? SavedItemState.saved
        : SavedItemState.deactivated
      await new SavedItemsApi(session.token).upsert('Transaction', [transaction?.tran.id ?? 0], false)
      setTransaction(a => !!a ? ({...a, notify: false}) : undefined)
      setSavedItemState(successState)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }


  async function handleDeleteSavedItem() {
    try {
      await new SavedItemsApi(session.token).destroy('Transaction', transaction?.tran.id ?? 0)
      setTransaction(a => !!a ? ({...a, notify: undefined}) : undefined)
      setSavedItemState(SavedItemState.deleted)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }


  const items: DropdownItem[] = [
    {
      icon: <EmailIcon />,
      onClick: handleSaveEmailAlert,
      children: <>Activate Email Notifications</>,
      excludeWhen: true,
    },
    {
      icon: !!transaction && transaction.notify === true ? <EmailIcon /> : <AddIcon />,
      onClick: handleSavedItems,
      children: <>{!!transaction && transaction.notify === true ? 'Deactivate Email Notifications' : 'Add to Saved Items'}</>,
      excludeWhen: false,
    },
    {
      icon:<TrashIcon />,
      onClick: handleDeleteSavedItem,
      children: <>Delete Saved Item</>,
      excludeWhen: undefined,
    },
    {
      icon:<div style={{height: '19px', width: '19px', marginLeft: '8px', marginRight: '8px', marginTop: '-8px'}}><PrinterIcon /></div>,
      onClick: window.print,
      children: <>Save to PDF</>,
      excludeWhen: null,
    },
  ]
    .filter(mi => !!transaction && mi.excludeWhen !== transaction.notify)


  return (
    <>
      <LoadingComponent loading={loading} error={error}>
        <div className="content py-1">
          <div className="border-right">
            <SectionedContent
              className="content-left"
              title={transaction?.tran.title ?? ''}
              subtitle={(
                <div className="no-print d-flex w-100 align-items-center">
                  <Link className="link"
                        to={backPath}
                  >
                    Back to Transactions
                  </Link>
                  <DropdownMenu className="ml-auto" items={items}/>
                </div>
              )}
            >
              <div className="print-on-same-page">
                <div className="content-section-title">Description</div>
                { transaction &&
                  <div dangerouslySetInnerHTML={{__html:transaction.tran.description}} />
                }
              </div>
              { transaction && transaction.tran.citations && transaction.tran.citations.length > 0 &&
                <div className="print-on-same-page">
                  <div className="content-section-title">Citations</div>
                  {transaction.tran.citations.map((c, i) =>
                    <p key={i}>{c.citation.description}</p>)
                  }
                </div>
              }

              <>
                <div className="print-on-same-page">
                  <div className="content-section-title">Primary Companies</div>

                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                      <tr>
                        <th>Name</th>
                        <th>Entity Type</th>
                        <th>Ownership Type</th>
                        <th>Country Of Domicile</th>
                      </tr>
                      </thead>

                      <tbody>
                      {transaction?.tran.primary_companies && transaction.tran.primary_companies.map(c => (
                        <tr key={c.id}>
                          <td>
                            <a role="button" className="buttonRole"
                              onClick={() => setSelectedEntityId(c.id)}
                            >
                              {c.name}
                            </a>
                          </td>
                          <td>{c.entity_type.name}</td>
                          <td>{c.ownership_types.map(o => o.name).join(', ')}</td>
                          <td><Link to={tranCountrySearch(c.country.id)}>{c.country.name}</Link></td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="print-on-same-page">
                  <div className="content-section-title">Customers</div>

                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                      <tr>
                        <th>Name</th>
                        <th>Entity Type</th>
                        <th>Ownership Type</th>
                        <th>Country Of Domicile</th>
                      </tr>
                      </thead>

                      <tbody>
                      {transaction?.tran.customers && transaction.tran.customers.map(c => (
                        <tr key={c.id}>
                          <td>
                            <a role="button" className="buttonRole"
                              onClick={() => setSelectedEntityId(c.id)}
                            >
                              {c.name}
                            </a>
                          </td>
                          <td>{c.entity_type.name}</td>
                          <td>{c.ownership_types.map(o => o.name).join(', ')}</td>
                          <td><Link to={tranCountrySearch(c.country.id)}>{c.country.name}</Link></td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>

            </SectionedContent>

            {selectedEntityId &&
              <NestedEntitySidebar 
                id={selectedEntityId}
                setId={setSelectedEntityId}
                nestedTitle="Transaction Details"
              />
            }
            {!selectedEntityId && transaction &&
              <TransactionSidebar transaction={transaction.tran} />
            }

          </div>
        </div>
      </LoadingComponent>
      <PrintFooter
        type={'Transactions'}
        updatedAt={transaction?.tran.updated_at}
      />
      <ModalSavedItemSuccessfully
        state={savedItemState}
        itemTitle={transaction?.tran.title ?? ''}
        close={() => setSavedItemState(SavedItemState.none)}
      />

    </>
  )
}
