import {
  MainContainer,
  PageContainer,
  SearchBar,
} from 'components';
import {
  Route,
  RouteComponentProps,
  Switch, useLocation,
} from 'react-router-dom';
import {TransactionView} from 'apps/transactions/TransactionView';
import {TransactionHome} from 'apps/transactions/TransactionHome';
import {useEffect, useState} from 'react';




export enum TranPaths {
  map = '/transactions/map',
  list = '/transactions/list',
  related = '/transactions/list/related',
  mapFilters = '/transactions/map/filters',
  listFilters = '/transactions/list/filters',
  relatedFilters = '/transactions/list/related/filters',
}
const HomePaths = [
  '/transactions', // assume map
  TranPaths.map,
  TranPaths.list,
  TranPaths.related,
  TranPaths.mapFilters,
  TranPaths.listFilters,
  TranPaths.relatedFilters,
]


export function Transactions(
  {
    searchBar,
  } : {
    searchBar: JSX.Element
  }
): JSX.Element {

  const location = useLocation()

  const [backPath, setBackPath] = useState(HomePaths.includes(location.pathname) ? location.pathname : TranPaths.map)


  useEffect(() => {
    if (HomePaths.includes(location.pathname))
      setBackPath(location.pathname + location.search)
  }, [location.pathname, location.search])



  return (

    <PageContainer navItem="transactions">
      <Switch>
        <Route exact path={HomePaths}>
          <TransactionHome searchBar={searchBar} />
        </Route>
        <Route exact path="/transactions/:id"
          render={({match}: RouteComponentProps<{id: string}>) => (
            <MainContainer searchBar={searchBar} rightContentActive>
              <TransactionView
                id={+match.params.id}
                backPath={backPath}
              />
            </MainContainer>
          )} 
        />
      </Switch>
    </PageContainer>
  )
}
