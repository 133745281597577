import {useEffect, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'
import { find } from 'lodash'

import {useAppContext, useAppNavBarContext} from 'context'

import CaretDown from './icons/CaretDown'
import {TranPaths} from 'apps/transactions/Transactions';
import {AlertPaths} from 'apps/alerts/Alerts';
import {DropdownList} from 'components/DropdownList';
import {CountryReportPaths} from 'apps/countryreports/CountryReports';
import SidebarToggleIcon from 'components/icons/SidebarToggleIcon';

interface SearchBarProps {
  searchBarState: SearchBarState
}

export interface SearchBarState {
  searchType: SearchType
  searchText: string
  edited?: boolean
}

const textParam = 'text'
const defaultPath = '/search'
export interface SearchType {
  type: 'all' | 'transactions' | 'ert' | 'alerts' | 'countryreports'
  title: string
  path: string
}
export const SearchTypes: SearchType[] = [
  {
    type: 'all',
    title: 'All IntelTrak',
    path: defaultPath
  },
  {
    type: 'transactions',
    title: 'Transactions',
    path: TranPaths.list
  },
  {
    type: 'ert',
    title: 'Entity Risk Tracker',
    path: '/entityrisktracker'
  },
  {
    type: 'alerts',
    title: 'Alerts',
    path: AlertPaths.list
  },
  {
    type: 'countryreports',
    title: 'Country Reports',
    path: CountryReportPaths.list
  }
]

export function SearchBar(
  {
    searchBarState,
    setSearchBarState,
  }: {
    searchBarState: SearchBarState
    setSearchBarState: (next: Partial<SearchBarState>) => void
  }
): JSX.Element {

  const { logout, user } = useAppContext()
  const { toggleOpen } = useAppNavBarContext()
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const [showUserMenu, setShowUserMenu] = useState<boolean>(false)

  useEffect(() => {
    if (searchBarState.searchText === '') {
      const searchText = query.get(textParam)
      if (!searchBarState.edited && searchText?.length && searchText?.length > 0)
        setSearchBarState({searchText})
    }
  }, [query])



  const searchTextChange = (text: string) => setSearchBarState({
    searchText: text,
    edited: true
  })
  const setSearchType = (type: SearchType) => setSearchBarState({searchType: type})

  function runSearch() {
    const path = searchBarState.searchType.path || defaultPath
    history.push(`${path}?${textParam}=${encodeURIComponent(searchBarState.searchText)}`)
  }

  function searchTextKeyUp(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === 'Enter') {
      runSearch()
    }
  }

  return (
    <nav className="navbar navbar-expand navbar-light">
      <div className="hamburger-menu">
        <button className="btn btn-link sidebar-toggle" onClick={toggleOpen}>
          <SidebarToggleIcon />
        </button>
      </div>
      <div className="input-group input-group-search">
        <input type="text" 
          className="form-control" 
          placeholder="Search IntelTrak…" 
          value={searchBarState.searchText}
          onChange={e => searchTextChange(e.target.value)} 
          onKeyUp={e => searchTextKeyUp(e)}
        />
        <div className="input-group-append show">
          <button className='btn btn-gray-light btn-sm text-uppercase px-2' onClick={runSearch}>Search</button>
          <DropdownList
            items={SearchTypes}
            value={searchBarState.searchType}
            onChange={setSearchType}
            lookupName={st => st.title}
            bare
          />
        </div>
      </div>
      <div className="user-menu">
        {user.loggedIn && (
          <div className="user-menu-info"
               onClick={() => setShowUserMenu(!showUserMenu)}
          >
            {user.first_name} {user.last_name}
            <CaretDown />
          </div>
        )}
        <div className={`user-menu-menu ${showUserMenu ? 'show' : ''}`}>
          <Link
            to='/profile'
            className='btn'
          >
            My Profile
          </Link>
          <button onClick={logout}>
            Sign Out
          </button>
        </div>
      </div>
    </nav>
  )
}
