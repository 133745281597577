import { useState, useRef } from 'react';
import { reverse, sortBy } from 'lodash'

import { EntityRiskDesignation } from 'models'
import { Accordion, DateView } from 'components';
import CaretUp from 'components/icons/CaretUp';
import CaretDown from 'components/icons/CaretDown';

import { RiskEventColumns } from 'apps/entityrisktracker/EntityView'
import { EntityRiskExampleCitation } from './EntityRiskExampleCitation' 

import './EntityRiskEvents.scss'

export function EntityRiskEventExamples(
  {
    event,
    expanded,
    setExpanded,
  }: {
    event: EntityRiskDesignation
    expanded: boolean
    setExpanded: (func: (prev: boolean) => boolean) => void
  }): JSX.Element {


  return (
    <>
      <div className={"risk-event-examples-header clickable" + (expanded ? " expanded" : '')} onClick={() => setExpanded(prev => !prev)}>
        Risk Events
        <span style={{marginLeft: '5px', alignItems: 'center'}}>{(expanded ? <CaretUp /> : <CaretDown />)}</span>
      </div>
      <Accordion expanded={expanded}>
        <div className="risk-examples">
          {event.examples.map((example, xkey) =>
            <div className="risk-example" key={xkey}>
              {example.location &&
                <div className="risk-example-location">{example.location}</div>
              }
              {example.location && example.date &&
                <div className="risk-example-separator">|</div>
              }
              {example.date &&
                <div className="risk-example-date">
                  <DateView type='date'>{example.date}</DateView>
                </div>
              }
              <div className="risk-example-summary"dangerouslySetInnerHTML={{__html: example.summary}}></div>

              <EntityRiskExampleCitation 
                className="risk-example-citation"
                citation={example.citation}
              />
            </div>
          )}
        </div>
      </Accordion>
    </>
  )
}


function EntityRiskEventRow(
  {
    event
  } : {
    event: EntityRiskDesignation
  }
): JSX.Element {

  const [examplesExpanded, setExamplesExpanded] = useState(false)

  return (
    <div className="risk-designation list-group-item">
      <div className="risk-score">{event.risk_score}</div>
      <div className="risk-tag">
        <a onClick={(e) => {setExamplesExpanded(prev => !prev); e.preventDefault();}} href='#'>{event.risk}</a>
      </div>
      <div className="risk-category">
        {event.parent}
      </div>
      <div className="risk-summary" dangerouslySetInnerHTML={{__html: event.summary}} />
      <EntityRiskEventExamples event={event} expanded={examplesExpanded} setExpanded={setExamplesExpanded} />
    </div>
  )
}


export function EntityRiskEvents(
  {
    downloadLink,
    riskEvents,
    changeSort,
    sortCol,
    sortDirAsc,
    filterText,
    changeFilterText,
  }: {
    downloadLink?: JSX.Element
    riskEvents: EntityRiskDesignation[]
    sortCol: RiskEventColumns
    sortDirAsc: boolean
    changeSort: (col: RiskEventColumns, dirAsc: boolean) => void
    filterText: string
    changeFilterText: (f: string) => void
  }): JSX.Element {

  function handleColClick(col: RiskEventColumns) {
    if (col === sortCol) {
      changeSort(col, !sortDirAsc)
    } else {
      changeSort(col, true)
    }
  }


  const up = <>&#x25b4;</>
  const down = <>&#x25be;</>

  const sortedRiskEvents = reverse(sortBy(riskEvents, e => e.risk_score))

  const searchInput = useRef<HTMLInputElement>(null);

  function handleClearSearchText() {
    changeFilterText('')
    if (searchInput.current)
      searchInput.current.focus()
  }

  return (
    <div className="content-section pt-2">
      <h3>Company Risk Events</h3>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Search"
          onChange={(e) => changeFilterText(e.target.value)}
          value={filterText}
          ref={searchInput}
        />
        <div className="input-group-append">
          <button className="btn btn-secondary btn-clear" type="button" onClick={handleClearSearchText}>&times;</button>
        </div>
      </div>
      <div className="risk-events list-group">
        {sortedRiskEvents.map((event, key) =>
          <EntityRiskEventRow key={key} event={event} />
        )}
      </div>
      {downloadLink}
    </div>
  )
}
