
export function parseDate(s: string): Date {
  // Dates look like '2021-12-13 13:42:12 -05:00'
  // Browser can parse this ISO format: '2021-12-13T13:42:12-05:00', so this converts the string to the ISO format

  const parts = s.split(' ')
  if (parts.length === 3) {
    s = `${parts[0]}T${parts[1]}${parts[2]}`
  }
  return new Date(s)
}
