import { CountryReportsSearchItem } from 'models'
import { DateView, HighlightText } from 'components'

export function SearchCountryResults({
  results,
  onSelect
}: {
  results: CountryReportsSearchItem[],
  onSelect: (id: number, title: string) => void,
}): JSX.Element {
  return (
    <div className="tab-pane fade show active pb-6 px-4" role="tabpanel">
      <div className="table-responsive">
        <table className="table table-large table-alignment-top">
          <colgroup>
            <col style={{width: '320px'}}>
            </col>
          </colgroup>
          <tbody>
            {results.map(r => (
              <tr key={r.id}
                onClick={() => onSelect(+r.id, r.title)}
              >
                <td className="col-name">{r.title}</td>
                <td>
                  <div className="info-box dot dot-left my-2">
                    Last Updated:
                    &nbsp;
                    <DateView type="date" parse="YYYY-MM-DD">
                      {r.publication_date}
                    </DateView>
                  </div>
                  <HighlightText text={r.published_text} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
