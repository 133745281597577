import {ErrorDetail, Session} from 'models';
import axios from 'axios';
import {MsAutocompleteResponse} from 'models/Forms';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';


const DefaultErrorMessage = "There was a problem completing your request"

export interface AsyncState {
  isMounted: boolean
}

export function handleAsyncLoader(
  setLoading: (v: boolean) => void,
  setError: (v: ErrorDetail | undefined) => void,
  action: (state: AsyncState) => Promise<void>
): () => () => void {

  return () => {
    const state = {
      isMounted: true
    };
    (async () => {
      setLoading(true)
      setError(undefined)
      await action(state)
    })()
    .catch(e => {
      logExceptionFromCatch(e)
      setError(convertError(e))
    })
    .finally(() => {
      if (state.isMounted)
        setLoading(false)
    });

    return () => {state.isMounted = false}
  }

}


export function convertError(e: any): ErrorDetail {

  if (typeof (e) === 'string')
    return {
      message: e,
      is401: false,
    }

  if (axios.isAxiosError(e)) {
    if (e.response?.data) {
      if (e.response.data.hasOwnProperty('errors') && Array.isArray(e.response.data.errors)) {
        const errors: string[] = e.response.data.errors
        if (errors.length > 0)
          return {
            message: errors[0],
            is401: e.response?.status === 401
          }
      }
      if (e.response.data.hasOwnProperty('errors') && typeof (e.response.data.error) === 'string') {
        const error: string = e.response.data.error
        return {
          message: error,
          is401: e.response?.status === 401
        }
      }
    }

    return {
      message: DefaultErrorMessage,
      is401: e.response?.status === 401
    }
  }

  if ('message' in e && typeof e.message === 'string') // Error-like objects
    return {
      message: e.message,
      is401: false,
    }

  return {
    message: DefaultErrorMessage,
    is401: false,
  }
}


export async function suppressAutoCompleteError(a: () => Promise<MsAutocompleteResponse>): Promise<MsAutocompleteResponse> {
  return await suppressError(a, {data: [], more: false})
}


export async function suppressError<T>(a: () => Promise<T>, defaultVal: T): Promise<T> {
  try {
    return await a()
  }
  catch (e) {
    logExceptionFromCatch(e)
    return defaultVal
  }
}
