import {ReactNode, useEffect, useState} from 'react';

export function Accordion(
  {
    expanded,
    children,
  } : {
    expanded: boolean
    children: ReactNode
  }
): JSX.Element {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => setLoading(false), 500)
    return () => console.log('unmounted')
  }, [])

  return (
    <div className={"expand-container"}>
      <div className={'expand-content ' + (expanded ? ' expanded' : '') + (loading ? ' ': ' enabled')}>
        {children}
      </div>
    </div>
  )
}
