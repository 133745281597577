export const updatedDaysOptions = [
  {label: "", value: ''},
  {label: "Past 24 Hours", value: '1'},
  {label: "Past 3 Days", value: '3'},
  {label: "Past 7 Days", value: '7'},
  {label: "Past 2 Weeks", value: '14'},
  {label: "Past Month", value: '31'},
  {label: "Past 2 Months", value: '62'},
  {label: "Past 3 Months", value: '93'},
  {label: "Past 6 Months", value: '183'},
  {label: "Past Year", value: '365'}
] as const


export const updatedDaysValues = updatedDaysOptions.map(d => d.value)


export type UpdatedDaysType = typeof updatedDaysValues[number]
