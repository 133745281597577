import dayjs from 'dayjs'

export function DateRange(
  {
    startDate,
    endDate,
  } : {
    startDate?: string | null,
    endDate?: string | null,
  }
): JSX.Element {
  return (
    <>
      {startDate && dayjs(startDate).format('MMMM D, YYYY')} — {endDate && dayjs(endDate).format('MMMM D, YYYY')}
    </>
  )
}
