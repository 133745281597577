export const CaretLeftIcon = ({
  width, 
  height
}: {
  width?: string,
  height?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 17"
    width={width || '10'}
    height={height || '17'}
  >
    <path d="M16,0,8,8,0,0" transform="translate(9 0) rotate(90)" fill="none" stroke="#707172"
          strokeMiterlimit="10" strokeWidth="2"></path>
  </svg>
)