import { ReactNode } from 'react'

export const SectionedContentSection = ({
  children
}: {
  children: ReactNode
}): JSX.Element => (
  <div>{children}</div>
)
export const SectionedContentSectionTitle = ({
  children
}: {
  children: ReactNode
}): JSX.Element => (
  <div className="content-section-title">{children}</div>
)

export const SectionedContent = ({
  className,
  title,
  subtitle,
  children
}: {
  className: string,
  title: ReactNode,
  subtitle: ReactNode,
  children: ReactNode
}): JSX.Element => {

  const sections = Array.isArray(children) ? children : [children]

  return (
    <div className={className}>
      {(title || subtitle) &&
        <div className="content-header">
          {title &&
            <div className="content-title">
              {title}
            </div>
          }
          {subtitle &&
            <div className="content-subtitle active">
              {subtitle}
            </div>
          }
        </div>
      }
      {sections.map((section, index) => (
        section ? 
          <div key={index} className="content-section">
            {section}
          </div> : 
          null
      ))}
    </div>
  )

}
