import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

import {useAppContext} from 'context'

import {AlertApi} from 'apis'
import {Alert, ErrorDetail} from 'models'

import {
  DateView,
  DropdownMenu,
  LoadingComponent,
  NestedEntitySidebar,
  SectionedContent,
  SectionedContentSection,
  SectionedContentSectionTitle,
  SectionedSidebar,
  SectionedSidebarContent,
  SectionedSidebarContentSection,
  SectionedSidebarContentSectionTitle,
} from 'components'
import AddIcon from 'components/icons/AddIcon'
import EmailIcon from 'components/icons/EmailIcon'

import {AlertEntitiesList} from './components/AlertEntitiesList'
import {AlertTransactionsList} from './components/AlertTransactionsList'
import {AlertPaths} from 'apps/alerts/Alerts';
import {handleAsyncLoader} from 'lib';
import {SavedItemsApi} from 'apis/SavedItemsApi';
import {DropdownItem} from 'components/DropdownMenu';
import {TrashIcon} from 'components/icons/TrashIcon';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';
import {ModalSavedItemSuccessfully, SavedItemState} from 'components/ModalSavedItemSuccessfully';
import {PrintFooter} from 'components/PrintFooter';
import PrinterIcon from 'components/icons/PrinterIcon';

interface AlertVm {
  alert: Alert
  notify: boolean | undefined
}

export const AlertView = (
  {
    id,
    backPath
  }: {
    id: string,
    backPath: string,
  }
): JSX.Element => {

  const { session } = useAppContext()

  const [alertObject, setAlertObject] = useState<AlertVm>()
  const [selectedEntityId, setSelectedEntityId] = useState<number>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ErrorDetail>()
  const [savedItemState, setSavedItemState] = useState(SavedItemState.none)

  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      const intId = +id
      const alert = await new AlertApi(session.token).id(intId)
      const notify = (await new SavedItemsApi(session.token).list('Alert')).saved_items.filter(si => si.saved_record_id === intId)[0]?.notify
      setAlertObject({
        alert,
        notify
      })
    }), [id, session.token])


  async function handleSaveEmailAlert() {
    try {
      const successState = typeof alertObject?.notify === 'undefined'
        ? SavedItemState.saved
        : SavedItemState.activated
      await new SavedItemsApi(session.token).upsert('Alert', [alertObject?.alert.id ?? 0], true)
      setAlertObject(a => !!a ? ({...a, notify: true}) : undefined)
      setSavedItemState(successState)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }

  async function handleSavedItems() {
    try {
      const successState = typeof alertObject?.notify === 'undefined'
        ? SavedItemState.saved
        : SavedItemState.deactivated
      await new SavedItemsApi(session.token).upsert('Alert', [alertObject?.alert.id ?? 0], false)
      setAlertObject(a => !!a ? ({...a, notify: false}) : undefined)
      setSavedItemState(successState)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }

  async function handleDeleteSavedItem() {
    try {
      await new SavedItemsApi(session.token).destroy('Alert', alertObject?.alert.id ?? 0)
      setAlertObject(a => !!a ? ({...a, notify: undefined}) : undefined)
      setSavedItemState(SavedItemState.deleted)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }

  // Deduplicate entity countries and sort by name
  let alertCountries = alertObject?.alert.entities.map(e => e.entity.country).filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
  alertCountries = alertCountries?.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

  const items: DropdownItem[] = [
    {
      icon: <EmailIcon />,
      onClick: handleSaveEmailAlert,
      children: <>Activate Email Notifications</>,
      excludeWhen: true,
    },
    {
      icon: !!alertObject && alertObject.notify === true ? <EmailIcon /> : <AddIcon />,
      onClick: handleSavedItems,
      children: <>{!!alertObject && alertObject.notify === true ? 'Deactivate Email Notifications' : 'Add to Saved Items'}</>,
      excludeWhen: false,
    },
    {
      icon:<TrashIcon />,
      onClick: handleDeleteSavedItem,
      children: <>Delete Saved Item</>,
      excludeWhen: undefined,
    },
    {
      icon:<div style={{height: '19px', width: '19px', marginLeft: '8px', marginRight: '8px', marginTop: '-8px'}}><PrinterIcon /></div>,
      onClick: window.print,
      children: <>Save to PDF</>,
      excludeWhen: null,
    },
  ]
    .filter(mi => !!alertObject && mi.excludeWhen !== alertObject.notify)


  return (
    <LoadingComponent loading={loading} error={error}>
      {alertObject &&
        <div className="border-right">
          <SectionedContent className="content-left"
            title={alertObject.alert.title}
            subtitle={(
              <div className="no-print d-flex w-100 align-items-center">
                <Link className="link"
                  to={backPath}
                >
                  Back to Alerts
                </Link>
                <DropdownMenu className="ml-auto" items={items}/>
              </div>
            )}
          >
            <SectionedContentSection>
              <SectionedContentSectionTitle>Description</SectionedContentSectionTitle>
              <div dangerouslySetInnerHTML={{__html: alertObject.alert.comment}} />
            </SectionedContentSection>

            {alertObject.alert.entities && alertObject.alert.entities.length > 0 &&
              <SectionedContentSection>
                <SectionedContentSectionTitle>
                  Entities
                </SectionedContentSectionTitle>
                <AlertEntitiesList 
                  setEntityId={setSelectedEntityId} 
                  alert={alertObject.alert}
                />
              </SectionedContentSection>
            }

            {alertObject.alert.transactions && alertObject.alert.transactions.length > 0 &&
              <SectionedContentSection>
                <SectionedContentSectionTitle>
                  Transactions
                </SectionedContentSectionTitle>
                <AlertTransactionsList alert={alertObject.alert} />
              </SectionedContentSection>
            }

          </SectionedContent>

          {selectedEntityId &&
            <NestedEntitySidebar 
              id={selectedEntityId}
              setId={setSelectedEntityId}
              nestedTitle="Alert Details"
            />
          }
          {!selectedEntityId &&
            <SectionedSidebar>
              <SectionedSidebarContent title={(<>
                Alert Details
              </>)}>
                <SectionedSidebarContentSection>
                  <div className="row">
                    <div className="col">
                      <SectionedSidebarContentSectionTitle>
                        Start Date
                      </SectionedSidebarContentSectionTitle>
                      <DateView type="date">{alertObject.alert.start_date}</DateView>
                    </div>
                    <div className="col">
                      <SectionedSidebarContentSectionTitle>
                        End Date
                      </SectionedSidebarContentSectionTitle>
                      <DateView type="date">{alertObject.alert.end_date}</DateView>
                    </div>
                  </div>
                </SectionedSidebarContentSection>
                <SectionedSidebarContentSection title="Active">
                  <div
                    className={`dot dot-left ${
                      alertObject.alert.status === 'Active' ?
                      'active' :
                      ''}`
                    }
                  >
                    {alertObject.alert.status === 'Active' ? 'Yes' : alertObject.alert.status}
                  </div>
                </SectionedSidebarContentSection>
                <SectionedSidebarContentSection title="Cities">
                  {alertObject.alert.cities?.map(({city}) => (
                    <>{city.display_name}</>
                  ))}
                </SectionedSidebarContentSection>
                <SectionedSidebarContentSection title="Countries">
                  {alertCountries?.map(country => (
                    <Link className="d-block"
                      to={`${AlertPaths.list}?country_ids[]=${country.id}`}
                    >
                      {country.name}
                    </Link>
                  ))}
                </SectionedSidebarContentSection>
              </SectionedSidebarContent>
            </SectionedSidebar>
          }


          <PrintFooter
            type={'Alerts'}
            updatedAt={alertObject.alert.updated_at}
          />
        </div>
      }
      {!alertObject &&
        <>Error loading Alert</>
      }

      <ModalSavedItemSuccessfully
        state={savedItemState}
        itemTitle={alertObject?.alert.title ?? ''}
        close={() => setSavedItemState(SavedItemState.none)}
        />

    </LoadingComponent>
  )
}
