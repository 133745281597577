export default function TransactionsBlueIcon() {
  return (
    <svg className="sidebar-section-title-icon" xmlns="http://www.w3.org/2000/svg" width="16.363"
         height="16.111" viewBox="0 0 16.363 16.111">
      <g transform="translate(-0.819 0.556)">
        <path className="icon-stroke" d="M0,0H14.407a1,1,0,0,1,.672,1.741L11.483,5" transform="translate(1 10)"
              fill="none" stroke="#f06c00" strokeMiterlimit="10" strokeWidth="1.5"/>
        <path className="icon-stroke" d="M17,5H2.593a1,1,0,0,1-.672-1.741L5.517,0" fill="none" stroke="#f06c00"
              strokeMiterlimit="10" strokeWidth="1.5"/>
      </g>
    </svg>
  )
}
