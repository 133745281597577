export default function IconRiskParentCompany() {
  return (
    <svg width="22px" height="25px" viewBox="0 0 22 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Company &amp; Parent Risk</title>
        <g id="Family-Tree-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-3" transform="translate(3.000000, 15.000000)"></g>
            <g id="Family-Tree-Icon">
                <polyline id="Stroke-2" stroke="#B3B5B6" points="3.15767534 19.0009133 3.15767534 15.0007103 18.9460521 15.0007103 18.9460521 19.0009133"></polyline>
                <line x1="11.0518637" y1="19.4009336" x2="11.0518637" y2="10.6004871" id="Line-2" stroke="#B3B5B6" strokeLinecap="square"></line>
                <path d="M3.10581164,25 C1.39048338,25 0,23.5672755 0,21.7998376 C0,20.0323998 1.39048338,18.5996753 3.10581164,18.5996753 C4.82113991,18.5996753 6.21162329,20.0323998 6.21162329,21.7998376 C6.21162329,23.5672755 4.82113991,25 3.10581164,25 Z" id="Fill-4" fill="#B3B5B6"></path>
                <path d="M14.1058116,21.7998376 C14.1058116,23.5672755 12.7153283,25 11,25 C9.28467173,25 7.89418836,23.5672755 7.89418836,21.7998376 C7.89418836,20.0323998 9.28467173,18.5996753 11,18.5996753 C12.7153283,18.5996753 14.1058116,20.0323998 14.1058116,21.7998376" id="Fill-4-Copy" fill="#B3B5B6"></path>
                <path d="M14.1058116,7.39910703 C14.1058116,9.16654484 12.7153283,10.5992694 11,10.5992694 C9.28467173,10.5992694 7.89418836,9.16654484 7.89418836,7.39910703 C7.89418836,5.63166921 9.28467173,4.19894467 11,4.19894467 C12.7153283,4.19894467 14.1058116,5.63166921 14.1058116,7.39910703" id="Fill-4-Copy-3" fill="#f06c00"></path>
                <path d="M22,21.7998376 C22,23.5672755 20.6095166,25 18.8941884,25 C17.1788601,25 15.7883767,23.5672755 15.7883767,21.7998376 C15.7883767,20.0323998 17.1788601,18.5996753 18.8941884,18.5996753 C20.6095166,18.5996753 22,20.0323998 22,21.7998376" id="Fill-4-Copy-2" fill="#B3B5B6"></path>
                <line x1="3.55238476" y1="0.400020295" x2="18.5513426" y2="0.400020295" id="Line-3" stroke="#f06c00" strokeWidth="2" strokeLinecap="square"></line>
            </g>
        </g>
    </svg>
  )
}
