import {LoadingComponent, Sidebar, SidebarContent} from 'components';
import {Link} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import {useAppContext} from 'context';
import {TransactionsApi} from 'apis';
import TransactionsBlueIcon from 'components/icons/TransactionsBlueIcon';
import {ErrorDetail, TransactionFilterForm} from 'models';
import {tranToQueryReq} from 'models/converters';
import {handleAsyncLoader} from 'lib';


interface TransactionRow {
  name: string,
  to: string,
  id: number,
}

export function TransactionGroupSidebar(
  {
    id,
    name,
    count,
    baseFilter,
    onClose,
  }: {
    id: number,
    name: string,
    count: number,
    baseFilter: TransactionFilterForm,
    onClose: () => void,
  }
): JSX.Element {

  const { session } = useAppContext()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()
  const [transactions, setTransactions] = useState<TransactionRow[]>([{
    to: '',
    name: 'abc',
    id: 1,
  }])

  const filter = useMemo<TransactionFilterForm>(() => {
    switch (baseFilter.clusterBy) {
      case 'city':
        return {
          ...baseFilter,
          cityIds: [`${id}`],
          page: '1',
          per_page: `${count + 10}`,
        }
      case 'continent':
        return {
          ...baseFilter,
          continentIds: [`${id}`],
          page: '1',
          per_page: `${count + 10}`,
        }
      case 'country':
        return {
          ...baseFilter,
          countryIds: [`${id}`],
          page: '1',
          per_page: `${count + 10}`,
        }
      case 'region':
        return {
          ...baseFilter,
          regionIds: [`${id}`],
          page: '1',
          per_page: `${count + 10}`,
        }
    }
    return {
      ...baseFilter,
      page: '1',
      per_page: `${count + 10}`,
    }
  }, [id, baseFilter, count])


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      const trans = await new TransactionsApi(session.token).search(tranToQueryReq(filter))
      setTransactions(trans.transactions.map(t => ({
        id: t.id,
        to: `/transactions/${t.id}`,
        name: t.title
      })))
    }), [filter, session.token])




  return (
    <Sidebar onClose={onClose}
             header={name}
    >
      <LoadingComponent loading={loading} error={error}>
        <SidebarContent>
          <div className="sidebar-section-title pb-2">
            <TransactionsBlueIcon />
            Transactions
          </div>
          <div className="content-title large">{count}</div>
        </SidebarContent>

        <SidebarContent>
          <div className="sidebar-section-title pb-4">Most Recent Transactions:</div>

          <div className="sidebar-section-content">
            <ol className="list-numeric px-0">
              {transactions && transactions.map(t => (
                (<li key={t.id} className="list-numeric-item"><Link to={t.to}>{t.name}</Link></li>)
              ))}
            </ol>
          </div>
        </SidebarContent>
      </LoadingComponent>
    </Sidebar>
  )
}
