import { useEffect, useRef, useState } from 'react'

import { buildQuery } from 'apis'
import {LoadingComponent, setupMapboxMap} from 'components'
import { intelTrakApiUrl } from 'Config'
import { useAppContext } from 'context'
import mapboxgl from 'mapbox-gl'
import {AlertFilterForm, ErrorDetail} from 'models'
import { alertToQueryReq } from 'models/converters'
import {SessionStorage} from 'lib';
import {convertError} from 'lib/HandleAsyncLoader';

const TilesUrl = `${intelTrakApiUrl}/map/{z}/{x}/{y}.pbf?type=alerts`

const MapContainerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '300px',
}
const FillColor = '#f06c00'
const FillOpacity = 0.2
const SelectedOpacity = 0.9

const Source = 'alerts'
const PolygonLayer: mapboxgl.AnyLayer = {
  'id': 'alerts',
  'type': 'circle',
  'source': 'alerts',
  'source-layer': 'alerts',
  'paint': {
    'circle-radius': [
      'case',
      // ['<=', ['get', 'count'], 1], 6,  // hard to click on small things
      ['<=', ['get', 'count'], 1], 10, // r = sqrt(80 * area), ie area = r^2 / 80
      ['<=', ['get', 'count'], 2], 14,
      ['<=', ['get', 'count'], 5], 20,
      ['<=', ['get', 'count'], 11], 30,
      ['<=', ['get', 'count'], 20], 40,
      ['<=', ['get', 'count'], 31], 50,
      ['<=', ['get', 'count'], 45], 60,
      ['<=', ['get', 'count'], 61], 70,
      ['<=', ['get', 'count'], 80], 80,
      ['<=', ['get', 'count'], 101], 90,
      100,
      // ['<=', ['get', 'count'], 1], 5,
      // ['<=', ['get', 'count'], 3], 10,
      // ['<=', ['get', 'count'], 5], 20,
      // ['<=', ['get', 'count'], 10], 30,
      // ['<=', ['get', 'count'], 25], 40,
      // ['<=', ['get', 'count'], 50], 50,
      // ['<=', ['get', 'count'], 100], 75,
      // ['<=', ['get', 'count'], 200], 90,
      // 60,
    ],
    'circle-color': FillColor,
    'circle-opacity': FillOpacity,
    'circle-stroke-color': FillColor,
    'circle-stroke-width': 1,
   }
}

export const AlertMap = (
  {
    className,
    filter,
    setPreviewLocation,
    previewLocation
  }: {
    className?: string
    filter: AlertFilterForm
    setPreviewLocation: (
      {id, name, count}: {id: number, name: string, count: number}
    ) => void
    previewLocation?: {id: number, name: string, count: number}
  }
) => {


  const { session } = useAppContext()
  
  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(30);
  const [zoom, setZoom] = useState(1.6);

  const [error, setError] = useState<ErrorDetail>()

  const getSourceUrl = () => {
    const url = TilesUrl
    let query = buildQuery(alertToQueryReq(filter))
    if (query.startsWith('?')) query = query.substr(1)
    return url + '&' + query
  }

  const getSource: () => mapboxgl.AnySourceData = () => {
    return {
      'type': 'vector',
      'tiles': [getSourceUrl()]
    }
  }

  const highlight = () => {
    if (map.current !== null) {
      map.current.setPaintProperty('alerts', 'circle-opacity', [
        'match',
        ['get', 'id'],
        previewLocation?.id || -1,
        SelectedOpacity,
        FillOpacity
      ])
    }
  }

  const loadSource = () => {
    if (map.current !== null) {
      if (map.current.getSource(Source)) {
        map.current.removeLayer(PolygonLayer.id)
        map.current.removeSource(Source)
      }
      map.current.addSource(Source, getSource())
      map.current.addLayer(PolygonLayer)
      highlight()
      map.current.resize()  // Call resize to resize when parent resizes
    }
  }


  useEffect(() => {
    SessionStorage.setCurrentAlertsFilterView('map')
  }, [])
  
  useEffect(() => {
    if (map.current !== null && map.current.isStyleLoaded()) {
      loadSource()
    }
  }, [filter])

  useEffect(() => {
    if (map.current !== null && map.current.isStyleLoaded()) highlight()
  }, [previewLocation])

  useEffect(() => {
    map.current?.resize()
  }, [previewLocation])

  useEffect(() => {
    const setupMap = () => {
      map.current = setupMapboxMap({
        session,
        container: mapContainer.current,
        center: [lng, lat],
        zoom: zoom,
        onLoad: loadSource,
        onMouseEnter: {
          layer: 'alerts',
          fn: () => map.current!.getCanvas().style.cursor = 'pointer'
        },
        onMouseLeave: {
          layer: 'alerts',
          fn: () => map.current!.getCanvas().style.cursor = ''
        },
        onClick: {
          layer: 'alerts',
          fn: (e) => {
            if (e.features && e.features[0].properties !== null)
              setPreviewLocation({
                id: e.features[0].properties.id,
                name: e.features[0].properties.name,
                count: e.features[0].properties.count,
              })
          }
        },
        onIdle: () => map.current!.resize,
        onError: e => setError(convertError(e)),
      })
    }
    if (!map.current && mapContainer.current) setupMap() // initialize map only once
  }, [mapContainer.current])

  return (
    <LoadingComponent loading={false} error={error}>
      <div ref={mapContainer}
        className="map-container"
        style={MapContainerStyle}
      />
    </LoadingComponent>
  )

}
