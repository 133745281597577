import { CountryReport } from 'models'
import { DateView } from 'components'

interface CountryReportHistoryParams {
  countryReport: CountryReport
}

export const CountryReportHistory = ({
  countryReport
}: CountryReportHistoryParams) => (
  <>
    <div className="content-sidebar-subtitle px-4 pt-2 pb-2 border-bottom">
      Last Update
    </div>
    <ul className="list-group list-group-flush">
      {countryReport.country_report_documents.map(({country_report_document}) => (
        <li key={country_report_document.id} className="list-group-item">
          <DateView format="MMMM D, YYYY">
            {country_report_document.published_at}
          </DateView>
        </li>
      ))}
    </ul>
  </>
)