import {Api, UrlParams} from './Api'
import {CountryReport, CountryReportsSearchItem, Pager} from 'models'
import {GlobalSearchApiParams} from 'apis';

const URLS = {
  countryReports: '/country_reports',
  countryReport: '/country_reports/:id',
  countryReportSlug: '/country_reports/slugs/:slug'
}

export interface CountryReportSearch extends UrlParams {
  text?: string
  country_id?: number
  page?: number
  per_page?: number
  order?: string
  sort_mode?: string
}


export interface CountryReportResult {
  country_report: CountryReport
}

export interface CountryReportsSearchResults {
  country_reports: CountryReportsSearchItem[],
  pager: Pager,
}

export class CountryReportApi extends Api {


  async search(params: CountryReportSearch | GlobalSearchApiParams): Promise<CountryReportsSearchResults> {
    return (
      await this.get<CountryReportsSearchResults>(URLS.countryReports, params)
    ).data
  }

  async id(id: number): Promise<CountryReport> {
    const url = URLS.countryReport.replace(":id", id+"")
    return (
      await this.get<CountryReportResult>(url)
    ).data.country_report
  }

  async slug(slug: string): Promise<CountryReport> {
    const url = URLS.countryReportSlug.replace(":slug", slug)
    return (
      await this.get<CountryReportResult>(url)
    ).data.country_report
  }

}
