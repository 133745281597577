import {Api} from './Api';


export class S3Files extends Api {
  async name(srcPath: string): Promise<Blob> {

    const response = await this.get(srcPath, {}, {responseType: 'arraybuffer'});

    const blob = new Blob(
      [response.data],
      {type: response.headers['content-type']}
    );

    return blob;
  }

}
