import {UrlParams} from 'apis';
import {UpdatedDaysType} from 'data';
import { SearchGroupOptions, SearchGroupOptionsT } from './SearchGroupOptions'

export type TransactionStatus = 'Pending' | 'Active' | 'Past' | 'Future' | 'Cancelled'

export interface Transaction {
  "id": number,
  "title": string,
  "date": string, // "2021-09-13",
  "start_date": string | null, // "2011-10-20"
  "end_date": string | null, // "2011-10-20"
  status: TransactionStatus,
  "project_value": string, // "0",
  // "ongoing": boolean,
  "cancelled": boolean,
  "description": string,
  "created_at": string, // "2021-09-13 14:46:30 -0400",
  "updated_at": string, // "2021-09-13 14:46:30 -0400",
  "cities": [
    {
      "city": {
        "id": number,
        "name": string,
        "state": string | null,
        "link": string, // "/api/v1/cities/156938"
      }
    }
  ],
  "countries": [
    {
      "country": {
        "id": number,
        "name": string,
        "iso3": string, // "JPN",
        "link": string, // "/api/v1/countries/114"
      }
    }
  ],
  "transaction_types": [
    {
      "transaction_type": {
        "id": number,
        "name": string
      }
    }
  ],
  "industries": {
    "industry": {
      "id": number,
      "name": string
    }
  }[],
  // "transaction_tags": [],
  "citations": {
    "citation": {
      "description": string
    }
  }[],
  "primary_companies": [
    {
      "id": number,
      "name": string,
      "link": string, // "/api/v1/entities/1211780",
      "type": string, // "Entity"
      "entity_type": {
        "id": number,
        "name": string, // "Corporation/Bank"
      },
      "ownership_types": {
        "id": number,
        "name": string, // "State-Owned Enterprise"
      }[],
      "country": {
        "id": number,
        "name": string,
        "iso3": string, // "CHN",
        "link": string, // "/api/v1/countries/48"
      },
      "city_names": string[
        // "Seoul, Republic of Korea (South Korea)",
        // "Los Angeles, United States of America",
        // "Berlin, Germany",
        // "London, United Kingdom",
        // "Manchester, United Kingdom",
        // "London, United Kingdom",
        // "Luxembourg, Luxembourg",
        // "Beijing, Beijing, People's Republic of China"
      ],
    }
  ],
  "customers": {
    "id": number
    "name": string
    "entity_type": {
      "id": number
      "name": string // "Corporation/Bank"
    }
    "ownership_types": [
      {
        "id": number
        "name": string // "Privately Held"
      }
    ]
    "country": {
      "id": number
      "name": string // "United States of America",
      "iso3": string // "USA",
      "link": string // "/api/v1/countries/233"
    }
    "city_names": string[] //     ["Tracy, United States of America"]
    "link": string // "/api/v1/entities/9661",
    "type": string // "Entity"
  }[]
  "type": "Transaction"
}





export interface TranFilterSavedItemReq extends UrlParams {
  'search[name]': string,
  'search[notify]'?: '1',

  "search[filters][transactions][entity_ids][]"?: number[],
  "search[filters][transactions][subsidiaries]"?: '1',
  "search[filters][transactions][owners]"?: '1',
  "search[filters][transactions][search_group]"?: SearchGroupOptionsT,
  "search[filters][transactions][industry_ids][]"?: number[],
  "search[filters][transactions][transaction_type_ids][]"?: number[],
  "search[filters][transactions][project_value_gte]"?: number,
  "search[filters][transactions][project_value_lte]"?: number,
  'search[filters][transactions][city_ids][]'?: number[],
  "search[filters][transactions][country_ids][]"?: number[],
  "search[filters][transactions][region_ids][]"?: number[],
  "search[filters][transactions][continent_ids][]"?: number[],
  "search[filters][transactions][keywords]"?: string,
  "search[filters][transactions][country_risk_ids][]"?: number[],
  "search[filters][transactions][entity_risk_ids][]"?: number[],
  "search[filters][transactions][person_risk_ids][]"?: number[],
  "search[filters][transactions][transaction_tag_ids][]"?: number[],
  "search[filters][transactions][active]"?: 'starting',
  "search[filters][transactions][pending]"?: 'only' | 'exclude',
  "search[filters][transactions][cancelled]"?: 'only' | 'exclude',
  "search[filters][transactions][start_date]"?: string, // '09/01/2021'
  "search[filters][transactions][end_date]"?: string, // '09/16/2021'
  "search[filters][transactions][updated_days]"?: number,
  'search[filters][transactions][current_for_year]'?: number,
  'search[filters][group][group]': ClusterByOptionsT,
  'search[filters][group][scale]': ScaleOptionsT,
  'search[filters][transactions][order]': SortByOptionsT,
  'search[filters][transactions][sort_mode]': 'desc',
}




export interface TranFilterSavedItemResTransaction {
  entity_ids?: number[] | string[],
  subsidiaries?: '1' | '0',
  owners?: '1' | '0',
  search_group?: SearchGroupOptionsT,
  industry_ids?: number[] | string[],
  transaction_type_ids?: number[] | string[],
  project_value_gte?: number | string,
  project_value_lte?: number | string,
  city_ids?: number[] | string[],
  country_ids?: number[] | string[],
  region_ids?: number[] | string[],
  continent_ids?: number[] | string[],
  keywords?: string,
  country_risk_ids?: number[] | string[],
  entity_risk_ids?: number[] | string[],
  person_risk_ids?: number[] | string[],
  transaction_tag_ids?: number[] | string[],
  active?: 'starting',
  pending?: 'only' | 'exclude',
  cancelled?: 'only' | 'exclude',
  start_date?: string, // '09/01/2021'
  end_date?: string, // '09/16/2021'
  updated_days?: number | string,
  current_for_year?: number | string,
  order: SortByOptionsT,
  sort_mode: 'desc',
}
export interface TranFilterSavedItemResGroup {
  group: ClusterByOptionsT,
  scale: ScaleOptionsT,
}
export interface TranFilterSavedItemRes {

  "transactions": TranFilterSavedItemResTransaction,
  "group": TranFilterSavedItemResGroup,
}




export interface TranIdsFilterSavedItemReq extends UrlParams {
  'search[name]': string,
  'search[notify]'?: '1'|'0',

  "search[filters][transactions][ids][]": number[],
}




export interface TranIdsFilterSavedItemRes {
  "transactions": {
    ids: string[],
  }
}



export  interface TranRelatedFilterQueryReq extends UrlParams {
  "entity_ids[]"?: number[],
  "subsidiaries"?: '1' | '0',
  "owners"?: '1' | '0',
  "search_group"?: SearchGroupOptionsT,
  "industry_ids[]"?: number[],
  "transaction_type_ids[]"?: number[],
  "project_value_gte"?: number,
  "project_value_lte"?: number,
  'city_ids[]'?: number[],
  "country_ids[]"?: number[],
  "region_ids[]"?: number[],
  "continent_ids[]"?: number[],
  "keywords"?: string,
  "country_risk_ids[]"?: number[],
  "entity_risk_ids[]"?: number[],
  "person_risk_ids[]"?: number[],
  "transaction_tag_ids[]"?: number[],
  "active"?: 'starting',
  "pending"?: 'only' | 'exclude',
  "cancelled"?: 'only' | 'exclude',
  "start_date"?: string, // '09/01/2021'
  "end_date"?: string, // '09/16/2021'
  "updated_days"?: number,
  page?: number,
  per_page?: number,
  'new_for_year'?: number,
  'current_for_year'?: number,
  'year'?: number,
  'group[group]': ClusterByOptionsT,
  'group[scale]': ScaleOptionsT,
}



export interface TranFilterQueryReq extends UrlParams {
  "entity_ids[]"?: number[],
  "subsidiaries"?: '1' | '0',
  "owners"?: '1' | '0',
  "search_group[]"?: ('russia' | 'china')[],
  "industry_ids[]"?: number[],
  "transaction_type_ids[]"?: number[],
  "project_value_gte"?: number,
  "project_value_lte"?: number,
  'city_ids[]'?: number[],
  "country_ids[]"?: number[],
  "region_ids[]"?: number[],
  "continent_ids[]"?: number[],
  "keywords"?: string,
  "country_risk_ids[]"?: number[],
  "entity_risk_ids[]"?: number[],
  "person_risk_ids[]"?: number[],
  "transaction_tag_ids[]"?: number[],
  "active"?: 'starting',
  "pending"?: 'only' | 'exclude',
  "cancelled"?: 'only' | 'exclude',
  "start_date"?: string, // '09/01/2021'
  "end_date"?: string, // '09/16/2021'
  "updated_days"?: number,
  page?: number,
  per_page?: number,
  'new_for_year'?: number,
  'current_for_year'?: number,
  'search_group'?: SearchGroupOptionsT,
  'group[group]': ClusterByOptionsT,
  'group[scale]': ScaleOptionsT,

  order: SortByOptionsT
  sort_mode: 'desc'
}




export enum TranFilterCategory {
  Company,
  Transaction,
  Location,
  Keywords,
  Risk,
  Date
}



export const ClusterByOptions = ['city', 'country', 'region', 'continent'] as const;
type ClusterByOptionsAryT = typeof ClusterByOptions
export type ClusterByOptionsT = ClusterByOptionsAryT[number]

export const ScaleOptions = ['count', 'value', 'amortized', 'gdp'] as const;
type ScaleOptionsAryT = typeof ScaleOptions
export type ScaleOptionsT = ScaleOptionsAryT[number]

export const SortByOptions = ['date' , 'start_date' , 'end_date' , 'project_value'] as const;
type SortByOptionsAryT = typeof SortByOptions
export type SortByOptionsT = SortByOptionsAryT[number]


// used internally when editing a filter
export interface TransactionFilterForm extends UrlParams {
  companyIds: string[],
  includeSubsidiaries?: true,
  includeOwners?: true,
  industryIds: string[],

  transactionTypeIds: string[],
  minTranValue: string,
  maxTranValue: string,

  cityIds: string[],
  countryIds: string[],
  regionIds: string[],
  continentIds: string[],

  keywords: string,

  countryRiskDesignationIds: string[],
  entityRiskDesignationIds: string[],
  personRiskDesignationIds: string[],
  taggedTransactionsIds: string[],

  pending: 'only' | 'exclude' | '',
  cancelled: 'only' | 'exclude' | '',
  startDate: string,
  endDate: string,
  dateRangeActive: 'starting' | '',
  modifiedWithin: UpdatedDaysType,

  year: string,
  clusterBy: ClusterByOptionsT,
  scale: ScaleOptionsT,

  searchGroup: SearchGroupOptionsT,

  page?: string,
  per_page?: string,

  filterId?: number,
  filterName: string,

  order: SortByOptionsT
  sortMode: 'desc'
}


export interface StickyTransactionFilterForm extends TransactionFilterForm {
  view: 'map' | 'list'
}
