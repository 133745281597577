import { MouseEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { uniqBy, sortBy } from 'lodash'

import { useAppContext } from 'context'
import {EntityRiskTrackerApi} from 'apis'
import {Entity, EntityRiskEvent, ErrorDetail} from 'models'
import { LoadingComponent, Sidebar, SidebarContent } from 'components'
import {handleAsyncLoader} from 'lib';

interface SidebarParams {
  onClose: (event: MouseEvent) => void
  id: number
}

export function EntityRiskTrackerSidebar({
  onClose,
  id
}: SidebarParams) {

  const { session } = useAppContext()

  const [entity, setEntity] = useState<Entity>()
  const [events, setEvents] = useState<EntityRiskEvent[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()

  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setEntity(await new EntityRiskTrackerApi(session.token).entity(id))
      const riskEvents = await new EntityRiskTrackerApi(session.token).entity_risk_events(id, 1, 6)
      setEvents(sortBy(uniqBy(riskEvents.results, 'event'), [r => r['event']]))
    }
  ), [id])

  const view = events ? (
    <>
      <div className="sidebar-section-title pb-4">Recent Risk Events:</div>
      <div className="sidebar-section-content">
        {events.length ? (
          <ul className="list-numeric px-0">
            {events.map((event) =>
              <li className="list-numeric-item">{event.event}</li>
            )}
          </ul>
        ) : (
          <strong>No recent risk events</strong>
        )}
      </div>
    </>
  ) : (
    <>Error loading Entity Risk Report</>
   )

  const nav = loading || !!error ? undefined : (
    <Link className="btn btn-gray text-primary btn-block"
      to={`/entityrisktracker/${entity?.id}`}
    >
      View Full Entity Risk Report
    </Link>
  )

  return (
    <Sidebar onClose={onClose}
      header="Entity Risk Preview"
      nav={nav}
    >
      <LoadingComponent loading={loading} error={error}>
        <SidebarContent>
          <div className="content-title">{entity?.name}</div>
        </SidebarContent>
        <SidebarContent>
          {view}
        </SidebarContent>
      </LoadingComponent>
    </Sidebar>
  )
}
