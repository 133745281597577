import { useEffect, useState } from 'react'
import { useAppContext } from 'context'
import { AlertApi } from 'apis'
import { LoadingComponent } from 'components'
import {AlertFilterForm, AlertsSearchItem, ErrorDetail, Pager} from 'models'

import { AlertTable } from './components/AlertTable'
import {alertToQueryReq} from 'models/converters';
import {handleAsyncLoader, SessionStorage} from 'lib';


export const AlertList = ({
  className,
  filter,
  setPager,
  setPreviewId,
  previewId
}: {
  className?: string
  filter: AlertFilterForm
  setPager: (pager: Pager) => void
  setPreviewId: (id: number) => void
  previewId?: number
}) => {

  const { session } = useAppContext()

  const [list, setList] = useState<AlertsSearchItem[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorDetail>()


  useEffect(() => {
    SessionStorage.setCurrentAlertsFilterView('list')
  }, [])

  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      const {
        alerts,
        pager
      } = await new AlertApi(session.token).search(alertToQueryReq(filter))
      setList(alerts)
      setPager(pager)
    }), [filter, session.token])

  return (
    <LoadingComponent loading={loading} error={error}>
      <AlertTable 
        alerts={list}
        selectedId={previewId}
        setSelectedId={setPreviewId}
      />
    </LoadingComponent>
  )

}
