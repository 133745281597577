import {useEffect, useMemo, useState} from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import {useAppContext} from 'context'

import {
  AlertsSearchItem,
  CountryReportsSearchItem,
  Entity, ErrorDetail,
  GlobalSearchResultTotals,
  Pager,
  TransactionsSearchItem
} from 'models'

import {
  MainContainer,
  PageContainer,
  PagerControl,
  SearchBar,
  Tab,
  TabbedNavContainer
} from 'components'

import {
  AlertApi,
  CountryReportApi,
  EntityRiskTrackerApi,
  SearchApi,
  GlobalSearchApiParams,
  TransactionsApi, buildQuery
} from 'apis'

import {SearchTransactionResults} from 'apps/search/components/SearchTransactionResults';
import {SearchEntityResults} from 'apps/search/components/SearchEntityResults';
import {SearchAlertResults} from 'apps/search/components/SearchAlertResults';
import {SearchCountryResults} from 'apps/search/components/SearchCountryResults';
import {EntityRiskTrackerSidebar} from 'apps/entityrisktracker/EntityRiskTrackerSidebar';
import {SearchAlertSidebar} from 'apps/search/components/SearchAlertSidebar';
import {SearchCountrySidebar} from 'apps/search/components/SearchCountrySidebar';
import {TransactionSidebar} from 'apps/transactions/TransactionSidebar';
import {handleAsyncLoader} from 'lib';


function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}


enum View {
  Transactions,
  EntityRiskTracker,
  Alerts,
  CountryReports
}



function buildPageUrl(view: View, params: GlobalSearchApiParams): string {
  const qs = buildQuery(params)
  switch (view) {
    case View.Transactions:
      return `/search/transactions${qs}`;
    case View.EntityRiskTracker:
      return `/search/ert${qs}`;
    case View.Alerts:
      return `/search/alerts${qs}`;
    case View.CountryReports:
      return `/search/countryreports${qs}`;
  }
}


function toView(pathname: string): View {
  switch (pathname) {
    case '/search/transactions':
      return View.Transactions;
    case '/search/ert':
      return View.EntityRiskTracker;
    case '/search/alerts':
      return View.Alerts;
    case '/search/countryreports':
      return View.CountryReports;

  }

  return View.Transactions;
}


function buildTabs(totals: GlobalSearchResultTotals, params: GlobalSearchApiParams, view: View): Tab[] {

  return [
    {
      active: view === View.Transactions,
      to: buildPageUrl(View.Transactions, {...params, page: 1}),
      children: <>
        <div>Transactions</div>
        <div className="small">{totals.transactions} Results</div>
      </>
    },
    {
      active: view === View.EntityRiskTracker,
      to: buildPageUrl(View.EntityRiskTracker, {...params, page: 1}),
      children: <>
        <div>Entity Risk Tracker</div>
        <div className="small">{totals.entities} Results</div>
      </>
    },
    {
      active: view === View.Alerts,
      to: buildPageUrl(View.Alerts, {...params, page: 1}),
      children: <>
        <div>Alerts</div>
        <div className="small">{totals.alerts} Results</div>
      </>
    },
    {
      active: view === View.CountryReports,
      to: buildPageUrl(View.CountryReports, {...params, page: 1}),
      children: <>
        <div>Country Reports</div>
        <div className="small">{totals.country_reports} Results</div>
      </>
    },
  ]
}

export function Search(
  {
    searchBar
  } : {
    searchBar: JSX.Element
  }
): JSX.Element {


  const query = useQuery();
  const location = useLocation();
  let history = useHistory();

  const view = toView(location.pathname);
  const text = query.get('text') ?? '';
  const page = query.get('page') ?? '';
  const per_page = query.get('per_page') ?? '';

  const { session } = useAppContext()


  const [transactions, setTransactions] = useState<TransactionsSearchItem[]>([]);
  const [alerts, setAlerts] = useState<AlertsSearchItem[]>([]);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [countryReports, setCountryReports] = useState<CountryReportsSearchItem[]>([]);

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ErrorDetail>()
  const [totals, setTotals] = useState<GlobalSearchResultTotals>({
    alerts: 0,
    transactions: 0,
    entities: 0,
    country_reports: 0,
  })
  const [pager, setPager] = useState<Pager>({
    page: 1,
    per_page: 50,
    total_records: 0,
    total_pages: 1
  });


  const [previewId, setPreviewId] = useState<number | undefined>(undefined);
  const [previewTitle, setPreviewTitle] = useState<string | undefined>(undefined);


  const qsParams = useMemo<GlobalSearchApiParams>(() => {

    let per_pageParam = parseInt(per_page);
    if (isNaN(per_pageParam))
      per_pageParam = 50;

    let pageParam = parseInt(page);
    if (isNaN(pageParam))
      pageParam = 1;

    const qs = ({
      text,
      page: pageParam,
      per_page: per_pageParam,
    });

    return qs;
  }, [text, page, per_page])


  const tabs = useMemo<Tab[]>(() =>
    buildTabs(totals, qsParams, view),
    [totals, qsParams, view]);


  useEffect(() => {
    setTotals({
      alerts: 0,
      entities: 0,
      country_reports: 0,
      transactions: 0
    })
  }, [text]);


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setTransactions([])
      setEntities([])
      setAlerts([])
      setCountryReports([])

      setPreviewId(undefined)
      setPreviewTitle(undefined)

      if (qsParams.page === 1) {
        const resp = await new SearchApi(session.token).search(qsParams);
        setTransactions(resp.transactions)
        setEntities(resp.entities)
        setAlerts(resp.alerts)
        setCountryReports(resp.country_reports)
        setTotals(resp.totals);

        let totalRecs = 0;
        switch (view) {
          case View.Transactions:
            totalRecs = resp.totals.transactions;
            break;
          case View.EntityRiskTracker:
            totalRecs = resp.totals.entities;
            break;
          case View.Alerts:
            totalRecs = resp.totals.alerts;
            break;
          case View.CountryReports:
            totalRecs = resp.totals.country_reports;
            break;
        }

        setPager({
          page: qsParams.page,
          total_records: totalRecs,
          total_pages: Math.ceil(totalRecs / qsParams.per_page),
          per_page: qsParams.per_page
        });
      } else {
        switch (view) {
          case View.Transactions: {
            const resp = await new TransactionsApi(session.token).search(qsParams);
            setTransactions(resp.transactions);
            setPager(resp.pager);
          }
            break;
          case View.EntityRiskTracker: {
            const resp = await new EntityRiskTrackerApi(session.token).search(qsParams);
            setEntities(resp.results);
            setPager(resp.paginate);
          }
            break;
          case View.Alerts: {
            const resp = await new AlertApi(session.token).search(qsParams);
            setAlerts(resp.alerts);
            setPager(resp.pager);
          }
            break;
          case View.CountryReports: {
            const resp = await new CountryReportApi(session.token).search(qsParams);
            setCountryReports(resp.country_reports);
            setPager(resp.pager);
          }
            break;
          default:
            throw new Error('unexpected view: ' + view);
        }

      }
    }),
    [qsParams, view, session.token]);


  const previousRecCount = () => (qsParams.page - 1) * qsParams.per_page;
  const recStart = () => previousRecCount() + 1;
  const recEnd = () => previousRecCount() + currPageCount();

  function currPageCount(): number {
    switch (view) {
      case View.Transactions:
        return transactions.length;
      case View.EntityRiskTracker:
        return entities.length;
      case View.Alerts:
        return alerts.length;
      case View.CountryReports:
        return countryReports.length;
    }
    return 0;
  }




  function onSelect(id: number, title: string): void {
    setPreviewId(id);
    setPreviewTitle(title);
  }


  function perPageLink(newSize: number): string {
    return buildPageUrl(view, {...qsParams, per_page: newSize, page: 1});
  }

  function pageLink(newPage: number): string {
    return buildPageUrl(view, {...qsParams, page: newPage});
  }


  return (
    <PageContainer navItem="">
      <MainContainer
        rightSidebarActive={!!previewId}
        searchBar={searchBar}
        pager={
          <PagerControl
            total={+pager.total_records}
            page={+pager.page}
            pageSize={+pager.per_page}
            setPageSize={ pageSize => history.push(perPageLink(pageSize)) }
            setPage={ page => history.push(pageLink(page)) }
          />
        }
      >

        <TabbedNavContainer
          tabs={tabs}
          loading={loading}
          error={error}
          className="global-search-results"
        >
          <SearchTransactionResults
            results={transactions}
            onSelect = {onSelect}
          />
          <SearchEntityResults
            results={entities}
            onSelect = {onSelect}
          />
          <SearchAlertResults
            results={alerts}
            onSelect = {onSelect}
          />
          <SearchCountryResults
            results={countryReports}
            onSelect = {onSelect}
          />
        </TabbedNavContainer>

      </MainContainer>


      {!!previewId && view === View.Transactions &&
      <TransactionSidebar
        id={previewId}
        onClose={() => {setPreviewId(undefined); setPreviewTitle(undefined)}}
      />}

      {!!previewId && view === View.EntityRiskTracker &&
      <EntityRiskTrackerSidebar
        id={previewId}
        onClose={() => {setPreviewId(undefined)}}
      />}

      {!!previewId && view === View.Alerts &&
      <SearchAlertSidebar
        id={previewId}
        onClose={() => {setPreviewId(undefined); setPreviewTitle(undefined)}}
      />}

      {!!previewId && view === View.CountryReports &&
      <SearchCountrySidebar
        id={previewId}
        onClose={() => {setPreviewId(undefined); setPreviewTitle(undefined)}}
        title={previewTitle ?? ''}
      />}

    </PageContainer>
  );
}
