import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {Modal} from 'components';
import {useAppContext} from 'context';
import {UserApi} from 'apis';
import {ChangePasswordModalContents} from 'apps/profile/components/ChangePasswordModalContents';

export type ChangePasswordModalRef = {
  show: () => void
} | null;

export const ChangePasswordModal = forwardRef<ChangePasswordModalRef,
  {
    afterChanged: () => void
  }>((
  {
    afterChanged,
  }, ref) => {

  const { session } = useAppContext()

  const [open, setOpen] = useState(false)


  useImperativeHandle(ref, () => ({
    show() {
      setOpen(true)
    },
  }))


  function handleClose() {
    setOpen(false)
  }

  return (
    <>
    {open &&
    <Modal close={handleClose} showCloseButton>
      <ChangePasswordModalContents
        onClose={() => setOpen(false)}
        token={session.token}
      />
    </Modal>
    }
    </>
  )
})
