import { Api, UrlParams } from './Api'
import {
  AlertsSearchItem,
  CountryReportsSearchItem,
  Entity,
  GlobalSearchResultTotals,
  RelatedEntityResult,
  TranFilterQueryReq,
  TransactionsSearchItem
} from 'models'
import {TranRelatedFilterQueryReq} from 'models/Transaction';

const URLS = {
  search: '/search',
  entities: '/entities',
}

export interface GlobalSearchResult {
  totals: GlobalSearchResultTotals,
  transactions: TransactionsSearchItem[],
  alerts: AlertsSearchItem[],
  entities: Entity[],
  country_reports: CountryReportsSearchItem[],
}

export interface GlobalSearchApiParams extends UrlParams {
  text: string,
  page: number,
  per_page: number,
}

export class SearchApi extends Api {

  async search(params: GlobalSearchApiParams): Promise<GlobalSearchResult> {
      const resp = (await this.get<GlobalSearchResult>(URLS.search, params)).data;

      // await (new Promise(r => setTimeout(r, 1000)));

      return resp;
  }


  async entities(params: TranRelatedFilterQueryReq): Promise<RelatedEntityResult> {
    const resp = (await this.get<RelatedEntityResult>(URLS.entities, params)).data;

    // await (new Promise(r => setTimeout(r, 1000)));

    return resp;
  }


}
