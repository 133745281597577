import { useEffect, useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useAppContext } from 'context'
import { intelTrakApiUrl } from 'Config'
import {
  Card, LoadingComponent,
  setupMapboxMap,
  setupMapboxPopup
} from 'components'
import { CaretRightSmallIcon } from 'components/icons/CaretRightSmallIcon'
import { TranPaths } from 'apps/transactions/Transactions';
import {Point} from 'geojson';
import {buildQueryParams} from 'apis';
import {qsToTranForm} from 'models/converters';
import {ErrorDetail, TransactionFilterForm} from 'models';
import {UpdatedDaysType} from 'data';
import {convertError} from 'lib/HandleAsyncLoader';


const TilesUrl = `${intelTrakApiUrl}/map/{z}/{x}/{y}.pbf?type=transactions&updated_days=31&pending=exclude&group[group]=country`

const MapContainerStyle = {
  width: '100%',
  height: '350px'
}
const FillColor = '#f06c00'
const FillOpacity = 0.4
const SelectedOpacity = 0.8

const Source = 'transactions'
const PolygonLayer: mapboxgl.AnyLayer = {
  'id': 'transactions',
  'type': 'circle',
  'source': 'transactions',
  'source-layer': 'transactions',
  'paint': {
    'circle-radius': [
      'case', // ['<=', ['get', 'count'], 1], 2,    // r = sqrt(8 * area), ie area = r^2 / 8
      // ['<=', ['get', 'count'], 2], 4,
      // ['<=', ['get', 'count'], 4], 6,  // hard to click on small things
      ['<=', ['get', 'count'], 8], 8,
      ['<=', ['get', 'count'], 12], 10,
      ['<=', ['get', 'count'], 25], 14,
      ['<=', ['get', 'count'], 50], 20,
      ['<=', ['get', 'count'], 112], 30,
      ['<=', ['get', 'count'], 200], 40,
      ['<=', ['get', 'count'], 312], 50,
      ['<=', ['get', 'count'], 450], 60,
      ['<=', ['get', 'count'], 612], 70,
      ['<=', ['get', 'count'], 800], 80,
      ['<=', ['get', 'count'], 1012], 90,
      100,

      // ['<=', ['get', 'count'], 5], 5,
      // ['<=', ['get', 'count'], 15], 10,
      // ['<=', ['get', 'count'], 50], 15,
      // ['<=', ['get', 'count'], 10], 20,
      // ['<=', ['get', 'count'], 250], 25,
      // ['<=', ['get', 'count'], 500], 30,
      // ['<=', ['get', 'count'], 800], 40,
      // ['<=', ['get', 'count'], 1200], 50,
      // 60,
    ],
    'circle-color': FillColor,
    'circle-opacity': FillOpacity,
    'circle-stroke-color': FillColor,
    'circle-stroke-width': 1,
   }
}

export const TransactionsWidget = ({
  className
}: {
  className: string
}): JSX.Element => {

  let history = useHistory()
  const { session } = useAppContext()

  const mapContainer = useRef(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const popup = useRef<mapboxgl.Popup | null>(null)
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(30);
  const [zoom, setZoom] = useState(0.3);

  const [error, setError] = useState<ErrorDetail>()

  const getSource: () => mapboxgl.AnySourceData = () => {
    return {
      'type': 'vector',
      'tiles': [TilesUrl]
    }
  }

  const loadSource = () => {
    if (map.current !== null) {
      if (map.current.getSource(Source)) {
        map.current.removeLayer(PolygonLayer.id)
        map.current.removeSource(Source)
      }
      map.current.addSource(Source, getSource())
      map.current.addLayer(PolygonLayer)
      map.current.resize()  // Call resize to resize when parent resizes
    }
  }

  function toTranSearchResults(modifiedWithin: UpdatedDaysType) {
    return `${TranPaths.list}?${buildQueryParams<TransactionFilterForm>({
      ...qsToTranForm(new URLSearchParams()),
      modifiedWithin
    }, true)}`
  }

  useEffect(() => {
    const setupMap = () => {
      map.current = setupMapboxMap({
        session,
        container: mapContainer.current,
        center: [lng, lat],
        zoom: zoom,
        onLoad: loadSource,
        onMouseEnter: {
          layer: PolygonLayer.id,
          fn: () => {
            if (map.current) {
              map.current.getCanvas().style.cursor = 'pointer'
            }
          }
        },
        onMouseLeave: {
          layer: PolygonLayer.id,
          fn: () => {
            if (map.current) {
              map.current.getCanvas().style.cursor = ''
            }
          }
        },

        onMouseFeatureEnter: {
          layer: PolygonLayer.id,
          fn: (f) => {
            if (map.current) {
              if (popup.current) {
                if (f.properties) {
                  const g: Point = f.geometry as any
                  const coord = g.coordinates.slice(0, 2) as [number, number]
                  const el = popup.current
                    .setLngLat(coord)
                    .setHTML(`<span>${f.properties.name}</span>`)
                    .addTo(map.current)
                    .getElement()

                  const countryId = f.properties.id

                  el.addEventListener('click', () => history.push(`${TranPaths.map}?countryIds=${countryId}&clusterBy=country&modifiedWithin=31`))
                }
              }
            }
          }
        },
        onMouseFeatureLeave: {
          layer: PolygonLayer.id,
          fn: () => {
            if (map.current) {
              if (popup.current) {
                popup.current.remove()
              }
            }
          }
        },
        onClick: {
          layer: Source,
          fn: (e) => {
            if (e.features && e.features[0].properties)
              history.push(`${TranPaths.map}?countryIds=${e.features[0].properties.id}&clusterBy=country&modifiedWithin=31`)
          }
        },
        onIdle: () => map.current !== null && map.current.resize,
        onError: e => setError(convertError(e)),
      })
      popup.current = setupMapboxPopup()
    }
    if (!map.current && mapContainer.current) setupMap() // initialize map only once
  }, [mapContainer.current])

  return (
    <Card className={className} 
      title={
        <div className="d-flex flex-column flex-md-row">
          <div className="mb-2 mb-md-0">Recent Transactions</div>
          <small className="pl-md-2">
            <Link className="dashboard-link" to={toTranSearchResults('31')}>updated in the last month <CaretRightSmallIcon/></Link>
          </small>
          <small className="pl-md-2 ml-md-auto">
            <Link className="dashboard-link" to={toTranSearchResults('')}>all transactions <CaretRightSmallIcon/></Link>
          </small>
        </div>
      }
    >

      <LoadingComponent loading={false} error={error}>
        <div ref={mapContainer}
          className="flex-fill clickable-popups"
          style={MapContainerStyle}
        />
      </LoadingComponent>

    </Card>
  )
}
