import {CountryReport, TransactionFilterForm} from 'models';
import {orderBy} from 'lib';
import {Link} from 'react-router-dom';
import {UpdatedDaysType} from 'data';
import {TranPaths} from 'apps/transactions/Transactions';
import {buildQueryParams} from 'apis';
import {qsToTranForm} from 'models/converters';


function toTranSearchResults(entityId: number) {
  return `${TranPaths.list}?${buildQueryParams<TransactionFilterForm>({
    ...qsToTranForm(new URLSearchParams()),
    companyIds: [`${entityId}`]
  }, true)}`
}


export const CountryReportEntityTracker = ({
 countryReport
}: {
 countryReport: CountryReport
}): JSX.Element => (
  <>
    <div className="tab-content">
      <div className="tab-pane fade show active" 
        role="tabpanel" 
        aria-labelledby="home-tab"
      >
        <div className="content-sidebar-subtitle px-4 pt-2 pb-2">
          Key Entities
        </div>
        <ol className="list-numeric">
          {countryReport.entities.sort(orderBy(e => e.entity.name)).map(e =>
            <li key={e.entity.id} className="list-numeric-item">
              <Link to={toTranSearchResults(e.entity.id)}>{e.entity.name}</Link>
            </li>
          )}
        </ol>
      </div>
    </div>
  </>
)
