import {Pager, SearchGroupOptionsT} from 'models'
import {UrlParams} from 'apis';

export interface Entity {
  id: number
  name: string
  website: string //"https://www.lenovo.com/ch/de/",
  sector: string
  ownership_types: {
    "id": number
    "name": string
  }[]
  country: string
  self_entity_risk_ids: number[]
  owner_entity_risk_ids: number[]
  subsidiary_entity_risk_ids: number[]
  distant_entity_risk_ids: number[]
  all_entity_risk_ids: number[]
  score: number
  score_inherited: boolean
  public: boolean
  address: string
  owners: {
    id: number
    name: string
  }[]
  "ultimate_owners": {
    "id": number,
    "name": string
  }[]
  "country_ultimate_owners": {
    "id": number,
    "name": string
  }[]
  highlight?: null | {
    field_name: string
    highlight: string
  }
}

export interface Entities {
  results: Entity[],
  paginate: Pager
}

export interface ErtSavedItemReq extends UrlParams {
  'search[name]': string,
  'search[notify]'?: '1',

  "search[filters][entities][risk_ids][]"?: number[],
  "search[filters][entities][entity_ids][]"?: number[],
  "search[filters][entities][public]"?: '1' | '0',
  "search[filters][entities][include_owners]"?: '1' | '0',
  "search[filters][entities][include_subsidiaries]"?: '1' | '0',
  "search[filters][entities][include_distant_relatives]"?: '1' | '0',
  "search[filters][entities][search_group]"?: SearchGroupOptionsT,

}




export interface ErtFilterSavedItemRes {
  "entities": {
    risk_ids: string[],
    entity_ids: string[],
    public?: '1' | '0',
    include_owners?: '1' | '0',
    include_subsidiaries?: '1' | '0',
    include_distant_relatives?: '1' | '0',
    search_group?: SearchGroupOptionsT,
  },
}

export interface ErtIdsFilterSavedItemReq extends UrlParams {
  'search[name]': string,
  'search[notify]'?: '1'|'0',

  "search[filters][entities][ids][]": number[],
}

export interface ErtIdsFilterSavedItemRes {
  "entities": {
    ids: [string]
  },
}


export interface ErtFilterQueryReq extends UrlParams {
  'risk_ids[]'?: number[],
  entity_ids?: number[],
  public?: '1',
  include_owners?: '0',
  include_subsidiaries?: '0',
  include_distant_relatives?: '0',
  "search_group"?: SearchGroupOptionsT,
  text?: string,
  page?: number,
  per_page?: number
  order?: string
  sort_mode?: string
}



export enum ErtFilterCategory {
  Location,
  Keywords,
  Date
}


// used internally when storing the state in the browser's querystring
export interface ErtFilterFormQs extends UrlParams {
  risk_ids: string[]
  entity_ids: string[]
  public?: true
  exclude_owners?: true
  exclude_subsidiaries?: true
  exclude_distant_relatives?: true
  search_group?: SearchGroupOptionsT,
  page?: string
  per_page?: string
  text?: string

  filterId?: number
  filterName: string
}

export interface StickyErtFilterFormQs extends ErtFilterFormQs {
  // nothing extra
}


export interface EntityRiskResult {
  id: number,
  name: string,
  children?: EntityRiskResult[],
}

export interface EntityRiskVm {
  id: number,
  name: string,
  children?: EntityRiskVm[],
  selected: boolean,
}


// used internally temporarily when editing the filters
export interface ErtFilterFormVm {
  risks: EntityRiskVm[],
  entity_ids: string[],
  public?: true,
  exclude_owners?: true,
  exclude_subsidiaries?: true,
  exclude_distant_relatives?: true,
  search_group: SearchGroupOptionsT,

  filterId?: number
  filterName: string
}
