import { useEffect, useRef, useState } from 'react'

import { CountryReportSearch } from 'apis'
import {LoadingComponent, setupMapboxMap, setupMapboxPopup} from 'components'
import { intelTrakApiUrl } from 'Config'
import { useAppContext } from 'context'
import mapboxgl from 'mapbox-gl'
import {ErrorDetail} from 'models';
import {convertError} from 'lib/HandleAsyncLoader';

const TilesUrl = `${intelTrakApiUrl}/map/{z}/{x}/{y}.pbf?type=country_reports`

const MapContainerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '300px',
}
const FillColor = '#f06c00'
const FillOpacity = 0.2
const SelectedOpacity = 0.9

const Source = 'country_reports'
const PolygonLayer: mapboxgl.AnyLayer = {
  'id': 'countries',
  'type': 'fill',
  'source': 'country_reports',
  'source-layer': 'countries',
  'paint': {
    'fill-color': FillColor,
    'fill-opacity': FillOpacity
   }
}
const BorderLayer: mapboxgl.AnyLayer = {
  'id': 'countries-borders',
  'type': 'line',
  'source': 'country_reports',
  'source-layer': 'countries',
  'layout': {
    'line-join': 'round',
  },
  'paint': {
    'line-color': FillColor,
    'line-width': 2
   }
}

export function CountryReportMap({
  search, 
  setCountryReportId,
  previewId
}: {
  search: CountryReportSearch
  setCountryReportId: (id: number) => void
  previewId?: number
}) {

  const { session } = useAppContext()

  const mapContainer = useRef(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const popup = useRef<mapboxgl.Popup | null>(null)
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(30);
  const [zoom, setZoom] = useState(1.6);

  const [error, setError] = useState<ErrorDetail>()

  const getSourceUrl = () => {
    let url = TilesUrl
    if (search?.text && search?.text?.length > 0) {
      url += `&text=${search.text}`
    }
    if (search?.country_id) {
      url += `&country_id=${search.country_id}`
    }
    return url
  }

  const getSource: () => mapboxgl.AnySourceData = () => {
    return {
      'type': 'vector',
      'tiles': [getSourceUrl()]
    }
  }

  const highlightPreviewId = () => {
    map.current!.setPaintProperty('countries', 'fill-opacity', [
      'match',
      ['get', 'id'],
      previewId || -1,
      SelectedOpacity,
      FillOpacity
    ])
  }

  const loadSource = () => {
    if (map.current!.getSource(Source)) {
      map.current!.removeLayer(PolygonLayer.id)
      map.current!.removeLayer(BorderLayer.id)
      map.current!.removeSource(Source)
    }
    map.current!.addSource(Source, getSource())
    map.current!.addLayer(PolygonLayer)
    map.current!.addLayer(BorderLayer)
    highlightPreviewId()  // Highlight if selected
    map.current!.resize()  // Call resize to resize when parent resizes
  }

  useEffect(() => {
    const setupMap = () => {
      map.current = setupMapboxMap({
        session,
        container: mapContainer.current,
        center: [lng, lat],
        zoom: zoom,
        onLoad: loadSource,
        onMouseEnter: {
          layer: PolygonLayer.id,
          fn: () => {
            if (map.current) {
              map.current.getCanvas().style.cursor = 'pointer'
            }
          }
        },
        onMouseLeave: {
          layer: PolygonLayer.id,
          fn: () => {
            if (map.current) {
              map.current.getCanvas().style.cursor = ''
            }
          }
        },


        onMouseFeatureEnter: {
          layer: PolygonLayer.id,
          fn: (f) => {
            if (map.current) {
              if (popup.current) {
                if (f.properties) {
                  const latLng: mapboxgl.LngLatLike = {
                    lat: f.properties.latitude,
                    lng: f.properties.longitude,
                  }
                  const el = popup.current
                    .setLngLat(latLng)
                    .setHTML(`<span>${f.properties.title}</span>`)
                    .addTo(map.current)
                    .getElement()

                  const countryId = f.properties.id

                  el.addEventListener('click', () => setCountryReportId(countryId))
                }
              }
            }
          }
        },
        onMouseFeatureLeave: {
          layer: PolygonLayer.id,
          fn: () => {
            if (map.current) {
              if (popup.current) {
                popup.current.remove()
              }
            }
          }
        },

        onClick: {
          layer: 'countries',
          fn: (e) => {
            if (e.features && e.features[0].properties)
              setCountryReportId(e.features[0].properties.id)
          }
        },
        onIdle: () => map.current!.resize,
        onError: e => setError(convertError(e)),
      })

      popup.current = setupMapboxPopup()
    }
    if (!map.current && mapContainer.current) setupMap() // initialize map only once
  }, [mapContainer.current])

  useEffect(() => {
    if (map.current && map.current.isStyleLoaded()) {
      highlightPreviewId()
    }
  }, [previewId])

  useEffect(() => {
    if (map.current && map.current.isStyleLoaded()) {
      loadSource()  
    }
  }, [search])


  useEffect(() => {
    map.current?.resize()
  }, [previewId])

  return (
    <LoadingComponent loading={false} error={error}>
      <div ref={mapContainer}
        className="map-container clickable-popups"
        style={MapContainerStyle}
      />
    </LoadingComponent>
  )

}
