export const HighlightText = ({
  delimiter,
  text
}: {
  delimiter?: string,
  text: string
}) => {

  const eDelimiter = (delimiter || '||').replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')

  const singleRegex = new RegExp(eDelimiter, 'g')
  const regex = new RegExp(`${eDelimiter}.+?${eDelimiter}`, 'g')

  const content = text.replace(regex, (match) => {
    return `<strong>${match.replace(singleRegex,'')}</strong>`
  })

  return (
    <span
      className="highlight-text"
      dangerouslySetInnerHTML={{__html: content}}
    />
  )
}
