import {CountryReportTableRow} from 'apps/countryreports/components/CountryReportTableRow';
import {CountryReportTableSectionVm, SaveNameModalVm} from 'apps/countryreports/components/CountryReportTable';

export function CountryReportTableSection(
  {
    section,
    setCountryReportId,
    previewId,
    setSelected,
    saveNameModalVm,
    setSaveNameModalVm,
  }: {
    section: CountryReportTableSectionVm
    setCountryReportId: (id: number) => void
    previewId?: number,
    setSelected: (id: number, selected: boolean) => void,
    saveNameModalVm?: SaveNameModalVm
    setSaveNameModalVm: (val: SaveNameModalVm | undefined) => void

  }) {
  return (
    <>
      <tr>
        <td className="col-title" colSpan={4}>{section.title}</td>
      </tr>
      {section.reports.map((countryReportVm) =>
        <CountryReportTableRow
          key={countryReportVm.id}
          countryReportVm={countryReportVm}
          setCountryReportId={setCountryReportId}
          previewId={previewId}
          setSelected={(s) => setSelected(countryReportVm.id, s)}
          saveNameModalVm={saveNameModalVm}
          setSaveNameModalVm={setSaveNameModalVm}
        />
      )}
    </>
  )
}
