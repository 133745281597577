import { MouseEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'context'
import { AlertApi } from 'apis'
import {Alert, ErrorDetail} from 'models'
import { LoadingComponent, Sidebar, SidebarContent, ExcerptText } from 'components'

import { AlertInfoBox } from './components/AlertInfoBox'
import {handleAsyncLoader} from 'lib';

export const AlertSidebar = ({
  id,
  onClose
}: {
  id: number,
  onClose: (event: MouseEvent) => void
}): JSX.Element => {

  const { session } = useAppContext()

  const [alert, setAlert] = useState<Alert>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setAlert(await new AlertApi(session.token).id(id))
    }), [id, session.token])

  const nav = loading || !!error ? undefined : (
    <Link className="btn btn-gray text-primary btn-block"
      to={`/alerts/${alert?.id}`}
    >
      View Alert
    </Link>
  )

  return (
    <Sidebar onClose={onClose}
      header="Alert Preview"
      nav={nav}
    >
      <LoadingComponent loading={loading} error={error}>
        {alert &&
          <>
            <SidebarContent>
              <div className="content-title">{alert.title}</div>
              {/*<div className="content-subtitle dot dot-left active">Alert</div>*/}
            </SidebarContent>
            <SidebarContent>
              <AlertInfoBox alert={alert} />
            </SidebarContent>
            <SidebarContent>
              <ExcerptText text={alert.comment} />
            </SidebarContent>
          </>
        }
      </LoadingComponent>
    </Sidebar>
  )

}
