import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import { Session } from 'models'
import {
  intelTrakApiUrl,
  mapboxToken,
  mapboxStyle
} from 'Config'
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const DefaultMapboxStyle = 'mapbox://styles/mapbox/streets-v11'

export const setupMapboxPopup = () => {
  return new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  })
}

function getHoveredFeature(
  e: mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined } & mapboxgl.EventData,
  layer: string,
  map: mapboxgl.Map,
): mapboxgl.MapboxGeoJSONFeature | null {
  if (map.getLayer(layer)) {
    const fs = map.queryRenderedFeatures(e.point, {layers: [layer]});
    if (fs.length > 0) {
      const f = fs[0];
      if (f.properties)
        return f;
    }
  }
  return null;
}

export const setupMapboxMap = (
  {
    session,
    container,
    style,
    center,
    zoom,
    onLoad,
    onMouseEnter,
    onMouseLeave,
    onMouseFeatureEnter,
    onMouseFeatureLeave,
    onClick,
    onIdle,
    onError,
  }: {
    session: Session,
    container?: HTMLElement | null,
    style?: string,
    center: [number, number],
    zoom: number,
    onLoad?: () => void,
    onMouseEnter?: ({
      layer: string,
      fn: () => void
    }),
    onMouseLeave?: ({
      layer: string,
      fn: () => void
    }),
    onMouseFeatureEnter?: ({
      layer: string,
      fn: (f: mapboxgl.MapboxGeoJSONFeature) => void
    }),
    onMouseFeatureLeave?: ({
      layer: string,
      fn: () => void
    }),
    onClick?: ({
      layer: string,
      fn: (e: (mapboxgl.MapMouseEvent & {features?: mapboxgl.MapboxGeoJSONFeature[] | undefined} & mapboxgl.EventData)) => void
    }),
    onIdle?: () => void
    onError?: (e: any) => void
  }): mapboxgl.Map | null => {

  try {

    if (!container)
      throw new Error("Mapbox container is undefined");

    mapboxgl.accessToken = mapboxToken

    const map = new mapboxgl.Map({
      container,
      style: style || mapboxStyle || DefaultMapboxStyle,
      center,
      zoom,
      renderWorldCopies: false,
      transformRequest: (url, resourceType) => {
        if (url.startsWith(intelTrakApiUrl)) {
          return {
            url,
            headers: {'Authorization': 'Bearer ' + session.token}
          }
        } else {
          return {url}
        }
      }
    })

    let hoveredFeatureId: string | number | undefined | null = null
    let layerEntered = false

    if (onMouseFeatureEnter && onMouseFeatureLeave) {
      if (onMouseFeatureLeave.layer !== onMouseFeatureEnter.layer)
        throw new Error(`onMouseFeatureLeave.layer !== onMouseFeatureEnter.layer: ${onMouseFeatureLeave.layer} ${onMouseFeatureEnter.layer}`)
    }

    map.addControl(new mapboxgl.NavigationControl())
    if (onLoad)
      map.on('load', onLoad)
    if (onMouseEnter)
      map.on('mouseenter', onMouseEnter.layer, e => {
        if (!layerEntered) {
          layerEntered = true
          onMouseEnter.fn()
        }
      })
    if (onMouseLeave)
      map.on('mouseleave', onMouseLeave.layer, e => {
        const f = getHoveredFeature(e, onMouseLeave.layer, map)
        if (f?.layer?.id !== onMouseLeave.layer && layerEntered) {
          layerEntered = false
          onMouseLeave.fn()
        }
      })
    if (onClick)
      map.on('click', onClick.layer, onClick.fn)
    if (onIdle)
      map.on('idle', onIdle)



    if (onMouseFeatureEnter && onMouseFeatureLeave) {
      map.on('mousemove', (e) => {

        const newHoverFeature = getHoveredFeature(e, onMouseFeatureEnter.layer, map)

        if (hoveredFeatureId !== null && hoveredFeatureId !== newHoverFeature?.properties?.id) {
          onMouseFeatureLeave.fn()
        }

        if (newHoverFeature !== null) {
          if (hoveredFeatureId !== newHoverFeature.properties?.id) {
            onMouseFeatureEnter.fn(newHoverFeature)
            hoveredFeatureId = newHoverFeature.properties?.id;
          }
        } else {
          hoveredFeatureId = null
        }

      })
    }
    if (onMouseFeatureLeave)
      map.on('mouseleave', onMouseFeatureLeave.layer, (e) => {
        if (hoveredFeatureId !== null) {
          const f = getHoveredFeature(e, onMouseFeatureLeave.layer, map)
          if (f?.layer?.id !== onMouseFeatureLeave.layer) {
            onMouseFeatureLeave.fn()
            hoveredFeatureId = null;
          }
        }
      })

    return map
  } catch (e) {
    logExceptionFromCatch(e)
    if (onError) {
      if (e && e.message && e.message === 'Failed to initialize WebGL.')
        onError('Failed to load Mapbox map library: Check that you have WebGL enabled and are able to connect to mapbox.com.')
      else
        onError(e)
    }
  }

  return null
}
