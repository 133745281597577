import { MouseEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'context'

import { AlertApi } from 'apis'
import { LoadingComponent, Sidebar, SidebarContent } from 'components'
import AlertsIcon from 'components/icons/AlertsIcon'
import { handleAsyncLoader } from 'lib'
import { AlertFilterForm, AlertsSearchItem, ErrorDetail } from 'models'
import { alertToQueryReq } from 'models/converters'

export const AlertMapSidebar = ({
  filter,
  count,
  locationType,
  location,
  onClose 
}: {
  filter: AlertFilterForm,
  count: number,
  locationType: string,
  location: {id: number, name: string, count: number},
  onClose: (event: MouseEvent) => void
}): JSX.Element => {

  const { session } = useAppContext()

  const [list, setList] = useState<AlertsSearchItem[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      const locationFilter: AlertFilterForm = {
        ...filter,
        city_ids: [`${location.id}`],
        page: `${1}`,
        per_page: `${location.count}`
      }

      setList((await new AlertApi(session.token)
        .search(alertToQueryReq(locationFilter))).alerts
      )
    }
  ), [location])

  return (
    <Sidebar header={location.name} onClose={onClose}>
      <SidebarContent>
        <div className="sidebar-section-title pb-2">
          <AlertsIcon />
          <span className="ml-1">Alerts</span>
        </div>
        <div className="content-title large">{location.count}</div>
      </SidebarContent>

      <SidebarContent>
        <div className="sidebar-section-title pb-4">Most Recent Alerts:</div>

        <LoadingComponent loading={loading} error={error} marginTopPx={20}>
          <div className="sidebar-section-content">
            <ol className="list-numeric px-0">
              {list && list.map(a => (
                <li key={a.id} className="list-numeric-item">
                  <Link to={`/alerts/${a.id}`}>{a.title}</Link>
                </li>
              ))}
            </ol>
          </div>
        </LoadingComponent>
      </SidebarContent>

    </Sidebar>
  )
}
