import { AlertsSearchItem } from 'models'
import { CitiesInfoList, DateRange } from 'components'

const InfoBoxClass = "info-box dot dot-left my-2"
const DelimiterStyle = {
  padding: '0 1rem'
}

export const AlertInfoBox = ({
  alert
}: {
  alert: AlertsSearchItem
}): JSX.Element => {
  const active = alert.status === 'Active'
  return (
    <div className={`${InfoBoxClass} ${active ? 'active' : ''}`}>
      {alert.status}
      <span style={DelimiterStyle}>|</span>
      <CitiesInfoList cities={
          alert.cities?.map(({city}) => city.display_name)
        } 
      />
      {alert.cities && alert.cities.length > 0 &&
        <span style={DelimiterStyle}>|</span>
      }
      <DateRange
        startDate={alert.start_date}
        endDate={alert.end_date}
      />
    </div>
  )
}