import {qsToErtForm, qsToTranForm} from 'models/converters';
import {StickyAlertFilterForm, StickyErtFilterFormQs, StickyTransactionFilterForm} from 'models';

export class SessionStorage {
  static get currentTransactionsFilter(): StickyTransactionFilterForm {
    const str = sessionStorage.getItem('currentTransactionsFilter')

    const filter = str === null
      ? qsToTranForm(new URLSearchParams())
      : JSON.parse(str)

    return {
      ...filter,
      page: undefined,
      per_page: undefined,
      filterId: undefined,
      filterName: '',
    }

  }

  static setCurrentTransactionsFilter(filter: StickyTransactionFilterForm) {
    sessionStorage.setItem('currentTransactionsFilter', JSON.stringify({
      ...filter,
      page: undefined,
      per_page: undefined,
      filterId: undefined,
      filterName: ''
    }))
  }

  static setCurrentTransactionsFilterView(view: 'map' | 'list') {
    this.setCurrentTransactionsFilter({
      ...this.currentTransactionsFilter,
      view
    })
  }



  static get currentERTFilter(): StickyErtFilterFormQs {
    const str = sessionStorage.getItem('currentERTFilter')

    const filter = str === null
      ? qsToErtForm(new URLSearchParams())
      : JSON.parse(str)

    return {
      ...filter,
      page: undefined,
      per_page: undefined,
      filterId: undefined,
      filterName: '',
    }

  }

  static setCurrentERTFilter(filter: StickyErtFilterFormQs) {
    sessionStorage.setItem('currentERTFilter', JSON.stringify({
      ...filter,
      page: undefined,
      per_page: undefined,
      filterId: undefined,
      filterName: ''
    }))
  }


  // static setCurrentERTFilterView(view: 'map' | 'list') {
  //   if (sessionStorage.getItem('currentERTFilter') !== null) {
  //     this.setCurrentERTFilter({
  //       ...this.currentERTFilter,
  //       view
  //     })
  //   }
  // }





  static get currentAlertsFilter(): StickyAlertFilterForm {
    const str = sessionStorage.getItem('currentAlertsFilter')

    const filter = str === null
      ? qsToErtForm(new URLSearchParams())
      : JSON.parse(str)

    return {
      ...filter,
      page: undefined,
      per_page: undefined,
      filterId: undefined,
      filterName: '',
    }

  }

  static setCurrentAlertsFilter(filter: StickyAlertFilterForm) {
    sessionStorage.setItem('currentAlertsFilter', JSON.stringify({
      ...filter,
      page: undefined,
      per_page: undefined,
      filterId: undefined,
      filterName: ''
    }))
  }



  static setCurrentAlertsFilterView(view: 'map' | 'list') {
    this.setCurrentAlertsFilter({
      ...this.currentAlertsFilter,
      view
    })
  }




}
