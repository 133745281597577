import {useLocation} from 'react-router-dom';
import {
  MainContainer, 
  Tab, 
  TabbedNavContainer
} from 'components';
import {TranGroup, TransactionMap} from 'apps/transactions/TransactionMap';
import {TransactionList} from 'apps/transactions/TransactionList';
import {TransactionSidebar} from 'apps/transactions/TransactionSidebar';
import {TransactionGroupSidebar} from 'apps/transactions/TransactionGroupSidebar';
import {useEffect, useMemo, useState} from 'react';
import {TransactionFilterForm} from 'models';
import {buildQuery} from 'apis';
import MapIcon from 'components/icons/MapIcon';
import ListIcon from 'components/icons/ListIcon';
import {qsToTranForm} from 'models/converters';
import {TranPaths} from 'apps/transactions/Transactions';
import {TransactionEntitySidebar} from 'apps/transactions/TransactionEntitySidebar';

type CountryOptionsType = {
  name: string
  code: 'all'|'china'|'russia'
}
const CountryOptions: CountryOptionsType[] = [
  {
    name: 'All',
    code: 'all'
  },
  {
    name: 'China',
    code: 'china'
  },
  {
    name: 'Russia',
    code: 'russia'
  },
]

export function TransactionHome(
  {
    searchBar,
  }: {
    searchBar: JSX.Element,
  }
): JSX.Element {

  const location = useLocation()

  const view: TranPaths = Object.values(TranPaths).includes(location.pathname as TranPaths)
    ? location.pathname as TranPaths
    : TranPaths.map

  const [previewTranId, setPreviewTranId] = useState<number>()
  const [previewEntityId, setPreviewEntityId] = useState<number>()
  const [previewTranGroup, setPreviewTranGroup] = useState<TranGroup>()

  const [pagerControl, setPagerControl] = useState<JSX.Element>()

  const country = CountryOptions[0]

  useEffect(() => {
    handleCloseSidebar()
  }, [view])

  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  const filter = useMemo(() => qsToTranForm(query), [query]);

  useEffect(() => {
    setPreviewTranGroup(undefined)
    setPreviewEntityId(undefined)
    setPreviewTranId(undefined)
  }, [filter])

  const tabs: Tab[] = [
    {
      active: view === TranPaths.map || view === TranPaths.mapFilters,
      to: TranPaths.map + buildQuery(filter, true),
      children: <><MapIcon />Map</>
    },
    {
      active: view === TranPaths.list || view === TranPaths.listFilters || view === TranPaths.related || view === TranPaths.relatedFilters,
      to: TranPaths.list + buildQuery<TransactionFilterForm>({...filter, page: '1'}, true),
      children: <><ListIcon />List</>
    }
  ]

  function handleCloseSidebar() {
    setPreviewTranId(undefined)
    setPreviewEntityId(undefined)
    setPreviewTranGroup(undefined)
  }


  return (
    <>
      <MainContainer
        rightSidebarActive={(!!previewTranId) || (!!previewEntityId) || (!!previewTranGroup)}
        searchBar={searchBar}
        pager={view === TranPaths.list || view === TranPaths.related ? pagerControl : undefined}
      >
        <TabbedNavContainer tabs={tabs} >
          <TransactionMap
            setPreviewGroup={setPreviewTranGroup}
            previewGroup={previewTranGroup}
            filter={filter}
          />
          <TransactionList
            className="py-6 px-4"
            setPreviewTranId={setPreviewTranId}
            previewTranId={previewTranId}
            setPreviewEntityId={setPreviewEntityId}
            previewEntityId={previewEntityId}
            setPagerControl={setPagerControl}
            filter={filter}
          />
        </TabbedNavContainer>
      </MainContainer>
      {previewTranId &&
      <TransactionSidebar
        id={previewTranId}
        onClose={handleCloseSidebar}
      />
      }
      {previewEntityId &&
      <TransactionEntitySidebar
        entityId={previewEntityId}
      />
      }
      {previewTranGroup &&
      <TransactionGroupSidebar
        id={previewTranGroup.id}
        baseFilter={{
          ...previewTranGroup.baseFilter,
          ...filter
        }}
        name={previewTranGroup.name}
        count={previewTranGroup.count}
        onClose={handleCloseSidebar}
      />}
    </>

  )
}
