import {Api, UrlParams} from 'apis/Api';
import {MsAutocompleteResponse} from 'models/Forms';



const URLS = {
  entities: '/entities/autocomplete',
  countries: '/countries/autocomplete',
}


export interface AutocompleteQuery extends UrlParams {
  q: string,
  page: number,
  per_page: number,
  '_': number,
}

export interface AutocompleteIds {
  id: number[],
}

export class AutocompleteApi extends Api {


  async entities(params: AutocompleteQuery): Promise<MsAutocompleteResponse> {
    return (
      await this.get<MsAutocompleteResponse>(URLS.entities, params)
    ).data
  }
  async entitiesFromIds(params: AutocompleteIds): Promise<MsAutocompleteResponse> {
    return (
      await this.get<MsAutocompleteResponse>(URLS.entities, {id: [params.id.map(d => +d).join(',')]})
  ).data
  }
  async countries(params: AutocompleteQuery): Promise<MsAutocompleteResponse> {
    return (
      await this.get<MsAutocompleteResponse>(URLS.countries, params)
    ).data
  }
  async countriesFromIds(params: AutocompleteIds): Promise<MsAutocompleteResponse> {
    return (
      await this.get<MsAutocompleteResponse>(URLS.countries, {id: [params.id.map(d => +d).join(',')]})
    ).data
  }

}
