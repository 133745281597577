import {Field} from 'models/Forms';


export function Input(
  {
    field,
    setField,
    className,
    placeholder,
    type,
    autoFocus
  } : {
    field: Field<string>,
    setField: (val: Field<string>) => void
    className: string,
    placeholder: string,
    type?: 'number' | 'date',
    autoFocus?: boolean
  }
): JSX.Element {

  return (
    <input
      autoFocus={autoFocus}
      value={field.value}
      onChange={(e) => setField({...field, value: e.target.value})}
      onBlur={() => setField({...field, touched: true})}
      className={className + (field.errors.length ? ' is-invalid' : field.touched ? ' is-valid' : '')}
      placeholder={placeholder}
      type={type}
    />
  )

}
