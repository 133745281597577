import {useAppContext} from 'context';
import {useEffect, useState} from 'react';
import {ErrorDetail, Transaction} from 'models';
import {TransactionsApi} from 'apis';
import {Link} from 'react-router-dom';
import {LoadingComponent, Sidebar, SidebarContent, DateRange} from 'components';
import {SearchCityBar} from 'apps/search/components/SearchCityBar';
import {handleAsyncLoader} from 'lib';

export function TransactionSidebar(
  {
    id,
    onClose,
  }: {
    id: number,
    onClose: () => void,
  }
): JSX.Element {

  const { session } = useAppContext()

  const [transaction, setTransaction] = useState<Transaction>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setTransaction(await new TransactionsApi(session.token).id(id))
    }), [id, session.token])

  const view = transaction ? (
    <div dangerouslySetInnerHTML={{__html:transaction.description}} />
  ) : (
    <>Error loading Transaction</>
  )

  const nav = loading || !!error ? undefined : (
    <Link className="btn btn-gray text-primary btn-block"
          to={`/transactions/${transaction?.id}`}
    >
      View Full Transaction
    </Link>
  )

  return (
    <Sidebar onClose={onClose}
             header="Transaction Preview"
             nav={nav}
    >
      <LoadingComponent loading={loading} error={error}>
        <SidebarContent>
          <div className="content-title">{transaction?.title}</div>
          <div className={'info-box dot dot-left mb-2 ' + (transaction?.status === 'Active' ? 'active' : '')}>
            {transaction?.status}
            <span className="info-box-divider">|</span>
            {transaction?.cities && <SearchCityBar cities={transaction.cities.map(c => (
              {
                cityName: c.city.name,
                to: '/transactions/' + transaction?.id
              }
            ))} />}
            <span className="info-box-divider">|</span>
            <DateRange startDate={transaction?.start_date} endDate={transaction?.end_date} />
          </div>
        </SidebarContent>
        <SidebarContent>
          {view}
        </SidebarContent>
      </LoadingComponent>
    </Sidebar>
  );
}
