import {ErrorDetail} from 'models';

export function ErrorPage(
  {
    error
  } :
    {
      error: ErrorDetail
    }
): JSX.Element {

  return (
    <div className="container-fluid">
      <div className="text-center mt-6">
        <div className="alert alert-danger" role="alert">
          {error.message}
        </div>
      </div>
    </div>
  )

}
