import {Api} from 'apis/Api';
import {SavedItemsListRecordTypes, SavedItemsListResponse} from 'models';

const URLS = {
  savedItems: '/saved_items/:type',
  savedItem: '/saved_items/:type/:id',
}

type Types = 'alerts' | 'countryreports' | 'entities' | 'transactions'

function convert(type: SavedItemsListRecordTypes): Types {
  switch (type) {
    case 'Alert': return 'alerts'
    case 'Transaction': return 'transactions'
    case 'Entity': return 'entities'
    case 'CountryReport': return 'countryreports'
  }
}

export class SavedItemsApi extends Api {

  async list(type: SavedItemsListRecordTypes): Promise<SavedItemsListResponse> {
    const resp = (await this.get<SavedItemsListResponse>(URLS.savedItems.replace(':type', convert(type)))).data;
    return resp;
  }

  async upsert(type: SavedItemsListRecordTypes, ids: number[], notify: boolean): Promise<void> {
    await this.post(URLS.savedItems.replace(':type', convert(type)), {
      ids,
      notify
    })
  }

  async destroy(type: SavedItemsListRecordTypes, id: number): Promise<void> {
    await this.delete(URLS.savedItem.replace(':type', convert(type)).replace(':id', `${id}`))
  }

}
