import {LoadingComponent, Sidebar, SidebarContent} from 'components';
import {useAppContext} from 'context';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Alert} from 'models/Alert';
import {AlertApi} from 'apis/AlertApi';
import {ErrorDetail} from 'models';
import {handleAsyncLoader} from 'lib';

export function SearchAlertSidebar(
  {
    id,
    onClose,
  }: {
    id: number,
    onClose: () => void,
  }
): JSX.Element {

  const { session } = useAppContext()

  const [alert, setAlert] = useState<Alert>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()



  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setAlert(await new AlertApi(session.token).id(id))
    }), [id, session.token])


  const nav = loading || !!error ? undefined : (
    <Link className="btn btn-gray text-primary btn-block"
      to={`/alerts/${alert?.id}`}
    >
      View Full Alert
    </Link>
)


  useEffect(() => {
    setAlert(undefined);
  }, [id])

  return (
    <Sidebar onClose={onClose}
      header='Alert Preview'
      nav={nav}
    >
    <LoadingComponent loading={loading} error={error}>
      <SidebarContent>
        <div className="content-title">{alert?.title}</div>
      </SidebarContent>
      <SidebarContent>
        <div
          dangerouslySetInnerHTML={{__html: alert?.comment || ''}}
        />
      </SidebarContent>
    </LoadingComponent>
  </Sidebar>
);
}
