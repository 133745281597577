import { useState } from 'react'
import { Link } from "react-router-dom"

import {Entity, EntityRiskEvent} from 'models'

import {Modal, HighlightText, DateView} from 'components';

import './EventTable.scss'

interface EventTableParams {
  className?: string
  onSelect?: (id: number, title: string) => void,
  events: EntityRiskEvent[]
  previewId?: number
  compact?: boolean
}

export function EventTable({
  className,
  onSelect,
  events,
  previewId,
  compact
}: EventTableParams) {

  const [riskIndexHover, setRiskIndexHover] = useState(0)
  const [riskExposureModalOpen, setRiskExposureModalOpen] = useState(false)

  const isCompact = compact || previewId

  function handleOpenRiskExposureInfo() {
    setRiskExposureModalOpen(true)
  }

  return (
    <div className={`risk-events table-responsive ${className || ''}`}>
      <table className="table table-large table-hover">
        <tbody>
          {events.map((event, key) =>
            <tr
              className={previewId === event.company_id ? 'active' : ''}
              onClick={(e) => {onSelect && onSelect(event.company_id, event.company); e.stopPropagation()}}
              key={key}
            >
              <td className="risk-designation">
                <div className="col-name">
                  <Link to={"/entityrisktracker/" + event.company_id} 
                    onClick={(e) => e.stopPropagation()}
                  >
                    {event.company}
                  </Link>
                </div>
                <div className="info-box info-box-no-alert mt-0">
                  <span className="event">
                    {event.event}
                  </span>
                  {event.event && (event.date || event.location) ? 
                    <span className="info-box-divider">|</span> : ''
                  }
                  <span className="date">
                    <DateView type='date'>
                      {event.date ?? ''}
                    </DateView>
                  </span>
                  {event.date && event.location ?  
                    <span className="info-box-divider">|</span> : ''
                  }
                  <span className="location">{event.location}</span>
                </div>
                <div className="mt-2 highlight-text">{event.summary}</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
