import {RelatedEntity} from 'models/RelatedEntity';
import { Link } from 'react-router-dom';

export function TransactionEntityTable(
  {
    previewId,
    onSelect,
    entities,
  }: {
    previewId?: number
    onSelect: (id: number, title: string) => void
    entities: RelatedEntity[]
  }
): JSX.Element {

  return (
    <div className="table-responsive">
      <table className="table table-large table-alignment-top table-hover">
        <colgroup>
          <col style={{width: '320px'}} />
          <col />
        </colgroup>
        <tbody>
        {entities.map(e => (
          <tr
            key={e.id}
            onClick={(ev) => {onSelect(e.id, e.name); ev.stopPropagation() }}
            className={previewId === e.id ? ' active' : ''}
          >
            <td className={'col-name'}>
              <Link
                to={'/entityrisktracker/' + e.id}
                onClick={e => e.stopPropagation()}
              >
                {e.name}
              </Link>
            </td>
            <td>
              <div className={'info-box dot dot-left mt-2 active'}>
                Alert TODO
                <span className="info-box-divider">|</span>
                <a href="#">TODO  Butterworth,  Malaysia</a>
                <span className="info-box-divider">|</span>
                TODO
                State-Owned Enterprise, Publicly Traded
                <span className="info-box-divider">|</span>
                TODO Chinese SOE
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>  )
}
