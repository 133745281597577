import {useState} from 'react';



export function DropdownList<T>(
  {
    items,
    value,
    onChange,
    lookupName,
    lookupSelectedName,
    className,
    buttonClassName,
    bare,
  } : {
    items: T[]
    value: T
    onChange: (val: T) => void
    lookupName: (val: T) => string
    lookupSelectedName?: (val: T) => string
    className?: string
    buttonClassName?: string
    bare?: boolean
  }
): JSX.Element {

  const [showSearchMenu, setShowSearchMenu] = useState(false)

  const core = (
    <>
      <button className={'btn btn-gray-light dropdown-toggle ' + buttonClassName}
              type="button"
              onClick={() => setShowSearchMenu(!showSearchMenu)}
      >
        { lookupSelectedName ? lookupSelectedName(value) : lookupName(value) }
      </button>
      <div className={`dropdown-menu dropdown-menu-right ${showSearchMenu ? 'show' : ''}`}>
        {items.map((st, index) => (
          <button className="btn btn-link dropdown-item"
                  onClick={() => {
                    onChange(st)
                    setShowSearchMenu(false)
                  }}
                  key={index}
          >
            {lookupName(st)}
          </button>
        ))}
      </div>
    </>
  )

  if (bare)
    return core

  return (
    <div className={'dropdown ' + className}>
      {core}
    </div>
  )
}
