export function LoadingPage(
  {
    marginTopPx,
  }: {
    marginTopPx: number
  }
) {

  const style = {
    maxWidth: 500,
    margin: `${marginTopPx}px auto 0`
  }

  return (
    <div className="container-fluid">
      <div className="text-center" style={style}>
        <p className="lead">
          Loading...
        </p>
        <div className="progress">
          <div style={{width: '100%'}}
            role="progressbar"
            className="progress-bar progress-bar-striped progress-bar-animated" 
          >
          </div>
        </div>
      </div>
    </div>
  )

}
