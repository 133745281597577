import { Api } from './Api'
import {
  AlertsSavedItemReq,
  AlertsFilterSavedItemRes,
  TranFilterSavedItemReq,
  TranFilterSavedItemRes,
  ErtFilterSavedItemRes,
  ErtSavedItemReq,
  CountryRptSavedItemReq,
  CountryRptFilterSavedItemRes,
  AlertIdsSavedItemReq,
  AlertIdsFilterSavedItemRes,
  TranIdsFilterSavedItemRes,
  TranIdsFilterSavedItemReq,
  ErtIdsFilterSavedItemRes
} from 'models';
import {ErtIdsFilterSavedItemReq} from 'models/Entity';

const URLS = {
  searches: '/searches',
  update: '/searches/:id',
  destroy: '/searches/:id',
}


export interface SavedSearchMetadataDto {
  "id": number,
  "name": string,
  "notify": boolean,
  filters: TranFilterSavedItemRes | AlertsFilterSavedItemRes | ErtFilterSavedItemRes | CountryRptFilterSavedItemRes | AlertIdsFilterSavedItemRes | TranIdsFilterSavedItemRes | ErtIdsFilterSavedItemRes,
  "created_at": string, // "2021-10-19T13:58:58.008-04:00",
  "updated_at": string, // "2021-10-19T13:58:58.008-04:00",
}

export interface SavedSearchListResponse {
  "searches":  {
    "search": SavedSearchMetadataDto
  }[],
  "pager": {
    "total_records": number,
    "total_pages": number,
    "per_page": number,
    "page": number,
  }
}

export type FilterSavedItemReq = TranFilterSavedItemReq | AlertsSavedItemReq | ErtSavedItemReq | CountryRptSavedItemReq | AlertIdsSavedItemReq | TranIdsFilterSavedItemReq | ErtIdsFilterSavedItemReq

export class SearchesApi extends Api {


  async list(): Promise<SavedSearchListResponse> {
    const resp = (await this.get<SavedSearchListResponse>(URLS.searches)).data;
    return resp;
  }

  async create<T extends FilterSavedItemReq>(params: T): Promise<SavedSearchMetadataDto> {
    const resp = (await this.postForm<SavedSearchMetadataDto>(URLS.searches, params)).data;
    return resp;
  }

  async update<T extends FilterSavedItemReq>(id: number, params: T): Promise<SavedSearchMetadataDto> {
    const resp = (await this.putForm<SavedSearchMetadataDto>(URLS.update.replace(':id', `${id}`), params)).data;
    return resp;
  }

  async destroy(id: number): Promise<void> {
    await this.delete(URLS.destroy.replace(':id', `${id}`));
  }

}
