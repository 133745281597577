import {MainContainer, TabbedNavContainer} from 'components';
import {CountryReportMap} from 'apps/countryreports/CountryReportMap';
import {CountryReportList} from 'apps/countryreports/CountryReportList';
import {CountryReportSidebar} from 'apps/countryreports/CountryReportSidebar';
import {useEffect, useMemo, useState} from 'react';
import MapIcon from 'components/icons/MapIcon';
import ListIcon from 'components/icons/ListIcon';
import {CountryReportPaths} from 'apps/countryreports/CountryReports';
import {useHistory, useLocation} from 'react-router-dom';
import {CountryReportSearch} from 'apis';


const searchFromParams = (query: URLSearchParams): CountryReportSearch => {
  const text = query.get('text')
  return text ? { text } : {}
}


export function CountryReportHome(
  {
    searchBar
  }: {
    searchBar: JSX.Element
  }): JSX.Element {

  const location = useLocation()

  const search = useMemo(() => searchFromParams(new URLSearchParams(location.search)), [location.search])

  const view: CountryReportPaths = Object.values(CountryReportPaths).includes(location.pathname as CountryReportPaths)
    ? location.pathname as CountryReportPaths
    : CountryReportPaths.map


  useEffect(() => {
    handleCloseSidebar()
  }, [view])


  function handleCloseSidebar() {
    setPreviewId(undefined)
  }

  const [previewId, setPreviewId] = useState<number>()

  const tabs = [
    {
      active: view === CountryReportPaths.map,
      to: `${CountryReportPaths.map}${location.search}`,
      children: <><MapIcon />Map</>
    },
    {
      active: view === CountryReportPaths.list,
      to: `${CountryReportPaths.list}${location.search}`,
      children: <><ListIcon />List</>
    }
  ]

  return (
    <>
      <MainContainer
        rightSidebarActive={!!previewId}
        searchBar={searchBar}
      >
        <TabbedNavContainer tabs={tabs}>
          <CountryReportMap
            search={search}
            setCountryReportId={setPreviewId}
            previewId={previewId}
          />
          <CountryReportList
            className="py-6 px-4"
            search={search}
            setCountryReportId={setPreviewId}
            previewId={previewId}
          />
        </TabbedNavContainer>
      </MainContainer>
      {previewId &&
      <CountryReportSidebar
        id={previewId}
        onClose={handleCloseSidebar}
      />
      }
    </>
  )
}
