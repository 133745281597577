export default function CountryReportsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.013" height="24.5" viewBox="0 0 19.013 24.5">
      <g transform="translate(0.25)">
      <path className="icon-stroke" d="M.5,0V24.5" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="1.5"/>
      <path className="icon-stroke" d="M0,0H12.257L7.3,4.959" transform="translate(0.5 13.471)" fill="none" stroke="#747576" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5"/>
      <path className="icon-stroke" d="M0,6.278v4.959H11.256V0H6" transform="translate(6.757 7.193)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="1.5"/>
      <path className="icon-stroke" d="M12.257,11.237V0H0" transform="translate(0.5 2.234)" fill="none" stroke="#747576" strokeMiterlimit="10" strokeWidth="1.5"/>
      </g>
    </svg>
  )
}
