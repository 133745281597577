import {useEffect, useMemo, useState} from 'react';
import {LoadingComponent, MainContainer} from 'components';
import {EntityList} from 'apps/entityrisktracker/EntityList';
import {EventList} from 'apps/entityrisktracker/EventList';
import {EntityRiskTrackerSidebar} from 'apps/entityrisktracker/EntityRiskTrackerSidebar';
import {useLocation} from 'react-router-dom';
import {EntityRiskResult, ErtFilterFormQs, ErrorDetail} from 'models';
import {EntityRiskTrackerApi} from 'apis';
import {qsToErtForm} from 'models/converters';
import {countErtFilters, handleAsyncLoader} from 'lib';
import {EntityUpload} from './EntityUpload';
import {EntityFilters} from 'apps/entityrisktracker/EntityFilters';
import {useAppContext} from 'context';


enum View {
  List,
  Upload,
  Filters
}

export function EntityRiskHome(
  {
    searchBar,
    Paths,
  }: {
    searchBar: JSX.Element,
    Paths: { list: string, events: string, upload: string, filters: string },
  }
): JSX.Element {

  const { session } = useAppContext()

  const { search, pathname } = useLocation()

  const view = pathname === Paths.list ? View.List : pathname === Paths.upload ? View.Upload : View.Filters
  const [previewId, setPreviewId] = useState<number>()
  const filter = useMemo<ErtFilterFormQs>(() =>
      qsToErtForm(new URLSearchParams(search))
    , [search])
  const [filterCount, setFilterCount] = useState(countErtFilters(filter))
  const [filterOptions, setFilterOptions] = useState<EntityRiskResult[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()




  useEffect(() => {(() => {
    setFilterCount(countErtFilters(filter))
  })()}, [view, filter])


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setFilterOptions((await new EntityRiskTrackerApi(session.token).filters()))
    }), [filter])




  return (
    <>
      <MainContainer
        rightSidebarActive={!!previewId}
        searchBar={searchBar}
      >
        <>

          {pathname === Paths.list &&
          <EntityList
            className="py-4"
            filter={filter}
            setEntityId={setPreviewId}
            previewId={previewId}
            Paths={Paths}
            filterCount={filterCount}
          />
          }

          {pathname === Paths.events &&
          <EventList
            className="py-2"
            filter={filter}
            setEntityId={setPreviewId}
            previewId={previewId}
            Paths={Paths}
            filterCount={filterCount}
          />
          }

          {pathname === Paths.upload &&
          <EntityUpload
          />
          }

          {(pathname === Paths.filters) &&
            <LoadingComponent loading={loading} error={error} >
              <EntityFilters
                origFilter={filter}
                Paths={Paths}
                filterOptions={filterOptions}
                filterCount={filterCount}
                setFilterCount={setFilterCount}
              />
            </LoadingComponent>
          }

        </>
      </MainContainer>
      {previewId &&
        <EntityRiskTrackerSidebar
          id={previewId}
          onClose={() => setPreviewId(undefined)}
        />
      }
    </>
  )

}
