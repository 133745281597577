import { ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'context'
import { EntityApi, AlertApi, TransactionsApi } from 'apis'
import { handleAsyncLoader } from 'lib'
import {
  AlertsSearchItem,
  EntityModel,
  ErrorDetail,
  TransactionsSearchItem
} from 'models'
import {
  DateView,
  LoadingComponent,
  SectionedSidebar,
  SectionedSidebarContent,
  SectionedSidebarContentTitle,
  SectionedSidebarContentSection
} from 'components'

interface OwnerType {
  id: number
  name: string
  type: string
  stake: string
}

const OwnerListItem = ({
  owner,
  setId
}: {
  owner: OwnerType
  setId: (id?: number) => void
}): JSX.Element => (
  <li className="list-numeric-item" key={owner.id}>
    {owner.type === 'Entity' &&
      <a role="button" onClick={() => setId(owner.id)}>
        {owner.name} {owner.stake === 'Majority' ? '(Majority)' : ''}
      </a>
    }
    {owner.type === 'Country' &&
      <>{owner.name}{owner.stake === 'Majority' ? '(Majority)' : ''}</>
    }
  </li>
)

export const NestedEntitySidebar = ({
  id,
  setId,
  nestedTitle
}: {
  id: number
  setId: (id?: number) => void
  nestedTitle: ReactNode
}) => {

  const { session } = useAppContext()

  const [entity, setEntity] = useState<EntityModel>()
  const [transactions, setTransactions] = useState<TransactionsSearchItem[]>()
  const [alerts, setAlerts] = useState<AlertsSearchItem[]>()

  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()

  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      await Promise.all([
        (async () =>
          setEntity(await new EntityApi(session.token).id(id))
        )(),
        (async () =>
          setTransactions((await new TransactionsApi(session.token).search({
            'entity_ids[]': [id],
            order: 'date',
            sort_mode: 'desc',
          })).transactions)
        )(),
        (async () =>
          setAlerts((await new AlertApi(session.token).search({
            'entity_ids[]': [id],
            order: 'updated_at',
            sort_mode: 'desc',
          })).alerts)
        )(),
      ])

    }
  ), [session.token, id])

  // Calculate Owners
  let stateOwners: OwnerType[] = []
  let ultimateOwners: OwnerType[] = []
  let directOwners: OwnerType[] = []

  if (entity && entity.owners) {
    entity.owners.forEach(({owner}) => {
      if (owner.type === 'Country') {
        stateOwners.push(owner)
      }
      if (owner.type === 'Entity' && owner.ultimate) {
        ultimateOwners.push(owner)
      }
      if (owner.type === 'Entity' && !owner.ultimate) {
        directOwners.push(owner)
      }
    })
  }

  return (
    <SectionedSidebar>
      <SectionedSidebarContentTitle secondary={true}>
        {nestedTitle}
      </SectionedSidebarContentTitle>
      <LoadingComponent loading={loading}>
        <SectionedSidebarContent title={
          <div>
            <h4 className="content-sidebar-title pt-2 mb-0 d-inline-block">
              Entity Details
            </h4>
            <button type="button" 
              className="close pt-1" 
              style={{fontSize: '2rem'}}
              onClick={() => setId(undefined)} 
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }>
            { entity?.name &&
              <SectionedSidebarContentSection title="Name">
                {entity?.name}
              </SectionedSidebarContentSection>
            }
            { entity?.sector &&
              <SectionedSidebarContentSection title="Sector">
                {entity?.sector}
              </SectionedSidebarContentSection>
            }
            { entity?.headquarters_address &&
              <SectionedSidebarContentSection title="Address">
                {entity?.headquarters_address.address1}
                {entity?.headquarters_address.city &&
                  <><br/>{entity?.headquarters_address.city}</>
                }
              </SectionedSidebarContentSection>
            }
            { entity && entity?.ownership_types.length > 0 &&
              <SectionedSidebarContentSection title="Ownership Type">
                {entity?.ownership_types.map(o => o.ownership_type.name).join(', ')}
              </SectionedSidebarContentSection>
            }
            { entity?.website &&
              <SectionedSidebarContentSection title="Website">
                <a href={entity?.website} target="_blank">{entity?.website}</a>
              </SectionedSidebarContentSection>
            }
            {/*{ stateOwners.length > 0 &&
              <SectionedSidebarContentSection title="State Owners">
                <ol className="list-numeric px-0">
                  {stateOwners.map((owner) => (
                    <OwnerListItem owner={owner} setId={setId} />
                  ))}
                </ol>
              </SectionedSidebarContentSection>
            }*/}
            { ultimateOwners.length > 0 &&
              <SectionedSidebarContentSection title="Ultimate Owners">
                <ol className="list-numeric px-0">
                  {ultimateOwners.map((owner) => (
                    <OwnerListItem owner={owner} setId={setId} />
                  ))}
                </ol>
              </SectionedSidebarContentSection>
            }
            { directOwners.length > 0 &&
              <SectionedSidebarContentSection title="Direct Owners">
                <ol className="list-numeric px-0">
                  {directOwners.map((owner) => (
                    <OwnerListItem owner={owner} setId={setId} />
                  ))}
                </ol>
              </SectionedSidebarContentSection>
            }
            { transactions && transactions.length > 0 &&
              <SectionedSidebarContentSection title="Recent Transactions">
                <ol className="list-numeric px-0">
                  {transactions.map(tran => (
                    <li className="list-numeric-item" key={tran.id}>
                      <Link to={ "/transactions/" + tran.id}>{tran.title}</Link>
                    </li>
                  ))}
                </ol>
              </SectionedSidebarContentSection>
            }
            { alerts && alerts.length > 0 &&
              <SectionedSidebarContentSection title="Recent Alerts">
                <ol className="list-numeric px-0">
                  {alerts.map(alert => (
                    <li className="list-numeric-item" key={alert.id}>
                      <Link to={ "/alerts/" + alert.id}>{alert.title}</Link>
                    </li>
                  ))}
                </ol>
              </SectionedSidebarContentSection>
            }
        </SectionedSidebarContent>
      </LoadingComponent>
    </SectionedSidebar>
  )
  
}
