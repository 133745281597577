import {useEffect, useState} from 'react';
import {useAppContext} from 'context';
import {S3Files} from 'apis/S3Files';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';


export function AuthImage({
  srcPath,
  className
}: {
  srcPath: string,
  className: string
}): JSX.Element {

  const [dataUrl, setDataUrl] = useState<string | undefined>(undefined);

  const {session} = useAppContext()

  useEffect(() => {
    let imageUrl: string | undefined;
    let unmounted = false;
    (async () => {
      const blob = await (new S3Files(session.token).name(srcPath));

      if (!unmounted) {
        imageUrl = URL.createObjectURL(blob);
        setDataUrl(imageUrl);
      }
    })()
      .catch(e => {
        logExceptionFromCatch(e, `error loading image: ${srcPath}.`);
        if (!unmounted) {
          setDataUrl('/error');
        }
      });

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
      unmounted = true;
    }
  }, [srcPath, session.token]);


  return (
    <img className={className} src={dataUrl} alt=''/>
  )
}
