import {Input} from 'components/Input';
import {validateRequired} from 'lib/Forms';
import SavedItemsBlueIcon from 'components/icons/SavedItemsBlueIcon';
import {useEffect, useState} from 'react';
import {Field} from 'models';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';

export function SaveNameForm(
  {
    namePlaceholder,
    typeBeingSaved,
    validate,
    save,
    close,
    defaultName,
  }: {
    namePlaceholder: string
    typeBeingSaved: string
    validate: () => boolean
    save: (name: string) => Promise<void>
    close: (cancelled: boolean) => void
    defaultName?: string
  }
): JSX.Element {


  const [savedName, setSavedName] = useState<Field<string>>({value: '', origValue: '', touched: false, errors: []})

  useEffect(() => {
    if (defaultName)
      setSavedName({
        ...savedName,
        value: defaultName
      })
  }, [])

  function validateSaveName(): boolean {
    validateRequired(savedName, setSavedName)

    if (!validate())
      return false

    return [...savedName.errors].length === 0
  }

  function handleSaveFilter() {
    setSavedName({...savedName, touched: true})
    if (!validateSaveName())
      return


    (async () => {
      await save(savedName.value)
      close(false)
    })()
      .catch((e) => {logExceptionFromCatch(e); alert(e)})
  }


  return (
    <>
      <div className="form-group">
        <Input
          autoFocus={true}
          className="form-control"
          placeholder={namePlaceholder}
          field={savedName}
          setField={(f) => {setSavedName(f); validateRequired(f, setSavedName)}}
        />
      </div>
      <div className="filters-form-footer-btns">
        <button type="button" className="btn btn-primary" onClick={handleSaveFilter}>Save</button>
        <button type="button" className="btn btn-dark" onClick={() => close(true)}>Cancel</button>
      </div>
      <div className="filters-form-footer-text mt-6">
        <SavedItemsBlueIcon classname="filters-form-footer-icon" />
        Your {typeBeingSaved} will be saved in your “Saved Items”
      </div>
    </>
  )
}
