import {useState, FormEvent, useEffect, useRef} from 'react'
import { Session, User } from 'models'
import {CountryReportApi, UserApi} from 'apis'
import { Alert, MainContainer } from "components"
import {SessionUser} from 'App';
import {LoginMode} from 'apps/login/LoginHome';
import {CaretRightSmallIcon} from 'components/icons/CaretRightSmallIcon';
import {handleAsyncLoader} from 'lib';
import ReCAPTCHA from "react-google-recaptcha";

export const Login = (
  {
    setSessionUser,
    setMode,
    email,
    setEmail,
  }: {
    setSessionUser: (sessionUser: SessionUser) => void
    setMode: (val: LoginMode) => void
    email: string
    setEmail: (val: string) => void
  }) => {

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const [password, setPassword] = useState("")
  const [loginErrors, setLoginErrors] = useState<string[]>()
  const [loggingIn, setLoggingIn] = useState(false)
  const [requireRecaptcha, setRequireRecaptcha] = useState<'yes' | 'no' | 'loading'>('loading')
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('')

  async function recaptchaCheck() {
    const resp = await new UserApi().recaptchaCheck()
    setRecaptchaSiteKey(resp.recaptcha_site_key)
    setRequireRecaptcha(resp.recaptcha_required ? 'yes' : 'no')
  }


  useEffect( () => {
    handleAsyncLoader(
      () => {},
      e => setLoginErrors(e?.message ? [e?.message] : undefined),
      async () => {
        await recaptchaCheck()
    })()
  }, [])

  const doLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    handleAsyncLoader(
      l => {if (mounted) setLoggingIn(l)},
      e => {setLoginErrors(e?.message ? [e?.message] : undefined)},async () => {
        let success = false
        try {
          const session = await new UserApi().login({email, password, recaptcha_token: recaptchaToken})
          const user = await new UserApi(session.token).me()
          setSessionUser({ session, user })
          success = true
        } finally {
          if (!success)
            await recaptchaCheck()
        }
      }
    )()
  }

  return (
    <form onSubmit={doLogin}>
      <div className="form-group">
        <input className="form-control"
          type="text"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input className="form-control"
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
      </div>
      {loginErrors && loginErrors.map((error, i) => (
        <Alert key={i} type="danger" dismissable>{error}</Alert>
      ))}
      <div className="form-group d-flex align-items-center">
        <input className="btn btn-primary"
          type="submit"
          value="Sign In"
          disabled={loggingIn || requireRecaptcha === 'loading' || (requireRecaptcha === 'yes' && !recaptchaToken)}
        />
        <a href="#" onClick={e => {setMode('forgot-password'); e.preventDefault()}} className="ml-auto">Forgot password or don't have one?</a>&nbsp; <CaretRightSmallIcon />
      </div>
      {requireRecaptcha === 'yes' &&
        <div className="mt-2 align-center">
        <ReCAPTCHA
          sitekey={recaptchaSiteKey}
          onChange={setRecaptchaToken}
        />
        </div>
      }
    </form>
  )
}
