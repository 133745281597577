import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAppContext } from 'context'
import {CountryReportApi} from 'apis'
import {CountryReport, ErrorDetail} from 'models'
import {
  DropdownMenu,
  LoadingComponent,
  SectionedContent,
  SectionedSidebar,
  TabbedContainer,
  TabbedContainerTab
} from 'components'
import AddIcon from 'components/icons/AddIcon'

import { CountryReportDocument } from './components/CountryReportDocument'
import { CountryReportHistory } from './components/CountryReportHistory'
import {CountryReportTransactions} from './components/CountryReportTransactions';
import {CountryReportEntityTracker} from './components/CountryReportEntityTracker';
import {handleAsyncLoader} from 'lib';
import {CountryReportSidebarMapbox} from 'apps/countryreports/components/CountryReportSidebarMapbox';
import {SavedItemsApi} from 'apis/SavedItemsApi';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';
import {DropdownItem} from 'components/DropdownMenu';
import {TrashIcon} from 'components/icons/TrashIcon';
import {ModalSavedItemSuccessfully, SavedItemState} from 'components/ModalSavedItemSuccessfully';
import {PrintFooter} from 'components/PrintFooter';
import PrinterIcon from 'components/icons/PrinterIcon';
import EmailIcon from 'components/icons/EmailIcon';


interface CountryReportVm {
  rpt: CountryReport
  notify: 'saved' | 'removed' | 'email'
}


export function CountryReportView(
  {
    slug,
    backPath,
  }: {
    slug: string
    backPath: string
  }) {

  const { session } = useAppContext()

  const [countryReport, setCountryReport] = useState<CountryReportVm>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()
  const [savedItemState, setSavedItemState] = useState(SavedItemState.none)


  useEffect(handleAsyncLoader(
    setLoading,
    setError,async () => {
      const rpt = await new CountryReportApi(session.token).slug(slug)
      const notify = (await new SavedItemsApi(session.token).list('CountryReport')).saved_items.filter(si => si.saved_record_id === rpt.id)[0]?.notify
      setCountryReport({
        rpt,
        notify: typeof notify === 'undefined' ? 'removed' : notify ? 'email' : 'saved',
      })
    }), [slug, session.token])



  async function handleSaveEmailAlert() {
    try {
      const successState = typeof countryReport?.notify === 'undefined'
        ? SavedItemState.saved
        : SavedItemState.activated
      await new SavedItemsApi(session.token).upsert('CountryReport', [countryReport?.rpt.id ?? 0], true)
      setCountryReport(a => !!a ? ({...a, notify: 'email'}) : undefined)
      setSavedItemState(successState)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }

  async function handleSavedItems() {
    try {
      const successState = typeof countryReport?.notify === 'undefined'
        ? SavedItemState.saved
        : SavedItemState.deactivated
      await new SavedItemsApi(session.token).upsert('CountryReport', [countryReport?.rpt.id ?? 0], false)
      setCountryReport(a => !!a ? ({...a, notify: 'saved'}) : undefined)
      setSavedItemState(successState)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }



  async function handleDeleteSavedItem() {
    try {
      await new SavedItemsApi(session.token).destroy('CountryReport', countryReport?.rpt.id ?? 0)
      setCountryReport(a => !!a ? ({...a, notify: 'removed'}) : undefined)
      setSavedItemState(SavedItemState.deleted)
    }
    catch(e) {
      logExceptionFromCatch(e);
      alert(e)
    }
  }

  const items: DropdownItem[] = [
    {
      icon: <EmailIcon />,
      onClick: handleSaveEmailAlert,
      children: <>Activate Email Notifications</>,
      show: (cr: CountryReportVm) => cr.notify !== 'email',
    },
    {
      icon: !!countryReport && countryReport.notify === 'email' ? <EmailIcon /> : <AddIcon />,
      onClick: handleSavedItems,
      children: <>{!!countryReport && countryReport.notify === 'email' ? 'Deactivate Email Notifications' : 'Add to Saved Items'}</>,
      show: (cr: CountryReportVm) => cr.notify !== 'saved',
    },
    {
      icon:<TrashIcon />,
      onClick: handleDeleteSavedItem,
      children: <>Delete Saved Item</>,
      show: (cr: CountryReportVm) => cr.notify !== 'removed',
    },
    {
      icon:<div style={{height: '19px', width: '19px', marginLeft: '8px', marginRight: '8px', marginTop: '-8px'}}><PrinterIcon /></div>,
      onClick: window.print,
      children: <>Save to PDF</>,
      show: (cr: CountryReportVm) => true,
    },
  ]
    .filter(mi => !!countryReport && mi.show(countryReport))



  const view = countryReport ? (
    <>
      <div className="py-1">
        <div className="border-right">
          <SectionedContent className="content-left"
            title={countryReport.rpt.title}
            subtitle={(
              <div className="no-print d-flex w-100 align-items-center">
                <Link className="link"
                  to={backPath}
                >
                  Back to Country Reports
                </Link>
                <DropdownMenu className="ml-auto" items={items}/>
              </div>
            )}
           >
            <CountryReportDocument countryReport={countryReport.rpt} />
          </SectionedContent>
          <SectionedSidebar className="no-print content-sidebar">
            {/*<CountryReportSidebarMap countryReport={countryReport} />*/}
            <CountryReportSidebarMapbox countryReport={countryReport.rpt} />
            <TabbedContainer>
              <TabbedContainerTab title="History">
                <CountryReportHistory countryReport={countryReport.rpt} />
              </TabbedContainerTab>
              <TabbedContainerTab title="Transactions">
                <CountryReportTransactions countryReport={countryReport.rpt} />
              </TabbedContainerTab>
              <TabbedContainerTab title="Entity Tracker">
                <CountryReportEntityTracker countryReport={countryReport.rpt} />
              </TabbedContainerTab>
            </TabbedContainer>
          </SectionedSidebar>
        </div>
      </div>

      <PrintFooter
        type={'Country Reports'}
        updatedAt={countryReport.rpt.updated_at}
      />
    </>
  ) : (
    <>Error loading Country Report</>
  )

  return (
    <>
      <LoadingComponent loading={loading} error={error}>
        {view}
      </LoadingComponent>

      <ModalSavedItemSuccessfully
        state={savedItemState}
        itemTitle={countryReport?.rpt.title ?? ''}
        close={() => setSavedItemState(SavedItemState.none)}
      />
    </>
  )
}
