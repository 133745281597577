import { useState } from 'react'

import { CaretLeftIcon } from './icons/CaretLeftIcon'
import { CaretRightIcon } from './icons/CaretRightIcon'
import {CsvDownload} from 'components/CsvDownload';

const pageSizes = [25, 50, 100]

export const PagerControl = (
  {
    total,
    page,
    pageSize,
    setPageSize,
    setPage,
    sideBarIsNonPreview,
    csvUrl,
    style
  }: {
    total: number
    page: number
    pageSize: number
    setPageSize: (pageSize: number) => void
    setPage: (page: number) => void
    sideBarIsNonPreview?: boolean
    csvUrl?: string,
    style?: any
  }): JSX.Element => {


  const [inputtingPage, setInputtingPage] = useState(false)
  const [inputPage, setInputPage] = useState(`${page}`)

  const selected = (size: number) => pageSize === size ? 'selected' : ''
  const numPages = Math.ceil(total / pageSize)

  const toValidPage = (value: any): number => {
    const numValue = parseInt(value, 10)
    if (!numValue || numValue < 1) return 1
    if (numValue > numPages) return numPages
    return numValue
  }

  return (
    <div className={'pager-control d-flex justify-content-between align-items-center' + (!!sideBarIsNonPreview ? ' non-preview' : '')} style={style}>
      <div className="pager-left">
        <span className="pills">
          {pageSizes.map((size) => (
            <span key={size}
              className={`control pill ${selected(size)}`}
              onClick={() => setPageSize(size)}
            >
              {size}
            </span>
          ))}
        </span>
        <span className="d-none d-sm-inline">Items per page</span>
        <span className="d-sm-none">per pg</span>
      </div>
      <div className="d-none d-sm-block">
        {total.toLocaleString('en-US')} results
        {csvUrl && <CsvDownload url={csvUrl} />}
      </div>
      <div className="pager-right d-flex">
        { !inputtingPage &&
          <>
            <span className="control editable ml-auto"
              onClick={ () => {setInputPage(`${page}`); setInputtingPage(true)} }
            >
              { page } of { numPages }
            </span>        
            { page > 1 &&
              <span className="step left"
                onClick={() => setPage(page - 1)}
              >
                <CaretLeftIcon height="12" />
              </span>
            }
            { page < numPages &&
              <span className="step right"
                onClick={() => setPage(page + 1)}
              >
                <CaretRightIcon height="12" />
              </span>
            }
          </>  
        } { inputtingPage &&
          <>
            <input className="form-control ml-auto"
              autoFocus
              onChange={(e) => {
                setInputPage(e.target.value)
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setInputtingPage(false)
                  setPage(toValidPage(inputPage))
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Esc' || e.key === 'Escape') {
                  setInputtingPage(false)
                  setInputPage(`${page}`)
                }
              }}
              value={inputPage}
            /> of { numPages }
          </>
        }
      </div>
    </div>
  )
}
