import {Modal} from 'components/Modal';
import React from 'react';

export function ModalSavedSuccessfully(
  {
    close,
    title,
    subtitle,
    buttons,
  }: {
    close: () => void
    title: string
    subtitle: string
    buttons: React.ReactNode
  }
): JSX.Element {

  return (
    <Modal close={close}>
      <div className="modal-title mb-2">{title}</div>
      <div className="modal-text mb-6">{subtitle}</div>
      <div className="modal-btns d-flex justify-content-between">
        {buttons}
      </div>
    </Modal>
  )

}
