import {useEffect, useState} from 'react'
import {
  Switch,
  Route,
  RouteComponentProps, useLocation,
} from 'react-router-dom'

import {
  MainContainer,
  PageContainer
} from "components"

import { AlertHome } from './AlertHome'
import { AlertView } from './AlertView'

export enum AlertPaths {
  list = '/alerts/list',
  map = '/alerts/map',
  listFilters = '/alerts/list/filters',
  mapFilters = '/alerts/map/filters',
}

const HomePaths = [
  '/alerts', // assume map
  AlertPaths.list,
  AlertPaths.map,
  AlertPaths.listFilters,
  AlertPaths.mapFilters
]



export const Alerts = ({
  searchBar
}: {
  searchBar: JSX.Element
}): JSX.Element => {

  const location = useLocation()

  const [backPath, setBackPath] = useState(HomePaths.includes(location.pathname) ? location.pathname : AlertPaths.list)


  useEffect(() => {
    if (HomePaths.includes(location.pathname))
      setBackPath(location.pathname + location.search)
  }, [location.pathname, location.search])



  return (
    <PageContainer navItem="alerts" >
      <Switch>
        <Route exact path={HomePaths}>
          <AlertHome
            searchBar={searchBar}
          />
        </Route>
        <Route exact path="/alerts/:id"
          render={({match}: RouteComponentProps<{id: string}>) => (
            <MainContainer searchBar={searchBar} rightContentActive>
              <AlertView 
                id={match.params.id}
                backPath={backPath}
              /> 
            </MainContainer>
          )} 
        />
      </Switch>
    </PageContainer> 
  )
}
