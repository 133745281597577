export default function DashboardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
      <g className="icon-stroke" transform="translate(10 10)" fill="none" stroke="#707172" strokeMiterlimit="10" strokeWidth="1.5">
      <rect x="0.75" y="0.75" width="5.5" height="5.5" fill="none"/>
      </g>
      <g className="icon-stroke" data-name="Rectangle" fill="none" stroke="#707172" strokeMiterlimit="10" strokeWidth="1.5">
      <rect x="0.75" y="0.75" width="5.5" height="15.5" fill="none"/>
      </g>
      <g className="icon-stroke" data-name="Rectangle" transform="translate(10)" fill="none" stroke="#707172" strokeMiterlimit="10" strokeWidth="1.5">
      <rect x="0.75" y="0.75" width="5.5" height="5.5" fill="none"/>
      </g>
    </svg>
  )
}
