import {ReactNode} from 'react';
import {AppNavBar, NavKey} from 'components/AppNavBar';
import { AppNavBarContext } from 'context';


export function PageContainer(
  {
    navItem,
    children,
  }: {
    navItem: NavKey | '',
    children: ReactNode
  }) {



  return (
    <AppNavBarContext.Provider value={{toggleOpen: () => null}}>
      <div className="container-fluid">
        <AppNavBar current={navItem} />
        {children}
      </div>
    </AppNavBarContext.Provider>
  )
}
