import { createContext, useContext } from 'react'
import { User, Session } from 'models'

export type AppContent = {
  user: Readonly<User>
  session: Session
  logout: () => void
  updateUser: (user: Pick<User, 'accept_csv_terms'>) => void
}
export const AppContext = createContext<AppContent>({
  user: { loggedIn: false, accept_csv_terms: false },
  session: {success: false},
  logout: () => null,
  updateUser: () => null,
})

export const useAppContext = () => useContext(AppContext)
