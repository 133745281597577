import { Link } from 'react-router-dom'

import { buildQuery } from 'apis'

import { Transaction, TransactionFilterForm } from 'models'
import { qsToTranForm } from 'models/converters'

import {
  DateView,
  SectionedSidebar,
  SectionedSidebarContent,
  SectionedSidebarContentSection
} from 'components'

import { TranPaths } from 'apps/transactions/Transactions'

function tranCountrySearch(id: number) {
  const filter: TransactionFilterForm = {
    ...qsToTranForm(new URLSearchParams()),
    countryIds: [`${id}`]
  }

  return TranPaths.list + buildQuery(filter, true)
}

function tranCitySearch(id: number) {
  const filter: TransactionFilterForm = {
    ...qsToTranForm(new URLSearchParams()),
    cityIds: [`${id}`]
  }

  return TranPaths.list + buildQuery(filter, true)
}

export const TransactionSidebar = ({
  transaction
}: {
  transaction: Transaction
}): JSX.Element => (

  <SectionedSidebar>
    <SectionedSidebarContent title="Transaction Details">
      <SectionedSidebarContentSection>
        <div className="row">
          <div className="col">
            <div className="list-group-item-title">Start Date</div>
            {transaction?.start_date &&
              <DateView>{transaction.start_date}</DateView>
            }
          </div>
          <div className="col">
            <div className="list-group-item-title">End Date</div>
            {transaction?.end_date &&
              <DateView>{transaction.end_date}</DateView>
            }
          </div>
        </div>
      </SectionedSidebarContentSection>
      <SectionedSidebarContentSection title="Status">
        <div className={'' + (transaction?.status === 'Active' 
            ? 'dot dot-left active' : ''
        )}>
          {transaction?.status}
        </div>
      </SectionedSidebarContentSection>
      <SectionedSidebarContentSection title="Cities">
        {transaction?.cities && transaction.cities.map(c => (
           <div key={c.city.id}>
             <Link to={tranCitySearch(c.city.id)}>{c.city.name}</Link>
          </div>
        ))}
      </SectionedSidebarContentSection>
      <SectionedSidebarContentSection title="Countries">
        {transaction?.countries.map((c, i) =>
          <Link key={c.country.id} to={tranCountrySearch(c.country.id)}>
            {c.country.name}{i < transaction?.countries.length ? ' ' : ''}
          </Link>
        )}
      </SectionedSidebarContentSection>
      <SectionedSidebarContentSection title="Transaction Type">
        {transaction?.transaction_types && transaction?.transaction_types.map(t => (
          <div key={t.transaction_type.id}>{t.transaction_type.name}</div>
        ))}
      </SectionedSidebarContentSection>
      <SectionedSidebarContentSection title="Industry">
        {transaction?.industries && transaction?.industries.map(i => (
          <div key={i.industry.id}>{i.industry.name}</div>
        ))}
      </SectionedSidebarContentSection>
      <SectionedSidebarContentSection title="Project Value">
        <div>
          {transaction?.project_value ? 
            (+transaction?.project_value).toLocaleString() + ' USD' 
            : <>&nbsp;</>
          }
        </div>
      </SectionedSidebarContentSection>
    </SectionedSidebarContent>
  </SectionedSidebar>

)
