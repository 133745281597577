import React from 'react';
import ReactDOM from 'react-dom';
import './styles/style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { gaId } from 'Config'

// Load Google Tag Manager config
declare global {
  interface Window {
    dataLayer:any
  }
}
window.dataLayer = window.dataLayer || [];
window.gtag = function(...args: any){window.dataLayer.push(args);}
window.gtag('js', new Date());
window.gtag('config', gaId);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
