import {createContext, ReactNode, useContext} from 'react';

export type AppNavBarContent = {
  toggleOpen: () => void
}
export const AppNavBarContext = createContext<AppNavBarContent>({
  toggleOpen: () => null,
})

export const useAppNavBarContext = () => useContext(AppNavBarContext)

