import { Link } from "react-router-dom";

export interface SearchCityBarParamItem {
  cityName: string,
  to: string
}

export function SearchCityBar(
  {
    cities
  }:
    {
      cities: {
        cityName: string,
        to: string
      }[]
    }

): JSX.Element {

  if (cities.length === 0) {
    return <></>;
  }

  return (
    <>
      <Link to={cities[0].to} onClick={e => e.stopPropagation()}>{cities[0].cityName}</Link>
      {cities.length > 1 && <> and {cities.length - 1} more</>}
    </>
  );
}
