

export function NavDropdown<T extends string>(
  {
    title,
    value,
    setValue,
    options,
    className,
  } : {
    title: string
    value: T
    setValue: (val: T) => void
    options: [T, string][]
    className?: string
  }
): JSX.Element {
  return (
    <div className={'filters-header-item' + (!!className ? ' ' + className : '')}>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">{title}</span>
        </div>
        <select
          className="custom-select"
          value={value}
          onChange={(e) => setValue(e.target.value as T)}
        >
          {
            options.map((o, i) => <option value={o[0]} key={i}>{o[1]}</option>)
          }
        </select>
      </div>
    </div>
  )
}
