
import {TranFilterCategory, TransactionFilterForm} from 'models';




export function calcTranCategories(filter: TransactionFilterForm): TranFilterCategory[] {

  const categories = [
    ((filter.companyIds.length !== 0
      || filter.includeSubsidiaries
      || filter.includeOwners) ? TranFilterCategory.Company : null),

    ((filter.industryIds.length !== 0
      || filter.transactionTypeIds.length !== 0
      || filter.minTranValue !== ''
      || filter.maxTranValue !== '') ? TranFilterCategory.Transaction : null),


    ((filter.cityIds.length !== 0
      || filter.countryIds.length !== 0
      || filter.regionIds.length !== 0
      || filter.continentIds.length !== 0) ? TranFilterCategory.Location : null),

    ((filter.keywords !== '') ? TranFilterCategory.Keywords : null),

    ((filter.countryRiskDesignationIds.length !== 0
      || filter.entityRiskDesignationIds.length !== 0
      || filter.personRiskDesignationIds.length !== 0
      || filter.taggedTransactionsIds.length !== 0) ? TranFilterCategory.Risk : null),

    ((filter.pending !== ''
      || filter.cancelled !== ''
      || filter.startDate !== ''
      || filter.endDate !== ''
      || filter.dateRangeActive !== ''
      || filter.modifiedWithin !== '') ? TranFilterCategory.Date : null)
  ]
    .filter(c => c !== null) as TranFilterCategory[]

  return categories

}






export function countTranFilters(filter: TransactionFilterForm) : number {
  return calcTranCategories(filter).length
}



