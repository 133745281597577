import {LoadingComponent} from 'components';
import {EntitySidebar} from 'apps/entityrisktracker/components/EntitySidebar';
import {useEffect, useState} from 'react';
import {Entity, ErrorDetail} from 'models';
import {handleAsyncLoader} from 'lib';
import {EntityRiskTrackerApi} from 'apis';
import {useAppContext} from 'context';

export function TransactionEntitySidebar(
  {
    entityId
  } : {
    entityId: number
  }
): JSX.Element {

  const { session } = useAppContext()

  const [previewEntity, setPreviewEntity] = useState<Entity>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()

  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setPreviewEntity(undefined)

      if (entityId) {
        const entity = await new EntityRiskTrackerApi(session.token).entity(entityId)
        setPreviewEntity(entity)
      }
    }), [entityId, session.token])

  return (
    <LoadingComponent loading={loading} error={error}>
      {previewEntity &&
      <EntitySidebar
        entity={previewEntity}
      />
      }
    </LoadingComponent>
  )
}
