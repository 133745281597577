import {useState, useEffect, useMemo} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import { gaId } from 'Config'

import { AppContext } from 'context'
import {User, Session, ErrorDetail} from 'models'
import { UserApi } from 'apis'
import {
  LoadingComponent,
  MainContainer,
  PageContainer,
  SearchBar
} from 'components'

import { Alerts } from 'apps/alerts/Alerts'
import { CountryReports } from 'apps/countryreports/CountryReports'
import { Dashboard } from 'apps/dashboard/Dashboard'
import { EntityRiskTracker } from 'apps/entityrisktracker/EntityRiskTracker'
import { SavedItems } from 'apps/saveditems/SavedItems'
import { Search } from 'apps/search/Search'
import { Transactions } from 'apps/transactions/Transactions'
import {convertError} from 'lib/HandleAsyncLoader';
import {ModalBackdrop} from 'components/ModalBackdrop';
import {LoginHome} from 'apps/login/LoginHome';
import {ResetPassword} from 'apps/login/ResetPassword';
import {GoogleAnalyticsPageViews} from 'components/GoogleAnalyticsPageViews';
import {useGaGlobalErrorHandler} from 'hooks';
import {logExceptionFromCatch} from 'hooks/GoogleAnalytics';
import logo from 'images/logo.svg'
import {Profile} from 'apps/profile/Profile';
import {SearchBarState, SearchTypes} from 'components/SearchBar';
import {AppContent} from 'context/AppContext';

export interface SessionUser {
  session: Session
  user: User
}

export default function App() {

  useGaGlobalErrorHandler()

  const [session, setSession] = useState<Session | null>(null)
  const [user, setUser] = useState<Readonly<User>>({loggedIn: false, accept_csv_terms: false})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<ErrorDetail>()
  const [searchBarState, setSearchBarState] = useState<SearchBarState>({
    searchText: '',
    searchType: SearchTypes.filter(f => f.type === 'all')[0]
  })

  const context = useMemo<AppContent>(() => ({ user, session: session ?? {success: false}, logout, updateUser }), [user, session])

  useEffect(() => {
    const loadSession = async () => {
      try {
        const storedSession = localStorage.getItem('session')
        if (storedSession) {
          const session = JSON.parse(storedSession) as Session
          if (session && session.exp && session.exp > Date.now() / 1000) {
            setSession(session);
            const user = await new UserApi(session.token).me()
            if (user.loggedIn) {
              setUser(user)
            } else {
              logout()
            }
          }
        }
      } catch (e) {
        logExceptionFromCatch(e)
        const err = convertError(e)
        if (err.is401)
          logout()
        else
          setError(err)
      }

      setLoading(false);
    }
    loadSession()
  }, [])

  const setSessionUser = ({session, user}: SessionUser) => {
    localStorage.setItem('session', JSON.stringify(session))
    setSession(session)
    setUser(user)
    setError(undefined)
  }

  function logout() {
    localStorage.removeItem('session')
    setSession(null)
    setUser({loggedIn: false, accept_csv_terms: false})
    setError(undefined)
  }

  function updateUser(user: Pick<User, 'accept_csv_terms'>) {
    setUser(u => ({
      ...u,
      accept_csv_terms: user.accept_csv_terms
    }))
  }
  
  const searchBar = (
    <SearchBar 
      searchBarState={searchBarState} 
      setSearchBarState={s => {
        setSearchBarState((p) => ({...p, ...s}))
      }} 
    />
  )

  return (
    <div className='print-margins'>
      <div className='print-border'></div>
      <div className='print-page-header'>
        <img src={logo} alt="Janes IntelTrak" />
      </div>
      <ModalBackdrop>
        { user && session ? (
          <AppContext.Provider value={context}>
            <Router>
              <Switch>
                <Route path="/countryreports">
                  <CountryReports searchBar={searchBar} />
                </Route>
                <Route path="/transactions">
                  <Transactions searchBar={searchBar} />
                </Route>
                <Route path="/alerts">
                  <Alerts searchBar={searchBar} />
                </Route>
                <Route path="/entityrisktracker">
                  <EntityRiskTracker searchBar={searchBar} />
                </Route>
                <Route path="/search">
                  <Search searchBar={searchBar} />
                </Route>
                <Route path="/saveditems">
                  <SavedItems searchBar={searchBar} />
                </Route>
                <Route path="/profile">
                  <Profile searchBar={searchBar} />
                </Route>
                <Route path="/resetpassword">
                  <Redirect to="/" />
                </Route>
                <Route exact path="/">
                  <Dashboard searchBar={searchBar} />
                </Route>
                <Route path="*">
                  <PageContainer navItem=''>
                    <MainContainer searchBar={searchBar} >
                      <p>Not Found</p>
                    </MainContainer>
                  </PageContainer>
                </Route>
              </Switch>
              <GoogleAnalyticsPageViews trackingId={gaId} />
            </Router>
          </AppContext.Provider>
        ) : (

          <Router>
            <Switch>
              <Route path="/resetpassword">
                <ResetPassword />
              </Route>
              <Route path="*">
                <LoadingComponent loading={loading} error={error}>
                  <LoginHome setSessionUser={setSessionUser} />
                </LoadingComponent>
              </Route>
            </Switch>
            <GoogleAnalyticsPageViews trackingId={gaId} />
          </Router>
          )}
      </ModalBackdrop>
    </div>
  )
}
