const OnlyLinkRegex = /^(https?):\/\/[^\s$.?#].[^\s]*$/

export function EntityRiskExampleCitation({
  className,
  citation
}: {
  className?: string
  citation: string
}): JSX.Element {

  let content = citation ? citation.trim() : ''

  // If content is only a link, embed in anchor tag
  if (OnlyLinkRegex.test(content)) {
    content = `<a target="_blank" href="${content}">${content}</a>`
  } 

  return (
    <div className={`EntityRiskExampleCitation ${className}`} 
      dangerouslySetInnerHTML={ { __html: content } } 
    />
  )
}