import {SearchBar} from 'components/SearchBar';

interface MainContainerProps {
  className?: string
  fullscreen?: boolean
  rightSidebarActive?: boolean
  rightContentActive?: boolean
  searchBar?: JSX.Element
  pager?: JSX.Element
  children: JSX.Element|JSX.Element[]|string
}

const defaultClass = "main main-with-left-sidebar"
const fullscreenClass = ""
const withRightSidebarClass = "main-with-right-sidebar"
const withRightContentClass = "main-with-right-content-sidebar"
const withPagerClass = "main-with-pager"

const contentStyle = {
  display: 'flex', 
  flexFlow: 'column'
}

export function MainContainer({
  className,
  fullscreen,
  rightSidebarActive,
  rightContentActive,
  searchBar,
  pager,
  children
}: MainContainerProps) {

  let mainClassName = className || ''
  mainClassName += fullscreen ? fullscreenClass : defaultClass
  if (rightSidebarActive) mainClassName += ' ' + withRightSidebarClass
  if (rightContentActive) mainClassName += ' ' + withRightContentClass
  if (pager) mainClassName += ' ' + withPagerClass

  const contentClass = fullscreen ? 'content-fullscreen' : 'content'
  const pagerClass = pager ? 'content-with-pager' : ''

  return (
    <main 
      role="main" 
      className={mainClassName}>
      {searchBar}
      <div className="main-container">
        <div className={`${contentClass} ${pagerClass}`} style={contentStyle}>
           {children}
        </div>
      </div>
      {pager}
    </main>
  )
}
