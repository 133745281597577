import { ReactNode } from 'react'


export const FilterForm = ({
  className,
  children
}: {
  className?: string
  children: ReactNode
}): JSX.Element => {

  return (
    <form className={`filters-form ${className ? className : ''}`}>
      <div className="accordion">
        {children}
      </div>
    </form>
  )

}
