import {CaretLeftSmallIcon} from 'components/icons/CaretLeftSmallIcon';
import {CaretRightSmallIcon} from 'components/icons/CaretRightSmallIcon';

export function TransactionYearSlider(
  {
    year,
    setYear,
    isFiltersTab,
  } : {
    year: number | undefined
    setYear: (val: number | undefined) => void
    isFiltersTab: boolean
  }
): JSX.Element {

  const yearVm: number | 'All' = year ?? 'All'

  const currentYear = new Date().getFullYear()
  const yearOptions: (number | 'All')[] = [...Array.from(Array(10).keys()).map(i => i + currentYear - 10 + 1), 'All']



  function handlePrevYear() {
    if (yearVm === 'All') {
      setYear(currentYear)
      return
    }
    if (yearVm === yearOptions[0])
      return;

    let newYear = yearVm - 1

    setYear(newYear)
  }
  function handleNextYear() {
    if (yearVm === 'All')
      return
    setYear(yearVm === currentYear ? undefined : yearVm + 1)
  }

  return (
    <>
      <div className="form-group d-sm-none pt-1 px-1 mb-0">
        <select className="custom-select w-100" value={yearVm} onChange={(e) => {setYear(e.target.value === 'All' ? undefined : parseInt(e.target.value));e.stopPropagation();e.preventDefault()}}>
          {yearOptions.map(yr => <option value={yr}>{yr}</option> )}
        </select>
      </div>
      <div className="narrow-years-range-year">{yearVm}</div>
      <div className={'years-range ' + (isFiltersTab ? 'filters' : '')}>
        <div style={{width: '100%'}} className="d-flex flex-wrap">
          <div className="years-range-year">{yearVm}</div>
          <div className="years-range-list">
            <a
              className="years-range-prev"
              onClick={(e) => {handlePrevYear();e.stopPropagation();e.preventDefault()}}
              href='#'
            >
              <CaretLeftSmallIcon />
            </a>
            {yearOptions.map(yr =>
              <a
                key={yr}
                className={'years-range-item' + (yr === yearVm ? ' active' : '')}
                onClick={(e) => {setYear(yr === 'All' ? undefined : yr);e.stopPropagation();e.preventDefault()}}
                href='#'
              >
                {yr}
              </a>
            )}
            <a
              className="years-range-next"
              onClick={(e) => {handleNextYear();e.stopPropagation();e.preventDefault()}}
              href='#'
            >
              <CaretRightSmallIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
