import { useState } from 'react'

interface AlertParams {
  type: string
  dismissable?: boolean
  children: JSX.Element[]|JSX.Element|string
}

export const Alert = ({
  type,
  dismissable,
  children
}: AlertParams) => {

  const [closed, setClosed] = useState<boolean>(false)

  return (
    <>
      {!closed &&
        <div className={`alert alert-${type}`} role="alert">
          {children}
          {dismissable && 
            <button onClick={() => setClosed(true)} 
              type="button" 
              className="close" 
              aria-label="Close"
             >
              <span aria-hidden="true">&times;</span>
            </button>
          }
        </div>
      }
    </>
  )
}