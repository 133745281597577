import { AlertsSearchItem } from 'models'
import { HighlightText } from 'components'

import { AlertInfoBox } from './AlertInfoBox'
import {Link} from 'react-router-dom';

export const AlertTable = ({
  alerts,
  selectedId,
  setSelectedId,
  className,
}: {
  alerts: AlertsSearchItem[]
  selectedId?: number,
  setSelectedId: (id: number) => void,
  className?: string
}): JSX.Element => {

  return  (
    <div className={`table-responsive list-details-table ${className || ''}`}>
      <table className="table table-large table-alignment-top table-hover">
        <colgroup>
          <col style={{width: '320px'}} />
        </colgroup>
        <tbody>
        {alerts.map(alert => {
          return (
            <tr key={alert.id}
                className={selectedId === alert.id ? 'active' : ''}
                onClick={() => setSelectedId(alert.id)}
            >
              <td className="col-name d-none d-md-table-cell">
                <Link to={`/alerts/${alert.id}`}
                        onClick={e => e.stopPropagation()}
                  >
                    {alert.title}
                  </Link>
              </td>
              <td>
                <Link
                  to={'/transactions/' + alert.id}
                  onClick={e => e.stopPropagation()}
                  className="d-md-none"
                >
                  {alert.title}
                </Link>
                <AlertInfoBox alert={alert} />
                <HighlightText text={alert.comment} />
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
    </div>
  )
}
