import { useState } from 'react'
import { Link } from "react-router-dom"

import {Entity, EntityRiskResult} from 'models'

import { IconRiskAll, IconRiskCompany, IconRiskCompanySubsidiary, IconRiskDistant, IconRiskParent, IconRiskParentCompany, IconRiskParentSubsidiary, IconRiskSubsidiary } from './icons'

import './EntityTable.scss'
import { InfoIcon } from 'components/icons/InfoIcon';
import { Modal, HighlightText } from 'components';

interface EntityRiskIconParams {
  entity: Entity,
  risk: EntityRiskResult
}

interface EntityTableParams {
  className?: string
  onSelect?: (id: number, title: string) => void,
  entities: Entity[]
  risks: EntityRiskResult[]
  previewId?: number
  compact?: boolean
}

interface EntityTableRowParams {
  entity: Entity
  onSelect?: (id: number, title: string) => void
  risks: EntityRiskResult[]
  previewId?: number
  onRiskIndexHover: (index: number) => void
}

export function EntityRiskIcon({
  entity,
  risk
}: EntityRiskIconParams) {
  var status = 0;
  status = status | (entity.self_entity_risk_ids.includes(risk.id) ? 1 : 0)
  status = status | (entity.owner_entity_risk_ids.includes(risk.id) ? 2 : 0)
  status = status | (entity.subsidiary_entity_risk_ids.includes(risk.id) ? 4 : 0)
  if(status === 0) {
    status = status | (entity.distant_entity_risk_ids.includes(risk.id) ? 8 : 0)
  }
  switch(status) {
    case 1:
      return(<IconRiskCompany />)
      break;
    case 2:
      return(<IconRiskParent />)
      break;
    case 3:
      return(<IconRiskParentCompany />)
      break;
    case 4:
      return(<IconRiskSubsidiary />)
      break;
    case 5:
      return(<IconRiskCompanySubsidiary />)
     break;
    case 6:
      return(<IconRiskParentSubsidiary />)
      break;
    case 7:
      return(<IconRiskAll />)
      break;
    case 8:
      return(<IconRiskDistant />)
      break;
    default:
      return(<></>)
  }
}

export function EntityTableRow({
  entity,
  onSelect,
  risks,
  previewId,
  onRiskIndexHover
}: EntityTableRowParams) {

  return (
    <tr
      className={previewId === entity.id ? 'active' : ''}
      onClick={(e) => {onSelect && onSelect(entity.id, entity.name); e.stopPropagation()}}
    >
      <td className="col-name" key={entity.id}>
        <Link
          to={`/entityrisktracker/${entity.id}`}
          onClick={e => e.stopPropagation()}
        >
          {(entity.highlight && entity.highlight.field_name == 'name') ? <HighlightText text={entity.highlight.highlight} /> : entity.name}
        </Link>
        {entity.highlight && (entity.highlight.field_name == 'entity_risk_summary' || entity.highlight.field_name == 'entity_risk_examples') &&
          <span className="excerpt">
            <span className="label">{entity.highlight.field_name == 'entity_risk_summary' ? 'Risk Summary: ' : 'Risk Event: '}</span>
            <HighlightText text={entity.highlight.highlight} />
          </span>
        }
      </td>
      {risks.map((risk, index) =>
        <td
          key={risk.id}
          className="text-center"
          onMouseEnter={() => onRiskIndexHover(index)}
        >
        <EntityRiskIcon
          entity={entity}
          risk={risk}
        />
        </td>
      )}
      <td className="col-score">
        <span className={`${entity.score_inherited ? "" : "text-primary"}`}>
          {entity.score}
        </span>
      </td>
    </tr>
  )
}

export function EntityTable({
  className,
  onSelect,
  entities,
  risks,
  previewId,
  compact
}: EntityTableParams) {

  const [riskIndexHover, setRiskIndexHover] = useState(0)
  const [riskExposureModalOpen, setRiskExposureModalOpen] = useState(false)

  const isCompact = compact || previewId

  function handleOpenRiskExposureInfo() {
    setRiskExposureModalOpen(true)
  }

  return (
    <div className={`EntityTable table-responsive ${className || ''}`}>
      <table className="table table-large table-hover table-type-1">
        <colgroup className={isCompact ? 'compact' : ''}>
          <col className="name"/>
          {risks.map((risk) =>
            <col key={risk.id} className="risk" />
          )}
          <col className="risk-score" />
        </colgroup>
        <thead className="thead-light">
          <tr>
            <th>Company</th>
            <th colSpan={risks.length}><div className="d-inline-flex flex-row align-items-center clickable" onClick={handleOpenRiskExposureInfo}><span className="mr-1">Risk Exposure</span> <InfoIcon heightPx={20} widthPx={20} /></div> </th>
            <th>Score</th>
          </tr>
          <tr className={isCompact ?  'd-none' : `d-none d-xl-table-row` }>
            <th></th>
            {risks.map((risk) =>
              <th className="smaller" key={risk.id}>{risk.name}</th>
            )}
            <th></th>
          </tr>
          <tr className={isCompact ? 'd-table-row' : `d-table-row d-xl-none`}>
            <th className="no-border"></th>
            <th colSpan={risks.length} className="no-border">
              {risks.length > 0 && risks[riskIndexHover].name }
            </th>
            <th className="no-border"></th>
          </tr>
          <tr className={isCompact ? 'd-table-row' : `d-table-row d-xl-none`}>
            <th className="p-0"></th>
            {risks.map((risk, index) =>
              <th key={risk.id}
                className={`p-0 ${riskIndexHover === index ? 'active-border' : ''}`}
              />
            )}
            <th className="p-0"></th>
          </tr>
        </thead>
        <tbody>
        {entities.map((entity) =>
          <EntityTableRow
            entity={entity}
            onSelect={onSelect}
            risks={risks}
            previewId={previewId}
            key={entity.id}
            onRiskIndexHover={setRiskIndexHover}
          />
        )}
        </tbody>
      </table>
      {riskExposureModalOpen &&
      <Modal close={() => setRiskExposureModalOpen(false)} showCloseButton={true}>
        <h3>Risk Exposure Glossary</h3>
        <p className="mb-5">
          These categories represent umbrella categories of reputational risk that incorporate various sub-types of risk.
        </p>
        <div className="d-flex flex-column">
          <div className="d-flex mb-4">
            <div className="mt-1 mb-2 mr-5"><IconRiskCompany /></div>
            <div className="mr-auto">Company is directly associated with this risk category.</div>
          </div>
          <div className="d-flex mb-4">
            <div className="mt-1 mb-2 mr-5"><IconRiskParent /></div>
            <div className="mr-auto">Company is associated with this risk category via a parent company.</div>
          </div>
          <div className="d-flex mb-4">
            <div className="mt-1 mb-2 mr-5"><IconRiskSubsidiary /></div>
            <div className="mr-auto">Company is associated with this risk category via a subsidiary company.</div>
          </div>
          <div className="d-flex mb-4">
            <div className="mt-1 mb-2 mr-5"><IconRiskDistant  /></div>
            <div className="mr-auto">Company is associated with this risk category via a sibling or cousin in its corporate family tree.</div>
          </div>
        </div>
      </Modal>
      }
    </div>
  )
}
