import EmailIcon from 'components/icons/EmailIcon';
import AddIcon from 'components/icons/AddIcon';
import DeselectIcon from 'components/icons/DeselectIcon';
import {TableSelect} from 'components/table/TableSelect';
import {Link} from 'react-router-dom';
import {DateView, DropdownMenu} from 'components';
import {CountryReportsSearchItemVm, SaveNameModalVm} from 'apps/countryreports/components/CountryReportTable';
import {SearchesApi} from 'apis';
import {CountryRptSavedItemReq} from 'models';
import {useAppContext} from 'context';

export function CountryReportTableRow(
  {
    countryReportVm,
    setCountryReportId,
    previewId,
    setSelected,
    saveNameModalVm,
    setSaveNameModalVm,
  }:
    {
      countryReportVm: CountryReportsSearchItemVm
      setCountryReportId: (id: number) => void
      previewId?: number,
      setSelected: (val: boolean) => void,
      saveNameModalVm?: SaveNameModalVm
      setSaveNameModalVm: (val: SaveNameModalVm | undefined) => void
    }
) {

  const { session } = useAppContext()

  async function saveItem(name: string, ids: number[], notify: boolean) {
    await new SearchesApi(session.token).create<CountryRptSavedItemReq>({
      'search[name]': name,
      'search[notify]': notify ? '1' : undefined,
      'search[filters][country_reports][country_ids][]': ids,
    })
  }

  async function handleSaveEmailAlert() {
    setSaveNameModalVm({
      saveCode: async name => saveItem(name, [countryReportVm.id], true),
      isNotify: true,
      defaultName: countryReportVm.title
    })
  }

  function handleSavedItems() {
    setSaveNameModalVm({
      saveCode: async name => saveItem(name, [countryReportVm.id], false),
      isNotify: false,
      defaultName: countryReportVm.title
    })
  }

  const dropdownItems = [
    {
      icon: <EmailIcon />,
      onClick: handleSaveEmailAlert,
      children: <>Activate Email Notifications</>
    },
    {
      icon:<AddIcon />,
      onClick: handleSavedItems,
      children: <>Add to Saved Items</>
    },
    {
      icon: <DeselectIcon />,
      onClick: () => setSelected(!countryReportVm.selected),
      children: <>{countryReportVm.selected ? 'Deselect' : 'Select'}</>
    }
  ]

  return (
    <tr key={countryReportVm.id}
        className={previewId === countryReportVm.id ? 'active' : ''}
        onClick={(e) => {setCountryReportId(countryReportVm.id); e.stopPropagation()}}
    >
      <td className="col-checkbox">
        <TableSelect checked={countryReportVm.selected}
                     id={countryReportVm.id}
                     onChange={() => setSelected(!countryReportVm.selected)}
        />
      </td>
      <td className="col-name">
        <Link
          to={`/countryreports/${countryReportVm.slug}`}
          onClick={e => e.stopPropagation()}
        >
          {countryReportVm.title}
        </Link>
      </td>
      <td className="col-date" style={{whiteSpace: 'nowrap'}}>
        <DateView type="date">{countryReportVm.publication_date}</DateView>
      </td>
      <td className="col-actions">
        <DropdownMenu items={dropdownItems} />
      </td>
    </tr>
  )
}
