import { useState, useEffect } from 'react'
import {DropdownMenu, Modal} from 'components'
import AddIcon from 'components/icons/AddIcon'
import DeselectIcon from 'components/icons/DeselectIcon'
import EmailIcon from 'components/icons/EmailIcon'
import {CountryReportsSearchItem, CountryRptSavedItemReq} from 'models';
import {CountryReportTableSection} from 'apps/countryreports/components/CountryReportTableSection';
import {SaveNameForm} from 'components/filterform/SaveNameForm';
import {SearchesApi} from 'apis';
import {useAppContext} from 'context';

export interface CountryReportsSearchItemVm extends CountryReportsSearchItem {
  selected: boolean,
}

export interface CountryReportTableSectionVm {
  "title": string,
  "reports": CountryReportsSearchItemVm[]
}

export interface SaveNameModalVm {
  saveCode: (name: string) => Promise<void>
  isNotify: boolean
  defaultName?: string
}



export function CountryReportTable(
  {
    className,
    setCountryReportId,
    countryReports,
    previewId,
    browseBy
  }:
    {
      className?: string
      setCountryReportId: (id: number) => void
      countryReports: CountryReportsSearchItem[]
      previewId?: number,
      browseBy: string
    }
) {


  const { session } = useAppContext()

  function createTableSections(browseBy: string) {
    let tableSections: Array<CountryReportTableSectionVm> = [];
    let titles: Array<string> = [];
    if(countryReports.length > 0) {
      switch(browseBy) {
        case 'Country':
          titles = countryReports.map(c => { return c.country.name })
          break;
        case 'Region':
          titles = countryReports.map(c => { return c.region.name })
          break;
        case 'Continent':
          titles = countryReports.map(c => { return c.continent.name })
          break;
        default:
          titles = countryReports.map(c => { return c.title.trim().substr(0,1).toUpperCase() })
      }
      // Unique titles, sorted
      titles = titles.filter((val,i, s) => { return s.indexOf(val) === i }).sort()
      for(let title of titles) {
        var sectionReports: CountryReportsSearchItemVm[]
        switch(browseBy) {
          case 'Country':
            sectionReports = countryReports.filter(c => { return c.country.name === title }).map(r => ({ ...r, selected: false }))
            break;
          case 'Region':
            sectionReports = countryReports.filter(c => { return c.region.name === title }).map(r => ({ ...r, selected: false }))
            break;
          case 'Continent':
            sectionReports = countryReports.filter(c => { return c.continent.name === title }).map(r => ({ ...r, selected: false }))
            break;
          default:
            sectionReports = countryReports.filter(c => { return c.title.trim().substr(0,1).toUpperCase() === title }).map(r => ({ ...r, selected: false }))
        }
        // Sort reports within each section by title
        sectionReports.sort((a, b) => (a.title < b.title ? -1 : 1))
        let section = {
          title: title,
          reports: sectionReports
        }
        tableSections.push(section)
      }
    }
    return tableSections
  }

  const [saveNameModalVm, setSaveNameModalVm] = useState<SaveNameModalVm>()

  const [countryReportTableSectionVms, setCountryReportTableSectionVms] = useState<CountryReportTableSectionVm[]>([])
  useEffect(() => setCountryReportTableSectionVms(createTableSections(browseBy)),[browseBy])

  const selectedState = countryReportTableSectionVms.every(section => section.reports.every(r => r.selected))
    ? 'all'
    : countryReportTableSectionVms.every(section => section.reports.every(r => !r.selected))
      ? 'none'
      : 'some'

  function setSelected(id: number, selected: boolean) {
    let sections = countryReportTableSectionVms.map((x) => Object.assign({},x))
    sections.forEach(section => {
      section.reports.forEach(report => {
        if(report.id === id) {
          report.selected = selected
        }
      })
    })
    setCountryReportTableSectionVms(sections)
  }

  function setAllSelected(selected: boolean) {
    let sections = countryReportTableSectionVms.map((x) => Object.assign({},x))
    countryReportTableSectionVms.forEach(section => {
      section.reports.forEach(report => {
        report.selected = selected
      })
    })
    setCountryReportTableSectionVms(sections)
  }

  function buildIds(filter: 'all' | 'selected'): number[] {
    return countryReportTableSectionVms.flatMap(
      s => s.reports
        .filter(r => r.selected || filter === 'all')
        .map(r => r.id)
    )
  }


  async function saveItem(name: string, ids: number[], notify: boolean) {
    await new SearchesApi(session.token).create<CountryRptSavedItemReq>({
      'search[name]': name,
      'search[notify]': notify ? '1' : undefined,
      'search[filters][country_reports][country_ids][]': ids,
    })
  }

  function findCountryName(id: number) : string {
    return countryReportTableSectionVms.flatMap(
      s => s.reports
        .filter(r => r.id === id)
        .map(r => r.title)
    )[0]
  }


  async function handleSaveEmailAlert(filter: 'all' | 'selected') {
    const ids = buildIds(filter)

    setSaveNameModalVm({
      saveCode: async name => saveItem(name, ids, true),
      isNotify: true,
      defaultName: ids.length === 1 ? findCountryName(ids[0]) : 'Country Reports'
    })
  }

  function handleSavedItems(filter: 'all' | 'selected') {
    const ids = buildIds(filter)

    setSaveNameModalVm({
      saveCode: async name => saveItem(name, ids, false),
      isNotify: false,
      defaultName: ids.length === 1 ? findCountryName(ids[0]) : 'Country Reports'
    })
  }

  const dropdownItems = [
    {
      icon: <EmailIcon />,
      onClick: () => handleSaveEmailAlert(selectedState === 'none' ? 'all' : 'selected'),
      children: <>Activate Email Notifications for {selectedState === 'none' ? 'All' : 'Selected'}</>
    },
    {
      icon:<AddIcon />,
      onClick: () => handleSavedItems(selectedState === 'none' ? 'all' : 'selected'),
      children: <>Add {selectedState === 'none' ? 'All' : 'Selected'} to Saved Items</>
    },
    {
      icon: <DeselectIcon />,
      onClick: () => setAllSelected(selectedState !== 'all'),
      children: <>{selectedState === 'all' ? 'Deselect All' : 'Select All'}</>
    }
  ]

  return (
    <>
      <div className={`table-responsive-lg ${className || ''}`}>
        <table className="table table-hover mt-4">
          <colgroup>
            <col style={{width: '30px'}} />
            <col />
            <col style={{width: '150px'}} />
            <col />
          </colgroup>
          <thead className="thead-light">
            <tr>
              <th></th>
              <th>{browseBy}</th>
              <th>Last Update</th>
              <th className="col-actions">
                <DropdownMenu items={dropdownItems} />
              </th>
            </tr>
          </thead>
          <tbody>
            {countryReportTableSectionVms.map((section) =>
              <CountryReportTableSection
                key={section.title}
                section={section}
                setCountryReportId={setCountryReportId}
                previewId={previewId}
                setSelected={setSelected}
                saveNameModalVm={saveNameModalVm}
                setSaveNameModalVm={setSaveNameModalVm}
              />
            )}
          </tbody>
        </table>
      </div>
      {saveNameModalVm &&
      <Modal close={() => setSaveNameModalVm(undefined)}>
        <SaveNameForm
          defaultName={saveNameModalVm.defaultName}
          namePlaceholder={`Name your ${saveNameModalVm.isNotify ? 'Country Report Alert' : 'Country Report Filter'}`}
          typeBeingSaved={saveNameModalVm.isNotify ? 'Country Report Alert' : 'Country Report Filter'}
          validate={() => true}
          save={saveNameModalVm.saveCode}
          close={() => setSaveNameModalVm(undefined)}
        />
      </Modal>
      }
    </>
  )
}
