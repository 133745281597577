export function ResetXIcon(): JSX.Element {
  return (<svg xmlns="http://www.w3.org/2000/svg" width="12.847" height="12.944" viewBox="0 0 12.847 12.944">
    <g transform="translate(0.826 1.414)">
      <path d="M.588,10.115,10.607,0" fill="none" stroke="#9d9ea0" strokeLinecap="square"
            strokeMiterlimit="10" strokeWidth="2"></path>
      <path d="M0,0,9.588,9.685" transform="translate(1 0.43)" fill="none" stroke="#9d9ea0"
            strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2"></path>
    </g>
  </svg>)
}
