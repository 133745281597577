import {LoadingComponent, Sidebar, SidebarContent} from 'components';
import {useAppContext} from 'context';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {CountryReport, ErrorDetail} from 'models';
import {CountryReportApi} from 'apis';
import {CountryReportDocument} from 'apps/countryreports/components/CountryReportDocument';
import {handleAsyncLoader} from 'lib';

export function SearchCountrySidebar(
  {
    id,
    onClose,
    title,
  }: {
    id: number,
    onClose: () => void,
    title: string,
  }
): JSX.Element {

  const { session } = useAppContext()

  const [countryReport, setCountryReport] = useState<CountryReport>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()


  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      setCountryReport(await new CountryReportApi(session.token).id(id))
    }), [id, session.token])


  const nav = loading || !!error ? undefined : (
    <Link className="btn btn-gray text-primary btn-block" to={`/countryreports/${countryReport?.slug}`}>
      View Full Country Report
    </Link>
  );


  useEffect(() => {
    setCountryReport(undefined);
  }, [id])

  return (
    <Sidebar onClose={onClose}
      header={countryReport?.title ?? title}
      nav={nav}
    >
      <LoadingComponent loading={loading} error={error}>
        <SidebarContent>
          <div className="content-title">{countryReport?.title}</div>
        </SidebarContent>
        <SidebarContent>
          <div>
            {countryReport && <CountryReportDocument countryReport={countryReport}  preview />}
          </div>
        </SidebarContent>
      </LoadingComponent>
    </Sidebar>
  );
}
