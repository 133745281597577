export const CitiesInfoList = ({
  cities
}: {
  cities?: string[] | JSX.Element[]
}): JSX.Element => {

  return (
    <>
      {cities && cities.length > 0 &&
        <>
          {cities[0]} {cities.length > 1 ? `and ${cities.length - 1}` : ''}
        </> 
      }
    </>
  )

}