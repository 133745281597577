import {useEffect, useMemo, useState} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'

import {MainContainer, PagerControl, TabbedNavContainer} from 'components'
import MapIcon from 'components/icons/MapIcon'
import ListIcon from 'components/icons/ListIcon'
import {ResetXIcon} from 'components/icons/ResetXIcon'

import {AlertApi, buildQueryParams} from 'apis'

import {AlertFilterForm, Pager, SearchGroupOptionsT} from 'models'

import {AlertFilters} from './AlertFilters'
import {AlertList} from './AlertList'
import {AlertMap} from './AlertMap'
import {AlertSidebar} from './AlertSidebar'
import {AlertMapSidebar} from './AlertMapSidebar'
import {alertToQueryReq, qsToAlertForm} from 'models/converters';
import {countAlertFilters, SessionStorage} from 'lib';
import {uniqueId} from 'lodash';
import {AlertPaths} from 'apps/alerts/Alerts';
import {NavDropdown} from 'components/NavDropdown';
import {useAppContext} from 'context';


export const AlertHome = ({
  searchBar,
}: {
  searchBar: JSX.Element,
}) => {

  const { session, user } = useAppContext()
  const location = useLocation()
  const history = useHistory()

  const view: AlertPaths = Object.values(AlertPaths).includes(location.pathname as AlertPaths)
    ? location.pathname as AlertPaths
    : AlertPaths.map

  const [previewId, setPreviewId] = useState<number>()
  const [previewLocation, setPreviewLocation] = useState<{
    id: number, name: string, count: number
  }>()

  const filter = useMemo<AlertFilterForm>(() =>
    qsToAlertForm(new URLSearchParams(location.search))
  , [location.search])

  const [filterCount, setFilterCount] = useState(countAlertFilters(filter))

  const [pager, setPager] = useState<Pager|undefined>()


  const filterPath = view === AlertPaths.list || view === AlertPaths.listFilters
    ? AlertPaths.listFilters + location.search
    : AlertPaths.mapFilters + location.search


  useEffect(() => {
    handleCloseSidebar()
  }, [view])


  const navigateToSearch = (newSearch: AlertFilterForm) => {
    const params = buildQueryParams(newSearch)
    history.push(`${AlertPaths.list}?${params}`)
  }

  const resetSearch = () => {
    const emptyFilter = qsToAlertForm(new URLSearchParams())
    SessionStorage.setCurrentAlertsFilter({...emptyFilter, view: (view === AlertPaths.listFilters || view === AlertPaths.list) ? 'list' : 'map'})
    history.push(`${view}?${buildQueryParams(emptyFilter, true)}&${uniqueId('_unq=')}`)
  }

  const setSearchGroup = (newSearchGroup: SearchGroupOptionsT) => {
    const curView = (view === AlertPaths.listFilters || view === AlertPaths.list) ? 'list' : 'map'
    SessionStorage.setCurrentAlertsFilter({
      ...filter,
      search_group: newSearchGroup as SearchGroupOptionsT,
      view: curView
    })
    const params = buildQueryParams({
      ...filter,
      search_group: newSearchGroup as SearchGroupOptionsT
    })
    history.push(`${view}?${params}`)
  }

  const pagerControl = view === AlertPaths.list && pager ? (
    <PagerControl
      total={+pager.total_records}
      page={+pager.page}
      pageSize={+pager.per_page}
      setPageSize={ per_page => navigateToSearch({...filter, per_page: `${per_page}`}) }
      setPage={ page => navigateToSearch({...filter, page: `${page}`}) }
      csvUrl={user.csvDownloadAllowed ? new AlertApi(session.token).downloadSearchLink(alertToQueryReq(filter)) : undefined}
    />
  ) : undefined

  const previewActive = !!previewId && (
    view !== AlertPaths.listFilters && view !== AlertPaths.mapFilters
  )
  const previewLocationActive = !!previewLocation && (
    view !== AlertPaths.listFilters && view !== AlertPaths.mapFilters
  )


  function handleCloseSidebar() {
    setPreviewId(undefined)
    setPreviewLocation(undefined)
  }

  return (
    <>
      <MainContainer
        rightSidebarActive={previewActive || previewLocationActive}
        searchBar={searchBar}
        pager={pagerControl}
      >
        <TabbedNavContainer
          tabs={[
            {
              active: view === AlertPaths.map,
              to: `${AlertPaths.map}${location.search}`,
              children: <><MapIcon />Map</>
            },
            {
              active: view === AlertPaths.list,
              to: `${AlertPaths.list}${location.search}`,
              children: <><ListIcon />List</>
            }
          ]}
          tabButtons={[
            {
              parentClassName: 'flex-fill col-12 col-sm-6',
              component: <NavDropdown
                title="Country"
                className="flex-fill"
                value={filter.search_group}
                setValue={val => setSearchGroup(val)}
                options={[
                  ['all', 'All'],
                  ['china', 'China'],
                  ['russia', 'Russia'],
                ]}
              />
            },
            {
              parentClassName: 'flex-fill col-6 col-sm-3',
              component: <Link to={filterPath}
                              className={'btn btn-sm text-uppercase btn-gray-light btn-filters w-100 ' + (view === AlertPaths.listFilters || view === AlertPaths.mapFilters ? ' active' : '')}
              >
                Filters
                { filterCount > 0 &&
                <span
                  className={'badge badge-primary ml-2'}>
                  {filterCount}
                </span>
                }
              </Link>
            },
            {
              parentClassName: 'flex-fill col-6 col-sm-3',
              component: <button
                type="button"
                className={`btn btn-sm btn-gray-light text-uppercase btn-reset w-100`}
                onClick={resetSearch}
              >
                Reset
                <ResetXIcon />
              </button>
            }
          ]}
          renderAll={view === AlertPaths.listFilters || view === AlertPaths.mapFilters}
          tabClassName="col-12 col-lg-5 col-xl-7"
        >
          {view === AlertPaths.map &&
            <AlertMap
              filter={filter}
              setPreviewLocation={setPreviewLocation}
              previewLocation={previewLocation}
            />
          }
          {view === AlertPaths.list &&
            <AlertList
              className="py-6 px-4"
              filter={filter}
              setPager={setPager}
              setPreviewId={setPreviewId}
              previewId={previewId}
            />
          }
          {(view === AlertPaths.listFilters || view === AlertPaths.mapFilters) &&
            <AlertFilters
              origFilter={filter}
              setFilterCount={setFilterCount}
            />
          }
        </TabbedNavContainer>
      </MainContainer>
      {previewId && previewActive &&
        <AlertSidebar
          id={previewId}
          onClose={() => setPreviewId(undefined)}
        />
      }
      {previewLocation && previewLocationActive &&
        <AlertMapSidebar
          filter={filter}
          location={previewLocation}
          locationType="cities"
          count={pager?.total_records || 0}
          onClose={(e) => setPreviewLocation(undefined)}
        />
      }
    </>
  )
}
