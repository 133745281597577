import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const DEFAULT_DATE_FORMAT = 'MMMM D, YYYY'
const DEFAULT_DATETIME_FORMAT = 'MMM D, YYYY h:mm A'
const DEFAULT_DATESHORT_FORMAT = 'YYYY.MM.DD'
const DEFAULT_PARSE = ['YYYY-MM-DD','YYYY-MM-DD HH:mm:ss Z']

interface DateViewParams {
  type?: string
  format?: string
  parse?: string
  children: string
}

export function DateView({
  type,
  format,
  parse,
  children
}: DateViewParams) {
  let defaultFormat = DEFAULT_DATETIME_FORMAT
  if (type === 'date') defaultFormat = DEFAULT_DATE_FORMAT
  if (type === 'dateshort') defaultFormat = DEFAULT_DATESHORT_FORMAT

  return (
    <>
      {dayjs(children, parse || DEFAULT_PARSE).format(format || defaultFormat)}
    </>
  )
}