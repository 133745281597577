import { useEffect, useState } from 'react'
import { useAppContext } from 'context'
import {buildQuery, buildQueryParams, EntityRiskTrackerApi} from 'apis'
import {
  Entity,
  Pager,
  EntityRiskResult,
  ErtFilterFormQs,
  ErrorDetail,
  SearchGroupOptionsT,
  TransactionFilterForm, SearchGroupOptions
} from 'models'
import { LoadingComponent, PagerControl } from 'components'
import { EntityTable } from './components/EntityTable'
import {ertToQueryReq} from 'models/converters';
import {useHistory, useLocation} from 'react-router-dom';
import {handleAsyncLoader, SessionStorage} from 'lib';
import {EntityTabbedNav} from 'apps/entityrisktracker/components/EntityRiskFilters';
import {uniqueId} from 'lodash';

export interface Filter {
  key: string,
  value: string
}

export function EntityList(
  {
    className,
    setEntityId,
    filter,
    previewId,
    Paths,
    filterCount,
  }: {
    className?: string
    setEntityId: (id: number) => void
    filter: ErtFilterFormQs
    previewId?: number
    Paths: { list: string, events: string, upload: string, filters: string }
    filterCount: number
  }
  ) {

  const { session } = useAppContext()

  const history = useHistory()
  const location = useLocation()

  const [list, setList] = useState<Entity[]>([])
  const [risks, setRisks] = useState<EntityRiskResult[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorDetail>()
  const [pager, setPager] = useState<Pager>({
    total_records: 0,
    total_pages: 0,
    per_page: 25,
    page: 1
  })



  useEffect(handleAsyncLoader(
    setLoading,
    setError,
    async () => {
      let searchResults = await new EntityRiskTrackerApi(session.token).search(ertToQueryReq(filter))
      setList(searchResults.results)
      setPager(searchResults.paginate)
      setRisks((await new EntityRiskTrackerApi(session.token).risks()))
    }), [filter])


  function perPageLink(newSize: number): string {
    const qs = buildQuery<ErtFilterFormQs>({...filter, per_page: `${newSize}`, page: '1'}, true)
    return location.pathname + qs;
  }

  function pageLink(newPage: number): string {
    const qs = buildQuery<ErtFilterFormQs>({...filter, page: `${newPage}`}, true)
    return location.pathname + qs;
  }


  function navigate(filter: ErtFilterFormQs) {
    SessionStorage.setCurrentERTFilter({...filter})

    history.replace(`${location.pathname}?${buildQueryParams<ErtFilterFormQs>(filter, true)}&${uniqueId('_unq=')}`)
  }

  const onSearchGroupChange = (newSearchGroup: SearchGroupOptionsT) => {
    const newFilter: ErtFilterFormQs = {
      ...filter,
      search_group: newSearchGroup,
    }
    navigate(newFilter)
  }


  return (
    <>
      <EntityTabbedNav
        filterCount={filterCount}
        Paths={Paths}
        searchGroup={filter.search_group ?? SearchGroupOptions[0]}
        setSearchGroup={onSearchGroupChange}
      />
      <LoadingComponent loading={loading} error={error}>
        <>
          <EntityTable
            className={className || ""}
            entities={list}
            risks={risks}
            onSelect={(id) => setEntityId(id)}
            previewId={previewId}
          />
          <PagerControl
            total={+pager.total_records}
            page={+pager.page}
            pageSize={+pager.per_page}
            setPageSize={pageSize => history.push(perPageLink(pageSize))}
            setPage={page => history.push(pageLink(page))}
          />
        </>
      </LoadingComponent>
    </>
  )

}
