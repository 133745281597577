import { useEffect, useRef, useState} from 'react'
import {LoadingComponent, setupMapboxMap} from 'components'
import { intelTrakApiUrl } from 'Config'
import { useAppContext } from 'context'
import mapboxgl from 'mapbox-gl';
import {buildQuery, buildQueryParams} from 'apis';
import {
  ClusterByOptionsT, ErrorDetail,
  ScaleOptionsT,
  SearchGroupOptionsT,
  TransactionFilterForm
} from 'models';
import {qsToTranForm, tranToQueryReq} from 'models/converters';
import {TransactionMapSubNav} from 'apps/transactions/components/TransactionMapSubNav';
import {TransactionFilterEditor} from 'apps/transactions/TransactionFilterEditor';
import {useHistory, useLocation} from 'react-router-dom';
import {countTranFilters, SessionStorage} from 'lib';
import {uniqueId} from 'lodash';
import {TranPaths} from 'apps/transactions/Transactions';
import {convertError} from 'lib/HandleAsyncLoader';

const TilesUrl = `${intelTrakApiUrl}/map/{z}/{x}/{y}.pbf?type=transactions`

const MapContainerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '300px',
}
const FillColor = '#f06c00'
const FillOpacity = 0.2
const SelectedOpacity = 0.9

const Source = 'transactions'
// https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#circle
const PolygonLayer: mapboxgl.AnyLayer = {
  'id': 'transactions',
  'type': 'circle',
  'source': 'transactions',
  'source-layer': 'transactions',
  'paint': {
    'circle-radius': [
      'case',
      // ['<=', ['get', 'count'], 1], 2,    // r = sqrt(8 * area), ie area = r^2 / 8
      // ['<=', ['get', 'count'], 2], 4,
      // ['<=', ['get', 'count'], 4], 6,  // hard to click on small things
      ['<=', ['get', 'count'], 8], 8,
      ['<=', ['get', 'count'], 12], 10,
      ['<=', ['get', 'count'], 25], 14,
      ['<=', ['get', 'count'], 50], 20,
      ['<=', ['get', 'count'], 112], 30,
      ['<=', ['get', 'count'], 200], 40,
      ['<=', ['get', 'count'], 312], 50,
      ['<=', ['get', 'count'], 450], 60,
      ['<=', ['get', 'count'], 612], 70,
      ['<=', ['get', 'count'], 800], 80,
      ['<=', ['get', 'count'], 1012], 90,
      100,


      // ['<=', ['get', 'count'], 5], 3,
      // ['<=', ['get', 'count'], 15], 5,
      // ['<=', ['get', 'count'], 50], 10,
      // ['<=', ['get', 'count'], 100], 20,
      // ['<=', ['get', 'count'], 250], 30,
      // ['<=', ['get', 'count'], 500], 40,
      // ['<=', ['get', 'count'], 800], 50,
      // ['<=', ['get', 'count'], 100], 60,
      // ['<=', ['get', 'count'], 1200], 80,
      // 60,

    ],
    'circle-color': FillColor,
    'circle-opacity': FillOpacity,
    'circle-stroke-color': FillColor,
    'circle-stroke-width': 1,
   }
}


export enum TranMapSubTab {
  Map,
  Filters
}

export interface TranGroup {
  id: number,
  name: string,
  count: number,
  baseFilter: TransactionFilterForm,
}


export function TransactionMap(
  {
    setPreviewGroup,
    previewGroup,
    filter,
  }: {
    setPreviewGroup: (v: TranGroup | undefined) => void,
    previewGroup?: TranGroup,
    filter: TransactionFilterForm,
  }
): JSX.Element {

  const { session } = useAppContext()

  const history = useHistory()
  const location = useLocation()

  const mapContainer = useRef(null)
  const map = useRef<mapboxgl.Map | null>(null)
  const [lng, setLng] = useState(0)
  const [lat, setLat] = useState(30)
  const [zoom, setZoom] = useState(1.6)

  const [error, setError] = useState<ErrorDetail>()


  const subTab = location.pathname === TranPaths.mapFilters
    ? TranMapSubTab.Filters
    : TranMapSubTab.Map

  const getSourceUrl = () => {
    const url = TilesUrl
    let query = buildQuery(tranToQueryReq(filter))
    if (query.startsWith('?'))
      query = query.substr(1)
    return url + '&' + query
  }

  const getSource: () => mapboxgl.AnySourceData = () => {
    return {
      'type': 'vector',
      'tiles': [getSourceUrl()]
    }
  }


  const highlightPreviewId = () => {
    if (map.current !== null)
      map.current.setPaintProperty('transactions', 'circle-opacity', [
        'match',
        ['get', 'id'],
        previewGroup?.id || -1,
        SelectedOpacity,
        FillOpacity
      ])
  }


  const loadSource = () => {
    if (map.current !== null) {
      if (map.current.getSource(Source)) {
        map.current.removeLayer(PolygonLayer.id)
        map.current.removeSource(Source)
      }
      map.current.addSource(Source, getSource())
      map.current.addLayer(PolygonLayer)
      highlightPreviewId()  // Highlight if selected
      map.current.resize()  // Call resize to resize when parent resizes
    }
  }


  useEffect(() => {
    SessionStorage.setCurrentTransactionsFilterView('map')
  }, [])

  useEffect(() => {
    const setupMap = () => {
      map.current = setupMapboxMap({
        session,
        container: mapContainer.current,
        center: [lng, lat],
        zoom: zoom,
        onLoad: loadSource,
        onMouseEnter: {
          layer: PolygonLayer.id,
          fn: () => map.current !== null && (map.current.getCanvas().style.cursor = 'pointer')
        },
        onMouseLeave: {
          layer: PolygonLayer.id,
          fn: () => map.current !== null && (map.current.getCanvas().style.cursor = '')
        },
        onClick: {
          layer: Source,
          fn: (e) => {
            if (e.features && e.features[0].properties !== null) {
              const properties = {
                id: e.features[0].properties.id,
                name: e.features[0].properties.name,
                count: e.features[0].properties.count,
              }

              setPreviewGroup({
                ...properties,
                baseFilter: filter
              })
            }
          }
        },
        onIdle: () => map.current !== null && map.current.resize,
        onError: e => setError(convertError(e)),
      })
    }
    if (subTab === TranMapSubTab.Map && map.current === null && mapContainer.current)
      setupMap() // initialize map only once

  }, [mapContainer.current, subTab])

  useEffect(() => {
    if (map.current !== null && map.current.isStyleLoaded()) {
      highlightPreviewId()
    }
  }, [previewGroup])

  useEffect(() => {
    if (map.current !== null && map.current.isStyleLoaded()) {
      loadSource()
    }
  }, [filter])

  useEffect(() => {
    map.current?.resize()
  }, [previewGroup])


  useEffect(() => {
    setPreviewGroup(undefined)
  }, [filter.clusterBy, filter.year])



  const year = filter.year === '' ? '' : parseInt(filter.year)

  const filterCount = countTranFilters(filter)



  function navigate(filter: TransactionFilterForm) {
    SessionStorage.setCurrentTransactionsFilter({...filter, view: 'map'})

    history.replace(`${location.pathname}?${buildQueryParams<TransactionFilterForm>(filter, true)}&${uniqueId('_unq=')}`)
  }

  function navigateToYear(yr: number | undefined) {
    const newFilter: TransactionFilterForm = {
      ...filter,
      year: typeof yr === 'undefined' ? '' : `${yr}`
    }
    navigate(newFilter)
  }

  function navigateToClusterBy(cl: ClusterByOptionsT) {
    const newFilter: TransactionFilterForm = {
      ...filter,
      clusterBy: cl
    }
    navigate(newFilter)
  }

  function navigateToScale(sc: ScaleOptionsT) {
    const newFilter: TransactionFilterForm = {
      ...filter,
      scale: sc
    }
    navigate(newFilter)
  }

  function navigateToSearchGroup(sg: SearchGroupOptionsT) {
    const newFilter: TransactionFilterForm = {
      ...filter,
      searchGroup: sg
    }
    navigate(newFilter)
  }

  function reset() {
    const emptyFilter = qsToTranForm(new URLSearchParams())
    navigate(emptyFilter)
  }

  return (
    <div style={{height: '100%'}} className={'d-flex flex-column'}>



      <>
        {subTab === TranMapSubTab.Map
        &&
        <>
          <TransactionMapSubNav
            filterCount={filterCount}
            subTab={subTab}
            year={year === '' ? undefined : year}
            setYear={navigateToYear}
            clusterBy={filter.clusterBy}
            setClusterBy={navigateToClusterBy}
            scale={filter.scale}
            setScale={navigateToScale}
            searchGroup={filter.searchGroup}
            setSearchGroup={navigateToSearchGroup}
            reset={reset}
          />
        </>}
        {subTab === TranMapSubTab.Filters
        && <TransactionFilterEditor origFilter={filter} showMapSubNav={true} />}


        <LoadingComponent loading={false} error={error}>
          <div ref={mapContainer}
               className="tran-map-container flex-fill"
               style={{
                 ...MapContainerStyle,
                 display: (subTab === TranMapSubTab.Filters ? 'none' : '')
               }}

          />
        </LoadingComponent>
      </>


    </div>
  )
}
